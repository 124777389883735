import React, { useState, useEffect } from 'react';
import ModalTemplate from 'templates/ModalTemplate';
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { getAllCustomerOrders } from 'services/apiRequests/orders/get';
import { setNewOrderManyFields } from 'services/store/actions/newOrder';
import { reasonsOfComplaint } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';
import { initZero } from 'services/utils/date';

// NEW ORDER -> USER

const orderTypes = [
  'Nowe zamówienie',
  'Materiał klienta',
  'Domówienie',
  'Poprawa (wina klienta)',
  'Reklamacja (wina DILLAK)',
];

const OrderType = ({ closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.auth.user);

  const [orders, setOrders] = useState([]);
  const [data, setData] = useState({
    orderType: undefined,
    number: undefined,
    reasonOfComplaint: undefined,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!user || !orders.length) {
      dispatch(setSpinner(true));
      getAllCustomerOrders(
        user._id,
        (orders) => {
          dispatch(setSpinner(false));
          if (orders && orders.length) setOrders(orders);
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd pobierania zamówień');
        },
      );
    }
  }, [user, orders]);

  useEffect(() => {
    setIsButtonDisabled(false);
    if (step === 0) {
      if (!data.orderType) setIsButtonDisabled(true);
      else if (
        data.orderType &&
        data.orderType.toLowerCase() === 'reklamacja (wina dillak)' &&
        !data.reasonOfComplaint
      )
        setIsButtonDisabled(true);
    } else if (step === 1) {
      if (!data.orderType || !data.number || !orders)
        setIsButtonDisabled(true);
    }
  }, [data, orders, step]);

  const handleData = (key, value) => setData({ ...data, [key]: value });
  const handleNext = () => {
    if (step === 0) {
      if (
        [
          'Domówienie',
          'Poprawa (wina klienta)',
          'Reklamacja (wina DILLAK)',
        ].includes(data.orderType)
      )
        setStep(step + 1);
      else handleSetOrder();
    } else {
      handleSetOrder();
    }
  };
  const handleSetOrder = () => {
    const fields = { orderType: data.orderType };
    if (data.reasonOfComplaint)
      fields.reasonOfComplaint = data.reasonOfComplaint;
    if (data.number) {
      fields.number = `${data.number}.${data.orderType[0]}`;
      orders.forEach((item) => {
        if (item.number === data.number) {
          fields.name = item.name;
          fields.handles = item.handles;
          fields.millings = item.millings;
          fields.veneer = item.veneer;
          fields.glassCase = item.glassCase;
          fields.color = item.color;
          fields.paintType = item.paintType;
          fields.paintStyle = item.paintStyle;
          fields.motherOrder = item._id;
        }
      });
    } else {
      const today = new Date();
      fields.number = `-/${initZero(today.getMonth() + 1)}`;
    }
    dispatch(setNewOrderManyFields({ ...fields }));
    closeModal();
  };

  return (
    <ModalTemplate
      title="Typ zamówienia"
      closeModal={closeModal}
      footer={
        <>
          <Button
            variant="success"
            disabled={isButtonDisabled}
            onClick={handleNext}
          >
            Dalej
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      {step === 0 ? (
        <OrderTypeCheck
          orderType={data.orderType}
          reasonOfComplaint={data.reasonOfComplaint}
          handleData={handleData}
        />
      ) : step === 1 ? (
        <OrderNumberCheck
          orders={orders}
          order={data.number}
          handleData={handleData}
        />
      ) : null}
    </ModalTemplate>
  );
};

export default OrderType;

const OrderNumberCheck = ({ orders, order, handleData }) => {
  return (
    <>
      <strong>Wybierz numer zamówienia</strong>
      <Form.Control
        as="select"
        onChange={(e) => handleData('number', e.target.value)}
        value={order}
      >
        <option value="" />
        {orders &&
          orders.map((order) => {
            if (!order.number.includes('.')) {
              return (
                <option key={order._id} value={order.number}>
                  {order.number}
                </option>
              );
            }
            return null;
          })}
      </Form.Control>
    </>
  );
};

const OrderTypeCheck = ({ reasonOfComplaint, orderType, handleData }) => {
  return (
    <>
      <strong>Wybierz typ zamówienia</strong>
      <Form.Group
        onChange={(e) => handleData('orderType', e.target.value)}
        value={orderType}
      >
        {orderTypes.map((item) => (
          <Form.Check
            custom
            key={item}
            value={item}
            type="radio"
            label={item}
            id={item}
            name="orderType"
          />
        ))}
      </Form.Group>
      {orderType &&
        orderType.toLowerCase() === 'reklamacja (wina dillak)' && (
          <>
            <strong>Przyczyna reklamacji</strong>
            <Form.Group
              onChange={(e) =>
                handleData('reasonOfComplaint', e.target.value)
              }
              value={reasonOfComplaint}
            >
              {reasonsOfComplaint.map((item) => (
                <Form.Check
                  custom
                  key={item}
                  value={item}
                  type="radio"
                  label={firstLetterToUpperCase(item)}
                  id={item}
                  name="reasonOfComplaint"
                />
              ))}
            </Form.Group>
          </>
        )}
    </>
  );
};
