import React from 'react';
import FlexRow from 'templates/FlexRowTemplate';
import { Button } from 'react-bootstrap';
import withContext from 'hoc/withContext';

const HistoryButtons = ({ order, permissionContext, setModal }) => {
 if (permissionContext === 'admin')
  return (
   <FlexRow justify="flex-start">
    <Button
     variant="outline-primary"
     disabled={
      !order.hasOwnProperty('updateHistory') || !order.updateHistory.length
     }
     onClick={() => setModal('_updateHistory')}
    >
     Historia zmian
    </Button>
    <Button
     variant="outline-info"
     disabled={
      !order.hasOwnProperty('productionHistory') ||
      !order.productionHistory.length
     }
     onClick={() => setModal('_productionHistory')}
    >
     Historia produkcji
    </Button>
    <Button
     variant="outline-secondary"
     disabled={
      !order.hasOwnProperty('productionPlan') || !order.productionPlan.length
     }
     onClick={() => setModal('_planHistory')}
    >
     Historia planowania
    </Button>
   </FlexRow>
  );
 return null;
};

export default withContext(HistoryButtons);
