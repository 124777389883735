import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { PageHeader } from 'components/header';
import { setHomePageKey, setSpinner } from 'services/store/actions/view';
import {
  getOrdersByStatus,
  clearOrdersList,
  getEndedOrders,
} from 'services/store/actions/orders';
import OrdersList from 'components/ordersList/static';
import { getCustomerMessages } from 'services/store/actions/auth';
import Filters from './components/Filters';
import EndedOrdersList from './components/EndedOrdersList';

const AdminHomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const key = useSelector(state => state.view.homePageKey);
  const orders = useSelector(state => state.orders);
  const filters = useSelector(state => state.orders.filters.ended);
  const { dateFrom, dateTo } = filters;
  const [isEndedLoading, setIsEndedLoading] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getCustomerMessages(user._id));
    }
    if (key === 'new') {
      if (!orders.newOrders) dispatch(setSpinner(true));
      dispatch(
        getOrdersByStatus(
          ['wysłane'],
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
          },
          'newOrders',
        ),
      );
    } else if (key === 'ended') {
      setIsEndedLoading(true);
      dispatch(
        getEndedOrders(
          dateFrom,
          dateTo,
          () => {
            setIsEndedLoading(false);
          },
          () => {
            setIsEndedLoading(false);
          },
        ),
      );
    }
    return () => {
      dispatch(clearOrdersList());
    };
  }, [key, dateFrom, dateTo]);

  return (
    <div>
      <PageHeader
        title="Zamówienia"
        extra={
          key === 'ended'
            ? [<Filters key="1" isEndedLoading={isEndedLoading} />]
            : []
        }
        buttonFormat={false}
      />

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={key => {
          dispatch(clearOrdersList());
          dispatch(setHomePageKey(key));
        }}
      >
        <Tab eventKey="new" title="Nowe">
          {key === 'new' && (
            <>
              <OrdersList
                orders={orders.newOrders}
                listName="new"
                summary
              />
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    width: 40,
                    height: 20,
                    border: '1px solid gray',
                    backgroundColor: '#ffd27d',
                  }}
                />{' '}
                -<small> Klient VIP. Zaplanuj zamówienie.</small>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey="ended" title="Odebrane">
          {key === 'ended' && (
            <EndedOrdersList
              orders={orders.endedOrders}
              listName="ended"
              pickUpDate
              paymentStatus
              summary
            />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminHomePage;
