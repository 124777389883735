export const theme = {
  dillakGreen: '#227a2e',
  dillakGreenLight: '#cde7a9',
  dillakPrimary: '#a4cfff',
  dillakPrimaryLight: '#b1cff1',
  dillakSuccess: '#85eb6a',
  dillakSuccessLight: '#b9faa7',
  dillakInfo: '#31b0c4',
  dillakInfoLight: '#45c5d9',
  dillakWarning: '#ffbd40',
  dillakWarningLight: '#fcd7a0',
  dillakDanger: '#fa3e54',
  dillakDangerLight: '#e7bcba',
  dillakDark: '#343a40',
  dillakLightGrey: '#f5f5f5',
  dillakPurple: '#e165b9',
  positions: {
    dillak: '#73a52e',
    piła: 'orange',
    surówka: '#ffbd40',
    podkład: '#f5f5f5',
    szlifiernia: '#33cec3',
    lakiernia: '#a4cfff',
    polernia: '#fa3e54',
    pakowanie: '#85eb6a',
    zakończone: '#e165b9',
    transport: 'gray',
  },
  items: {
    lost: 'rgba(231, 188, 186, 0.8)',
    toCorrect: 'rgba(204, 247, 179, 0.8)',
    long: 'rgba(255, 153, 0, 0.5)',
    notIncludedToPrice: 'rgba(177, 207, 241, 0.8)',
  },
};
