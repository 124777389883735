import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import ListRow from './ListRow';
import withContext from 'hoc/withContext';

const Th = styled.th`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.dillakGreen;
    if (secondary) return theme.dillakWarning;
  }};
  ${({ small }) => (small ? 'font-weight:normal;font-size:12px' : '')}
`;

const ItemsList = ({ order, permissionContext }) => {
  return (
    <Table responsive="xl" bordered hover size="sm">
      <thead>
        <tr>
          {permissionContext !== 'user' && (
            <Th width="50" rowSpan="4">
              <small>Piła</small>
            </Th>
          )}
          {['Materiał klienta', 'Poprawa (wina klienta)'].includes(
            order.orderType,
          ) && (
            <Th width="50" rowSpan={4}>
              <small>Materiał DILLAK</small>
            </Th>
          )}
          <Th width="50" rowSpan={4}>
            Lp
          </Th>
          <Th width="95" rowSpan="4">
            Typ
          </Th>
          <th colSpan="6">Wysokość</th>
          <th colSpan="6">Szerokość</th>
          <Th width="50" rowSpan="4">
            Grubość płyty
            <br />
            <small>[mm]</small>
          </Th>
          <Th width="50" rowSpan="4">
            Ilość
          </Th>
          <th rowSpan="2" colSpan={order.transparent ? 3 : 2}>
            Lakierowane strony
          </th>
          {order.muntinType && (
            <th rowSpan="2" colSpan={order.muntinType === '+' ? 2 : 1}>
              Szprosy
            </th>
          )}
          <th rowSpan="4">Uwagi</th>
          <th rowSpan="2" colSpan="2">
            Powierzchnia
            <br />
            <small>
              [m<sup>2</sup>]
            </small>
          </th>
          {permissionContext !== 'user' && (
            <>
              <Th width={50} rowSpan="4">
                <small>Brak</small>
              </Th>
              <Th width={50} rowSpan="4">
                <small>Popr.</small>
              </Th>
            </>
          )}
        </tr>

        <tr>
          <Th width="50" rowSpan="2">
            wys.
            <br />
            <small>[mm]</small>
          </Th>
          <td colSpan="2">Uchwyt frezowany/R</td>
          <td colSpan="2">Otw. pod zaw./R</td>
          <Th width="20" secondary rowSpan="2" small>
            <div>Lakier 100mm po wys</div>
          </Th>
          <Th width="50" rowSpan="2">
            szer.
            <br />
            <small>[mm]</small>
          </Th>
          <td colSpan="2">Uchwyt frezowany/R</td>
          <td colSpan="2">Otw. pod zaw./R</td>
          <Th width="20" secondary rowSpan="2" small>
            <div>Lakier 100mm po szer</div>
          </Th>
        </tr>

        <tr>
          <Th width={50} primary>
            W1
          </Th>
          <Th width={50} primary>
            W2
          </Th>
          <Th width={50} secondary>
            W1'
          </Th>
          <Th width={50} secondary>
            W2'
          </Th>
          <Th width={50} primary>
            S1
          </Th>
          <Th width={50} primary>
            S2
          </Th>
          <Th width={50} secondary>
            S1'
          </Th>
          <Th width={50} secondary>
            S2'
          </Th>
          <Th width={50} primary>
            P
          </Th>
          <Th width={50} secondary>
            L
          </Th>
          {order.muntinType === 'x' && (
            <Th
              style={{ fontSize: 12 }}
              title="Ilość szprosów w kszt. X"
              width={50}
            >
              Ilość [X]
            </Th>
          )}
          {order.muntinType === '+' && (
            <>
              <Th
                style={{ fontSize: 12 }}
                title="Ilość szprosów PIONOWYCH"
                width={50}
              >
                Ilość |{' '}
              </Th>
              <Th
                style={{ fontSize: 12 }}
                title="Ilość szprosów POZIOMYCH"
                width={50}
              >
                Ilość -{' '}
              </Th>
            </>
          )}
          {order.transparent && (
            <Th
              width={50}
              secondary
              style={{ fontSize: 12, fontWeight: 'normal' }}
            >
              Bezb. 2str
            </Th>
          )}
          <Th width={50} primary>
            PL
          </Th>
          <Th width={50} secondary>
            PP
          </Th>
        </tr>
      </thead>
      <tbody>
        {order.items.map((item, index) =>
          item !== null ? (
            <ListRow
              item={item}
              order={order}
              index={index}
              key={item._id || Math.random()}
            />
          ) : null,
        )}
      </tbody>
    </Table>
  );
};

export default withContext(ItemsList);
