/* eslint-disable no-nested-ternary */
import React from 'react';
import { dateToString } from 'services/utils/date';
import styled from 'styled-components';
import { weekDays } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router';

const Wrapper = styled.div`
 margin: 20px auto 40px auto;
 width: 90vw;
`;
const Top = styled.div`
 border-bottom: 1px solid gray;
 display: flex;
 justify-content: space-between;
 padding: 0 3px;
 color: ${({ day }) =>
  day === 'sobota' ? 'green' : day === 'niedziela' ? 'red' : 'black'};
`;

const Day = ({ day }) => {
 const history = useHistory();
 const date = new Date(day.date);
 const dayName = weekDays[date.getDay()];

 const redirect = id => history.push(`/order/${id}`);
 return (
  <Wrapper>
   <Top day={dayName}>
    <span>{dateToString(date)}</span>
    <strong>{firstLetterToUpperCase(dayName)}</strong>
   </Top>
   {day.orders.length ? (
    <Orders orders={day.orders} handleRedirect={redirect} />
   ) : null}
  </Wrapper>
 );
};

export default Day;

const Orders = ({ orders, handleRedirect }) => {
 return (
  <Table bordered>
   <thead>
    <tr>
     <th>Lp</th>
     <th>Klient</th>
     <th>Nr</th>
     <th>Elem</th>
     <th>Cena</th>
    </tr>
   </thead>
   <tbody>
    {orders.map(({ order }, i) => {
     const price =
      order.price +
      (order.ventilations.price || 0) +
      (order.manHours.price || 0);
     return (
      <tr
       style={{ height: 50, cursor: 'pointer' }}
       key={order._id}
       onClick={() => handleRedirect(order._id)}
      >
       <td>{i + 1}</td>
       <td>{order.user.company}</td>
       <td>{order.number.toUpperCase()}</td>
       <td>{order.elements}</td>
       <td>
        <small>{price ? price.toFixed(2) : 0}</small>
       </td>
      </tr>
     );
    })}
   </tbody>
  </Table>
 );
};
