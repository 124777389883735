/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
import { weekDays } from 'services/utils/const';
import { isDatesEqual } from 'services/utils/date';
import { getOrderCustomerName } from 'services/utils/orders';
import { firstLetterToUpperCase } from 'services/utils/string';

export const getScheduleDates = timetables => {
 let dates = [];

 const positions = Object.keys(timetables);

 for (let i = 0; i < positions.length; i++) {
  const position = positions[i];
  const timetable = timetables[position];

  if (!dates.length) {
   dates = timetable.map(day => ({
    date: day.date,
    name: firstLetterToUpperCase(weekDays[new Date(day.date).getDay()]),
   }));
  }
  break;
 }
 return dates;
};

export const getOrdersFromSchedules = (timetables, dates) => {
 const positions = Object.keys(timetables);
 let schedules = [];
 let orders = [];

 const createScheduleArr = () => {
  const arr = [];
  for (let i = 0; i < 28; i++)
   arr[i] = { dayName: dates[i].name, positions: [] };
  return arr;
 };

 for (let i = 0; i < positions.length; i++) {
  const position = positions[i];
  const timetable = timetables[position];

  const schedule = timetable.filter(item => item.orders.length);
  schedules = schedules.concat(schedule);
 }

 for (let i = 0; i < schedules.length; i++) {
  const schedule = schedules[i];

  schedule.orders.forEach(({ order }) => {
   if (order) {
    const isExist = orders.find(o => o.orderId === order._id);

    if (!isExist)
     orders.push({
      order,
      orderId: order._id,
      customerId: order.user._id,
      customerName: getOrderCustomerName(order),
      orderName: `${getOrderCustomerName(order)}.${order.number}`,
      schedule: createScheduleArr(),
     });
   }
  });
 }
 orders = orders.sort((a, b) => `${a.orderName}`.localeCompare(b.orderName));

 return orders;
};

export const getOrdersSchedules = timetables => {
 const dates = getScheduleDates(timetables);
 const orders = getOrdersFromSchedules(timetables, dates);
 const positions = Object.keys(timetables);
 let schedules = [];

 const result = [];

 for (let i = 0; i < positions.length; i++) {
  const position = positions[i];
  const timetable = timetables[position];

  const schedule = timetable.filter(item => item.orders.length);
  schedules = schedules.concat(schedule);
 }

 for (let i = 0; i < orders.length; i++) {
  const order = { ...orders[i] };

  schedules.forEach(schedule => {
   const isExist = schedule.orders.find(
    o => o && o.order && order.orderId === o.order._id,
   );
   if (isExist) {
    const dateIndex = dates.findIndex(date =>
     isDatesEqual(date.date, schedule.date),
    );
    order.schedule[dateIndex].positions.push(schedule.position);
   }
  });

  result.push(order);
 }

 const customersList = orders
  .map(({ customerId, customerName }) => ({ customerName, customerId }))
  .reduce((customers, current) => {
   const isExist = customers.find(
    item => item.customerId === current.customerId,
   );
   if (!isExist) return [...customers, current];

   return customers;
  }, []);

 return { schedules: result, dates, customersList };
};
