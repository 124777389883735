import React from 'react';
import withContext from 'hoc/withContext';

const Color = ({ order, permissionContext }) => {
 return (
  <td
   style={
    order.isBlackBase && permissionContext !== 'user'
     ? { backgroundColor: 'black', color: 'white' }
     : {}
   }
  >
   {order.color.toUpperCase()}
  </td>
 );
};

export default withContext(Color);
