import React from 'react';
import Modal from 'templates/ModalTemplate';
import { useHistory } from 'react-router';

const ConfirmModal = ({ closeModal }) => {
 const history = useHistory();

 const handleClose = () => {
  history.push('/login');
  closeModal();
 };
 return (
  <Modal title="Odzyskiwanie konta" closeModal={handleClose} onOk={handleClose}>
   Jeżeli adres email jest prawidłowy, zostanie wysłana wiadomość z nowym
   hasłem.
  </Modal>
 );
};

export default ConfirmModal;
