import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import FlexRow from 'templates/FlexRowTemplate';
import Modal from 'templates/ModalTemplate';
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  setNewOrderPaintType,
  setNewOrderPaintStyle,
  removeNewOrderHandle,
  removeNewOrderMilling,
  setNewOrderColor,
  setNewOrderVeneer,
  setNewOrderGlassCase,
  setNewOrderFelc,
  setNewOrderNut,
  setNewOrderChamfering,
  setNewOrderField,
  setNewOrderMuntinType,
} from 'services/store/actions/newOrder';
import {
  faTrash,
  faQuestion,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'scenes/Catalog/Colors';
import Handles from 'scenes/Catalog/Handles';
import Millings from 'scenes/Catalog/Millings';
import Veneers from 'scenes/Catalog/Veneers';
import GlassCases from 'scenes/Catalog/GlassCases';
import { containsOneOf } from 'services/utils/array';
import BackMillingModal from './BackMillingModal';

const ItemWrapper = styled.div`
  text-align: center;
  position: relative;
`;
const Select = styled.select`
  border-radius: 5px;
  padding: 3px 5px;
  width: 90%;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
  color: red;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const OrderData = ({ order }) => {
  const dispatch = useDispatch();
  const [catalogModal, setCatalogModal] = useState(null);

  const [modal, setModal] = useState(false);

  const handleCloseModal = () => setCatalogModal(null);
  const handleRemoveColor = () => {
    dispatch(setNewOrderColor(''));
    dispatch(setNewOrderField('transparent', false));
  };
  const handleRemoveVeneer = () => dispatch(setNewOrderVeneer(''));
  const handleRemoveGlassCase = () => dispatch(setNewOrderGlassCase(''));

  const handleCheck = (type, value) => {
    if (type === 'isFelc') return dispatch(setNewOrderFelc(value));
    if (type === 'isNut') return dispatch(setNewOrderNut(value));
    if (type === 'isChamfering')
      return dispatch(setNewOrderChamfering(value));
    if (type === 'transparent')
      return dispatch(setNewOrderField('transparent', value));
  };

  const handleMuntin = value => dispatch(setNewOrderMuntinType(value));
  return (
    <Fade left>
      <>
        <FlexRow justify="space-between">
          <ColorItem
            order={order}
            handleClick={() =>
              setCatalogModal(<Colors closeModal={handleCloseModal} />)
            }
            handleRemove={handleRemoveColor}
            handleSelect={value => dispatch(setNewOrderPaintType(value))}
            handleCheck={handleCheck}
          />
          <PaintStyleItem
            order={order}
            handleSelect={value => dispatch(setNewOrderPaintStyle(value))}
          />
          <ArrayItem
            title="Uchwyty"
            values={order.handles}
            handleClick={() =>
              setCatalogModal(<Handles closeModal={handleCloseModal} />)
            }
            handleRemove={handle => dispatch(removeNewOrderHandle(handle))}
          />
          <MillingItem
            order={order}
            handleClick={() =>
              setCatalogModal(<Millings closeModal={handleCloseModal} />)
            }
            handleRemove={milling =>
              dispatch(removeNewOrderMilling(milling))
            }
            handleMuntin={handleMuntin}
          />
          <GlassCaseItem
            order={order}
            handleClick={() =>
              setCatalogModal(<GlassCases closeModal={handleCloseModal} />)
            }
            handleRemove={handleRemoveGlassCase}
            handleMuntin={handleMuntin}
          />
          <SingleItem
            title="Fornir"
            value={order.veneer}
            handleClick={() =>
              setCatalogModal(<Veneers closeModal={handleCloseModal} />)
            }
            handleRemove={handleRemoveVeneer}
            disabled={
              containsOneOf(order.handles, ['uk', 'up', 'uc']) ||
              (order.millings.length &&
                !order.millings.includes('ryflowany')) ||
              (order.glassCase && order.glassCase !== 'w4')
            }
          />
          <BackMilling
            order={order}
            handleCheck={handleCheck}
            setModal={() => setModal(true)}
          />
        </FlexRow>

        {/* MODALS */}
        {catalogModal && (
          <Modal
            title="Katalog"
            size="xl"
            footer={<div />}
            closeModal={handleCloseModal}
          >
            <div style={{ maxHeight: '75vh', overflowY: 'scroll' }}>
              {catalogModal}
            </div>
          </Modal>
        )}
        {modal && <BackMillingModal closeModal={() => setModal(false)} />}
      </>
    </Fade>
  );
};

export default OrderData;

const MillingItem = ({
  order,
  handleClick,
  handleRemove,
  disabled,
  handleMuntin,
}) => {
  return (
    <ItemWrapper>
      <strong>Frezowanie</strong>
      <div>
        {order.millings.map(item => (
          <FlexRow justify="space-between" key={item}>
            {item.toUpperCase()}{' '}
            <RemoveIcon
              icon={faTrash}
              onClick={() => handleRemove(item)}
            />
          </FlexRow>
        ))}
      </div>
      <Button
        variant="info"
        size="sm"
        onClick={handleClick}
        disabled={disabled}
      >
        Wybierz
      </Button>
      {order.millings.length ? (
        <div>
          <div>
            <Form.Check
              style={{ marginTop: 10 }}
              checked={order.muntinType === '+'}
              type="checkbox"
              label={
                <span>
                  Szpros <FontAwesomeIcon icon={faPlus} />
                </span>
              }
              id="muntinType"
              name="muntinType"
              onChange={e => handleMuntin(e.target.checked ? '+' : null)}
            />
          </div>
        </div>
      ) : null}
    </ItemWrapper>
  );
};

const GlassCaseItem = ({
  order,
  handleClick,
  handleRemove,
  handleMuntin,
}) => {
  return (
    <ItemWrapper>
      <strong>Witryna</strong>
      <div>
        {order.glassCase && (
          <FlexRow justify="space-between">
            {order.glassCase.toUpperCase()}{' '}
            <RemoveIcon
              icon={faTrash}
              onClick={() => handleRemove(null)}
            />
          </FlexRow>
        )}
      </div>
      <Button variant="info" size="sm" onClick={handleClick}>
        Wybierz
      </Button>
      {order.glassCase && (
        <div>
          <Form.Check
            style={{ marginTop: 10 }}
            checked={order.muntinType === '+'}
            type="checkbox"
            label={
              <span>
                Szpros <FontAwesomeIcon icon={faPlus} />
              </span>
            }
            id="muntinType+"
            name="muntinType"
            onChange={e => handleMuntin(e.target.checked ? '+' : null)}
          />
          <Form.Check
            style={{ marginTop: 10 }}
            checked={order.muntinType === 'x'}
            type="checkbox"
            label={
              <span>
                Szpros <FontAwesomeIcon icon={faTimes} />
              </span>
            }
            id="muntinTypex"
            name="muntinType"
            onChange={e => handleMuntin(e.target.checked ? 'x' : null)}
          />
        </div>
      )}
    </ItemWrapper>
  );
};

const BackMilling = ({ order, handleCheck, setModal }) => {
  return (
    <ItemWrapper>
      <strong>Frezowanie pod plecy</strong>
      <div>
        <Form.Check
          style={{ marginBottom: 10 }}
          checked={order.isChamfering}
          type="checkbox"
          label="Gierunek"
          id="gierunek"
          name="gierunek"
          onChange={e => handleCheck('isChamfering', e.target.checked)}
        />
        <Form.Check
          style={{ marginBottom: 10 }}
          checked={order.isFelc}
          type="checkbox"
          label="Felc"
          id="felc"
          name="felc"
          onChange={e => handleCheck('isFelc', e.target.checked)}
        />
        <Form.Check
          style={{ marginBottom: 10 }}
          checked={order.isNut}
          type="checkbox"
          label="Nut"
          id="nut"
          name="nut"
          onChange={e => handleCheck('isNut', e.target.checked)}
        />
      </div>
      <FontAwesomeIcon
        icon={faQuestion}
        onClick={() => setModal(true)}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          bottom: 0,
          right: 0,
          opacity: 0.3,
        }}
      />
    </ItemWrapper>
  );
};

const SingleItem = ({
  title,
  value,
  handleClick,
  handleRemove,
  disabled,
}) => (
  <ItemWrapper>
    <strong>{title}</strong>
    <div>
      {value && (
        <FlexRow justify="space-between">
          <span>{value}</span>
          <RemoveIcon icon={faTrash} onClick={handleRemove} />
        </FlexRow>
      )}
    </div>
    <Button
      variant="info"
      size="sm"
      onClick={handleClick}
      disabled={disabled}
    >
      Wybierz
    </Button>
  </ItemWrapper>
);

const ArrayItem = ({
  title,
  values,
  handleClick,
  handleRemove,
  disabled,
}) => (
  <ItemWrapper>
    <strong>{title}</strong>
    <div>
      {values.map(item => (
        <FlexRow justify="space-between" key={item}>
          {item.toUpperCase()}{' '}
          <RemoveIcon icon={faTrash} onClick={() => handleRemove(item)} />
        </FlexRow>
      ))}
    </div>
    <Button
      variant="info"
      size="sm"
      onClick={handleClick}
      disabled={disabled}
    >
      Wybierz
    </Button>
  </ItemWrapper>
);

const PaintStyleItem = ({ order, handleSelect }) => (
  <ItemWrapper>
    <strong>Rodzaj lakierowania</strong>
    <div>
      <Select
        value={order.paintStyle}
        onChange={e => handleSelect(e.target.value)}
      >
        <option value="jednostronne">Jednostronne</option>
        <option value="dwustronne">Dwustronne</option>
        <option value="prawa - połysk / lewa - półmat">
          Prawa - połysk / Lewa - półmat
        </option>
      </Select>
    </div>
  </ItemWrapper>
);

const ColorItem = ({
  order,
  handleClick,
  handleRemove,
  handleSelect,
  handleCheck,
}) => (
  <ItemWrapper>
    <strong>Kolor</strong>
    <FlexRow justify="space-between">
      {order.color && (
        <>
          <span>{order.color.toUpperCase()}</span>
          <RemoveIcon icon={faTrash} onClick={handleRemove} />
        </>
      )}
    </FlexRow>
    <Button variant="info" size="sm" onClick={handleClick}>
      Wybierz
    </Button>
    <div>
      <Select
        value={order.paintType}
        onChange={e => handleSelect(e.target.value)}
      >
        <option value="półmat">Półmat</option>
        <option value="połysk">Połysk</option>
        <option value="mat">Mat</option>
      </Select>
    </div>
    {order.color && order.color.toLowerCase() !== 'bezbarwny' && (
      <div>
        <Form.Check
          style={{ marginTop: 10 }}
          checked={order.transparent}
          type="checkbox"
          label="+Bezbarwny"
          id="transparent"
          name="transparent"
          onChange={e => handleCheck('transparent', e.target.checked)}
        />
      </div>
    )}
  </ItemWrapper>
);
