import React from 'react';
import Fade from 'react-reveal/Fade';
import { firstLetterToUpperCase } from 'services/utils/string';
import styled from 'styled-components';
import FlexRow from 'templates/FlexRowTemplate';
import { dateToString } from 'services/utils/date';

const ItemWrapper = styled.div`
 text-align: center;
`;

const OrderProperties = ({ order }) => {
 return (
  <Fade right>
   <FlexRow justify="space-between">
    <Item name="nazwa klienta" value={order.userName || '-'} />
    <Item
     name="numer"
     value={
      typeof order.number === 'string'
       ? order.number.toUpperCase()
       : order.number || '-'
     }
    />
    <Item
     name="Typ zamówienia"
     value={firstLetterToUpperCase(order.orderType)}
    />
    <Item name="data" value={dateToString(order.date)} />
    <Item
     name="przewidywana data realizacji"
     value={order.finishDate ? dateToString(order.finishDate) : '-'}
    />
   </FlexRow>
  </Fade>
 );
};

export default OrderProperties;

const Item = ({ name, value }) => (
 <ItemWrapper>
  <div>
   <strong>{firstLetterToUpperCase(name)}</strong>
  </div>
  <div>{value}</div>
 </ItemWrapper>
);
