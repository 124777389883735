export const filterCallback = (item, search) =>
  item.name
    .toLowerCase()
    .replace(/[\s-]/g, '')
    .indexOf(search.toLowerCase().replace(/[/\s-]/g, '')) !== -1;

export const sortFrontsByPricingGroup = fronts => {
  const result = { 1: [], 2: [], 3: [], 4: [] };

  if (fronts && fronts.length) {
    fronts.forEach(front => result[front.pricingGroup].push(front));
  }
  return result;
};
