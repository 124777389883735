import React, { useState } from 'react';
import { StyledH3 } from 'components/header';
import TextInput from 'components/FormInputs/TextInput';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';
import FlexRow from 'templates/FlexRowTemplate';
import styled from 'styled-components';
import * as yup from 'yup';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firstLetterToUpperCase } from 'services/utils/string';
import Select from 'components/FormInputs/Select';

const Wrapper = styled.div`
 max-width: 500px;
`;
const List = styled.div`
 max-height: 600px;
 width: 300px;
 overflow-y: auto;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
 color: red;
 cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
 opacity: 0.2;
 &:hover {
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
 }
`;

const Producers = ({ producers, handleSubmit }) => {
 const [isEdit, setIsEdit] = useState(false);
 const [producer, setProducer] = useState('');

 const removeItem = (producer, arr, arrName, setFieldValue) => {
  const newArr = arr.filter(item => item !== producer);
  setFieldValue(arrName, newArr);
 };
 const addItem = (arr, arrName, setFieldValue) => {
  const newArr = arr.concat(producer.toLowerCase());
  setFieldValue(arrName, newArr);
  setProducer('');
 };
 return (
  <div>
   <Formik
    validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async values =>
     await handleSubmit(
      { paintProducers: values },
      () => setIsEdit(false),
      'Zaktualizowano producentów',
     )
    }
    initialValues={{
     ...producers,
    }}
    render={props => {
     const { values, handleSubmit, setFieldValue } = props;
     return (
      <Form noValidate onSubmit={handleSubmit}>
       <Wrapper>
        <StyledH3>Producenci</StyledH3>
        {values.producers && values.producers.length ? (
         <>
          <List>
           <ul>
            {values.producers
             .sort((a, b) => a.localeCompare(b))
             .map(item => (
              <li key={item}>
               <FlexRow justify="space-between">
                {firstLetterToUpperCase(item)}{' '}
                <RemoveIcon
                 disabled={!isEdit}
                 title="Usuń"
                 icon={faMinusCircle}
                 onClick={
                  !isEdit
                   ? null
                   : () => {
                      removeItem(
                       item,
                       values.producers,
                       'producers',
                       setFieldValue,
                      );
                     }
                 }
                />
               </FlexRow>
              </li>
             ))}
           </ul>
          </List>
         </>
        ) : null}{' '}
        {isEdit && (
         <InputGroup style={{ width: 400 }}>
          <FormControl
           placeholder="Nazwa"
           value={producer}
           onChange={e => setProducer(e.target.value)}
          />
          <InputGroup.Append>
           <Button
            variant="outline-primary"
            onClick={() =>
             addItem(values.producers, 'producers', setFieldValue)
            }
           >
            Dodaj
           </Button>
          </InputGroup.Append>
         </InputGroup>
        )}
        <StyledH3>Domyślni producenci</StyledH3>
        <strong>Połysk</strong>{' '}
        <Select
         style={{ marginBottom: 5, width: 300 }}
         items={values.producers}
         value={values.gloss}
         onChange={({ target: { value } }) => setFieldValue('gloss', value)}
        />
        <strong>Półmat</strong>
        <Select
         style={{ marginBottom: 5, width: 300 }}
         items={values.producers}
         value={values.semiGloss}
         onChange={({ target: { value } }) => setFieldValue('semiGloss', value)}
        />
        <strong>
         Podkład <small>Połysk</small>
        </strong>
        <Select
         style={{ marginBottom: 5, width: 300 }}
         items={values.producers}
         value={values.baseGloss}
         onChange={({ target: { value } }) => setFieldValue('baseGloss', value)}
        />
        <strong>
         Podkład <small>Półmat</small>
        </strong>
        <Select
         style={{ marginBottom: 5, width: 300 }}
         items={values.producers}
         value={values.baseSemiGloss}
         onChange={({ target: { value } }) =>
          setFieldValue('baseSemiGloss', value)
         }
        />
        <FlexRow justify="flex-end">
         <Button variant="success" type="submit" disabled={!isEdit}>
          Zatwierdź
         </Button>
         <Button
          variant={isEdit ? 'outline-secondary' : 'secondary'}
          onClick={() => setIsEdit(!isEdit)}
         >
          {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
         </Button>
        </FlexRow>
       </Wrapper>
      </Form>
     );
    }}
   />
  </div>
 );
};

export default Producers;

const schema = yup.object({
 gloss: yup.string().required('wymagane'),
 semiGloss: yup.string().required('wymagane'),
 base: yup.string().required('wymagane'),
});
