export const setFinishDate = (state, terms) => {
  const { veneer, milling, gloss, semiGloss } = terms;
  let term = semiGloss;
  if (state.millings.length) term = milling;
  else if (state.veneer) term = veneer;
  else if (state.paintType === 'połysk') term = gloss;

  const newDate = new Date();
  const finishDate = newDate.setDate(newDate.getDate() + term);

  return { ...state, finishDate: new Date(finishDate) };
};
