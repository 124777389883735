import React from 'react';
import Modal from 'templates/ModalTemplate';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setSpinner } from 'services/store/actions/view';
import { setOrder } from 'services/store/actions/orders';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';

const StartOrder = ({ order, position, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();

 const handleStart = async () => {
  dispatch(setSpinner(true));
  await updateOrder(
   'start',
   order._id,
   { position },
   (order) => {
    dispatch(setOrder(order));
    dispatch(setSpinner(false));
    alert.success('Rozpoczęto wykonywanie zamówienia');
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
    closeModal();
   },
  );
 };

 return (
  <Modal title="Start" closeModal={closeModal} onOk={handleStart}>
   Czy chcesz rozpocząć zamówienie?
  </Modal>
 );
};

export default StartOrder;
