import React from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  padding: 20px 20px 0 20px;
  @media (max-width: 768px) {
    padding: 20px 10px 0 10px;
  }
`;

const PageTemplate = ({ children }) => {
  const { token } = localStorage;
  if (!token) return <Redirect to="/login" />;
  return <StyledWrapper>{children}</StyledWrapper>;
};

export default PageTemplate;
