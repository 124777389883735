import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
 display: flex;
 flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'no-wrap')};
 flex-direction: ${({ direction }) => direction};
 justify-content: ${({ justify }) => justify};
 button {
  margin: 5px 5px 10px 5px;
  padding: ${({ direction }) => (direction === 'column' ? '10px' : '5px')} 30px;
  &.react-datepicker__navigation {
   margin: 0px;
   padding: 0px;
  }
 }
 @media (max-width: 600px) {
  flex-direction: column;
  justify-content: 'center';
  button {
   width: 100%;
   margin: 10px 0;
  }
 }
`;

const FlexRow = ({ children, justify = 'center', direction = 'row', wrap }) => (
 <StyledRow justify={justify} direction={direction} isWrap={wrap}>
  {children}
 </StyledRow>
);

export default FlexRow;
