import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Gallery } from 'react-grid-gallery';
import { getFileExtension } from 'services/utils/files';
import { useLocation, useHistory } from 'react-router';
import { domain } from 'services/utils/const';
import useLightbox from 'hooks/useLightbox';
// import { downloadPdfImage } from 'utils/apiHandlers/orders/get';
// import { setSpinner } from 'actions/view';

const StyledImg = styled.img`
  width: 150px;
  height: 150px;
  cursor: pointer;
`;

const Images = ({ order }) => {
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState([]);
  const { openImage, lightbox } = useLightbox(images);

  useEffect(() => {
    let imgs = [],
      pdfs = [];
    //  get custom milling image
    getImages(
      order.customMilling.path,
      'Rodzaj frezowania',
      (image) => imgs.push(image),
      (newPdf) => pdfs.push(newPdf),
    );

    // get elements images
    order.items.forEach((item, index) => {
      getImages(
        item.image.path,
        `Rysunek do pozycji nr: ${index + 1}`,
        (image) => imgs.push(image),
        (newPdf) => pdfs.push(newPdf),
      );
    });
    setImages(imgs);
    setPdf(pdfs);
  }, [order]);

  console.log(images);

  return (
    <div>
      {location.pathname.includes('summary') &&
      (images.length > 0 || pdf.length > 0) ? (
        <h5>
          Wszystkie zdjęcia zostaną wyświetlone po złożeniu zamówienia
        </h5>
      ) : (
        <>
          <Gallery images={images} onClick={openImage} margin={5} />
          {pdf.length > 0 &&
            pdf.map((item) => {
              return (
                <span key={item.src}>
                  <a href={`${domain}${item.src}`}>
                    <StyledImg
                      src={require(`assets/images/pdf-icon.png`)}
                      alt="Rysunek"
                      title={item.title}
                    />
                  </a>
                </span>
              );
            })}
        </>
      )}
      {lightbox}
    </div>
  );
};

export default Images;

const getImages = (path, caption, setImages, setPdf) => {
  if (path) {
    const ext = getFileExtension(path);
    if (ext !== 'pdf') {
      const image = createImage(path, caption);

      setImages(image);
    } else {
      const newPdf = {
        src: path,
        title: caption,
      };
      setPdf(newPdf);
    }
  }
};

const createImage = (path, caption) => ({
  src: path,
  thumbnail: path,
  thumbnailWidth: 150,
  thumbnailHeight: 150,
  caption: caption,
  alt: 'Brak zdjęcia w bazie danych. Zdjęcie pojawi się po złożeniu zamówienia.',
});
