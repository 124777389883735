import { Button } from 'react-bootstrap';
import { PageHeader } from 'components/header';
import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getCatalog, removeFront } from 'services/store/actions/catalog';
import { useAlert } from 'react-alert';
import { useModal } from 'hooks/useModal';

import AddMillingModal from '../components/AddMillingModal/AddMillingModal';
import { sortFrontsByPricingGroup } from '../utils';
import PricingGroup from '../components/MillingPricingGroup/PricingGroup';
import { setNewOrderGlassCase } from 'services/store/actions/newOrder';

const initModals = {
  addFront: false,
};

const GlassCases = ({
  permissionContext,
  closeModal: closeNewOrderModal,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { modal, showModal, closeModal } = useModal(initModals);
  const glassCases = useSelector(state => state.catalog.glassCases);
  const newOrder = useSelector(state => state.newOrder);
  const [frontToUpdate, setGlassCaseToUpdate] = useState(null);

  const [glassCasesToDisplay, setGlassCasesToDisplay] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
  });

  useEffect(() => {
    if (!glassCases.length) dispatch(setSpinner(true));
    dispatch(
      getCatalog(
        () => {},
        () => {
          alert.error('Błąd pobierania katalogu');
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (glassCases) {
      let newGlassCases = glassCases;
      if (
        newOrder.veneer ||
        newOrder.color.toLowerCase().includes('bejca')
      )
        newGlassCases = glassCases.filter(
          glassCase => glassCase.symbol.toLowerCase() === 'w4',
        );
      const toDisplay = sortFrontsByPricingGroup(newGlassCases);
      setGlassCasesToDisplay(toDisplay);
    }
  }, [glassCases, newOrder.veneer, newOrder.glassCase]);

  const handleGlassCase = glassCase => {
    dispatch(setNewOrderGlassCase(glassCase));
    alert.success('Wybrałeś witrynę');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleEditFront = (front, index) =>
    setGlassCaseToUpdate({ ...front, index });

  const handleRemoveFront = async front => {
    dispatch(setSpinner(true));
    dispatch(
      removeFront(
        front,
        () => {
          alert.success('Front usunięty');
        },
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  };

  return (
    <PageTemplate>
      <PageHeader
        title="Witryny"
        extra={[
          permissionContext === 'admin' && (
            <Button key={1} onClick={() => showModal('addFront')}>
              Dodaj nową witrynę
            </Button>
          ),
          <Button
            variant="outline-primary"
            key={2}
            onClick={() =>
              handleGlassCase({ symbol: 'inny', pricingGroup: 1 })
            }
          >
            Inna
          </Button>,
        ]}
      />

      {[1, 2, 3, 4].map(group => (
        <PricingGroup
          key={group}
          group={group}
          fronts={glassCasesToDisplay[group]}
          removeFront={handleRemoveFront}
          editFront={handleEditFront}
          handleFront={handleGlassCase}
        />
      ))}

      {/* MODALS */}
      {(modal.addFront || frontToUpdate) && (
        <AddMillingModal
          closeModal={() => {
            setGlassCaseToUpdate(null);
            closeModal();
          }}
          toUpdate={frontToUpdate}
          type="glassCase"
        />
      )}
    </PageTemplate>
  );
};

export default withContext(GlassCases);
