import axios from 'axios';

export const addPaintToSecondPricingGroup = async (
  color,
  onEnd,
  onErr,
) => {
  try {
    const res = await axios.put(
      `/api/settings/add-color-to-second-pricing`,
      { color },
    );

    if (onEnd) onEnd();
    return res.data;
  } catch (error) {
    console.log(error);
    if (onErr) onErr();
  }
};
