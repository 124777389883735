/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import {
  getCustomerData,
  clearCustomerData,
  setCustomer,
} from 'services/store/actions/customers';
import { useParams } from 'react-router';
import { useAlert } from 'react-alert';
import { Button } from 'react-bootstrap';
import {
  getCustomerOrders,
  clearOrdersList,
} from 'services/store/actions/orders';
import styled from 'styled-components';
import { updateCustomerDataByAdmin } from 'services/apiRequests/customers/update';
import CustomerData from './components/CustomerData';
import Orders from './components/Orders';
import Discounts from './components/Discounts';
import RemoveCustomerModal from './components/RemoveCustomerModal';
import SetSubordinatesModal from './components/SetSubordinatesModal';
import UserTerms from './components/UserTerms';
import ChangePermission from './components/ChangePermission';

const StyledHeader = styled.h1`
  color: red;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const initModals = {
  removeCustomer: false,
  setSubordinates: false,
  changePermission: false,
};

const CustomerProfile = () => {
  const params = useParams();
  const { customerId } = params;

  const dispatch = useDispatch();
  const alert = useAlert();

  const customer = useSelector(state => state.customers.customerData);
  const filters = useSelector(state => state.orders.filters.customer);

  const [modals, setModals] = useState(initModals);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);

  const getCustomer = () => {
    dispatch(
      getCustomerData(
        customerId,
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        }
      )
    );
  };

  useEffect(() => {
    if (!customer || customer._id !== customerId) {
      dispatch(setSpinner(true));
      getCustomer();
    } else {
      getCustomer();
    }
    return () => {
      dispatch(clearCustomerData());
      dispatch(clearOrdersList());
    };
  }, [customerId]);

  useEffect(() => {
    setIsOrdersLoading(true);
    dispatch(
      getCustomerOrders(
        customerId,
        filters.dateFrom,
        filters.dateTo,
        () => {
          setIsOrdersLoading(false);
        },
        () => {
          alert.error('Błąd pobierania zamówień');
          setIsOrdersLoading(false);
        }
      )
    );
  }, [filters, customerId]);

  const handleModal = key => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);
  const handleAcceptUser = async (body, message) => {
    dispatch(setSpinner(true));
    await updateCustomerDataByAdmin(
      customerId,
      body,
      customer => {
        dispatch(setCustomer(customer));
        alert.success(message);
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      }
    );
  };

  if (customer)
    return (
      <PageTemplate>
        <PageHeader
          title={
            <span>
              Dane użytkownika{' '}
              <sup style={{ color: 'blue', fontWeight: 'bold' }}>
                {customer.isVip ? 'VIP' : ''}
              </sup>
            </span>
          }
          extra={[
            <Button
              variant="danger"
              key={1}
              onClick={() => handleModal('removeCustomer')}
            >
              Usuń
            </Button>,
            <Button
              variant="outline-secondary"
              key={2}
              onClick={() => handleModal('setSubordinates')}
            >
              Wybierz podwładnych
            </Button>,
            <Button
              variant={customer.isVip ? 'outline-primary' : 'primary'}
              key={3}
              onClick={() =>
                handleAcceptUser(
                  { isVip: !customer.isVip },
                  `Użytkownik ${
                    customer.isVip ? 'stracił' : 'zyskał'
                  } status VIP`
                )
              }
            >
              {customer.isVip ? 'Usuń status VIP' : 'Nadaj status VIP'}
            </Button>,
            <Button
              variant={'outline-success'}
              key={4}
              onClick={() => handleModal('changePermission')}
            >
              Zmień uprawnienia
            </Button>,
          ]}
        />
        {!customer.isAccepted && (
          <>
            <StyledHeader
              onClick={() =>
                handleAcceptUser(
                  { isAccepted: true },
                  'Użytkownik zaakceptowany'
                )
              }
            >
              Zaakceptuj
            </StyledHeader>
          </>
        )}
        <CustomerData customer={customer} />
        <hr />
        <UserTerms customer={customer} />
        <hr />
        <Discounts discounts={customer.discounts} customerId={customerId} />
        <hr />
        <Orders isLoading={isOrdersLoading} />
        {/* MODALS */}
        {modals.removeCustomer && (
          <RemoveCustomerModal userId={customerId} closeModal={closeModal} />
        )}
        {modals.setSubordinates && (
          <SetSubordinatesModal
            customerId={customerId}
            closeModal={closeModal}
          />
        )}
        {modals.changePermission && (
          <ChangePermission user={customer} closeModal={closeModal} />
        )}
      </PageTemplate>
    );
  return null;
};

export default CustomerProfile;
