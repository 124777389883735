import { Button } from 'react-bootstrap';
import { PageHeader } from 'components/header';
import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { getCatalog, removeFront } from 'services/store/actions/catalog';
import { useAlert } from 'react-alert';
import { useModal } from 'hooks/useModal';
import AddMillingModal from '../components/AddMillingModal/AddMillingModal';
import PricingGroup from '../components/MillingPricingGroup/PricingGroup';
import { setNewOrderHandle } from 'services/store/actions/newOrder';
import { veneerHandles } from 'services/utils/const';

const initModals = {
  addHandle: false,
};

const Handles = ({
  permissionContext,
  closeModal: closeNewOrderModal,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { modal, showModal, closeModal } = useModal(initModals);
  const handles = useSelector(state => state.catalog.handles);
  const newOrder = useSelector(state => state.newOrder);
  const [handleToUpdate, setHandleToUpdate] = useState(null);

  const [handlesToDisplay, sethandlesToDisplay] = useState([]);

  useEffect(() => {
    if (!handles.length) dispatch(setSpinner(true));
    dispatch(
      getCatalog(
        () => {},
        () => {
          alert.error('Błąd pobierania katalogu');
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (handles) {
      let toDisplay = handles;
      if (
        newOrder.veneer ||
        newOrder.color.toLowerCase().includes('bejca')
      )
        toDisplay = handles.filter(item =>
          veneerHandles.includes(item.symbol),
        );

      sethandlesToDisplay(toDisplay);
    }
  }, [handles, newOrder.veneer, newOrder.color]);

  const handleHandle = handle => {
    dispatch(setNewOrderHandle(handle.symbol));
    alert.success('Wybrałeś uchwyt');
    if (closeNewOrderModal) closeNewOrderModal();
  };

  const handleEditHandle = (front, index) =>
    setHandleToUpdate({ ...front, index });

  const handleRemoveHandle = async front => {
    dispatch(setSpinner(true));
    dispatch(
      removeFront(
        front,
        () => {
          alert.success('Uchwyt usunięty');
        },
        () => {
          alert.error('Błąd!');
        },
      ),
    );
  };

  return (
    <PageTemplate>
      <PageHeader
        title="Uchwyty"
        extra={[
          permissionContext === 'admin' && (
            <Button key={1} onClick={() => showModal('addHandle')}>
              Dodaj nowy uchwyt
            </Button>
          ),
        ]}
      />

      <PricingGroup
        group={1}
        fronts={handlesToDisplay}
        removeFront={handleRemoveHandle}
        editFront={handleEditHandle}
        handleFront={handleHandle}
      />

      {/* MODALS */}
      {(modal.addHandle || handleToUpdate) && (
        <AddMillingModal
          closeModal={() => {
            setHandleToUpdate(null);
            closeModal();
          }}
          toUpdate={handleToUpdate}
          type="handle"
        />
      )}
    </PageTemplate>
  );
};

export default withContext(Handles);
