/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { dateToString } from 'services/utils/date';
import { getOrderCustomerName } from 'services/utils/orders';

const Wrapper = styled.div`
 width: fit-content;
 display: flex;
 flex-wrap: nowrap;
 overflow-y: hidden;
`;

const OrderWrapper = styled.div`
 min-width: 210px;
 position: relative;
 background-color: #f7f7f7;
 margin: 0 5px;
 margin: 5px;
 float: left;
 padding: 5px 15px 30px 15px;

 .orderInfo {
  border-bottom: 1px solid lightgray;
  padding: 5px;
  font-size: 11px;
  max-width: 200px;
  min-height: 150px;
  z-index: 99999;
 }
 h5 {
  text-align: center;
  font-size: 16px;
 }
`;

const UserPlanedOrders = ({ order }) => {
 const { user, number } = order;
 const orders = useSelector(state => state.orders.productionOrders);
 const [userOrders, setUserOrders] = useState([]);

 useEffect(() => {
  if (orders && order) {
   const userOrders = orders.filter(
    item => item.user.company.includes(user.company) && item.number !== number,
   );
   setUserOrders(userOrders);
  }
 }, [orders, order]);

 return (
  <Wrapper>
   {userOrders.map(item => (
    <Order order={item} key={item._id} />
   ))}
  </Wrapper>
 );
};

export default UserPlanedOrders;

const Order = ({ order }) => {
 if (order.productionPlan && order.productionPlan.length)
  return (
   <OrderWrapper>
    <h5>
     {getOrderCustomerName(order)} - {order.number}
    </h5>
    <div className="orderInfo">
     <DetailsItem name="Kolor" value={order.color} />
     <DetailsItem name="Matowość" value={order.paintType} />
     <DetailsItem name="Elementy" value={order.elements} />
     <DetailsItem
      name="PL"
      value={order.surfaceRight ? order.surfaceRight.toFixed(2) : 0}
     />
     <DetailsItem
      name="PP"
      value={order.surfaceLeft ? order.surfaceLeft.toFixed(2) : 0}
     />
     {order.millings.length ? (
      <div>
       <span>Wzór frezowania:</span>
       <strong> {order.millings.join(', ')}</strong>
      </div>
     ) : null}
     {order.veneer && <DetailsItem name="Wzór forniru" value={order.veneer} />}
     <DetailsItem
      name="Data realizacji"
      value={dateToString(order.finishDate)}
     />
     <DetailsItem name="Status" value={order.status.join(', ')} />
    </div>
    <div>
     {order.productionPlan &&
      order.productionPlan.map(item => (
       <div
        key={item._id}
        style={item.done ? { textDecoration: 'line-through' } : {}}
       >
        <span>{dateToString(item.planedOn)}: </span>
        <span className={item.position} style={{ fontWeight: 'bold' }}>
         {item.position}
        </span>
       </div>
      ))}
    </div>
   </OrderWrapper>
  );
 return null;
};

const DetailsItem = ({ name, value }) => (
 <div>
  <span>{name}: </span>
  <strong>{value}</strong>
 </div>
);
