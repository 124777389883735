import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import background from 'assets/images/landing_background2.jpg';
import logo from 'assets/images/dillak-logo.png';
import landing from 'assets/images/dillak-landing.png';

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
`;
const StyledWrapper = styled.div`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.91);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledContent = styled.div`
  width: 70%;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const StyledLogo = styled.div`
  padding-top: 20px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 20px;
  @media (max-width: 600px) {
    top: -20px;
    right: 20px;
    left: auto;
    transform: scale(0.7);
  }
`;
const StyledHeader = styled.div`
  position: relative;
  margin-bottom: 30px;
  h1 {
    font-size: 50px;
    margin-left: 50px;
    margin-bottom: 0;
    color: black;
    @media (max-width: 600px) {
      font-size: 35px;
    }
  }
`;
const StyledButton = styled.button`
  position: absolute;
  bottom: 0;
  margin: 0 !important;
  background-color: white;
  border: none;
  font-size: 20px;
  letter-spacing: 3px;
  padding: 10px 30px;
  transition: transform 0.2s;
  &:hover {
    transform: translateX(10px);
  }
  @media (max-width: 600px) {
    padding: 3px 20px;
  }
`;
const StyledBackButton = styled.button`
  position: absolute;
  top: 10px;
  padding: 0 5px !important;
  background-color: transparent;
  border: none;
  z-index: 9990;
  color: white;
  transition: color 0.2s;
  &:hover {
    color: ${({ theme }) => theme.dillakDark};
  }
`;
const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  @media (max-width: 600px) {
    font-size: 23px;
  }
`;
const StyledLanding = styled.div`
  max-width: 500px;
  margin: 0 auto;
  img {
    width: 100%;
  }

  h4 {
    margin-top: 50px;
    text-align: center;
  }
`;

const LandingPage = () => {
  // const [title, setTitle] = useState('Kim jesteśmy?');
  return (
    <StyledContainer>
      <StyledLogo>
        <img src={logo} alt="logo" />
      </StyledLogo>
      <StyledWrapper>
        <StyledContent>
          <StyledHeader>
            {/* {title !== 'Kim jesteśmy?' ? (
              <StyledBackButton onClick={() => setTitle('Kim jesteśmy?')}>
                <Fade>
                  <StyledIcon icon={faArrowLeft} />
                </Fade>
              </StyledBackButton>
            ) : null} */}
            {/* <Fade>
              <h1>{title}</h1>
            </Fade> */}
          </StyledHeader>
          <StyledLanding>
            <img src={landing} alt="landing" />
            <h4>
              Produkcja wysokiej jakości frontów meblowych lakierowanych
            </h4>
          </StyledLanding>
          {/* {title === 'Kim jesteśmy?' && (
            <Fade>
              <About setTitle={setTitle} />
            </Fade>
          )}
          {title === 'Kontakt' && (
            <Fade>
              <ContactForm setTitle={setTitle} />
            </Fade>
          )}
          {title === 'Galeria' && (
            <Fade>
              <Gallery setTitle={setTitle} />
            </Fade>
          )} */}
        </StyledContent>
      </StyledWrapper>
      {/* <StyledButton onClick={() => setTitle('Galeria')}>
        Galeria <FontAwesomeIcon icon={faArrowRight} />
      </StyledButton> */}
    </StyledContainer>
  );
};

export default LandingPage;
