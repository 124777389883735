import {
 STATS_LOAD_SUCCESS,
 STATS_LOAD_ERROR,
 STATS_SET_DATE,
 STATS_SET_POSITION,
 STATS_CLEAR,
 STATS_SET_FIELD,
} from '../types/statistics';
import Axios from 'axios';
import setAuthToken from 'services/utils/setAuthToken';

export const setStatsField = (field, value) => ({
 type: STATS_SET_FIELD,
 field,
 value,
});
export const clearStats = () => ({ type: STATS_CLEAR });
export const setStatsDate = (field, key, date) => ({
 type: STATS_SET_DATE,
 field,
 key,
 date,
});
export const setStatsPosition = (key, value) => ({
 type: STATS_SET_POSITION,
 key,
 value,
});

export const loadStatisticsSuccess = stats => ({
 type: STATS_LOAD_SUCCESS,
 stats,
});
export const loadStatisticsError = () => ({ type: STATS_LOAD_ERROR });

export const getProductionStatistics = (
 dates,
 onEnd,
 onError,
) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);

 try {
  const res = await Axios.post('/api/statistics/production', { ...dates });
  const { stats } = res.data;

  if (res.status === 200) dispatch(loadStatisticsSuccess(stats));
  onEnd();
 } catch (error) {
  console.log(`ERROR_STATISTICS_LOAD: `, error.response);
  dispatch(loadStatisticsError());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};

export const getEmployeeStatistics = (
 dates,
 employeeId,
 onEnd,
 onError,
) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);

 try {
  const res = await Axios.post('/api/statistics/employee', {
   ...dates,
   employeeId,
  });
  const { stats } = res.data;

  if (res.status === 200) dispatch(loadStatisticsSuccess(stats));
  onEnd();
 } catch (error) {
  console.log(`ERROR_STATISTICS_LOAD: `, error.response);
  dispatch(loadStatisticsError());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};
