import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';

const CustomElements = ({ closeModal }) => {
 return (
  <PageTemplate>
   <PageHeader title="Elementy niestandardowe" />
  </PageTemplate>
 );
};

export default CustomElements;
