import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, StyledH1 } from 'components/header';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import {
 getOrdersWithoutPaint,
 clearOrdersList,
} from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import OrdersList from 'components/ordersList/static';
import { getGlobalSettings } from 'services/store/actions/settings';
import { setInitPaints } from 'services/store/actions/paints';
import PermProductsList from './components/PermProducts';
import { getOthersArray, getPaintsArr } from './utils';
import OrderSummary from './components/SummaryModal';
import AddPosition from './components/AddPositionModal';
import OrdersModal from './components/OrdersModal';

const initModals = {
 paintsOrders: false,
 orderSummary: false,
 addOrder: false,
};

const PaintsOrders = () => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const orders = useSelector(state => state.orders.orders);
 const paints = useSelector(state => state.paints.paints);
 const other = useSelector(state => state.paints.other);
 const paintProducers = useSelector(state => state.settings.paintProducers);
 const permProducts = useSelector(state => state.settings.paintOrders);

 const [modals, setModals] = useState(initModals);

 useEffect(() => {
  dispatch(setSpinner(true));
  dispatch(
   getOrdersWithoutPaint(
    () => {
     dispatch(setSpinner(false));
    },
    () => {
     dispatch(setSpinner(false));
     alert.error('Błąd!');
    },
   ),
  );
  if (!paintProducers)
   dispatch(
    getGlobalSettings(
     () => {},
     () => {
      alert.error('Błąd!');
     },
    ),
   );
  return () => {
   dispatch(clearOrdersList());
  };
 }, []);

 useEffect(() => {
  if (orders) {
   const newPaints = getPaintsArr(orders);
   dispatch(setInitPaints('paints', newPaints));
  }
  if (permProducts && !other.paints && !other.other) {
   const newOther = { paints: [], other: [] };
   newOther.paints = getOthersArray(permProducts.paints);
   newOther.other = getOthersArray(permProducts.other);

   dispatch(setInitPaints('other', newOther));
  }
 }, [orders, permProducts]);

 const handleModal = key => setModals({ ...modals, [key]: true });
 const closeModal = () => setModals(initModals);

 return (
  <PageTemplate>
   <PageHeader
    title="Lakiery"
    extra={[
     <Button
      key={0}
      variant="outline-dark"
      onClick={() => handleModal('paintsOrders')}
     >
      Złożone zamówienia
     </Button>,
     <Button
      key={1}
      variant="outline-success"
      onClick={() => handleModal('addOrder')}
     >
      Dodaj pozycję
     </Button>,
     <Button key={2} onClick={() => handleModal('orderSummary')}>
      Podsumowanie
     </Button>,
    ]}
   />
   {orders && paints && paintProducers && (
    <OrdersList orders={paints} listName="paints" status paintsOrder />
   )}
   <StyledH1>Pozostałe</StyledH1>
   <PermProductsList perms={other} />
   {/* MODAL */}
   {modals.orderSummary && <OrderSummary closeModal={closeModal} />}
   {modals.addOrder && <AddPosition closeModal={closeModal} />}
   {modals.paintsOrders && <OrdersModal closeModal={closeModal} />}
  </PageTemplate>
 );
};

export default PaintsOrders;
