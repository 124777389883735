/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { StyledH3, StyledH6 } from 'components/header';
import TextInput from 'components/FormInputs/TextInput';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import FlexRow from 'templates/FlexRowTemplate';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { getFromObject } from 'services/utils/object';
import { schema } from '../../scenes/Settings/Global/components/utils/pricesValidate';

const Wrapper = styled.div`
  width: fit-content;
  input {
    width: 100px;
  }
`;

const Prices = ({ prices, handleSubmit, discounts, setDefault }) => {
  const [isEdit, setIsEdit] = useState(false);

  if (prices)
    return (
      <div>
        <Formik
          validationSchema={schema}
          // ONSUBMIT REQUEST
          onSubmit={async values => {
            await handleSubmit(
              { prices: values },
              () => setIsEdit(false),
              'Zaktualizowano cennik',
            );
          }}
          initialValues={{
            ...prices,
          }}
          render={props => {
            console.log(props.errors);
            return (
              <Form noValidate onSubmit={props.handleSubmit}>
                <Wrapper>
                  <FlexRow justify="flex-start">
                    <div>
                      <StyledH3>Materiał DILLAK</StyledH3>
                      <FlexRow justify="space-between">
                        <div>
                          <StyledH6>Połysk</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.gloss.oneSide"
                            label={
                              <Label
                                name="paint.companyMaterial.gloss.oneSide"
                                discounts={discounts}
                                text="Gładki - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.gloss.twoSides"
                            label={
                              <Label
                                name="paint.companyMaterial.gloss.twoSides"
                                discounts={discounts}
                                text="Gładki - Dwustronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.glossSecondGroup"
                            label={
                              <Label
                                name="paint.glossSecondGroup"
                                discounts={discounts}
                                text="2 grupa cenowa"
                                unit="+ zł*m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Półmat/mat</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.semiGloss.oneSide"
                            label={
                              <Label
                                name="paint.companyMaterial.semiGloss.oneSide"
                                discounts={discounts}
                                text="Gładki"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Półmat/mat - lewa strona</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.leftSide"
                            label={
                              <Label
                                name="paint.companyMaterial.leftSide"
                                discounts={discounts}
                                text="Lewa strona"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>CNC</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.semiGloss.groovedOneSide"
                            label={
                              <Label
                                name="paint.companyMaterial.semiGloss.groovedOneSide"
                                discounts={discounts}
                                text="Ryflowany"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.cnc[1]"
                            label={
                              <Label
                                name="paint.companyMaterial.cnc[1]"
                                discounts={discounts}
                                text="CNC - 1 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.cnc[2]"
                            label={
                              <Label
                                name="paint.companyMaterial.cnc[2]"
                                discounts={discounts}
                                text="CNC - 2 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.cnc[3]"
                            label={
                              <Label
                                name="paint.companyMaterial.cnc[3]"
                                discounts={discounts}
                                text="CNC - 3 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.cnc[4]"
                            label={
                              <Label
                                name="paint.companyMaterial.cnc[4]"
                                discounts={discounts}
                                text="CNC - 4 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Witryny</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.glassCase[1]"
                            label={
                              <Label
                                name="paint.companyMaterial.glassCase[1]"
                                discounts={discounts}
                                text="Witryna - 1 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.glassCase[2]"
                            label={
                              <Label
                                name="paint.companyMaterial.glassCase[2]"
                                discounts={discounts}
                                text="Witryna - 2 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.glassCase[3]"
                            label={
                              <Label
                                name="paint.companyMaterial.glassCase[3]"
                                discounts={discounts}
                                text="Witryna - 3 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.glassCase[4]"
                            label={
                              <Label
                                name="paint.companyMaterial.glassCase[4]"
                                discounts={discounts}
                                text="Witryna - 4 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Szprosy</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.muntin"
                            label={
                              <Label
                                name="paint.muntin"
                                discounts={discounts}
                                text="Szpros"
                                unit="szt"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Długie elementy</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.companyMaterial.longElement"
                            label={
                              <Label
                                name="paint.companyMaterial.longElement"
                                discounts={discounts}
                                text="Wys./Szer. >= 2800mm"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                        </div>
                        <div>
                          <StyledH6>Kolor + bezbarwny</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.transparent.oneSide"
                            label={
                              <Label
                                name="paint.transparent.oneSide"
                                discounts={discounts}
                                text="Bezbarwny - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Dorabiany</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.extraColor"
                            label={
                              <Label
                                name="paint.extraColor"
                                discounts={discounts}
                                text="Kolor dorabiany"
                                unit="do zam"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Obróbka elementów</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="services.handle"
                            label={
                              <Label
                                name="services.handle"
                                discounts={discounts}
                                text="Uchwyt frez + lak."
                                unit="m.b."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.partHandle"
                            label={
                              <Label
                                name="services.partHandle"
                                discounts={discounts}
                                text="Uchwyt frez. częściowy + lak."
                                unit="szt."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.millingHandle"
                            label={
                              <Label
                                name="services.millingHandle"
                                discounts={discounts}
                                text="Frezowanie uchwytu"
                                unit="m.b"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.hingeHole"
                            label={
                              <Label
                                name="services.hingeHole"
                                discounts={discounts}
                                text="Otw. pod zawias"
                                unit="szt."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.chamfering"
                            label={
                              <Label
                                discounts={discounts}
                                name="services.chamfering"
                                text="Gierowanie "
                                unit="m.b."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.backMilling"
                            label={
                              <Label
                                name="services.backMilling"
                                discounts={discounts}
                                text="Frezowanie pod plecy"
                                unit="m.b."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.manHour"
                            label={
                              <Label
                                discounts={discounts}
                                text="Roboczogodziny"
                                unit="zł/h"
                                name="services.manHour"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.ventilations"
                            label={
                              <Label
                                name="services.ventilations"
                                discounts={discounts}
                                text="Kratki wentylacyjne"
                                unit="szt."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Porgubienia</StyledH6>
                          <TextInput
                            {...props}
                            name="bold[22]"
                            label={
                              <Label
                                discounts={discounts}
                                text="22mm "
                                unit="m2"
                                name="bold.22"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="bold[25]"
                            label={
                              <Label
                                discounts={discounts}
                                text="25mm "
                                unit="m2"
                                name="bold.25"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="bold[28]"
                            label={
                              <Label
                                discounts={discounts}
                                text="28mm "
                                unit="m2"
                                name="bold.28"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="bold[30]"
                            label={
                              <Label
                                discounts={discounts}
                                text="30mm "
                                unit="m2"
                                name="bold.30"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="bold[38]"
                            label={
                              <Label
                                discounts={discounts}
                                text="38mm "
                                unit="m2"
                                name="bold.38"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                        </div>
                      </FlexRow>
                    </div>
                    {/* NOTE MATERIAŁ KLIENTA */}
                    <div>
                      <StyledH3>Materiał klienta</StyledH3>
                      <FlexRow justify="space-between">
                        <div>
                          <StyledH6>Połysk</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.gloss.oneSide"
                            label={
                              <Label
                                name="paint.customerMaterial.gloss.oneSide"
                                discounts={discounts}
                                text="Gładki - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.gloss.twoSides"
                            label={
                              <Label
                                name="paint.customerMaterial.gloss.twoSides"
                                discounts={discounts}
                                text="Gładki - Dwustronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />{' '}
                          <TextInput
                            {...props}
                            name="paint.glossSecondGroup"
                            label={
                              <Label
                                name="paint.glossSecondGroup"
                                discounts={discounts}
                                text="2 grupa cenowa"
                                unit="+ zł*m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Półmat/mat</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.semiGloss.oneSide"
                            label={
                              <Label
                                name="paint.customerMaterial.semiGloss.oneSide"
                                discounts={discounts}
                                text="Gładki"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Półmat/mat - lewa strona</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.leftSide"
                            label={
                              <Label
                                name="paint.customerMaterial.leftSide"
                                discounts={discounts}
                                text="Lewa strona"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>CNC</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.semiGloss.groovedOneSide"
                            label={
                              <Label
                                name="paint.customerMaterial.semiGloss.groovedOneSide"
                                discounts={discounts}
                                text="Ryflowany - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.cnc[1]"
                            label={
                              <Label
                                name="paint.customerMaterial.cnc[1]"
                                discounts={discounts}
                                text="CNC - 1 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.cnc[2]"
                            label={
                              <Label
                                name="paint.customerMaterial.cnc[2]"
                                discounts={discounts}
                                text="CNC - 2 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.cnc[3]"
                            label={
                              <Label
                                name="paint.customerMaterial.cnc[3]"
                                discounts={discounts}
                                text="CNC - 3 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.cnc[4]"
                            label={
                              <Label
                                name="paint.customerMaterial.cnc[4]"
                                discounts={discounts}
                                text="CNC - 4 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Witryny</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.glassCase[1]"
                            label={
                              <Label
                                name="paint.customerMaterial.glassCase[1]"
                                discounts={discounts}
                                text="Witryna - 1 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.glassCase[2]"
                            label={
                              <Label
                                name="paint.customerMaterial.glassCase[2]"
                                discounts={discounts}
                                text="Witryna - 2 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.glassCase[3]"
                            label={
                              <Label
                                name="paint.customerMaterial.glassCase[3]"
                                discounts={discounts}
                                text="Witryna - 3 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.glassCase[4]"
                            label={
                              <Label
                                name="paint.customerMaterial.glassCase[4]"
                                discounts={discounts}
                                text="Witryna - 4 grupa"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Długie elementy</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.customerMaterial.longElement"
                            label={
                              <Label
                                name="paint.customerMaterial.longElement"
                                discounts={discounts}
                                text="Wys./Szer. >= 2800mm"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                        </div>
                        <div>
                          <StyledH6>Fornir</StyledH6>{' '}
                          <TextInput
                            {...props}
                            name="paint.veneer.colorlessOneSide"
                            label={
                              <Label
                                discounts={discounts}
                                text="Fornir [Bezbarwny] - Jednostronny"
                                unit="m2"
                                name="paint.veneer.colorlessOneSide"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.colorlessTwoSides"
                            label={
                              <Label
                                discounts={discounts}
                                text="Fornir [Bezbarwny] - Dwustronny"
                                unit="m2"
                                name="paint.veneer.colorlessTwoSides"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.mordantOneSide"
                            label={
                              <Label
                                name="paint.veneer.mordantOneSide"
                                discounts={discounts}
                                text="Fornir [Bejcowany] - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.mordantTwoSides"
                            label={
                              <Label
                                name="paint.veneer.mordantTwoSides"
                                discounts={discounts}
                                text="Fornir [Bejcowany] - Dwustronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.groovedColorlessOneSide"
                            label={
                              <Label
                                name="paint.veneer.groovedColorlessOneSide"
                                discounts={discounts}
                                text="Ryflowany [Bezbarwny] - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.groovedColorlessTwoSides"
                            label={
                              <Label
                                name="paint.veneer.groovedColorlessTwoSides"
                                discounts={discounts}
                                text="Ryflowany [Bezbarwny] - Dwustronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.groovedMordantOneSide"
                            label={
                              <Label
                                name="paint.veneer.groovedMordantOneSide"
                                discounts={discounts}
                                text="Ryflowany [Bejcowany] - Jednostronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="paint.veneer.groovedMordantTwoSides"
                            label={
                              <Label
                                name="paint.veneer.groovedMordantTwoSides"
                                discounts={discounts}
                                text="Ryflowany [Bejcowany] - Dwustronny"
                                unit="m2"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <StyledH6>Inne</StyledH6>
                          <TextInput
                            {...props}
                            name="services.paintHandle"
                            label={
                              <Label
                                name="services.paintHandle"
                                discounts={discounts}
                                text="Lakierowanie uchwytu"
                                unit="m.b."
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                          <TextInput
                            {...props}
                            name="services.paintPartHandle"
                            label={
                              <Label
                                name="services.paintPartHandle"
                                discounts={discounts}
                                text="Lakierowanie uchwytu częściowego"
                                unit="szt"
                              />
                            }
                            disabled={!isEdit}
                            required
                          />
                        </div>
                      </FlexRow>
                    </div>
                  </FlexRow>
                  <FlexRow justify="flex-end">
                    <Button
                      variant="success"
                      type="submit"
                      disabled={!isEdit}
                    >
                      Zatwierdź
                    </Button>
                    {setDefault && (
                      <Button
                        onClick={() => setDefault(() => setIsEdit(false))}
                        disabled={!isEdit}
                      >
                        Ustaw domyślne
                      </Button>
                    )}
                    <Button
                      variant={isEdit ? 'outline-secondary' : 'secondary'}
                      onClick={() => setIsEdit(!isEdit)}
                    >
                      {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
                    </Button>
                  </FlexRow>
                </Wrapper>
              </Form>
            );
          }}
        />
      </div>
    );
  return null;
};

export default Prices;

const Label = ({ discounts, name, text, unit }) => {
  return (
    <small>
      {text}{' '}
      {discounts && name && getFromObject(discounts, name) > 0 && (
        <FontAwesomeIcon icon={faSortDown} />
      )}
      {discounts && name && getFromObject(discounts, name) < 0 && (
        <FontAwesomeIcon icon={faSortUp} />
      )}{' '}
      <sup>[{unit}]</sup>
    </small>
  );
};
