import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dateToString,
  getArrayOfDatesFromTo,
  getDayName,
  dateToStringWithoutYear,
} from 'services/utils/date';
import styled from 'styled-components';
import { StyledH3, StyledH1 } from 'components/header';
import Select from 'components/FormInputs/Select';
import { setStatsPosition } from 'services/store/actions/statistics';
import {
  getSummaryByItemType,
  findByDateInStats,
  getAverageValue,
} from 'scenes/Statistics/Production/components/PositionStats/utils';
import { Table } from 'react-bootstrap';
import { getPositionsFromStats } from '../utils';
import { math } from 'polished';

const Wrapper = styled.div`
  text-align: center;
`;
const Title = styled.h2`
  color: ${({ color, theme }) => theme[color]};
  font-size: 35px;
  letter-spacing: 5px;
  font-weight: bold;
`;
const Info = styled.span`
  font-size: 25px;
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const RangeStatistics = ({ date, employee }) => {
  const dispatch = useDispatch();
  const employees = useSelector(state => state.employees.employees);
  const stats = useSelector(state => state.statistics.stats);
  const position = useSelector(
    state => state.statistics.position.employee,
  );

  const [userName, setUserName] = useState('');
  const [positions, setPositions] = useState([]);
  const [days, setDays] = useState([]);

  const [data, setData] = useState(null);
  const [sum, setSum] = useState(null);

  const [average, setAverage] = useState(null);

  useEffect(() => {
    if (employees && employee) {
      const user = employees.find(item => item._id === employee);
      setUserName(`${user.firstname} ${user.surname}`);
    }
  }, [employees, employee]);

  useEffect(() => {
    if (stats) {
      const newPositions = getPositionsFromStats(stats);
      setPositions(newPositions);
      if (newPositions.length)
        dispatch(setStatsPosition('employee', newPositions[0]));
    }
  }, [stats]);

  useEffect(() => {
    if (stats && position) {
      const data = stats.filter(item => item.position === position);
      setData(data);
      const days = getArrayOfDatesFromTo(date.from, date.to);
      setDays(days);
      const sum = getSummaryByItemType(data);
      setSum(sum);
      const ave = getAverageValue(data);
      setAverage(ave);
    }
  }, [stats, position]);

  const handlePosition = e =>
    dispatch(setStatsPosition('employee', e.target.value));
  if (stats && position && sum)
    return (
      <div>
        <Wrapper>
          <StyledH1>{userName}</StyledH1>
          {positions.length ? (
            <Select
              style={{ width: 300, margin: 'auto' }}
              items={positions}
              value={position}
              onChange={handlePosition}
            />
          ) : null}
          <small>
            Dane pochodzą z zamówień wykonanych między{' '}
            <strong>{dateToString(date.from)}</strong> a{' '}
            <strong>{dateToString(date.to)}</strong>
          </small>
          <hr />
          <span>
            W tym okresie {userName},{' '}
            <i style={{ textDecoration: 'underline' }}>
              w dniach roboczych
            </i>
            , średnio wykonał:{' '}
            <strong>{average ? average.toFixed(2) : '-'}</strong> m
            <sup>2</sup>
            /dzień
          </span>
        </Wrapper>
        <hr />
        <StyledH3>Liczby</StyledH3>
        <StatsTables stats={data} days={days} sum={sum} />
      </div>
    );
  return null;
};

export default RangeStatistics;

const Th = styled.th`
  width: ${({ width }) => width || 'fit-content'};
  color: ${({ dayName }) =>
    dayName === 'niedziela'
      ? 'red'
      : dayName === 'sobota'
      ? 'green'
      : 'black'};
`;
const Td = styled.td`
  width: ${({ width }) => width || 'fit-content'};
`;
const StatsTables = ({ stats, days, sum }) => {
  return (
    <TableWrapper>
      <Table responsive="lg" bordered hover striped size="sm">
        <tbody>
          <tr>
            <Th colSpan={2}>Dni</Th>
            {days.map(day => (
              <Th width={20} key={day} dayName={getDayName(day)}>
                {dateToStringWithoutYear(day)}
              </Th>
            ))}
            <th colSpan={3}>SUMA</th>
          </tr>

          <tr>
            <Th width={80} rowSpan={2}>
              Płaskie
            </Th>
            <Th width={150}>Jednostronne</Th>
            <DaysCells days={days} stats={stats} field="flatOneSide" />
            <Th width={40}>
              {sum.flatOneSide ? sum.flatOneSide.toFixed(2) : 0}
            </Th>
            <Th width={80} rowSpan={2}>
              <StyledH3>
                {sum.flatSummary ? sum.flatSummary.toFixed(2) : 0}
              </StyledH3>
            </Th>
            <Th width={80} rowSpan={4}>
              <StyledH1>
                {sum.summary ? sum.summary.toFixed(2) : 0}
              </StyledH1>
            </Th>
          </tr>
          <tr>
            <Th width={150}>Obustronne</Th>
            <DaysCells days={days} stats={stats} field="flatBothSides" />
            <Th width={40}>
              {sum.flatBothSides ? sum.flatBothSides.toFixed(2) : 0}
            </Th>
          </tr>

          <tr>
            <Th width={80} rowSpan={2}>
              CNC
            </Th>
            <Th width={150}>Jednostronne</Th>

            <DaysCells days={days} stats={stats} field="cncOneSide" />

            <Th width={40}>
              {sum.cncOneSide ? sum.cncOneSide.toFixed(2) : 0}
            </Th>
            <Th width={80} rowSpan={2}>
              <StyledH3>
                {sum.cncSummary ? sum.cncSummary.toFixed(2) : 0}
              </StyledH3>
            </Th>
          </tr>
          <tr>
            <Th width={150}>Obustronne</Th>

            <DaysCells days={days} stats={stats} field="cncBothSides" />

            <Th width={40}>
              {sum.cncBothSides ? sum.cncBothSides.toFixed(2) : 0}
            </Th>
          </tr>

          <tr>
            <Th width={80} rowSpan={3}>
              Typ lakieru
            </Th>
            <Th width={150}>Półmat/mat</Th>

            <DaysCells days={days} stats={stats} field="semiGloss" />

            <Th width={40} colSpan={3}>
              {sum.semiGloss ? sum.semiGloss.toFixed(2) : 0}
            </Th>
          </tr>
          <tr>
            <Th width={150}>Połysk 1</Th>

            <DaysCells days={days} stats={stats} field="gloss" />

            <Th width={40} colSpan={3}>
              {sum.gloss ? sum.gloss.toFixed(2) : 0}
            </Th>
          </tr>
          <tr>
            <Th width={150}>Połysk 2</Th>

            <DaysCells
              days={days}
              stats={stats}
              field="glossSecondGroup"
            />

            <Th width={40} colSpan={3}>
              {sum.glossSecondGroup ? sum.glossSecondGroup.toFixed(2) : 0}
            </Th>
          </tr>

          <tr>
            <th colSpan={2}>
              SUMA{' '}
              <small>
                m<sup>2</sup>
              </small>
            </th>
            <DaysCells days={days} stats={stats} field="sum" />
            <td colSpan={3} />
          </tr>
          <tr>
            <th rowSpan={2}>Elementy</th>
            <th>Płaskie</th>
            <DaysCells days={days} stats={stats} field="flatElements" />
            <td>
              <strong>{Math.ceil(sum.flatElements)}</strong>
            </td>
            <td rowSpan={2} colSpan={2}>
              <StyledH3>{Math.ceil(sum.elements)}</StyledH3>
            </td>
          </tr>
          <tr>
            <th>CNC</th>
            <DaysCells days={days} stats={stats} field="cncElements" />
            <td>
              <strong>{Math.ceil(sum.cncElements)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const DaysCells = ({ days, stats, field }) =>
  days.map(day => {
    const value = findByDateInStats(stats, day, field);

    return (
      <Td width={20} key={day}>
        {value}
      </Td>
    );
  });
