import Axios from 'axios';

export const sendMessage = async (body, onEnd, onErr) => {
 try {
  await Axios.post(`/api/messages/`, body);

  return onEnd();
 } catch (error) {
  console.log(error);
  onErr();
 }
};
export const sendCustomerMessage = async (body, onEnd, onErr) => {
 try {
  const res = await Axios.post(`/api/messages/customer`, body);
  const { newMessage } = res.data;
  return onEnd(newMessage);
 } catch (error) {
  console.log(error);
  onErr();
 }
};
