import React from 'react';
import Modal from 'templates/ModalTemplate';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setNewOrderField } from 'services/store/actions/newOrder';

const MechanicalBy = ({ order, closeModal, goNext }) => {
  const dispatch = useDispatch();

  const handleData = (field, value) => dispatch(setNewOrderField(field, value));
  return (
    <Modal
      title="Kto wykonuje te czynności w elementach powierzonych?"
      closeModal={closeModal}
      onOk={goNext}
    >
      {order.handles.length ? (
        <>
          <strong>Frezowanie uchwytów?</strong>
          <Form.Control
            as="select"
            onChange={e => handleData('handlesMilledBy', e.target.value)}
            value={order.handlesMilledBy}
          >
            <option value="customer">Klient</option>
            <option value="dillak">Dillak</option>
          </Form.Control>
        </>
      ) : null}
      {order.items.some(item => checkIsItemHas('otw', false, true, item)) ? (
        <>
          <strong>Wiercenie otworów?</strong>
          <Form.Control
            as="select"
            onChange={e => handleData('hingeHolesDrilledBy', e.target.value)}
            value={order.hingeHolesDrilledBy}
          >
            <option value="customer">Klient</option>
            <option value="dillak">Dillak</option>
          </Form.Control>
        </>
      ) : null}
      {(order.items.some(item => checkIsItemHas('nut', false, true, item)) ||
        order.items.some(item =>
          checkIsItemHas('felc', false, true, item)
        )) && (
        <>
          <strong>Frezowanie pod plecy?</strong>
          <Form.Control
            as="select"
            onChange={e => handleData('backMillingBy', e.target.value)}
            value={order.backMillingBy}
          >
            <option value="customer">Klient</option>
            <option value="dillak">Dillak</option>
          </Form.Control>
        </>
      )}
      {order.items.some(item =>
        checkIsItemHas('gierunek', true, true, item)
      ) && (
        <>
          <strong>Gierowanie?</strong>
          <Form.Control
            as="select"
            onChange={e => handleData('chamferingBy', e.target.value)}
            value={order.chamferingBy}
          >
            <option value="customer">Klient</option>
            <option value="dillak">Dillak</option>
          </Form.Control>
        </>
      )}
    </Modal>
  );
};

export default MechanicalBy;

const checkIsItemHas = (value, right, left, item) => {
  const { h1L, h2L, w1L, w2L, h1P, h2P, w1P, w2P } = item;
  if (
    !item.dillakMaterial &&
    left &&
    (h1L.toLowerCase().includes(value.toLowerCase()) ||
      h2L.toLowerCase().includes(value.toLowerCase()) ||
      w1L.toLowerCase().includes(value.toLowerCase()) ||
      w2L.toLowerCase().includes(value.toLowerCase()))
  )
    return true;
  if (
    !item.dillakMaterial &&
    right &&
    (h1P.toLowerCase().includes(value.toLowerCase()) ||
      h2P.toLowerCase().includes(value.toLowerCase()) ||
      w1P.toLowerCase().includes(value.toLowerCase()) ||
      w2P.toLowerCase().includes(value.toLowerCase()))
  )
    return true;
  return false;
};
