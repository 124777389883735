/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import withContext from 'hoc/withContext';

const Th = styled.th`
  width: 150px;
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.dillakGreen;
    if (secondary) return theme.dillakWarning;
    return 'white';
  }};
`;
const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.dillakGreenLight;
    if (secondary) return theme.dillakWarningLight;
    return 'white';
  }};
`;

const Summary = ({ order, permissionContext }) => {
  const getPrice = () => {
    let orderPrice = order.price;
    if (order.manHours && order.manHours.price)
      orderPrice += order.manHours.price;
    if (order.ventilations && order.ventilations.price)
      orderPrice += order.ventilations.price;
    return orderPrice ? orderPrice.toFixed(2) : 0;
  };

  const getItemsQuantity = key =>
    order.items.reduce((elements, item) => {
      if (item[key]) elements += item.quantity;
      return elements;
    }, 0);
  return (
    <Table responsive striped bordered hover size="sm">
      <thead>
        <tr>
          <th rowSpan={2}>
            Ilość elementów:
            <br />
            <small>(szt)</small>
          </th>

          <th colSpan={2}>
            Powierzchnia lakierowania:
            <br />
            <small>
              (m<sup>2</sup>)
            </small>
          </th>
          {permissionContext !== 'employee' &&
            ['Materiał klienta', 'Poprawa (wina klienta)'].includes(
              order.orderType
            ) && (
              <th rowSpan={2}>
                Powierzchnia krawędzi* <br />
                <small>
                  (m<sup>2</sup>)
                </small>
              </th>
            )}

          <th rowSpan={2}>
            Uchwyt frezowany:
            <br />
            <small>(mb)</small>
          </th>

          {order.milledPartHandle ? (
            <th rowSpan={2}>
              Uchwyt częściowy:
              <br />
              <small>(szt)</small>
            </th>
          ) : null}
          {order.chamfering ? (
            <th rowSpan={2}>
              Gierowanie:
              <br />
              <small>(mb)</small>
            </th>
          ) : null}
          {order.backMilling ? (
            <th rowSpan={2}>
              Frezowanie pod plecy:
              <br />
              <small>(mb)</small>
            </th>
          ) : null}

          <th rowSpan={2}>
            Ilość otworów:
            <br />
            <small>(szt)</small>
          </th>
          {permissionContext !== 'employee' && permissionContext !== 'display' && (
            <>
              {order.manHours && order.manHours.hours ? (
                <th rowSpan={2}>
                  Prace stolarskie:
                  <br />
                  <small>(h)</small>
                </th>
              ) : null}
              {order.ventilations && order.ventilations.quantity ? (
                <th rowSpan={2}>
                  Kratki wentylacyjne:
                  <br />
                  <small>(szt)</small>
                </th>
              ) : null}
              <th rowSpan={2}>
                Przewidywana cena**:
                <br />
                <small>(zł)</small>
              </th>
            </>
          )}
        </tr>
        <tr>
          <Th primary>PL</Th>
          <Th secondary>PP</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{order.elements}</td>
          <Td primary>
            {order.surfaceRight && (
              <>
                {order.surfaceRight.toFixed(3)}
                <sub>
                  <strong> {getItemsQuantity('surfaceRight')}</strong>
                </sub>
              </>
            )}
          </Td>
          <Td secondary>
            {order.surfaceLeft && (
              <>
                {order.surfaceLeft.toFixed(3)}
                <sub>
                  <strong> {getItemsQuantity('surfaceLeft')}</strong>
                </sub>
              </>
            )}
          </Td>
          {permissionContext !== 'employee' &&
            ['Materiał klienta', 'Poprawa (wina klienta)'].includes(
              order.orderType
            ) && <td rowSpan={2}>{order.edgesSurface.toFixed(2)}</td>}
          <td>{order.milledHandle && order.milledHandle.toFixed(2)}</td>
          {order.milledPartHandle ? (
            <td>{order.milledPartHandle && order.milledPartHandle}</td>
          ) : null}
          {order.chamfering ? (
            <td>{order.chamfering && order.chamfering.toFixed(2)}</td>
          ) : null}
          {order.backMilling ? (
            <td>{order.backMilling && order.backMilling.toFixed(2)}</td>
          ) : null}
          <td>{order.hingesHoles}</td>
          {permissionContext !== 'employee' && permissionContext !== 'display' && (
            <>
              {order.manHours && order.manHours.hours ? (
                <td>{order.manHours.hours}</td>
              ) : null}
              {order.ventilations && order.ventilations.quantity ? (
                <td>{order.ventilations.quantity}</td>
              ) : null}
              <td>{getPrice()}</td>
            </>
          )}
        </tr>
      </tbody>
    </Table>
  );
};

export default withContext(Summary);
