import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 400px;
`;

const StyledReactDatePicker = styled(ReactDatePicker)`
  border-radius: 5px;
  border: 1px solid #73a52e;
  font-size: 16px;
  height: 36px;
`;

const CustomDateRangePicker = props => {
  return (
    <Wrapper>
      <StyledReactDatePicker {...props} />
    </Wrapper>
  );
};

export default CustomDateRangePicker;
