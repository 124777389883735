/* eslint-disable no-shadow */
export const getOrdersAndCustomersFromTimetables = (
  timetables,
  setOrders,
  setCustomers,
) => {
  const ordersArr = [];
  const customersArr = [];

  Object.keys(timetables).forEach(key => {
    const timetable = timetables[key];

    timetable.forEach(day =>
      day.orders.forEach(order => {
        if (!ordersArr.find(item => item._id === order.order._id))
          ordersArr.push(order.order);
      }),
    );
  });
  ordersArr.forEach(item => {
    if (!customersArr.map(i => i._id).includes(item.user._id))
      customersArr.push(item.user);
  });
  setOrders(ordersArr);
  setCustomers(customersArr);
};

export const getOrderSchedule = (timetables, orderId, setSchedule) => {
  let orderSchedule = [];

  Object.keys(timetables).forEach(key => {
    const timetable = timetables[key];

    timetable.forEach(day => {
      day.orders.forEach(order => {
        if (order && order.order && order.order._id === orderId) {
          orderSchedule.push({
            date: new Date(day.date),
            position: day.position,
            actions: order.actions,
          });
        }
      });
    });
  });

  orderSchedule = orderSchedule.sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  );

  setSchedule(orderSchedule);
};

export const getDaySurfaces = (dayOrders, order = null) => {
  let orders = [...dayOrders];

  if (order && orders.length) {
    const isExist = orders.find(
      o =>
        o &&
        o.order &&
        order &&
        order.order &&
        o.order._id === order.order._id,
    );
    if (!isExist) orders = [...orders, order];
    else
      orders = orders.map(o => {
        if (o.order && order.order && o.order._id === order.order._id)
          return order;
        return o;
      });
  }

  const result = { cnc: 0, surface: 0, elements: 0 };

  orders.forEach(item => {
    if (item && item.order) {
      const { order, actions } = item;
      let items = [...order.items];

      if (actions.left) {
        items = items.filter(i => {
          if (i.surfaceLeft) {
            return item;
          }
        });
      } else if (actions.corrections) {
        items = order.items.filter(
          i => i.elementToCorrect.position && i.elementToCorrect.quantity,
        );
      } else if (actions.losts) {
        items = order.items.filter(
          i => i.elementLost.position && i.elementLost.quantity,
        );
      }

      if (items.length) {
        items.forEach(i => {
          let surface = i.surfaceLeft || i.surfaceRight;
          let { quantity } = i;
          if (actions.corrections) {
            quantity = i.elementToCorrect.quantity;
            const elementSurface = surface / quantity;
            surface = elementSurface * quantity;
          } else if (actions.losts) {
            quantity = i.elementLost.quantity;
            const elementSurface = surface / quantity;
            surface = elementSurface * quantity;
          }

          result.surface += surface;
          result.elements += quantity;
          if (i.type !== 'gładki') result.cnc += surface;
        });
      }
    }
  });

  return result;
};
