/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import OrdersList from 'components/ordersList/static';
import FlexRow from 'templates/FlexRowTemplate';
import { Button, Table } from 'react-bootstrap';
import ScheduleItem from './ScheduleItem';
import UserPlanedOrders from './UserPlanedOrders';

const scheduleObject = {
 date: new Date(),
 position: '',
 actions: { all: true, left: false, corrections: false, losts: false },
};

const OrderScheduleDetails = props => {
 const { order, schedule, setSchedule } = props;
 const addPosition = () => setSchedule(schedule.concat(scheduleObject));

 return (
  <div>
   <OrdersList
    orders={[order]}
    listName="production"
    status
    lastOperation
    isRedirect={false}
   />
   <FlexRow justify="space-between">
    <div style={{ width: '40%' }}>
     <Table>
      <thead>
       <tr>
        <th style={{ width: 200 }}>Data</th>
        <th style={{ width: 200 }}>Stanowisko</th>
        <th>Wsz.</th>
        <th>Lewe</th>
        <th>Popr.</th>
        <th>Brak.</th>
        <th style={{ color: 'red' }}>Usuń</th>
       </tr>
      </thead>
      <tbody>
       {schedule.map((item, index) => {
        return (
         <ScheduleItem
          key={item.date + index}
          item={item}
          index={index}
          {...props}
         />
        );
       })}
      </tbody>
     </Table>
     <Button size="sm" onClick={addPosition}>
      Dodaj
     </Button>
    </div>
    <div style={{ width: '60%', overflowX: 'auto' }}>
     <UserPlanedOrders order={order} />
    </div>
   </FlexRow>
  </div>
 );
};

export default OrderScheduleDetails;
