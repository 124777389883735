import React from 'react';
import { StyledH6 } from 'components/header';
import FlexRow from 'templates/FlexRowTemplate';
import styled from 'styled-components';
import { dateToString } from 'services/utils/date';
import { firstLetterToUpperCase } from 'services/utils/string';
import { months } from 'services/utils/const';

const Wrapper = styled.div`
 display: flex;
 justify-content: flex-start;
 div {
  margin-right: 20px;
 }
`;
const DataWrapper = styled.div`
 min-width: 400px;
 max-width: 500px;
`;

const EmployeeData = ({ employee }) => {
 return (
  <div>
   <Wrapper>
    <DataWrapper>
     <StyledH6>{`${employee.firstname} ${employee.surname}`}</StyledH6>

     <DataItem name="E-mail" value={employee.email} />
     <DataItem name="Data dodania" value={dateToString(employee.addDate)} />
     <DataItem name="Zarobki" value={`${employee.earnings} zł/h`} />
     <DataItem
      name="Uprawnienia"
      value={employee.isManager ? 'Kierownik' : 'Pracownik'}
     />
    </DataWrapper>

    <DataWrapper>
     <StyledH6>Stanowisko/a</StyledH6>
     <ul>
      {employee.positions.map((item) => (
       <li key={item}>
        <span className={item.toLowerCase()}>
         {firstLetterToUpperCase(item)}
        </span>
       </li>
      ))}
     </ul>
    </DataWrapper>

    {employee.workedHours && employee.workedHours.length ? (
     <DataWrapper>
      <StyledH6>Godziny pracy</StyledH6>
      {employee.workedHours.map((item) => {
       const date = new Date(item.date);
       const month = months[date.getMonth()];
       const name = `${date.getFullYear()} ${month}`;

       return (
        <DataItem key={item.date} name={name} value={`${item.hours} h`} />
       );
      })}
     </DataWrapper>
    ) : null}
   </Wrapper>
  </div>
 );
};

export default EmployeeData;

const DataItem = ({ name, value }) => (
 <FlexRow justify="space-between">
  <span>{name}</span>
  <strong>{value || '-'}</strong>
 </FlexRow>
);
