import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import withContext from 'hoc/withContext';
import { dateToString } from 'services/utils/date';
import { Button } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
 isLostItemsInOrder,
 isItemsCorrectedInOrder,
} from 'services/utils/orders';

const StyledWrapper = styled.div`
 text-align: right;
`;
const StyledStatus = styled.span`
 font-size: ${({ size }) => (size ? size : 20)}px;
`;
const BoldSpan = styled.span`
 font-weight: bold;
 color: ${({ isPaid }) => {
  if (isPaid === true) return 'green';
  if (isPaid === false) return 'red';
 }};
`;
const StyledButton = styled(Button)`
 padding: 2px 15px !important;
 margin: 0 !important;
`;

const Status = ({ order, ordersInProduction, setModal, permissionContext }) => {
 const [isWarning, setIsWarning] = useState(false);

 useEffect(() => {
  setIsWarning(false);
  const itemsStatuses = order.items.reduce((acc, item) => {
   const { elementLost, elementToCorrect } = item;
   if (elementLost.position && !acc.includes(elementLost.position))
    return acc.concat(elementLost.position);
   if (elementToCorrect.position && !acc.includes(elementToCorrect.position))
    return acc.concat(elementToCorrect.position);
   return acc;
  }, []);
  itemsStatuses.forEach(item =>
   !order.status.includes(item) ? setIsWarning(true) : null,
  );
 }, [order, order.items, order.status]);

 return (
  <StyledWrapper>
   <div>
    <StyledStatus>
     {permissionContext === 'admin' && isWarning && (
      <FontAwesomeIcon
       icon={faExclamationCircle}
       title="Zamówienie nie zostanie obsłużone prawidłowo!"
       style={{ color: 'red' }}
      />
     )}{' '}
     Status:{' '}
     {order.status.map(item => (
      <BoldSpan key={item} className={item}>
       {firstLetterToUpperCase(item)}{' '}
      </BoldSpan>
     ))}
    </StyledStatus>
   </div>
   {order.pickUpDate && (
    <div>
     <StyledStatus size={18}>
      Data odbioru: <BoldSpan>{dateToString(order.pickUpDate)}</BoldSpan>
     </StyledStatus>
    </div>
   )}
   {permissionContext !== 'user' && (
    <div>
     <StyledStatus size={17}>
      Lakier:{' '}
      <BoldSpan>
       {order.paintOrderStatus === 0
        ? 'Nie zamówiony'
        : order.paintOrderStatus === 1
        ? 'Zamówiony'
        : order.paintOrderStatus === 2
        ? 'Odebrany'
        : '?'}
      </BoldSpan>
     </StyledStatus>
    </div>
   )}
   {permissionContext === 'admin' && (
    <div>
     <StyledStatus size={15}>
      Status płatności:{' '}
      <BoldSpan>{order.isPaid ? 'Opłacone' : 'Nieopłacone'}</BoldSpan>
     </StyledStatus>
    </div>
   )}
   {permissionContext === 'user' && (
    <div>
     {isLostItemsInOrder(order) && (
      <div>
       <small style={{ fontWeight: 'bold', color: 'red' }}>
        Zamówienie nie jest kompletne
       </small>
      </div>
     )}
     {isItemsCorrectedInOrder(order) && (
      <div>
       <small style={{ fontWeight: 'bold', color: 'orange' }}>
        Zamówienie zawiera elementy do poprawy
       </small>
      </div>
     )}
    </div>
   )}
   <StyledButton
    size="sm"
    onClick={() => setModal('_ordersInProduction')}
    disabled={!ordersInProduction.length}
   >
    Zamówienia na produkcji
   </StyledButton>
  </StyledWrapper>
 );
};

export default withContext(Status);
