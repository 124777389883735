import React, { useState } from 'react';
import Modal from 'templates/ModalTemplate';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { updateEmployeeData } from 'services/apiRequests/employees/update';
import { setEmployee } from 'services/store/actions/employees';
import { employeePositions } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';

const EmployeePositions = ({ closeModal, employee }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const [positions, setPositions] = useState(
  employee.positions.map((item) => item.toLowerCase()),
 );

 const handleChange = (e) => {
  const { value, checked } = e.target;
  if (checked) {
   if (!positions.includes(value)) setPositions(positions.concat(value));
  } else {
   setPositions(positions.filter((item) => item !== value));
  }
 };

 const handleSubmit = async () => {
  dispatch(setSpinner(true));

  await updateEmployeeData(
   employee._id,
   { positions },
   (employee) => {
    dispatch(setSpinner(false));
    alert.success(`Zaktualizowano stanowiska`);
    dispatch(setEmployee(employee));
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error(`Błąd!`);
   },
  );
 };

 return (
  <Modal
   title="Zmiana stanowisk"
   closeModal={closeModal}
   onOk={handleSubmit}
   disabled={!positions}
  >
   <div>
    <Form.Group>
     {employeePositions.map((item) => {
      return (
       <Form.Check
        key={item}
        className={item}
        value={item}
        type="checkbox"
        checked={positions.includes(item)}
        id={item}
        label={firstLetterToUpperCase(item)}
        onChange={handleChange}
       />
      );
     })}
    </Form.Group>
   </div>
  </Modal>
 );
};

export default EmployeePositions;
