import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_USER,
  AUTH_LOAD_USER,
  AUTH_SET_MESSAGES,
} from '../types/auth';

const initialState = {
  token: localStorage.token,
  isAuthenticated: false,
  user: {
    _id: null,
    permission: null,
    firstname: null,
    surname: null,
    company: null,
    email: null,
    isAccepted: null,
  },
  messages: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOAD_USER: {
      localStorage.setItem('permission', action.permission);
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
        messages: action.messages,
      };
    }
    case AUTH_LOGIN_SUCCESS: {
      localStorage.setItem('token', action.token);
      return { ...state, isAuthenticated: true, token: action.token };
    }
    case AUTH_LOGIN_ERROR: {
      localStorage.removeItem('token');
      localStorage.removeItem('permission');
      return { ...initialState };
    }
    case AUTH_LOGOUT_USER: {
      localStorage.removeItem('token');
      localStorage.removeItem('permission');
      return { ...initialState };
    }
    case AUTH_SET_MESSAGES: {
      return { ...state, messages: action.messages };
    }
    default:
      return state;
  }
};

export { authReducer };
