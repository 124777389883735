import Customer from './Basic/Customer';
import Name from './Basic/Name';
import PaintType from './Basic/PaintType';
import Elements from './Basic/Elements';
import Surface from './Basic/Surface';
import Type from './Basic/Type';
import Date from './Basic/Date';
import FinishDate from './Basic/FinishDate';
import PickUpDate from './Basic/PickUpDate';
import PaymentStatus from './Production/PaymentStatus';
import Status from './Basic/Status';
import LastOperation from './Production/LastOperation';
import LastEdit from './Production/LastEdit';
import Icons from './Production/Icons';
import Checkbox from './Production/Checkbox';
import Action from './Production/Action';
import Color from './Basic/Color';
import PaintValue from './PaintsOrder/PaintValue';
import SkippedOrder from './PaintsOrder/SkippedOrder';
import ProducerCell from './PaintsOrder/ProducerCell';

export default {
 Customer,
 Action,
 Name,
 PaintType,
 Elements,
 Surface,
 Type,
 Date,
 FinishDate,
 PickUpDate,
 PaymentStatus,
 Status,
 LastOperation,
 LastEdit,
 Icons,
 Checkbox,
 Color,
 PaintValue,
 SkippedOrder,
 ProducerCell,
};
