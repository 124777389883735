import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ScrollUpButton from 'react-scroll-up-button';
import PrivateRoute from 'hoc/PrivateRoute';
import Spinner from 'components/spinner';

// Sing
import Sign from 'scenes/Sign';

// Layout
import Navigation from 'components/navigation';
import MainTemplate from 'templates/MainTemplate';
// import Footer from 'components/molecules/footer/Footer';

// Stats
import ProductionStatistics from 'scenes/Statistics/Production';
import EmployeesStatistics from 'scenes/Statistics/Employee';
import CustomersStatistics from 'scenes/Statistics/Customer';

// Timetable
import Timetable from 'scenes/Timetables/Timetable';
import TimetablePosition from 'scenes/Timetables/Position';

// Users
import UserPrices from 'scenes/UserPrices';
import Customers from 'scenes/UsersList/Customers';
import GlobalSettings from 'scenes/Settings/Global';
import Employee from 'scenes/UsersProfiles/Employee';
import Customer from 'scenes/UsersProfiles/Customer';
import AccountSettings from 'scenes/Settings/Account';
import EmployeesList from 'scenes/UsersList/Employees';
import PaymentsPage from 'scenes/Payments';

// Orders
import HomePage from 'scenes/Home';
import Order from 'scenes/Order/View';
import Production from 'scenes/Production/';
import PaintsOrders from 'scenes/PaintsOrders';
import NewOrder from 'scenes/OrderForm/NewOrder';
import EditOrder from 'scenes/OrderForm/EditOrder';
import OrderSummary from 'scenes/Order/FormSummary';

// Catalog
import Colors from 'scenes/Catalog/Colors';
import Veneers from 'scenes/Catalog/Veneers';
import Handles from 'scenes/Catalog/Handles';
import Millings from 'scenes/Catalog/Millings';
import GlassCases from 'scenes/Catalog/GlassCases';
import CustomElements from 'scenes/Catalog/CustomElements';
import ElementDetails from 'scenes/Catalog/ElementDetails';

// Other
import Regulations from 'scenes/Regulations/';
import Contact from 'scenes/Contact';

import UserNotAccepted from 'components/modals/Users/UserNotAccepted';

import setAuthToken from 'services/utils/setAuthToken';
import { loadUserData } from 'services/store/actions/auth';
import {
  setEmployeeActivePosition,
  setEmployeePaintRoom,
} from 'services/store/actions/employee';
import CuttingPage from 'scenes/Cutting/CuttingPage';
import NewCuttingPackage from 'scenes/NewCuttingPackage';
import CuttingPackage from 'scenes/CuttingPackage';

// IF IS TOKEN, SET TO HEADERS IN AXIOS REQUESTS
if (localStorage.token) setAuthToken(localStorage.token);

// LOAD USER DATA
const App = () => {
  const dispatch = useDispatch();
  const isSpinner = useSelector(state => state.view.isSpinner);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);

  //  GET USER
  useEffect(() => {
    dispatch(loadUserData());
  }, []);
  useEffect(() => {
    if (user.permission === 'employee') {
      const position = localStorage.getItem('activePosition');
      if (position && user.positions.includes(position))
        dispatch(setEmployeeActivePosition(position));
      else dispatch(setEmployeeActivePosition(user.positions[0]));

      const paintRoom = localStorage.getItem('paintRoom');
      if (paintRoom) dispatch(setEmployeePaintRoom(paintRoom));
    }
  }, [user]);
  return (
    <div
      style={{
        minHeight: '100vh',
        position: 'relative',
        paddingBottom: isAuthenticated ? 50 : 0,
      }}
    >
      {isAuthenticated && user.isAccepted === false && <UserNotAccepted />}
      {isSpinner && <Spinner />}
      <ScrollUpButton />
      <Router>
        <MainTemplate>
          <>
            <Navigation />
            <Switch>
              <Route path="/login" component={Sign} />
              <Route path="/register" component={Sign} />
              <Route path="/passwordremind" component={Sign} />
              <Route exact path="/" component={HomePage} />
              <Route path="/regulations" component={Regulations} />
              <Route exact path="/order/:id" component={Order} />
              <PrivateRoute
                permissions={['admin', 'user']}
                path="/order/:id/edit"
                component={EditOrder}
              />
              <PrivateRoute
                permissions={['admin', 'user']}
                exact
                path="/neworder"
                component={NewOrder}
              />
              <PrivateRoute
                permissions={['admin', 'user']}
                path="/neworder/summary"
                component={OrderSummary}
              />
              <PrivateRoute
                permissions={['admin', 'user']}
                path="/editorder/summary"
                component={OrderSummary}
              />
              <PrivateRoute
                permissions={['admin', 'employee']}
                exact
                path="/timetable"
                component={Timetable}
              />
              <PrivateRoute
                permissions={['admin']}
                exact
                path="/cutting"
                component={CuttingPage}
              />
              <PrivateRoute
                permissions={['admin', 'employee']}
                exact
                path="/cutting/package/:id"
                component={CuttingPackage}
              />
              <PrivateRoute
                permissions={['admin']}
                exact
                path="/cutting/new"
                component={NewCuttingPackage}
              />
              <PrivateRoute
                permissions={['admin', 'employee']}
                exact
                path="/:position/timetable/"
                component={TimetablePosition}
              />
              <PrivateRoute
                permissions={['admin']}
                exact
                path="/production"
                component={Production}
              />
              <PrivateRoute
                permissions={['admin', 'employee']}
                exact
                path="/paints"
                component={PaintsOrders}
              />
              <PrivateRoute
                permissions={['admin']}
                path="/statistics/production"
                component={ProductionStatistics}
              />
              <PrivateRoute
                permissions={['admin', 'employee']}
                path="/statistics/employees"
                component={EmployeesStatistics}
              />
              <PrivateRoute
                permissions={['admin']}
                path="/statistics/customers"
                component={CustomersStatistics}
              />
              <PrivateRoute
                permissions={['admin']}
                exact
                path="/customers"
                component={Customers}
              />
              <PrivateRoute
                permissions={['admin']}
                path="/customers/:customerId"
                component={Customer}
              />
              <PrivateRoute
                permissions={['user', 'admin']}
                path="/prices"
                component={UserPrices}
              />
              <PrivateRoute
                permissions={['user']}
                path="/contact"
                component={Contact}
              />
              <PrivateRoute
                permissions={['admin']}
                exact
                path="/payments"
                component={PaymentsPage}
              />
              <PrivateRoute
                permissions={['admin']}
                exact
                path="/employees"
                component={EmployeesList}
              />
              <PrivateRoute
                permissions={['admin']}
                path="/employees/:employeeId"
                component={Employee}
              />
              <Route path="/catalog/colors" component={Colors} />
              <Route path="/catalog/veneers" component={Veneers} />
              <Route path="/catalog/handles" component={Handles} />
              <Route path="/catalog/millings" component={Millings} />
              <Route path="/catalog/glassCases" component={GlassCases} />
              <Route path="/catalog/customs" component={CustomElements} />
              <Route
                path="/catalog/element/:name"
                exact
                component={ElementDetails}
              />

              <PrivateRoute
                permissions={['admin']}
                path="/settings"
                exact
                component={GlobalSettings}
              />
              <Route
                path="/settings/account"
                exact
                component={AccountSettings}
              />
            </Switch>
          </>
        </MainTemplate>
      </Router>
    </div>
  );
};

export default App;
