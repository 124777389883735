import Axios from 'axios';

export const orderRemove = async (orderId, onEnd, onError) => {
 try {
  await Axios.delete(`/api/orders/${orderId}`);

  onEnd();
 } catch (error) {
  console.log(error.response);
  onError();
 }
};
