/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader, StyledH3 } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import { firstLetterToUpperCase } from 'services/utils/string';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExchangeAlt,
  faEnvelope,
  faFill,
} from '@fortawesome/free-solid-svg-icons';
import { BlinkingMessageAnimation } from 'theme/animations';
import styled from 'styled-components';
import { getOrdersByStatus } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import OrdersList from 'components/ordersList/static';
import { getMessagesForPosition } from 'services/store/actions/messages';
import { isUnreadedMessageInArray } from 'services/utils/messages';
import { getTimetablesForPosition } from 'services/store/actions/timetables';
import { setEmployeePaintRoom } from 'services/store/actions/employee';
import Messages from './components/Modals/Messages';
import Filters from './components/Filters';
import ChangePosition from './components/Modals/ChangePosition';
import TransportView from './components/TranportView';

const MessageButton = styled.button`
  padding: 6px 12px;
  border: 1px solid ${({ theme }) => theme.dillakDark};
  border-radius: 5px;
  animation: ${({ ismessage }) =>
      ismessage ? BlinkingMessageAnimation : 'none'}
    2s infinite;
`;

const initModals = {
  changePosition: false,
  messages: false,
  scanOrder: false,
  tableElements: false,
};

const EmployeeHomePage = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector(state => state.auth.user);
  const position = useSelector(state => state.employee.activePosition);
  const paintRoom = useSelector(state => state.employee.paintRoom);
  const orders = useSelector(state => state.orders.orders);
  const timetable = useSelector(state => state.timetables.timetables);
  const filters = useSelector(state => state.orders.filters.employee);
  const messages = useSelector(state => state.messages);

  const [isLoading, setIsLoading] = useState(false);
  const [modals, setModals] = useState(initModals);
  const [list, setList] = useState([]);
  const [scheduleOrders, setScheduleOrders] = useState([]);

  const getData = async () => {
    dispatch(
      getMessagesForPosition(
        position,
        () => {},
        () => {
          alert.error('Błąd!');
        }
      )
    );
    if (position !== 'transport') {
      const statuses = [position];
      if (position === 'pakowanie') statuses.push('zakończone');
      setIsLoading(true);
      dispatch(
        getOrdersByStatus(
          statuses,
          () => {
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
            alert.error('Błąd!');
          }
        )
      );
    }
    dispatch(
      getTimetablesForPosition(
        position,
        () => {
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
          alert.error('Błąd!');
        }
      )
    );
  };
  // get timetable for position

  useEffect(() => {
    if (position) {
      getData();
    }
    const interval = setInterval(() => {
      setIsLoading(true);
      getData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [position]);

  useEffect(() => {
    let filteredOrders = orders;
    if (orders) {
      if (filters.customer)
        filteredOrders = filteredOrders.filter(
          item => item.user._id === filters.customer
        );

      if (filters.color)
        filteredOrders = filteredOrders.filter(item =>
          item.color.includes(filters.color)
        );

      if (filters.paintType)
        filteredOrders = filteredOrders.filter(
          item => item.paintType === filters.paintType
        );
    }
    setList(filteredOrders);
  }, [orders, filters]);

  useEffect(() => {
    if (
      timetable &&
      timetable[position] &&
      timetable[position].length &&
      timetable[position][0].orders.length
    ) {
      let newScheduleOrders = Array.from(
        timetable[position][0].orders.map(order => ({
          ...order.order,
          actions: order.actions,
        }))
      );
      if (position === 'lakiernia' && paintRoom) {
        newScheduleOrders = newScheduleOrders.filter(order =>
          [`${paintRoom}`, '0'].includes(`${order.paintRoom}`)
        );
      }
      setScheduleOrders(newScheduleOrders);
    }
  }, [timetable, paintRoom]);

  const handleModal = key => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);

  return (
    <PageTemplate>
      <PageHeader
        title={
          <span>
            {user.firstname}{' '}
            <sup className={position}>
              {position && firstLetterToUpperCase(position)}
              {position === 'lakiernia' && <sup>{paintRoom}</sup>}
            </sup>
          </span>
        }
        extra={[
          <div key={0}>
            {isLoading && <Spinner animation="border" variant="success" />}
          </div>,
          position === 'lakiernia' && (
            <Button
              key={3}
              variant={paintRoom === 1 ? 'primary' : 'outline-primary'}
              onClick={() =>
                dispatch(setEmployeePaintRoom(paintRoom === 1 ? 2 : 1))
              }
            >
              <FontAwesomeIcon icon={faFill} /> Zmień lakiernię na{' '}
              {paintRoom === 1 ? 2 : 1}
            </Button>
          ),
          <MessageButton
            key={1}
            ismessage={isUnreadedMessageInArray(user, messages)}
            onClick={() => handleModal('messages')}
          >
            <FontAwesomeIcon icon={faEnvelope} /> Wiadomości
          </MessageButton>,
          <Button
            key={2}
            variant="success"
            onClick={() => handleModal('changePosition')}
          >
            <FontAwesomeIcon icon={faExchangeAlt} /> Zmień stanowisko
          </Button>,
        ]}
      />
      {position === 'transport' ? (
        timetable && timetable[position] && timetable[position].length ? (
          <TransportView timetable={timetable[position]} />
        ) : null
      ) : (
        <>
          {scheduleOrders.length ? (
            <>
              <StyledH3>W pierwszej kolejności</StyledH3>
              <OrdersList
                orders={scheduleOrders}
                listName="default"
                position={position}
                status
                lastOperation
                actions
              />
            </>
          ) : null}
          <Filters />
          <OrdersList
            orders={list}
            listName="employee"
            position={position}
            status
            lastOperation
            summary
            legend
          />
        </>
      )}
      {/* MODALS */}
      {modals.changePosition && <ChangePosition closeModal={closeModal} />}
      {modals.messages && <Messages closeModal={closeModal} />}
      {/* scan order */}
    </PageTemplate>
  );
};

export default EmployeeHomePage;
