export const getHingeHolesPrice = (order, prices) => {
  const { orderType, hingesHoles, hingeHolesDrilledBy, items } = order;
  const { services } = prices;

  if (orderType.toLowerCase() === 'reklamacja (wina dillak)') return 0;
  else if (
    ['materiał klienta', 'poprawa (wina klienta)'].includes(
      orderType.toLowerCase()
    )
  ) {
    // drilled by dillak
    if (hingeHolesDrilledBy === 'dillak')
      return hingesHoles * services.hingeHole;
    else {
      //   order includes items from dillak
      if (items.some(item => item.dillakMaterial)) {
        const dillakDrilledHoles = items.reduce((holes, item) => {
          if (item.dillakMaterial) {
            return (holes += itemHolesQuantity(item));
          }
          return holes;
        }, 0);

        return dillakDrilledHoles * services.hingeHole;
      } else return 0;
    }
  }
  return hingesHoles * services.hingeHole;
};

const itemHolesQuantity = ({ h1L, h2L, w1L, w2L }) => {
  let holes = 0;
  if (h1L.toLowerCase().includes('otw')) holes += parseInt(h1L[0]);
  if (h2L.toLowerCase().includes('otw')) holes += parseInt(h2L[0]);
  if (w1L.toLowerCase().includes('otw')) holes += parseInt(w1L[0]);
  if (w2L.toLowerCase().includes('otw')) holes += parseInt(w2L[0]);
  return holes;
};
