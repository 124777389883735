/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';
import withContext from 'hoc/withContext';
import { lighten } from 'polished';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Cells from '../components/cells';

const StyledTableRow = styled.tr`
  cursor: pointer;
  background-color: ${({
    status,
    isNeedPlan,
    theme,
    listName,
    inProduction,
    isHalfGriding,
  }) => {
    if (isNeedPlan) return lighten(0.12, theme.dillakWarning);
    if (listName === 'production') return theme.positions[status] || '';
    if (inProduction) return lighten(0.12, theme.dillakGreenLight);
    if (isHalfGriding) return '#fff3cb';

    return 'none';
  }};
`;

const ListRow = props => {
  const {
    order,
    index,
    pickUpDate,
    status,
    edited,
    paymentStatus,
    handleClickRow,
    lastOperation,
    listName,
    checkbox,
    onCheck,
    checked,
    paintsOrder,
    redirect = true,
    desc,
    permissionContext,
    actions,
  } = props;
  const history = useHistory();

  const position = useSelector(state => state.employee.activePosition);

  const [isRedirect, setIsRedirect] = useState(redirect);

  const handleClick = e => {
    const isNotClicable = e.target.getAttribute(`data-isnotclickable`);
    if (!isRedirect || isNotClicable) return;
    if (handleClickRow) return handleClickRow(order);
    return history.push(`/order/${order._id}`);
  };
  return (
    <StyledTableRow
      onClick={handleClick}
      status={order.status[0]}
      listName={listName}
      inProduction={order.inProduction.includes(position)}
      isHalfGriding={
        permissionContext === 'employee' &&
        position === 'szlifiernia' &&
        order.isHalfGriding
      }
      isNeedPlan={
        permissionContext === 'admin' &&
        listName === 'new' &&
        order.user.isVip &&
        !order.productionPlan.length
      }
    >
      {checkbox && (
        <Cells.Checkbox onChange={onCheck} checked={checked} order={order} />
      )}
      {edited && <Cells.LastEdit order={order} />}
      <Cells.Icons index={index} order={order} position={position} />
      {actions && <Cells.Action order={order} />}
      <Cells.Customer user={order.user} />
      <td>{order.number.toUpperCase()}</td>
      <Cells.Name name={order.name} />
      <Cells.Color order={order} />
      <Cells.PaintType order={order} />
      <Cells.Elements order={order} />
      <Cells.Surface order={order} surface="surfaceRight" />
      <Cells.Surface order={order} surface="surfaceLeft" />
      <Cells.Type order={order} />
      <Cells.Date date={order.date} />
      <Cells.FinishDate date={order.finishDate} />
      {status && <Cells.Status {...props} order={order} position={position} />}
      {paintsOrder && <Cells.SkippedOrder {...props} order={order} />}
      {paintsOrder && <Cells.PaintValue {...props} order={order} />}
      {paintsOrder && <Cells.ProducerCell {...props} order={order} />}
      {lastOperation && <Cells.LastOperation order={order} />}
      {pickUpDate && (
        <Cells.PickUpDate
          pickUpDate={order.pickUpDate}
          transportDate={order.transportDate}
        />
      )}
      {paymentStatus && (
        <Cells.PaymentStatus order={order} setIsRedirect={setIsRedirect} />
      )}
      {desc && (
        <td>
          <small>{order.desc}</small>
        </td>
      )}
    </StyledTableRow>
  );
};

export default withContext(ListRow);
