import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import FlexRow from 'templates/FlexRowTemplate';
import { Form, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import List from './List';

const Items = ({ setImportDimensions }) => {
 const [tabIndex, setTabIndex] = useState(0);

 return (
  <Fade bottom>
   <>
    <FlexRow justify="space-between">
     <FlexRow justify="flex-start">
      <Form.Check
       custom
       type="checkbox"
       onChange={e => setTabIndex(e.target.checked ? -1 : 0)}
       checked={tabIndex}
       id="fastWriteCheckbox"
       label="Szybkie uzupełnianie"
      />{' '}
      <OverlayTrigger trigger="click" placement="right" overlay={FastWriteInfo}>
       <FontAwesomeIcon
        icon={faQuestion}
        style={{
         opacity: 0.5,
         margin: 3,
         cursor: 'pointer',
        }}
       />
      </OverlayTrigger>
     </FlexRow>
     <Button
      size="sm"
      onClick={() => setImportDimensions(true)}
      variant="outline-primary"
     >
      Import wymiarów
     </Button>
    </FlexRow>
    <List tabIndex={tabIndex} />
   </>
  </Fade>
 );
};

export default Items;

const FastWriteInfo = (
 <Popover id="popover-basic">
  <Popover.Title as="h3">Szybkie uzupełnianie</Popover.Title>
  <Popover.Content>
   Jeżeli opcja jest zaznaczona, przycisk TAB zmienia pozycję kursora tylko
   pomiędzy okienkami <strong>wysokości, szerokości i ilości</strong> elementów,
   pomijając inne
  </Popover.Content>
 </Popover>
);
