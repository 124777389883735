/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React from 'react';
import { dateToString } from 'services/utils/date';
import { weekDays } from 'services/utils/const';
import styled from 'styled-components';
import { lighten } from 'polished';
import { firstLetterToUpperCase } from 'services/utils/string';
import { Table } from 'react-bootstrap';
import withContext from 'hoc/withContext';
import { getOrderCustomerName } from 'services/utils/orders';
import { useSelector } from 'react-redux';
import { getDaySurfaces } from '../../Modals/utils';

const Card = styled.div`
 width: 100%;
 min-height: 200px;
 margin-bottom: 5px;
 border-radius: 5px;
 border: 1px solid lightgray;
 background-color: ${({ pos, theme }) => {
  if (pos === 'szlifiernia' || pos === 'transport')
   return lighten(0.4, theme.positions[pos]);
  if (pos === 'pakowanie' || pos === 'surówka' || pos === 'podkład')
   return lighten(0.3, theme.positions[pos]);
  if (pos === 'polernia') return lighten(0.35, theme.positions[pos]);
  if (pos === 'lakiernia') return lighten(0.15, theme.positions[pos]);
  return '#f0f0f0';
 }};
 transition: transform 0.2s;
 &:hover {
  cursor: pointer;
  transform: scale(1.05);
 }
 @media (max-width: 600px) {
  margin: 10px 0;
  transform: none;
 }
`;
const Bar = styled.div`
 display: flex;
 justify-content: space-between;
 border-bottom: ${({ position }) => {
  if (position === 'top') return '1px solid gray';
  return 'none';
 }};
 border-top: ${({ position }) => {
  if (position === 'bottom') return '1px solid gray';
  return 'none';
 }};
 padding: 3px;
 background-color: ${({ dayName }) => {
  if (dayName === 'sobota') return lighten(0.2, 'green');
  if (dayName === 'niedziela') return lighten(0.2, 'red');
  return 'inherit';
 }};
`;
const Content = styled.div`
 height: 260px;
 overflow-y: auto;
`;

const DayCard = ({ day, handleModal, permissionContext }) => {
 const user = useSelector(state => state.auth.user);
 const dayName = weekDays[new Date(day.date).getDay()];

 const values = getDaySurfaces(day.orders);

 if (day)
  return (
   <>
    <Card
     pos={day.position}
     onClick={() => {
      if (day.position !== 'lakiernia') {
       if (handleModal && permissionContext === 'admin')
        handleModal('day', day);
      } else if ((user && user.isManager) || permissionContext === 'admin')
       handleModal('paintRoom', day);
     }}
    >
     <Bar position="top" dayName={dayName}>
      <span>{dateToString(day.date)}</span>
      <strong>{firstLetterToUpperCase(dayName)}</strong>
     </Bar>
     <Content>
      <DayTable day={day} />
     </Content>
     <Bar position="bottom" dayName={dayName}>
      <small>
       <span>CNC: </span>
       {values.cnc.toFixed(1)}
      </small>
      <small>
       <span>Pow: </span>
       {values.surface.toFixed(1)}
      </small>
      <small>
       <span>Elem: </span>
       {values.elements}
      </small>
     </Bar>
    </Card>
   </>
  );
 return null;
};

export default withContext(DayCard);

const DayTable = ({ day }) => {
 const { orders } = day;
 if (orders.length)
  return (
   <Table size="sm" bordered>
    <thead>
     <tr>
      <th>Nazwa</th>
      <th>Nr</th>
      <th>Status</th>
     </tr>
    </thead>
    <tbody>
     {orders &&
      orders.map((order, i) => {
       const isAfterDeadline = isAfterPlanedDate(order, day);

       if (order && order.order)
        return (
         <tr key={`${order._id}${i}`} style={{ lineHeight: 0.9 }}>
          <td
           style={
            isAfterDeadline
             ? { backgroundColor: 'rgba(214,49,49,1)', color: 'white' }
             : {}
           }
          >
           <small>
            {order && order.order && getOrderCustomerName(order.order)}
           </small>
          </td>
          <td>
           <small>{order.order.number}</small>
          </td>
          <td>
           {order.order &&
            order.order.status &&
            order.order.status.map(item => {
             let paintRoom = order.order.paintRoom;
             return (
              <small className={item} key={item}>
               {item && firstLetterToUpperCase(item.slice(0, 3))}.
               {item === 'lakiernia' && <sup>{paintRoom || ''}</sup>}{' '}
              </small>
             );
            })}
          </td>
         </tr>
        );
       return null;
      })}
    </tbody>
   </Table>
  );
 return null;
};

const isAfterPlanedDate = ({ planedOn }, { date }) => {
 const today = new Date();
 const dayDate = new Date(date);
 let result = false;

 if (dayDate.getDate() !== today.getDate()) return false;

 if (planedOn) {
  const planedDate = new Date(planedOn);
  if (dayDate.getTime() - planedDate.getTime() >= 86400000) result = true;
 }

 return result;
};
