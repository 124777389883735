import { StyledH3, StyledH6 } from 'components/header';
import OrdersList from 'components/ordersList/static';
import React from 'react';
import { Button } from 'react-bootstrap';
import FlexRow from 'templates/FlexRowTemplate';
import ItemsList from './ItemsList/ItemsList';

const PackageOrder = ({
  order,
  orderIndex,
  handleRemoveOrderFromPackage,
  handleItemStatus,
  handleMachining,
}) => {
  return (
    <div>
      <FlexRow justify="space-between">
        <StyledH3>
          {order.user.company} - {order.user.firstname} nr. {order.number}
        </StyledH3>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={handleRemoveOrderFromPackage}
        >
          Usuń z listy
        </Button>
      </FlexRow>
      <OrdersList orders={[order]} listName="new" />
      <StyledH6>Lista elementów</StyledH6>
      <ItemsList
        order={order}
        orderIndex={orderIndex}
        handleItemStatus={handleItemStatus}
        handleMachining={handleMachining}
      />
      <hr style={{ marginBottom: 50, marginTop: 30 }} />
    </div>
  );
};

export default PackageOrder;
