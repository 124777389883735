import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import ListRow from './ListRow';
import { addNewOrderItem } from 'services/store/actions/newOrder';

const WideColumn = styled.th`
  width: 95px;
`;
const Th = styled.th`
  vertical-align: middle;
  width: 50px;
  background-color: ${({ side, theme }) => {
    if (side === 'left') return theme.dillakWarning;
    if (side === 'right') return theme.dillakGreen;
  }};
`;
const TighterColumn = styled.th`
  width: 20px;
  background-color: ${({ side, theme }) =>
    side === 'left' && theme.dillakWarning};
`;

const List = ({ tabIndex }) => {
  const dispatch = useDispatch();
  const order = useSelector(state => state.newOrder);

  useEffect(() => {
    if (order.items.length === 0) dispatch(addNewOrderItem());
  }, []);

  return (
    <>
      <Table striped bordered hover size="sm" responsive="md">
        <thead>
          <tr>
            {['Materiał klienta', 'Poprawa (wina klienta)'].includes(
              order.orderType,
            ) && (
              <Th rowSpan={4}>
                <small>Materiał DILLAK</small>
              </Th>
            )}
            <Th rowSpan="4">Lp</Th>
            <WideColumn rowSpan="4">Typ</WideColumn>
            <th colSpan="6">Wysokość</th>
            <th colSpan="6">Szerokość</th>
            <Th rowSpan="4">
              Grubość płyty
              <br />
              <small>[mm]</small>
            </Th>
            <Th rowSpan="4">Ilość</Th>
            <th rowSpan="2" colSpan={order.transparent ? 3 : 2}>
              Lakierowane strony
            </th>
            {order.muntinType && (
              <th rowSpan="2" colSpan={order.muntinType === '+' ? 2 : 1}>
                Szprosy
              </th>
            )}

            <Th rowSpan="4">Rys</Th>
            <th rowSpan="4">Uwagi</th>
          </tr>
          <tr>
            <Th rowSpan="2">
              wys.
              <br />
              <small>[mm]</small>
            </Th>

            <td colSpan="2">Uchwyt frezowany/R</td>
            <td colSpan="2">Otwory pod zawiasy(szt)/R</td>
            <TighterColumn
              rowSpan="2"
              side="left"
              style={{ fontWeight: 'normal', fontSize: 12 }}
            >
              <div>Lakier lewa 100mm po wys</div>
            </TighterColumn>
            <Th rowSpan="2">
              szer.
              <br />
              <small>[mm]</small>
            </Th>
            <td colSpan="2">Uchwyt frezowany/R</td>
            <td colSpan="2">Otwory pod zawiasy(szt)/R</td>
            <TighterColumn
              rowSpan="2"
              side="left"
              style={{ fontWeight: 'normal', fontSize: 12 }}
            >
              <div>Lakier lewa 100mm po szer</div>
            </TighterColumn>
          </tr>
          <tr>
            <Th side="right">W1</Th>
            <Th side="right">W2</Th>
            <Th side="left">W1'</Th>
            <Th side="left">W2'</Th>
            <Th side="right">S1</Th>
            <Th side="right">S2</Th>
            <Th side="left">S1'</Th>
            <Th side="left">S2'</Th>
            <Th side="right">P</Th>
            <Th side="left">L</Th>
            {order.muntinType === 'x' && (
              <Th
                style={{ fontSize: 12 }}
                title="Ilość szprosów w kszt. X"
              >
                Ilość [X]
              </Th>
            )}
            {order.muntinType === '+' && (
              <>
                <Th
                  style={{ fontSize: 12 }}
                  title="Ilość szprosów PIONOWYCH"
                >
                  Ilość |{' '}
                </Th>
                <Th
                  style={{ fontSize: 12 }}
                  title="Ilość szprosów POZIOMYCH"
                >
                  Ilość -{' '}
                </Th>
              </>
            )}
            {order.transparent && (
              <Th
                side="left"
                style={{ fontSize: 12, fontWeight: 'normal' }}
              >
                Bezb. 2str
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) => (
            <ListRow
              key={index}
              item={item}
              order={order}
              index={index}
              tabIndex={tabIndex}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default List;
