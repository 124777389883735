import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { authUser } from 'services/apiRequests/auth/get';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import {
  logInSuccess,
  loadUserData,
  logInError,
} from 'services/store/actions/auth';
import { schema } from './utils/validateLoginForm';

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledContent = styled.div`
  width: 70%;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const StyledH1 = styled.h1`
  margin-bottom: 30px;
  font-size: 50px;
  @media (max-width: 600px) {
    font-size: 35px;
    margin-bottom: 10px;
  }
`;
const StyledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  background-color: ${({ theme }) => theme.dillakGreen};
  color: white;
  border: none;
  font-size: 20px;
  letter-spacing: 3px;
  padding: 10px 30px;
  transition: transform 0.2s;
  &:hover {
    transform: translateX(-10px);
  }
  @media (max-width: 600px) {
    padding: 3px 20px;
  }
`;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <StyledContainer>
      <StyledButton onClick={() => history.push('/register')}>
        <FontAwesomeIcon icon={faArrowLeft} /> Zarejestruj
      </StyledButton>
      <StyledContent>
        <StyledH1>Zaloguj się</StyledH1>
        <Formik
          validationSchema={schema}
          // ONSUBMIT REQUEST
          onSubmit={async (values, actions) => {
            dispatch(setSpinner(true));
            await authUser(
              values,
              token => {
                dispatch(logInSuccess(token));
                dispatch(loadUserData());
              },
              error => {
                console.log(error);
                dispatch(logInError());
                actions.setErrors({
                  email: 'Nieprawidłowe dane logowania',
                  password: 'Nieprawidłowe dane logowania',
                });
              }
            );
            dispatch(setSpinner(false));
          }}
          initialValues={{
            email: '',
            password: '',
          }}
          render={({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* SPINNER */}

              <Form.Group controlId="formEmail">
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.email && !errors.email}
                  placeholder="Adres email*"
                  className={errors.email && touched.email && 'is-invalid'}
                />
                {errors.email && touched.email && (
                  <div className="invalid">{errors.email}</div>
                )}
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Control
                  required
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.password && !errors.password}
                  placeholder="Hasło*"
                  className={
                    errors.password && touched.password && 'is-invalid'
                  }
                />
                {errors.password && touched.password && (
                  <div className="invalid">{errors.password}</div>
                )}
              </Form.Group>
              <Form.Text style={{ textAlign: 'right' }}>
                <Link to="/passwordremind" style={{ color: 'grey' }}>
                  <span>Nie pamiętam hasła</span>
                </Link>
              </Form.Text>
              <div>
                <Button
                  type="submit"
                  variant="success"
                  style={{
                    margin: '5px 0',
                    padding: '10px 0',
                    letterSpacing: 5,
                    width: '100%',
                  }}
                >
                  Zaloguj
                </Button>
              </div>

              <Form.Text className="text-muted" style={{ fontSize: 14 }}>
                Nie posiadasz konta?{' '}
                <Link to="/register" className="text--signin">
                  <strong style={{ color: 'black' }}>Załóż je!</strong>
                </Link>
              </Form.Text>
            </Form>
          )}
        />
      </StyledContent>
    </StyledContainer>
  );
};

export default Login;
