import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { skipOrder, removeSkipedOrder } from 'services/store/actions/paints';

const SkippedOrder = ({ order: { _id, quantity } }) => {
 const dispatch = useDispatch();
 const skipped = useSelector((state) => state.paints.skipped);

 const handleChange = ({ target: { checked } }) => {
  if (checked) dispatch(skipOrder(_id));
  else dispatch(removeSkipedOrder(_id));
 };
 return (
  <td data-isnotclickable="1">
   <input
    disabled={quantity}
    data-isnotclickable="1"
    style={{ margin: '5px' }}
    type="checkbox"
    checked={skipped.includes(_id)}
    onChange={handleChange}
   />
  </td>
 );
};

export default SkippedOrder;
