import {
  CUTTING_PACKAGE_SET_ITEM_STATUS,
  CUTTING_PACKAGE_SET_FIELD,
  CUTTING_PACKAGE_SET_ITEM_MACHINING,
} from '../types/newCuttingPackage';

export const cuttingPackageSetField = (field, value) => ({
  type: CUTTING_PACKAGE_SET_FIELD,
  field,
  value,
});

export const cuttingPackageSetItemStatus = (
  orderIndex,
  itemIndex,
  status,
) => ({
  type: CUTTING_PACKAGE_SET_ITEM_STATUS,
  orderIndex,
  itemIndex,
  status,
});

export const cuttingPackageSetItemMachning = (
  orderIndex,
  itemIndex,
  value,
) => ({
  type: CUTTING_PACKAGE_SET_ITEM_MACHINING,
  orderIndex,
  itemIndex,
  value,
});
