import React, { useEffect, useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { StyledH1 } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import FlexRow from 'templates/FlexRowTemplate';
import { Button } from 'react-bootstrap';
import { substractSimilarObjects } from 'services/utils/object';
import {
  calcNewOrderPrice,
  clearNewOrder,
} from 'services/store/actions/newOrder';
import { Redirect, useHistory, useLocation } from 'react-router';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { sendOrder } from 'services/apiRequests/orders/post';
import Legend from '../components/Order/Legend';
import SurfaceSummary from '../components/Order/SurfaceSummary';
import Summary from '../components/Order/Summary';
import ItemsList from '../components/Order/ItemsList';
import Data from '../components/Order/Data';

const SubmitButton = styled(Button)`
  padding: 10px 30px;
  letter-spacing: 5px;
`;
const Wrapper = styled.div`
  width: 49%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const NewOrderSummary = () => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const globalPrices = useSelector((state) => state.settings.prices);
  const order = useSelector((state) => state.newOrder);

  const [prices, setPrices] = useState(null);

  useEffect(() => {
    if (order.userPrices && globalPrices) {
      const userPrices = substractSimilarObjects(
        globalPrices,
        order.userPrices,
      );
      setPrices(userPrices);
    }
  }, [order.userPrices, globalPrices]);

  useEffect(() => {
    if (prices) dispatch(calcNewOrderPrice(prices));
  }, [prices]);

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await sendOrder(
      { ...order, user: order.user?._id || order.user },
      pathname.includes('neworder') ? 'new' : 'edit',
      (id) => {
        dispatch(setSpinner(false));
        alert.success('Zamówienie utworzone pomyślnie');
        dispatch(clearNewOrder());
        return history.push(`/order/${id}`);
      },
      (err) => {
        dispatch(setSpinner(false));
        console.log(err);
        if (err && err.toString().length > 25) alert.error('Błąd!');
        else alert.error(`${err}`);
        return history.push(`/order/${order._id}`);
      },
    );
  };

  if (!order.items.length) return <Redirect to="/neworder" />;

  return (
    <PageTemplate>
      <StyledH1>
        {order.userName} -{' '}
        {typeof order.number === 'string'
          ? order.number.toUpperCase()
          : order.number}
      </StyledH1>
      <Data order={order} />
      <ItemsList order={order} />
      <FlexRow justify="space-between" wrap>
        <Wrapper />
        <Wrapper>
          <Summary order={order} />
          <SurfaceSummary order={order} />
          <Legend />
        </Wrapper>
      </FlexRow>
      <SubmitButton disabled={!order.elements} onClick={handleSubmit}>
        Wyślij
      </SubmitButton>
    </PageTemplate>
  );
};

export default NewOrderSummary;
