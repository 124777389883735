import {
 PAINTS_SET_ORDER_VALUE,
 PAINTS_SET_INIT,
 PAINTS_ADD_SKIPPED,
 PAINTS_REMOVE_SKIPPED,
 PAINTS_HANDLE_OTHER,
 PAINTS_CLEAR,
 PAINTS_ADD_ORDERS,
} from '../types/paints';
import update from 'immutability-helper';
import { getIdsFromPaints } from './utils/paints';

const initialState = {
 paints: null,
 skipped: [],
 other: { paints: null, other: null },
};

const paintsReducer = (state = initialState, action) => {
 switch (action.type) {
  case PAINTS_SET_ORDER_VALUE: {
   return update(state, {
    paints: {
     [action.index]: { [action.field]: { $set: action.value } },
    },
   });
  }
  case PAINTS_SET_INIT: {
   return { ...state, [action.field]: action.values };
  }
  case PAINTS_ADD_SKIPPED: {
   return update(state, {
    skipped: { $push: [action.orderId] },
   });
  }
  case PAINTS_REMOVE_SKIPPED: {
   const newSkipped = state.skipped.filter((item) => item !== action.orderId);
   return { ...state, skipped: newSkipped };
  }
  case PAINTS_HANDLE_OTHER: {
   return update(state, {
    other: {
     [action.key]: {
      [action.index]: { quantity: { $set: action.value } },
     },
    },
   });
  }
  case PAINTS_ADD_ORDERS: {
   return update(state, {
    paints: { $unshift: action.orders },
   });
  }
  case PAINTS_CLEAR: {
   const orders = getIdsFromPaints(action.paints);
   const paints = state.paints.filter((item) => !orders.includes(item._id));
   const other = {
    paints: state.other.paints.map((item) => {
     item.quantity = 0;
     return item;
    }),
    other: state.other.other.map((item) => {
     item.quantity = 0;
     return item;
    }),
   };
   return { ...state, paints, skipped: [], other };
  }
  default:
   return state;
 }
};

export { paintsReducer };
