import { MESSAGES_LOAD_ERROR, MESSAGES_LOAD_SUCCESS } from '../types/messages';
import setAuthToken from 'services/utils/setAuthToken';
import Axios from 'axios';

export const messagesLoadSuccess = messages => ({
 type: MESSAGES_LOAD_SUCCESS,
 messages,
});
export const messagesLoadError = () => ({
 type: MESSAGES_LOAD_ERROR,
});

export const getAllMessages = (onEnd, onErr) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);
 try {
  const res = await Axios.get(`/api/messages/`);

  dispatch(messagesLoadSuccess(res.data.messages));
  onEnd();
 } catch (error) {
  console.log(`ERROR_MESSAGES_LOAD: `, error.response);
  dispatch(messagesLoadError());
  onErr();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};

export const getMessagesForPosition = (
 position,
 onEnd,
 onErr,
) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);
 try {
  const res = await Axios.get(`/api/messages/${position}`);

  dispatch(messagesLoadSuccess(res.data.messages));
  onEnd();
 } catch (error) {
  console.log(`ERROR_MESSAGES_LOAD: `, error.response);
  dispatch(messagesLoadError());
  onErr();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};
