import {
 EMPLOYEES_LIST_LOAD_SUCCESS,
 EMPLOYEES_LIST_LOAD_ERROR,
 EMPLOYEES_SET_SORT,
 EMPLOYEES_SET_EMPLOYEE,
 EMPLOYEES_CLEAR_EMPLOYEE_DATA,
} from '../types/employees';

import setAuthToken from 'services/utils/setAuthToken';
import Axios from 'axios';

export const loadEmployeesSuccess = employees => ({
 type: EMPLOYEES_LIST_LOAD_SUCCESS,
 employees,
});
export const loadEmployeesError = () => ({ type: EMPLOYEES_LIST_LOAD_ERROR });
export const setSortEmployeesList = (sortBy, direction) => ({
 type: EMPLOYEES_SET_SORT,
 sortBy,
 direction,
});
export const setEmployee = employee => ({
 type: EMPLOYEES_SET_EMPLOYEE,
 employee,
});
export const clearEmployeeData = () => ({
 type: EMPLOYEES_CLEAR_EMPLOYEE_DATA,
});

export const getEmployees = (onEnd, onError) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);
 try {
  const res = await Axios.get('/api/employees/');
  const { employees } = res.data;

  if (res.status === 200) dispatch(loadEmployeesSuccess(employees));
  onEnd();
 } catch (error) {
  console.log(`EMPLOYEESTOMERS_LOAD: `, error.response);
  dispatch(loadEmployeesError());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};

export const getEmployeeData = (
 employeeId,
 onEnd,
 onError,
) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);

 const res = await Axios.get(`/api/employees/${employeeId}`);
 const { employee } = res.data;

 if (res.status === 200) dispatch(setEmployee(employee));
 onEnd();
 try {
 } catch (error) {
  console.log(`THUNK_EMPLOYEE_DATA: `, error.response);
  dispatch(clearEmployeeData());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};
