import React from 'react';
import Modal from 'templates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/FormInputs/TextInput';
import * as yup from 'yup';

const AddEmail = ({ closeModal, emails, setFieldValue }) => {
 return (
  <Modal
   title="Dodaj email"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" type="submit" form="addEmailModal">
      Dodaj
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   <Formik
    validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async (values) => {
     const newEmails = emails.concat(values);
     setFieldValue('emails', newEmails);
     closeModal();
    }}
    initialValues={{
     name: '',
     email: '',
    }}
    render={(props) => {
     return (
      <Form noValidate onSubmit={props.handleSubmit} id="addEmailModal">
       <TextInput {...props} name="name" label="Nazwa" required />
       <TextInput {...props} name="email" label="E-mail" required />
      </Form>
     );
    }}
   />
  </Modal>
 );
};

export default AddEmail;

const schema = yup.object({
 name: yup.string().required('Nazwa jest wymagana'),
 email: yup
  .string()
  .email('Nieprawidłowy format adresu e-mail')
  .required('Email jest wymagany'),
});
