import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { authReducer } from './reducers/auth';
import { viewReducer } from './reducers/view';
import { ordersReducer } from './reducers/orders';
import { customersReducer } from './reducers/customers';
import { settingsReducer } from './reducers/settings';
import { newOrderReducer } from './reducers/newOrder';
import { employeesReducer } from './reducers/employees';
import { messagesReducer } from './reducers/messages';
import { employeeReducer } from './reducers/employee';
import { paintsReducer } from './reducers/paints';
import { timetablesReducer } from './reducers/timetables';
import { statisticsReducer } from './reducers/statistics';
import { catalogReducer } from './reducers/catalog';
import { newCuttingPackageReducer } from './reducers/newCuttingPackage';

const rootReducer = combineReducers({
  auth: authReducer,
  view: viewReducer,
  orders: ordersReducer,
  catalog: catalogReducer,
  customers: customersReducer,
  employees: employeesReducer,
  employee: employeeReducer,
  settings: settingsReducer,
  newOrder: newOrderReducer,
  messages: messagesReducer,
  paints: paintsReducer,
  timetables: timetablesReducer,
  statistics: statisticsReducer,
  newCuttingPackage: newCuttingPackageReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
