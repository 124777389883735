import { containsOneOf } from 'services/utils/array';
import {
  veneerHandles,
  veneerExcludedHandles,
} from 'services/utils/const';
import { getFileExtension } from 'services/utils/files';
import { handleItemEdges } from './orderItems';

export const addMuntinType = (state, muntinType) => {
  if (!muntinType) {
    const items = state.items.map(item => {
      return { ...item, muntinH: 0, muntinW: 0, muntinX: 0 };
    });
    return { ...state, muntinType: null, items };
  }

  return { ...state, muntinType };
};

// Adding veener to order is available when order has no milling/handles, or as one of handles suitable for veneer. All items right edges must be changed to '-' from 'r1/r2'.
export const addVeneer = (state, veneer) => {
  const { handles, millings, items } = state;

  if (
    containsOneOf(veneerExcludedHandles, handles) ||
    (millings.length && !millings.includes('ryflowany'))
  )
    return { ...state };

  const newItems = items.map(item => {
    const edges = ['r1', 'r2'];
    handleItemEdges(true, false, item, (edgeValue, edgeName) => {
      if (edges.includes(edgeValue)) item[edgeName] = '-';
    });
    return item;
  });

  return { ...state, veneer, items: newItems };
};

// Adding handle to order is avaiable when order has no veneer/color(bejca), or if has veneer/color(bejca) and handle is one of suitable to veneers.
export const addHandle = (state, handle) => {
  const { color, veneer } = state;

  if (veneer || color.toLowerCase().includes('bejca')) {
    if (veneerHandles.includes(handle.toLowerCase()))
      return pushItemToArray(state, 'handles', handle);
    return { ...state };
  }

  return pushItemToArray(state, 'handles', handle);
};

// Remove handle, removes this handle from all items
export const removeHandle = (state, handle) => {
  const isGloss = state.paintType === 'połysk';

  state.items = state.items.map(item => {
    handleItemEdges(true, true, item, (edgeValue, edgeName) => {
      if (edgeValue === handle) item[edgeName] = isGloss ? 'r2' : 'r1';
    });
    return item;
  });
  const handles = state.handles.filter(item => item !== handle);

  return { ...state, handles };
};

// Adding milling to order is available then order has no veneer/color(bejca)
export const addMilling = (state, milling) => {
  if (milling && state.items.length === 1)
    if (!state.items[0].width && !state.items[0].height) {
      if (milling === 'frez') state.items[0].type = 'frez';
      if (milling === 'gładki CNC') state.items[0].type = 'gładki CNC';
    }

  return {
    ...state,
    millings: [milling.symbol],
    millingPricingGroup: milling.pricingGroup || 1,
  };
};

// Adding custom milling picture to order is available then order has no veneer/color(bejca)
export const addCustomMilling = (state, file) => {
  const ext = getFileExtension(file.name);
  const customMilling = {
    path: `customMilling.${ext}`,
    file,
  };

  return {
    ...state,
    customMilling,
    millings: ['Ze zdjęcia'],
    millingPricingGroup: 1,
  };
};

// Remove milling, must change items type
export const removeMilling = (state, milling) => {
  const items = state.items.map(item => {
    if (item.type.toLowerCase() === 'frez') {
      item.type = 'gładki';
      item.muntinH = 0;
      item.muntinW = 0;
      item.muntinX = 0;
    } else if (item.type.toLowerCase() === 'gładki cnc')
      item.type = 'gładki';
    else if (item.type.toLowerCase() === 'ryflowany') item.type = 'gładki';
    return item;
  });

  return {
    ...state,
    items,
    millings: [],
    millingPricingGroup: 1,
    customMilling: { path: '', file: null },
    muntinType: null,
  };
};

// Adding glassCase to order is available then order has no veneer/color(bejca)
export const addGlassCase = (state, glassCase) => {
  const { veneer, color } = state;

  if (!glassCase || glassCase === '') {
    const items = state.items.map(item => {
      if (item.type.toLowerCase() === 'witryna') {
        item.type = 'gładki';
        item.muntinH = 0;
        item.muntinW = 0;
        item.muntinX = 0;
      }
      return item;
    });
    return { ...state, glassCase, items, muntinType: null };
  }
  if (
    (veneer || color.toLowerCase().includes('bejca')) &&
    glassCase.toLowerCase() !== 'w4'
  )
    return { ...state };
  return {
    ...state,
    glassCase: glassCase.symbol,
    glassCasePricingGroup: glassCase.pricingGroup || 1,
  };
};

// Adding paintStyle to order must change all items edges
export const addPaintType = (state, paintType) => {
  let { items } = state;

  if (state.items.length) {
    if (paintType === 'połysk')
      items = state.items.map(item => {
        handleItemEdges(true, false, item, (edgeValue, edgeName) => {
          if (edgeValue === 'r1') item[edgeName] = 'r2';
        });
        return item;
      });
    else
      items = state.items.map(item => {
        handleItemEdges(true, false, item, (edgeValue, edgeName) => {
          if (edgeValue === 'r2') item[edgeName] = 'r1';
        });
        return item;
      });
  }
  return { ...state, items, paintType };
};

// Adding paintStyle to two painted sides, first item is changed to two sides paint
export const addPaintStyle = (state, paintStyle) => {
  let { paintType } = state;
  if (paintStyle === 'prawa - połysk / lewa - półmat')
    paintType = 'połysk';

  if (!paintStyle !== 'jednostronne' && state.items.length === 1)
    if (!state.items[0].width && !state.items[0].height)
      state.items[0].paintLeft = true;

  return { ...state, paintStyle, paintType };
};

// Setting felc/nut on false, must remove value from all items left edges
export const setBackMilling = (state, backMillingType, value) => {
  const name = backMillingType === 'isFelc' ? 'felc' : 'nut';
  let { items } = state;

  if (!value)
    items = state.items.map(item => {
      handleItemEdges(false, true, item, (edgeValue, edgeName) => {
        if (edgeValue === name) item[edgeName] = '-';
      });
      return item;
    });

  return { ...state, items, [backMillingType]: value };
};

// set chamfering on false, must remove value from all items edges
export const setChamfering = (state, isChamfering) => {
  let { items } = state;
  const isGloss = state.paintType === 'połysk';

  if (!isChamfering) {
    items = items.map(item => {
      handleItemEdges(true, false, item, (value, key) => {
        if (value === 'gierunek') item[key] = isGloss ? 'r2' : 'r1';
      });
      handleItemEdges(false, true, item, (value, key) => {
        if (value === 'gierunek') item[key] = '-';
      });

      return item;
    });
  }

  return { ...state, items, isChamfering };
};

// HELPERS
const pushItemToArray = (state, key, item) => {
  if (!state[key].includes(item))
    return { ...state, [key]: [...state[key], item] };
  return { ...state };
};
