import React, { useState, useEffect } from 'react';
import Modal from 'templates/ModalTemplate';
import { Button, Table } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { getPaintOrdersByDate } from 'services/apiRequests/paintsOrders/get';
import { setSpinner } from 'services/store/actions/view';
import { setOrder } from 'services/store/actions/orders';
import { dateToString } from 'services/utils/date';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getPaintOrder } from 'services/apiRequests/files/get';
import { pickUpPaintsOrder } from 'services/apiRequests/paintsOrders/update';
import FlexRow from 'templates/FlexRowTemplate';

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 20px;
  transition: color 0.2s;
  &:hover {
    color: ${({ theme }) => theme.dillakGreen};
  }
`;
const Wrapper = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const initDates = {
  from: new Date(new Date().setDate(new Date().getDate() - 28)),
  to: new Date(),
};
const OrdersModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [dates, setDates] = useState(initDates);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getData = async () => {
      dispatch(setSpinner(true));
      const data = await getPaintOrdersByDate(
        dates.from,
        dates.to,
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        }
      );
      return setOrders(data);
    };
    if (dates.from && dates.to) getData();
  }, [dates]);

  const handleDate = (field, value) => setDates({ ...dates, [field]: value });
  const handleDownload = async id => {
    dispatch(setSpinner(true));
    await getPaintOrder(
      id,
      () => {
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd');
      }
    );
  };
  const confirmPickUp = async id => {
    dispatch(setSpinner(true));
    await pickUpPaintsOrder(
      id,
      order => {
        dispatch(setSpinner(false));
        alert.success('Zatwierdzono odbiór');

        const newOrders = orders.map(item => {
          if (item._id === id) item = order;
          return item;
        });
        setOrders(newOrders);
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      }
    );
  };
  return (
    <Modal
      title="Złożone zamówienia"
      size="xl"
      closeModal={closeModal}
      footer={
        <>
          <Button variant="danger" onClick={closeModal}>
            Zamknij
          </Button>
        </>
      }
    >
      <FlexRow justify="flex-start">
        <div>
          Od{' '}
          <DatePicker
            value={dates.from}
            onChange={date => handleDate('from', date)}
          />
        </div>
        <div>
          {' '}
          Do{' '}
          <DatePicker
            value={dates.to}
            onChange={date => handleDate('to', date)}
          />
        </div>
      </FlexRow>
      <br />
      <Wrapper>
        {orders.length && (
          <List
            orders={orders}
            confirmPickUp={confirmPickUp}
            handleDownload={handleDownload}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

export default OrdersModal;

const List = ({ orders, handleDownload, confirmPickUp }) => {
  return (
    <Table bordered responsive="lg" striped hover size="sm">
      <thead>
        <tr>
          <th>Lp</th>
          <th>Data</th>
          <th>Zamawiał</th>
          <th>Kolory</th>
          <th>Inne</th>
          <th>Pobierz</th>
          <th>Odbiór</th>
        </tr>
      </thead>
      <tbody>
        {orders
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((item, i) => {
            return (
              <tr key={item._id}>
                <td>{i + 1}</td>
                <td>{dateToString(item.date)}</td>
                <td>{item.user}</td>
                <td>
                  <small>
                    {item.colors.map(color => `${color.name} ${color.type}, `)}
                  </small>
                </td>
                <td>
                  <small>
                    {item.other.map(other => `${other.name} ${other.type}, `)}
                  </small>
                </td>
                <td>
                  <StyledIcon
                    icon={faDownload}
                    onClick={() => handleDownload(item._id)}
                  />
                </td>
                <td>
                  {item.isPicked ? (
                    <strong style={{ color: 'green' }}>Odebrane</strong>
                  ) : (
                    <Button size="sm" onClick={() => confirmPickUp(item._id)}>
                      Potwierdź
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
