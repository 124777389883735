/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { addNewOrderItem } from 'services/store/actions/newOrder';
import OrderProperties from '../OrderProperties';
import OrderData from '../OrderData';
import OrderInfo from '../OrderInfo';
import Items from '../Items';
import MechanicalBy from './components/MechanicalBy';
import ImportDataFromFile from '../Modals/ImportDataFromFile';

const Hr = styled.hr`
  margin: 30px 0;
`;
const Row = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
`;
const NextButton = styled(Button)`
  padding: 10px 30px;
  letter-spacing: 8px;
`;
const ColorInfo = styled.h1`
  text-align: center;
  font-weight: bold;
  letter-spacing: 10px;
  color: ${({ theme, color }) => theme[color]};
`;

const OrderForm = ({ order, handleNext }) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();

  const [newItems, setNewItems] = useState('');
  const [isMachining, setIsMachining] = useState(false);
  const [importDimensions, setImportDimensions] = useState(false);

  const scrollToBottom = () =>
    setTimeout(
      () => scrollTo.current.scrollIntoView({ behavior: 'smooth' }),
      200
    );

  const handleNewItems = ({ target: { value } }) => {
    const numbers = /^(\s*|\d+)$/;
    if (!value.match(numbers)) return;
    setNewItems(value);
  };

  const handleAddItems = () => {
    if (!newItems) {
      dispatch(addNewOrderItem());
      scrollToBottom();
    } else {
      for (let i = 0; i < newItems; i++) {
        dispatch(addNewOrderItem());
      }
    }
    setNewItems('');
  };

  const goToSummary = () => {
    if (
      ['Materiał klienta', 'Poprawa (wina klienta)'].includes(
        order.orderType
      ) &&
      ((order.items.some(item => !item.dillakMaterial) &&
        order.handles.length) ||
        order.items.some(item => checkIsItemHas('otw', false, true, item)) ||
        order.items.some(item => checkIsItemHas('nut', false, true, item)) ||
        order.items.some(item => checkIsItemHas('felc', false, true, item)) ||
        order.items.some(item => checkIsItemHas('gierunek', true, true, item)))
    )
      return setIsMachining(true);
    return handleNext();
  };
  return (
    <div>
      <OrderProperties order={order} />
      <Hr />
      <OrderData order={order} />
      <Hr />
      <OrderInfo order={order} />
      <Hr />

      {!order.color ? (
        <ColorInfo color="dillakGreen">Musisz wybrać kolor</ColorInfo>
      ) : order.color.includes('bejca') && !order.veneer ? (
        <ColorInfo color="dillakWarning">Musisz wybrać fornir</ColorInfo>
      ) : (
        <>
          <Items order={order} setImportDimensions={setImportDimensions} />

          <Row>
            <NextButton size="xl" variant="success" onClick={goToSummary}>
              Dalej
            </NextButton>
            <div>
              <InputGroup>
                <FormControl
                  placeholder="liczba pozycji"
                  value={newItems}
                  onChange={handleNewItems}
                />
                <InputGroup.Append>
                  <Button variant="outline-primary" onClick={handleAddItems}>
                    Dodaj
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Row>
        </>
      )}

      <div ref={scrollTo} />
      {/* MODALS */}
      {isMachining && (
        <MechanicalBy
          order={order}
          closeModal={() => setIsMachining(false)}
          goNext={handleNext}
        />
      )}
      {importDimensions && (
        <ImportDataFromFile closeModal={() => setImportDimensions(false)} />
      )}
    </div>
  );
};

export default OrderForm;

const checkIsItemHas = (value, right, left, item) => {
  const { h1L, h2L, w1L, w2L, h1P, h2P, w1P, w2P } = item;
  if (
    !item.dillakMaterial &&
    left &&
    (h1L.toLowerCase().includes(value.toLowerCase()) ||
      h2L.toLowerCase().includes(value.toLowerCase()) ||
      w1L.toLowerCase().includes(value.toLowerCase()) ||
      w2L.toLowerCase().includes(value.toLowerCase()))
  )
    return true;
  if (
    !item.dillakMaterial &&
    right &&
    (h1P.toLowerCase().includes(value.toLowerCase()) ||
      h2P.toLowerCase().includes(value.toLowerCase()) ||
      w1P.toLowerCase().includes(value.toLowerCase()) ||
      w2P.toLowerCase().includes(value.toLowerCase()))
  )
    return true;
  return false;
};
