import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OrdersList from 'components/ordersList/static';
import { flatString } from 'services/utils/string';

const EndedOrdersList = ({ orders, ...props }) => {
 const [list, setList] = useState(orders);
 const filters = useSelector((state) => state.orders.filters.ended);
 const { company, color, name, number } = filters;

 useEffect(() => {
  if (orders) {
   let newList = orders;
   if (company)
    newList = newList.filter((order) => order.user._id.includes(company));
   if (color)
    newList = newList.filter((order) =>
     flatString(order.color).includes(flatString(color)),
    );
   if (name)
    newList = newList.filter((order) =>
     flatString(order.name).includes(flatString(name)),
    );
   if (number)
    newList = newList.filter((order) =>
     flatString(order.number).includes(flatString(number)),
    );
   setList(newList);
  }
 }, [company, number, name, color, orders]);

 return <OrdersList orders={list} {...props} />;
};

export default EndedOrdersList;
