import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faTrashAlt,
 faEdit,
 faDownload,
 faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';

const CustomerButtons = ({ order, setModal, editOrder, getFiles }) => {
 return (
  <>
   <Button
    variant="danger"
    disabled={order.status[0].toLowerCase() !== 'wysłane'}
    onClick={() => setModal('_remove')}
   >
    <FontAwesomeIcon icon={faTrashAlt} /> Usuń
   </Button>
   <Button
    style={{
     backgroundColor: '#6f42c1',
     borderColor: '#6f42c1',
     color: 'white',
    }}
    onClick={editOrder}
    disabled={order.status[0].toLowerCase() !== 'wysłane'}
   >
    <FontAwesomeIcon icon={faEdit} /> Edytuj
   </Button>
   <Button onClick={() => setModal('_neworder')}>
    <FontAwesomeIcon icon={faPlusSquare} /> Zamów
   </Button>
   <Button variant="secondary" onClick={getFiles}>
    <FontAwesomeIcon icon={faDownload} /> Pobierz
   </Button>
  </>
 );
};

export default CustomerButtons;
