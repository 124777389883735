import React, { useState, useEffect } from 'react';
import Modal from 'templates/ModalTemplate';
import styled from 'styled-components';
import { lighten } from 'polished';
import { DragDropContext } from 'react-beautiful-dnd';
import { StyledH3 } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import { getProductionOrders } from 'services/store/actions/orders';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { updateDayTimetable } from 'services/apiRequests/timetable/update';
import { getAllTimetables } from 'services/store/actions/timetables';
import OrdersList from './OrdersList';
import ProductionList from './ProductionList';

const Container = styled.div`
  min-height: 75vh;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${({ theme }) =>
    lighten(0.15, theme.positions.lakiernia)};
  padding-bottom: 5px;
  height: 350px;
`;
const List = styled.div`
  width: 32%;
`;

const PaintRoomEdit = ({ closeModal, day }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const productionOrders = useSelector(
    (state) => state.orders.productionOrders,
  );
  const [productionList, setProductionList] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [orders, setOrders] = useState({
    paintRoom1: [],
    paintRoom2: [],
    rest: [],
  });

  useEffect(() => {
    if (!productionOrders) {
      dispatch(setSpinner(true));
      dispatch(
        getProductionOrders(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, []);
  useEffect(() => {
    if (productionOrders && productionOrders.length) {
      const newProductionList = productionOrders.filter((order) => {
        if (order.status.includes('lakiernia')) {
          if (order.productionPlan) {
            const hasPlanedPainting = order.productionPlan.find(
              (item) => item.position === 'lakiernia',
            );
            if (!hasPlanedPainting) return order;
          }
        }
      });
      setProductionList(newProductionList);
    }
  }, [productionOrders]);

  useEffect(() => {
    if (day) {
      const paintRoom1 = [];
      const paintRoom2 = [];
      const rest = [];
      day.orders.forEach((order, i) => {
        if (order && order.order) {
          if (order.order.paintRoom === 1)
            paintRoom1.push({ id: i, ...order });
          else if (order.order.paintRoom === 2)
            paintRoom2.push({ id: i, ...order });
          else if (order.order.paintRoom === 0)
            rest.push({ id: i, ...order });
        }
      });
      setOrders({ paintRoom1, paintRoom2, rest });
    }
  }, [day]);

  const handleDragEnd = (result) => {
    const { destination, source } = result;
    let paintRoom = 0;
    if (destination.droppableId === 'paintRoom1') paintRoom = 1;
    else if (destination.droppableId === 'paintRoom2') paintRoom = 2;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const start = orders[source.droppableId];
    const finish = orders[destination.droppableId];
    const draggedItem = start[source.index];
    draggedItem.order.paintRoom = paintRoom;

    if (start === finish) {
      const newList = Array.from(start);
      newList.splice(source.index, 1);
      newList.splice(destination.index, 0, draggedItem);

      const newState = {
        ...orders,
        [source.droppableId]: newList,
      };
      setOrders(newState);
      return;
    }

    // Moving from one list to another

    const newSourceList = Array.from(start);
    newSourceList.splice(source.index, 1);

    const newDestinationList = Array.from(finish);
    newDestinationList.splice(destination.index, 0, draggedItem);

    const newState = {
      ...orders,
      [source.droppableId]: newSourceList,
      [destination.droppableId]: newDestinationList,
    };
    setOrders(newState);
  };

  const handleRemove = (listName, index, order) => {
    if (listName && index >= 0) {
      const newList = Array.from(orders[listName]);
      newList.splice(index, 1);

      const newState = {
        ...orders,
        [listName]: newList,
      };
      setOrders(newState);

      if (!removed.includes(order._id))
        setRemoved([...removed, order._id]);
      setProductionList([...productionList, order]);
    }
  };
  const handleAdd = (order, actions, index) => {
    const obj = { order, actions, planedOn: day.date, id: Math.random() };
    const newProductionList = Array.from(productionList);
    newProductionList.splice(index, 1);
    setProductionList(newProductionList);
    const newState = { ...orders, rest: [...orders.rest, obj] };
    setOrders(newState);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateDayTimetable(
      {
        orders: orders.rest.concat(orders.paintRoom1, orders.paintRoom2),
        removed,
      },
      day._id,
      () => {
        dispatch(
          getAllTimetables(
            () => {
              dispatch(setSpinner(false));
              //    closeModal();
              alert.success('Harmonogram zaktualizowany');
            },
            () => {
              dispatch(setSpinner(false));
              alert.error('Błąd!');
            },
          ),
        );
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="Edycja dnia [LAKIERNIA]"
      closeModal={closeModal}
      onOk={handleSubmit}
      dialogClassName="mybootstrapmodal"
    >
      <Container>
        <Wrapper>
          <DragDropContext onDragEnd={handleDragEnd}>
            <List>
              <StyledH3 style={{ textAlign: 'center', fontSize: 18 }}>
                Lakiernia 1
              </StyledH3>
              <OrdersList
                orders={orders.paintRoom1}
                droppableId="paintRoom1"
                handleRemove={handleRemove}
              />
            </List>
            <List>
              <StyledH3 style={{ textAlign: 'center', fontSize: 18 }}>
                - - -
              </StyledH3>
              <OrdersList
                orders={orders.rest}
                droppableId="rest"
                handleRemove={handleRemove}
              />
            </List>
            <List>
              <StyledH3 style={{ textAlign: 'center', fontSize: 18 }}>
                Lakiernia 2
              </StyledH3>
              <OrdersList
                orders={orders.paintRoom2}
                droppableId="paintRoom2"
                handleRemove={handleRemove}
              />
            </List>
          </DragDropContext>
        </Wrapper>
        <ProductionList orders={productionList} handleAdd={handleAdd} />
      </Container>
    </Modal>
  );
};

export default PaintRoomEdit;
