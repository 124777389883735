import React from 'react';
import { dateToString } from 'services/utils/date';

const PickUpDate = ({ pickUpDate, transportDate }) => {
 return (
  <td>
   {pickUpDate && dateToString(pickUpDate)}
   {transportDate ? (
    <small>
     <strong title={dateToString(transportDate)}>(T)</strong>
    </small>
   ) : null}
  </td>
 );
};

export default PickUpDate;
