import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { sortMethod } from 'services/utils/sortMethods';
import { useSelector, useDispatch } from 'react-redux';
import { setSortOrders } from 'services/store/actions/orders';
import ListRow from './ListRow';
import Legend from '../components/Legend';
import Summary from '../components/Summary';

const StyledTh = styled.th`
  cursor: pointer;
`;

const OrdersList = (props) => {
  const {
    orders,
    pickUpDate,
    paymentStatus,
    status,
    edited,
    lastOperation,
    listName = null,
    legend,
    summary,
    checkbox,
    paintsOrder,
    position = null,
    desc,
    actions,
  } = props;

  const dispatch = useDispatch();
  const sort = useSelector((state) => state.orders.sort[listName]);
  const { sortBy, direction } = sort;

  const [sortDirection, setSortDirection] = useState(direction);

  useEffect(() => {
    setSortDirection(direction === 'ascend' ? 'descend' : 'ascend');
  }, [sortBy, direction]);

  const handleSort = (by, dir) =>
    listName && dispatch(setSortOrders(listName, by, dir));
  return (
    <div>
      <Table responsive="lg" bordered hover size="sm">
        <thead>
          <tr>
            {checkbox && (
              <th style={{ width: 30, visibility: 'hidden' }} />
            )}
            {edited && <th style={{ width: 30, visibility: 'hidden' }} />}
            <th>LP</th>
            {actions && <th>Akcja</th>}
            <StyledTh onClick={() => handleSort('company', sortDirection)}>
              Klient <FontAwesomeIcon icon={faSort} />
            </StyledTh>
            <StyledTh onClick={() => handleSort('number', sortDirection)}>
              Nr <FontAwesomeIcon icon={faSort} />
            </StyledTh>
            <th>Nazwa</th>
            <StyledTh onClick={() => handleSort('color', sortDirection)}>
              Kolor <FontAwesomeIcon icon={faSort} />
            </StyledTh>
            <StyledTh
              onClick={() => handleSort('paintType', sortDirection)}
            >
              Matowość <FontAwesomeIcon icon={faSort} />
            </StyledTh>
            <th>Elementy</th>
            <th>PL</th>
            <th>PP</th>
            <th>Typ</th>
            <StyledTh onClick={() => handleSort('date', sortDirection)}>
              Data <FontAwesomeIcon icon={faSort} />
            </StyledTh>
            <StyledTh
              onClick={() => handleSort('finishDate', sortDirection)}
            >
              Data realizacji <FontAwesomeIcon icon={faSort} />
            </StyledTh>
            {status && (
              <StyledTh
                onClick={() => handleSort('status', sortDirection)}
              >
                Status <FontAwesomeIcon icon={faSort} />
              </StyledTh>
            )}
            {paintsOrder && <StyledTh>Pomiń</StyledTh>}
            {paintsOrder && (
              <StyledTh>
                Ilość <small>[kg]</small>
              </StyledTh>
            )}
            {paintsOrder && <StyledTh>Producent</StyledTh>}
            {pickUpDate && (
              <StyledTh
                onClick={() => handleSort('pickUpDate', sortDirection)}
              >
                Data odbioru{' '}
                <small title="Transport">
                  (<strong>T</strong>)
                </small>{' '}
                <FontAwesomeIcon icon={faSort} />
              </StyledTh>
            )}
            {lastOperation && <th>Ostatnia operacja</th>}
            {paymentStatus && <th>Status płatności</th>}
            {desc && <th>Opis</th>}
          </tr>
        </thead>
        <tbody>
          {orders && orders.length
            ? orders
                .sort((a, b) => {
                  if (listName === 'default') return;
                  return sortMethod[sortMethod.getSortMethod(sortBy)](
                    a,
                    b,
                    sortBy,
                    direction,
                    position,
                  );
                })
                .map((order, index) => (
                  <ListRow
                    {...props}
                    key={order._id}
                    order={order}
                    index={index}
                  />
                ))
            : null}
        </tbody>
      </Table>
      {summary && <Summary orders={orders} />}
      {legend && <Legend />}
    </div>
  );
};

export default OrdersList;
