import Axios from 'axios';

export const pickUpPaintsOrder = async (orderId, onEnd, onErr) => {
 try {
  const url = `/api/paints/${orderId}`;
  const res = await Axios.put(url);
  const { order } = res.data;

  onEnd(order);
 } catch (error) {
  console.log(error);
  onErr();
 }
};
