import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import { Button } from 'react-bootstrap';
import EmployeeData from './components/EmployeeData';
import { useSelector, useDispatch } from 'react-redux';
import {
 getEmployeeData,
 clearEmployeeData,
} from 'services/store/actions/employees';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router';
import { setSpinner } from 'services/store/actions/view';
import EmployeePermissions from './components/PermissionsChangeModal';
import EmployeeEarnings from './components/EarningsChangeModal';
import EmployeePositions from './components/PositionsChangeModal';
import RemoveEmployeeModal from './components/RemoveEmployeeModal';

const initModals = {
 remove: false,
 permissions: false,
 earnings: false,
 positions: false,
};

const EmployeeProfile = () => {
 const params = useParams();
 const { employeeId } = params;
 const dispatch = useDispatch();
 const alert = useAlert();

 const employee = useSelector((state) => state.employees.employeeData);

 const [modals, setModals] = useState(initModals);

 const getEmployee = () => {
  dispatch(
   getEmployeeData(
    employeeId,
    () => {
     dispatch(setSpinner(false));
    },
    () => {
     dispatch(setSpinner(false));
     alert.error('Błąd!');
    },
   ),
  );
 };

 useEffect(() => {
  if (!employee || employee._id !== employeeId) {
   dispatch(setSpinner(true));
   getEmployee();
  } else {
   getEmployee();
  }
  return () => {
   dispatch(clearEmployeeData());
  };
 }, [employeeId]);

 const handleModal = (key) => setModals({ ...modals, [key]: true });
 const closeModal = () => setModals(initModals);

 if (employee)
  return (
   <PageTemplate>
    <PageHeader
     title="Dane pracownika"
     extra={[
      <Button variant="danger" key={1} onClick={() => handleModal('remove')}>
       Usuń pracownika
      </Button>,
      <Button
       variant="outline-primary"
       key={2}
       onClick={() => handleModal(`permissions`)}
      >
       Zmień uprawnienia
      </Button>,
      <Button
       variant="secondary"
       key={3}
       onClick={() => handleModal(`earnings`)}
      >
       Ustal wynagrodzenie
      </Button>,
      <Button
       variant="outline-dark"
       key={4}
       onClick={() => handleModal(`positions`)}
      >
       Edytuj stanowiska
      </Button>,
     ]}
    />
    <EmployeeData employee={employee} />
    {/* MODALS */}
    {modals.permissions && (
     <EmployeePermissions employee={employee} closeModal={closeModal} />
    )}
    {modals.earnings && (
     <EmployeeEarnings employee={employee} closeModal={closeModal} />
    )}
    {modals.positions && (
     <EmployeePositions employee={employee} closeModal={closeModal} />
    )}
    {modals.remove && (
     <RemoveEmployeeModal userId={employeeId} closeModal={closeModal} />
    )}
   </PageTemplate>
  );
 return null;
};

export default EmployeeProfile;
