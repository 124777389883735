import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader, StyledH6 } from 'components/header';
import PageTemplate from 'templates/PageTemplate';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { setSpinner } from 'services/store/actions/view';
import withContext from 'hoc/withContext';
import FlexRow from 'templates/FlexRowTemplate';
import CuttingPackageStatus from 'components/CuttingPackageStatus/CuttingPackageStatus';
import CuttingPackageOrder from './components/CuttingPackageOrder';
import styled from 'styled-components';
import ItemsList from './components/ItemsList';
import { cuttingPackageStatus } from 'services/utils/const';
import { getCuttingItemsList } from 'services/apiRequests/files/get';
import { cuttingPackageRemove } from 'services/apiRequests/cutting/remove';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router';
import { useModal } from 'hooks/useModal';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ChangeStatusModal from './components/ChangeStatusModal';
import ChangeEmployeeStatusModal from './components/ChangeEmployeeStatusModal';
import { createCSVPackageFile } from './utils';
import { createCSVPackageFileForCNC } from './utils/createCSVFileForCNC';
// import { createCSVPackageFileForCNC } from './utils/createCSVFileForCNC';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  margin: 5px 10px;
  border: 2px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.blowPrimaryLight};
  }
`;

const initModals = {
  changeStatus: false,
  changeEmployeeStatus: false,
  remove: false,
};

const CuttingPackage = ({ match, permissionContext }) => {
  const { params } = match;
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  const { modal, showModal, closeModal } = useModal(initModals);

  const [view, setView] = useState('items');
  const [cuttingPackage, setCuttingPackage] = useState(null);
  const [items, setItems] = useState([]);

  const getCuttingPackage = useCallback(async () => {
    dispatch(setSpinner(true));
    const res = await axios.get(`/api/cutting/${params.id}`);

    setCuttingPackage(res.data.cuttingPackage);
    dispatch(setSpinner(false));
  }, []);

  useEffect(() => {
    getCuttingPackage();
  }, []);

  useEffect(() => {
    if (cuttingPackage) {
      const items = cuttingPackage.orders.reduce((acc, order) => {
        const orderItems = order.items.map((item) => ({
          ...item,
          orderId: order._id,
          orderName: `${order.user.company}${
            order.user.firstname
              ? ` - ${order.user.firstname[0].toUpperCase()}`
              : ''
          } nr. ${order.number}`,
        }));
        return [...acc, ...orderItems];
      }, []);

      setItems(items);
    }
  }, [cuttingPackage]);

  const getList = async () => {
    dispatch(setSpinner(true));
    await getCuttingItemsList(
      params.id,
      () => {
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
      },
    );
  };

  const handleRemove = async () => {
    dispatch(setSpinner(true));
    await cuttingPackageRemove(
      params.id,
      () => {
        dispatch(setSpinner(false));
        alert.success('Paczka cięcia usunięta');
        history.push('/cutting');
      },
      () => {
        dispatch(setSpinner(false));
        alert.success('Błąd');
      },
    );
    closeModal();
  };

  const handleSelect = (e) => setView(e.target.value);

  return (
    <PageTemplate>
      <PageHeader
        title={cuttingPackage?.name || 'Paczka cięcia'}
        extra={[
          permissionContext === 'admin' && (
            <Button
              disabled={[
                cuttingPackageStatus.IN_PROGRESS,
                cuttingPackageStatus.READY,
              ].includes(cuttingPackage?.status)}
              variant="danger"
              key={0}
              onClick={() => showModal('remove')}
            >
              Usuń
            </Button>
          ),
          <Button variant="outline-secondary" key={1} onClick={getList}>
            Pobierz listę elementów
          </Button>,
          // <Button
          //   variant="secondary"
          //   key={2}
          //   onClick={() => createCSVPackageFile(cuttingPackage)}
          // >
          //   Pobierz .csv
          // </Button>,
          <Button
            variant="secondary"
            key={3}
            onClick={() => createCSVPackageFileForCNC(cuttingPackage)}
          >
            Pobierz .csv
          </Button>,
          permissionContext === 'admin' && (
            <Button
              variant="success"
              key={4}
              onClick={() => showModal('changeStatus')}
              disabled={cuttingPackage?.status === 'gotowy'}
            >
              Zmień status
            </Button>
          ),
          permissionContext === 'employee' && (
            <Button
              variant="success"
              key={5}
              onClick={() => showModal('changeEmployeeStatus')}
              disabled={
                cuttingPackage?.status === cuttingPackageStatus.READY ||
                !cuttingPackage
              }
            >
              {cuttingPackage?.status === cuttingPackageStatus.IN_PROGRESS
                ? 'Zakończ'
                : 'Rozpocznij'}
            </Button>
          ),
        ]}
        buttonFormat={false}
      />
      {cuttingPackage && (
        <div>
          <FlexRow justify="space-between">
            <StyledH6>
              Utworzona przez: {cuttingPackage.createdBy.firstname}{' '}
              {cuttingPackage.createdBy.surname}
            </StyledH6>
            <FlexRow justify="space-between">
              <StyledSelect
                name="cuttingPackageView"
                value={view}
                onChange={handleSelect}
              >
                <option value="orders">Zamówienia</option>
                <option value="items">Lista elementów</option>
              </StyledSelect>

              <CuttingPackageStatus
                status={cuttingPackage.status}
                size={26}
              />
            </FlexRow>
          </FlexRow>
          <hr />

          {view === 'orders' &&
            cuttingPackage.orders.map((order) => (
              <CuttingPackageOrder key={order._id} order={order} />
            ))}
          {view === 'items' && (
            <ItemsList order={{ items }} withOrderData />
          )}
        </div>
      )}
      {/* MODALS */}
      {modal.remove && (
        <ConfirmationModal
          closeModal={closeModal}
          title="Usunięcie paczki cięcia"
          description="Usunięcie paczki jest nieodwracalne. Czy chcesz usunąć?"
          handleSubmit={handleRemove}
        />
      )}
      {modal.changeStatus && (
        <ChangeStatusModal
          closeModal={closeModal}
          refetch={getCuttingPackage}
          cuttingPackageId={params.id}
        />
      )}
      {modal.changeEmployeeStatus && cuttingPackage && (
        <ChangeEmployeeStatusModal
          closeModal={closeModal}
          cuttingPackage={cuttingPackage}
        />
      )}
    </PageTemplate>
  );
};

export default withContext(CuttingPackage);
