import React, { useEffect, useState } from 'react';
import withContext from 'hoc/withContext';
import {
 isOrderWetForStaticList,
 isOrderWetForPosition,
} from 'services/utils/orders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faExclamation,
 faComments,
 faClock,
 faTint,
 faTruck,
 faUndo,
 faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

const Icons = ({ index, order, permissionContext, position }) => {
 const [iconsArr, setIconsArr] = useState([]);
 let isWet;
 if (permissionContext === 'admin') isWet = isOrderWetForStaticList(order);
 if (permissionContext === 'employee')
  isWet = isOrderWetForPosition(order, position);

 useEffect(() => {
  if (order) {
   const arr = [];
   if (permissionContext === 'user') {
    if (order.orderType.toLowerCase() === 'materiał klienta')
     arr.push({ title: 'Materiał powierzony', icon: faTruck });
   } else if (['employee', 'display'].includes(permissionContext)) {
    if (order.orderType.toLowerCase() === 'materiał klienta')
     arr.push({ title: 'Materiał powierzony', icon: faTruck });
    if (order.priority) arr.push({ title: 'Priorytet', icon: faExclamation });
    if (order.employeesComments && order.employeesComments.length)
     arr.push({ title: 'Komentarze', icon: faComments });
    if (position !== 'surówka') {
     if (isWet) arr.push({ title: 'Mokre', icon: faTint });
    }
    if (position === 'szlifiernia') {
     if (order.wasInGriding) arr.push({ title: '', icon: faUndo });
    }
   } else if (permissionContext === 'admin') {
    if (order.orderType.toLowerCase() === 'materiał klienta')
     arr.push({ title: 'Materiał powierzony', icon: faTruck });
    if (order.priority) arr.push({ title: 'Priorytet', icon: faExclamation });
    if (order.employeesComments && order.employeesComments.length)
     arr.push({ title: 'Komentarze', icon: faComments });
    if (order.productionPlan && order.productionPlan.length)
     arr.push({ title: 'Zaplanowane', icon: faCalendarAlt });
    if (order.inProduction && order.inProduction.length)
     arr.push({
      title: `${order.inProduction.join(', ')}`,
      icon: faClock,
     });
   }
   setIconsArr(arr);
  }
 }, [order]);

 return (
  <td>
   {iconsArr.length ? (
    <>
     {iconsArr.map((item) => (
      <FontAwesomeIcon
       key={item.title}
       title={item.title}
       icon={item.icon}
       style={{ margin: 1 }}
      />
     ))}
    </>
   ) : (
    index + 1
   )}
  </td>
 );
};

export default withContext(Icons);
