import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import FlexRow from 'templates/FlexRowTemplate';
import TextInput from 'components/FormInputs/TextInput';
import { StyledH3 } from 'components/header';
import { useAlert } from 'react-alert';
import { schema } from './utils/validate';
import { setSpinner } from 'services/store/actions/view';
import { loadUserData } from 'services/store/actions/auth';
import { updateCustomerData } from 'services/apiRequests/customers/update';

const UserData = () => {
 const alert = useAlert();
 const dispatch = useDispatch();
 const user = useSelector((state) => state.auth.user);
 const [isEdit, setIsEdit] = useState(false);

 return (
  <div>
   <Formik
    validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async (values) => {
     dispatch(setSpinner(true));
     await updateCustomerData(
      { ...values },
      () => {
       dispatch(loadUserData());
       alert.success('dane zaktualizowane');
       setIsEdit(false);
      },
      () => {
       alert.error('błąd');
      },
     );
     dispatch(setSpinner(false));
    }}
    initialValues={{
     firstname: user.firstname,
     surname: user.surname,
     email: user.email,
     company: user.company,
     postcode: user.postcode,
     city: user.city,
     street: user.street,
     NIP: user.NIP,
     phone: user.phone,
    }}
    render={(props) => {
     const { handleSubmit } = props;
     return (
      <Form noValidate onSubmit={handleSubmit}>
       <FlexRow justify="flex-start" wrap>
        <div>
         <StyledH3>Dane użytkownika</StyledH3>
         <TextInput
          {...props}
          name="firstname"
          label="Imię"
          placeholder="Imię"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="surname"
          label="Nazwisko"
          placeholder="Nazwisko"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="email"
          label="E-mail"
          placeholder="E-mail"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="phone"
          label="Nr telefonu"
          placeholder="Nr telefonu"
          disabled={!isEdit}
          required
         />
        </div>

        <div>
         <StyledH3>Dane do FV</StyledH3>
         <TextInput
          {...props}
          name="company"
          label="Firma"
          placeholder="Firma"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="postcode"
          label="Kod pocztowy"
          placeholder="Kod pocztowy"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="city"
          label="Miejscowość"
          placeholder="Miejscowość"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="street"
          label="Ulica"
          placeholder="Ulica"
          disabled={!isEdit}
          required
         />
         <TextInput
          {...props}
          name="NIP"
          label="NIP"
          placeholder="NIP"
          disabled={!isEdit}
          required
         />
         <FlexRow justify="flex-end" wrap>
          <Button variant="success" type="submit" disabled={!isEdit}>
           Zatwiedź
          </Button>
          <Button
           variant={isEdit ? 'outline-secondary' : 'secondary'}
           onClick={() => setIsEdit(!isEdit)}
          >
           {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
          </Button>
         </FlexRow>
        </div>
       </FlexRow>
      </Form>
     );
    }}
   />
  </div>
 );
};

export default UserData;
