import React, { useEffect, useState } from 'react';
import ModalTemplate from 'templates/ModalTemplate';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import OrdersList from 'components/ordersList/static';
import { orderItemCuttingStatus } from 'services/utils/const';
import { cuttingPackageSetField } from 'services/store/actions/newCuttingPackage';

const Wrapper = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const PickOrdersToCuttingPackageModal = ({ closeModal, orders }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [toCutElements, setToCutElements] = useState(0);

  useEffect(() => {
    if (list.length) {
      const itemsToCut = orders
        .filter(order => list.includes(order._id))
        .map(order => {
          const items = order.items.map(item => {
            let cuttingStatus = orderItemCuttingStatus.TO_CUT;
            // const isMachiningRequired =
            //   item.isMachiningRequired || isMachningRequired(item);
            if (!item.cuttingStatus) {
              if (!item.dillakMaterial)
                cuttingStatus = orderItemCuttingStatus.SKIPPED;
              if (
                [
                  'frez',
                  'witryna',
                  'ryflowany',
                  'korona',
                  'pilaster',
                ].includes(item.type)
              )
                cuttingStatus = orderItemCuttingStatus.SKIPPED;
              if (item.elementLost && item.elementLost.position === 'piła')
                cuttingStatus = orderItemCuttingStatus.SUSPENDED;
              if (
                item.elementToCorrect &&
                item.elementToCorrect.position === 'piła'
              )
                cuttingStatus = orderItemCuttingStatus.SUSPENDED;
            } else {
              cuttingStatus = item.cuttingStatus;
            }
            return {
              ...item,
              cuttingStatus,
              // isMachiningRequired
            };
          });
          return { ...order, items };
        })
        .reduce((items, order) => [...items, ...order.items], [])
        .filter(
          item => item.cuttingStatus === orderItemCuttingStatus.TO_CUT,
        )
        .reduce((qty, item) => (qty += item.quantity), 0);
      setToCutElements(itemsToCut);
    }
  }, [list]);

  const handleOrders = e => {
    let { checked, value } = e.target;
    const obj = JSON.parse(value);
    value = obj._id;
    if (checked) {
      if (!list.includes(value)) setList(list.concat(value));
    } else {
      const newList = list.filter(item => item !== value);
      setList(newList);
    }
  };

  const handleSubmit = async () => {
    if (list.length) {
      const ordersToAdd = orders
        .filter(order => list.includes(order._id))
        .map(order => {
          const items = order.items.map(item => {
            let cuttingStatus = orderItemCuttingStatus.TO_CUT;
            // const isMachiningRequired =
            //   item.isMachiningRequired || isMachningRequired(item);
            if (!item.cuttingStatus) {
              if (!item.dillakMaterial)
                cuttingStatus = orderItemCuttingStatus.SKIPPED;
              if (
                [
                  'frez',
                  'witryna',
                  'ryflowany',
                  'korona',
                  'pilaster',
                ].includes(item.type)
              )
                cuttingStatus = orderItemCuttingStatus.SKIPPED;
              if (item.elementLost && item.elementLost.position === 'piła')
                cuttingStatus = orderItemCuttingStatus.SUSPENDED;
              if (
                item.elementToCorrect &&
                item.elementToCorrect.position === 'piła'
              )
                cuttingStatus = orderItemCuttingStatus.SUSPENDED;
            } else {
              cuttingStatus = item.cuttingStatus;
            }
            return {
              ...item,
              cuttingStatus,
              // isMachiningRequired
            };
          });
          return { ...order, items };
        });
      dispatch(cuttingPackageSetField('orders', [...ordersToAdd]));
    }
    history.push('/cutting/new');
  };

  return (
    <ModalTemplate
      size="xl"
      title="Wybierz zamówienia do paczki"
      closeModal={closeModal}
      onOk={handleSubmit}
      disabled={!list.length}
    >
      <div>Elementy do wycięcia: {toCutElements}</div>
      <Wrapper>
        {orders && (
          <OrdersList
            orders={orders}
            listName="new"
            checkbox
            onCheck={handleOrders}
            redirect={false}
          />
        )}
      </Wrapper>
    </ModalTemplate>
  );
};

export default PickOrdersToCuttingPackageModal;
