import { handleItemEdges } from './orderItems';

export const calcOrderValues = state => {
  const { isNut, isFelc, handles, orderType } = state;

  let surfaceCNC = 0;
  let hingesHoles = 0;
  let milledHandle = 0;
  let milledPartHandle = 0;
  let chamfering = 0;
  let backMilling = 0;

  const items = state.items.map(item => {
    const { type, paintLeft, paintRight } = item;

    const itemSurface = parseInt(item.height) * parseInt(item.width);
    const itemQuantity = parseInt(item.quantity);

    if (
      ['materiał klienta', 'poprawa (wina klienta)'].includes(
        orderType.toLowerCase(),
      ) &&
      item.thickness > 22 &&
      !item.dillakMaterial
    ) {
      item.edgesSurface =
        (parseInt(item.height) * parseInt(item.thickness) * 2 +
          parseInt(item.width) * parseInt(item.thickness) * 2) *
        itemQuantity *
        0.000001;
    } else {
      item.edgesSurface = 0;
    }

    //   CNC SURFACE
    if (type !== 'gładki' && type !== 'gładki cnc')
      surfaceCNC += itemSurface * itemQuantity * 0.000001;

    //   PAINTED SURFACE
    if (paintRight && !paintLeft) {
      // Right side element

      let elementSurface = itemSurface;
      if (item.hLPaintedEdge)
        elementSurface += parseInt(item.height) * 100;
      if (item.wLPaintedEdge) elementSurface += parseInt(item.width) * 100;

      item.surfaceLeft = 0;
      item.surfaceRight = elementSurface * itemQuantity * 0.000001;
    } else if (paintLeft && paintRight) {
      //   Both sides element

      item.surfaceRight = 0;
      item.surfaceLeft = itemSurface * itemQuantity * 0.000001;
    } else if (!paintRight && paintLeft) {
      // Only left

      item.surfaceLeft = 0;
      item.surfaceRight = itemSurface * itemQuantity * 0.000001;
      if (!item.comments.toLowerCase().includes('tylko lewa'))
        item.comments += ' TYLKO LEWA';
    }

    //   CHAMFERING
    handleItemEdges(true, true, item, (value, name) => {
      if (value === 'gierunek')
        chamfering +=
          parseInt(name.includes('w') ? item.width : item.height, 10) *
          itemQuantity *
          0.001;
    });

    // BACK MILLING
    if (isFelc || isNut) {
      const tab = ['felc', 'nut'];
      handleItemEdges(false, true, item, (value, name) => {
        if (tab.includes(value))
          backMilling +=
            parseInt(name.includes('w') ? item.width : item.height, 10) *
            itemQuantity *
            0.001;
      });
    }

    //   HOLES
    const holesArr = ['-', 'r1', 'r2', 'nut', 'felc', 'gierunek'];
    handleItemEdges(false, true, item, (value, name) => {
      if (!holesArr.includes(value))
        hingesHoles += parseInt(value.charAt(0)) * itemQuantity;
    });

    //   HANDLES
    if (handles.length) {
      const handlesArr = ['-', 'r1', 'r2', 'gierunek'];
      handleItemEdges(true, false, item, (value, name) => {
        if (!handlesArr.includes(value)) {
          if (value === 'uc') {
            milledPartHandle += itemQuantity;
            item.milledPartHandle += itemQuantity;
          } else {
            const handleLength =
              parseInt(name.includes('w') ? item.width : item.height, 10) *
              itemQuantity *
              0.001;
            milledHandle += handleLength;
            item.milledHandle = handleLength;
          }
        }
      });
    }

    return item;
  });

  let elements = getSum(state.items, 'quantity');
  let surfaceRight = getSum(state.items, 'surfaceRight');
  let surfaceLeft = getSum(state.items, 'surfaceLeft');
  let edgesSurface = getSum(state.items, 'edgesSurface');
  return {
    ...state,
    items,
    elements,
    surfaceRight,
    surfaceLeft,
    surfaceCNC,
    hingesHoles,
    milledHandle,
    milledPartHandle,
    chamfering,
    backMilling,
    edgesSurface,
  };
};

const getSum = (arr, key) =>
  arr.reduce((all, curr) => (all += curr[key] || 0), 0);
