import React, { useState } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import withContext from 'hoc/withContext';
import EmployeeData from './components/EmployeeData';
import UserData from './components/UserData';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import RemoveUser from './components/Modals/RemoveUser';
import PasswordChange from './components/Modals/PasswordChange';

const initModals = { removeUser: false, passwordChange: false };

const AccountSettings = ({ permissionContext }) => {
 const user = useSelector((state) => state.auth.user);
 const [modals, setModals] = useState(initModals);

 const handleModal = (key) => setModals({ ...modals, [key]: true });
 const closeModal = () => setModals(initModals);

 return (
  <PageTemplate>
   <PageHeader
    title="Ustawienia konta"
    extra={[
     <Button key="1" variant="danger" onClick={() => handleModal('removeUser')}>
      Usuń konto
     </Button>,
     <Button key="2" onClick={() => handleModal('passwordChange')}>
      Zmień hasło
     </Button>,
    ]}
   />

   {user.permission && (
    <> {permissionContext === 'employee' ? <EmployeeData /> : <UserData />}</>
   )}

   {/* MODALS */}
   {modals.removeUser && <RemoveUser closeModal={closeModal} />}
   {modals.passwordChange && <PasswordChange closeModal={closeModal} />}
  </PageTemplate>
 );
};

export default withContext(AccountSettings);
