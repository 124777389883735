import React, { useState } from 'react';
import ModalTemplate from 'templates/ModalTemplate';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import OrdersList from 'components/ordersList/static';
import { setSpinner } from 'services/store/actions/view';
import { getProductionList } from 'services/apiRequests/files/get';
import { useAlert } from 'react-alert';

const Wrapper = styled.div`
 max-height: 70vh;
 overflow-y: auto;
`;

const ProductionListGenerate = ({ closeModal, orders }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const [list, setList] = useState([]);

 const handleOrders = (e) => {
  let { checked, value } = e.target;
  const obj = JSON.parse(value);
  value = obj._id;
  if (checked) {
   if (!list.includes(value)) setList(list.concat(value));
  } else {
   const newList = list.filter((item) => item !== value);
   setList(newList);
  }
 };

 const handleSubmit = async () => {
  dispatch(setSpinner(true));
  await getProductionList(
   { ordersIds: list },
   () => {
    dispatch(setSpinner(false));
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
   },
  );
 };

 return (
  <ModalTemplate
   size="xl"
   title="Generuj listę"
   closeModal={closeModal}
   onOk={handleSubmit}
   disabled={!list.length}
  >
   <Wrapper>
    {orders && (
     <OrdersList
      orders={orders}
      listName="production"
      checkbox
      onCheck={handleOrders}
      status
      lastOperation
      redirect={false}
     />
    )}
   </Wrapper>
  </ModalTemplate>
 );
};

export default ProductionListGenerate;
