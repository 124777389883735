import React, { useState } from 'react';
import Modal from 'templates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { Table, Form } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';

const ChangeItemsDimentions = ({ order, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();

 const [items, setItems] = useState([...order.items]);

 const isButtonDisabled = () =>
  items.some((item) => !item.height || !item.width);

 const handleInput = (i, key, value) => {
  const numbers = /^(\s*|\d+)$/;
  if (!value.match(numbers)) return;
  if (!value) value = 0;
  value = parseInt(value, 10);
  const newItems = [...items];
  newItems[i][key] = value;
  setItems(newItems);
 };
 const handleSubmit = async () => {
  dispatch(setSpinner(true));
  await updateOrder(
   'changedimensions',
   order._id,
   { items },
   (order) => {
    dispatch(setOrder(order));
    dispatch(setSpinner(false));
    alert.success('Zmieniono wymiary');
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
   },
  );
 };
 return (
  <Modal
   title="Zmiana wymiarów"
   closeModal={closeModal}
   disabled={isButtonDisabled()}
   onOk={handleSubmit}
  >
   {items.length ? (
    <div>
     <ItemsTable items={items} handleInput={handleInput} />
    </div>
   ) : null}
  </Modal>
 );
};

export default ChangeItemsDimentions;

const ItemsTable = ({ items, handleInput }) => {
 return (
  <Table responsive="lg" bordered hover size="sm">
   <thead>
    <tr>
     <th>Lp.</th>
     <th>Typ</th>
     <th>Wys.</th>
     <th>Szer.</th>
     <th>Ilość</th>
    </tr>
   </thead>
   <tbody>
    {items.map((item, i) => {
     return (
      <tr key={item._id}>
       <td>{i + 1}</td>
       <td>{firstLetterToUpperCase(item.type)}</td>
       <td>
        <Form.Control
         style={{ width: 100, margin: 'auto' }}
         type="text"
         value={item.height}
         onChange={(e) => handleInput(i, 'height', e.target.value)}
        />
       </td>
       <td>
        <Form.Control
         style={{ width: 100, margin: 'auto' }}
         type="text"
         value={item.width}
         onChange={(e) => handleInput(i, 'width', e.target.value)}
        />
       </td>
       <td>{item.quantity}</td>
      </tr>
     );
    })}
   </tbody>
  </Table>
 );
};
