import React from 'react';
import FlexRow from 'templates/FlexRowTemplate';
import Day from './components/Day';

const TransportView = ({ timetable }) => {
 return (
  <div>
   <FlexRow direction="column" />
   {timetable.map((item) => (
    <Day day={item} key={item.date} />
   ))}
  </div>
 );
};

export default TransportView;
