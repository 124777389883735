import Axios from 'axios';

export const cuttingPackageRemove = async (
  cuttingPackageId,
  onEnd,
  onError,
) => {
  try {
    await Axios.delete(`/api/cutting/${cuttingPackageId}`);

    onEnd();
  } catch (error) {
    console.log(error.response);
    onError();
  }
};
