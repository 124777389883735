/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ModalContainer from 'templates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const Price = ({ order, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const [price, setPrice] = useState('');

 const handleInput = e => {
  const { value } = e.target;
  const numbers = /^[\d|.|,]*$/;
  if (!value.match(numbers)) return;
  setPrice(value.replace(',', '.'));
 };
 const handleSubmit = async () => {
  dispatch(setSpinner(true));
  await updateOrder(
   'price',
   order._id,
   { price },
   order => {
    dispatch(setOrder(order));
    dispatch(setSpinner(false));
    alert.success('Zaktualizowano cenę');
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('błąd!');
    closeModal();
   },
  );
 };

 return (
  <ModalContainer
   title="Cena zamówienia"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" onClick={handleSubmit}>
      Zmień
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   Aktualna cena lakierowania zamówienia Wynosi:{' '}
   <strong>{order.price ? order.price.toFixed(2) : 0}</strong> zł.
   <br />
   Chcesz ją zmienić na:{' '}
   <Form.Control
    type="text"
    placeholder="kwota"
    style={{ width: '30%', display: 'inline' }}
    value={price}
    onChange={handleInput}
   />
   <hr />
   {(order.manHours || order.ventilations) && (
    <small>
     Do ceny zostanie dodane: <br />
     <strong>{order.manHours.price.toFixed(2) || 0}</strong>
     zł za <strong>{order.manHours.hours || 0}</strong>h prac stolarskich.
     <br />
     <strong>{order.ventilations.price.toFixed(2) || 0}</strong>
     zł za <strong>{order.ventilations.quantity || 0}</strong>szt kratek
     wentylacyjnych.
    </small>
   )}
  </ModalContainer>
 );
};

export default Price;
