import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOrdersList,
  getCustomerOrdersInProgress,
  getCustomerEndedOrders,
} from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import OrdersList from 'components/ordersList/static';
import { getCustomerMessages } from 'services/store/actions/auth';
import Filters from './components/Filters';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { setHomePageKey } from 'services/store/actions/view';
import EndedOrdersList from '../Admin/components/EndedOrdersList';

const CustomerHomePage = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector(state => state.auth.user);
  const key = useSelector(state => state.view.homePageKey);
  const orders = useSelector(state => state.orders);
  const filters = useSelector(state => state.orders.filters.customer);
  const { dateFrom, dateTo } = filters;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user._id) {
      dispatch(getCustomerMessages(user._id));

      if (key === 'new') {
        setIsLoading(true);
        dispatch(
          getCustomerOrdersInProgress(
            user._id,
            () => {
              setIsLoading(false);
            },
            () => {
              alert.error('Błąd pobierania zamówień');
              setIsLoading(false);
            },
          ),
        );
      } else if (key === 'ended') {
        setIsLoading(true);
        dispatch(
          getCustomerEndedOrders(
            user._id,
            dateFrom,
            dateTo,
            () => {
              setIsLoading(false);
            },
            () => {
              alert.error('Błąd pobierania zamówień');
              setIsLoading(false);
            },
          ),
        );
      }
    }
  }, [user, key, dateFrom, dateTo]);

  return (
    <PageTemplate>
      <PageHeader
        title="Zamówienia"
        extra={
          key === 'ended'
            ? [
                isLoading && (
                  <Spinner
                    animation="border"
                    variant="success"
                    key={1}
                    style={{ marginRight: 5 }}
                  />
                ),
                <Filters key={2} />,
              ]
            : [
                isLoading && (
                  <Spinner
                    animation="border"
                    variant="success"
                    key={1}
                    style={{ marginRight: 5 }}
                  />
                ),
              ]
        }
      />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={key => {
          dispatch(clearOrdersList());
          dispatch(setHomePageKey(key));
        }}
      >
        <Tab eventKey="new" title="Nowe">
          {key === 'new' && (
            <>
              <OrdersList
                orders={orders.newOrders}
                listName="customer"
                summary
                status
              />
            </>
          )}
        </Tab>
        <Tab eventKey="ended" title="Odebrane">
          {key === 'ended' && (
            <EndedOrdersList
              orders={orders.endedOrders}
              listName="customer"
              pickUpDate
              summary
            />
          )}
        </Tab>
      </Tabs>
      {/* {orders && <OrdersList orders={orders} listName="customer" status />} */}
    </PageTemplate>
  );
};

export default CustomerHomePage;
