import React from 'react';

const Checkbox = ({ onChange, order }) => {
 const value = {
  _id: order._id,
  orderName: `${order.user.company} ${order.number}`,
  paintMaker: order.paintMaker,
 };
 return (
  <td>
   <input
    style={{ margin: '5px' }}
    type="checkbox"
    name={order._id}
    id={order._id}
    value={JSON.stringify(value)}
    onChange={onChange}
   />
  </td>
 );
};

export default Checkbox;
