/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateToString } from 'services/utils/date';
import { getOrderCustomerName } from 'services/utils/orders';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import FlexRow from 'templates/FlexRowTemplate';
import { getDaySurfaces } from '../utils';

const ListWrapper = styled.div`
 min-height: 200px;
 max-height: 270px;
 overflow-y: scroll;
 overflow-x: hidden;
 border: 1px solid lightgray;
 border-radius: 3px;
 padding: 2px;
`;
const ItemWrapper = styled.div`
 border: 1px solid lightgray;
 border-radius: 3px;
 background-color: rgba(255, 255, 255, 0.8);
 margin: 3px;
 padding: 3px;
 width: 100%;
 font-size: 14px;
 display: flex;
 justify-content: space-between;
 flex-wrap: nowrap;
`;
const Title = styled.span`
 font-weight: bold;
 padding-right: 15px;
`;
const Color = styled.span`
 text-transform: capitalize;
 font-size: 12px;
`;
const DataWrapper = styled.div`
 width: 45%;
`;

const OrdersList = ({ orders, droppableId, handleRemove }) => {
 const values = getDaySurfaces(orders);
 return (
  <div>
   {values && <OrdersSummary values={values} />}

   <Droppable droppableId={droppableId}>
    {(provided, snapshot) => {
     return (
      <ListWrapper
       ref={provided.innerRef}
       {...provided.droppableProps}
       isDraggingOver={snapshot.isDraggingOver}
      >
       {orders.map(
        (item, i) =>
         item && (
          <ListItem
           item={item}
           index={i}
           key={item.id}
           handleRemove={handleRemove}
           listName={droppableId}
          />
         ),
       )}
      </ListWrapper>
     );
    }}
   </Droppable>
  </div>
 );
};

export default OrdersList;

const ListItem = ({ item, index, handleRemove, listName }) => {
 const { order, actions } = item;
 let action = '-';

 if (actions.all) action = 'Wszystko';
 else if (actions.left) action = 'Lewe';
 else if (actions.corrections) action = 'Poprawki';
 else if (actions.losts) action = 'Braki';
 if (order)
  return (
   <Draggable draggableId={order._id + item.id} index={index}>
    {(provided, snapshot) => (
     <ItemWrapper
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      isDragging={snapshot.isDragging}
     >
      <DataWrapper>
       <div>
        <Title>
         {getOrderCustomerName(order)}.{order.number}
        </Title>
        <Color>
         /{order.color}{' '}
         <sup
          style={
           order.paintType === 'połysk' ? { backgroundColor: 'lightblue' } : {}
          }
         >
          {order.paintType}
         </sup>
         /
        </Color>
       </div>
       <div>
        <sub>
         Elementy: <strong>{order.elements}</strong>
        </sub>
       </div>
      </DataWrapper>
      <DataWrapper>
       <div>
        Data realizacji: <strong>{dateToString(order.finishDate)}</strong>
       </div>
       <small>
        Akcja: <strong>{action}</strong>
       </small>
      </DataWrapper>
      <div>
       <FontAwesomeIcon
        icon={faTrash}
        style={{ color: 'red', cursor: 'pointer' }}
        onClick={() => handleRemove(listName, index, order)}
       />
      </div>
     </ItemWrapper>
    )}
   </Draggable>
  );
 return null;
};

const OrdersSummary = ({ values }) => {
 return (
  <FlexRow justify="space-around">
   <small>
    <span>CNC: </span>
    {values.cnc.toFixed(1)}
   </small>
   <small>
    <span>Pow: </span>
    {values.surface.toFixed(1)}
   </small>
   <small>
    <span>Elem: </span>
    {values.elements}
   </small>
  </FlexRow>
 );
};
