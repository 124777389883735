import {
 PAINTS_SET_ORDER_VALUE,
 PAINTS_SET_INIT,
 PAINTS_ADD_SKIPPED,
 PAINTS_REMOVE_SKIPPED,
 PAINTS_HANDLE_OTHER,
 PAINTS_ADD_ORDERS,
 PAINTS_CLEAR,
} from '../types/paints';

export const setInitPaints = (field, values) => ({
 type: PAINTS_SET_INIT,
 field,
 values,
});
export const setPaintOrderValue = (index, field, value) => ({
 type: PAINTS_SET_ORDER_VALUE,
 index,
 field,
 value,
});
export const skipOrder = orderId => ({ type: PAINTS_ADD_SKIPPED, orderId });
export const removeSkipedOrder = orderId => ({
 type: PAINTS_REMOVE_SKIPPED,
 orderId,
});
export const handleOther = (key, index, value) => {
 return {
  type: PAINTS_HANDLE_OTHER,
  key,
  index,
  value,
 };
};
export const addCustomOrders = orders => ({
 type: PAINTS_ADD_ORDERS,
 orders,
});
export const clearPaints = paints => ({
 type: PAINTS_CLEAR,
 paints,
});
