import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

const AuthTemplate = ({ children }) => {
 const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
 const { token } = localStorage;

 if (token && isAuthenticated) return <Redirect to="/" />;
 return <div>{children}</div>;
};

export default AuthTemplate;
