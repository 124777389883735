import moment from 'moment';
import FileSaver from 'file-saver';
import { orderItemCuttingStatus } from 'services/utils/const';

export const createCSVPackageFileForCNC = ({ orders }) => {
  const date = moment().format('DD.MM.YYYYr');
  const items = orders
    .reduce((allItems, order) => {
      return [
        ...allItems,
        ...order.items.map((item, index) => ({
          ...item,
          numberInOrder: index + 1,
          orderName: `${order.userName} - ${order.number}`,
          orderNumber: order.number,
          orderMilling: order.millings[0],
          orderGlassCase: order.glassCase,
          orderColor: order.color,
          orderPaintType: order.paintType,
        })),
      ];
    }, [])
    .filter(
      (item) => item.cuttingStatus === orderItemCuttingStatus.TO_CUT,
    );

  const T = `nazwa,Material,x,y,Ilosc,Grubosc,Kat usłojenia,Kat manipualcji,ramka,sciezka do pliku,typ plyty,typ uchwytu,uchwyt x,uchwyt y,opis,kolor,matowosc\r`;

  const dataT = items.map(
    (item, index) =>
      `${item.orderName} [${item.numberInOrder}],MZ${item.thickness},${
        item.width
      },${item.height},${item.quantity},${
        item.thickness
      },0,90,0,${getFilePath(item)},zwykla,${isHandle(
        item,
      )},${isHandleOnWidth(item)},${isHandleOnHeight(item)},${
        item.orderColor
      },${item.orderPaintType}\r`,
  );
  const CSV = `${T}${dataT.join('')}`;

  FileSaver.saveAs(
    new Blob([CSV], { type: 'text/csv;charset=windows-1252;' }),
    `ciecie_CSV_${date}.csv`,
  );
};

const getFilePath = (item) => {
  const basicPath = `Nesting\\Projekty\\`;
  if (item.type === 'gładki') return basicPath + 'Gladki.fmc';
  if (item.type === 'frez') return basicPath + item.orderMilling + '.fmc';
  if (item.type === 'witryna')
    return basicPath + item.orderGlassCase + '.fmc';
  return '-';
};

const isHandle = (item) => {
  if (!['r1', 'r2', '-'].includes(item.w1P.toLowerCase())) return item.w1P;
  if (!['r1', 'r2', '-'].includes(item.w2P.toLowerCase())) return item.w2P;
  if (!['r1', 'r2', '-'].includes(item.h1P.toLowerCase())) return item.h1P;
  if (!['r1', 'r2', '-'].includes(item.h2P.toLowerCase())) return item.h2P;
  return '-';
};
const isHandleOnWidth = (item) => {
  if (!['r1', 'r2', '-'].includes(item.w1P.toLowerCase())) return 'TAK';
  if (!['r1', 'r2', '-'].includes(item.w2P.toLowerCase())) return 'TAK';
  return '-';
};
const isHandleOnHeight = (item) => {
  if (!['r1', 'r2', '-'].includes(item.h1P.toLowerCase())) return 'TAK';
  if (!['r1', 'r2', '-'].includes(item.h2P.toLowerCase())) return 'TAK';
  return '-';
};
