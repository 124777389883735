export const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS';
export const ORDERS_LOAD_ERROR = 'ORDERS_LOAD_ERROR';
export const ORDERS_SET_ORDER = 'ORDERS_SET_ORDER';
export const ORDERS_ORDER_LOAD_ERROR = 'ORDERS_ORDER_LOAD_ERROR';
export const ORDERS_UPDATE_ORDER_IN_LIST = 'ORDERS_UPDATE_ORDER_IN_LIST';
export const ORDERS_CLEAR_LIST = 'ORDERS_CLEAR_LIST';
export const ORDERS_SET_ENDED_FILTERS = 'ORDERS_SET_ENDED_FILTERS';
export const ORDERS_CLEAR_FILTERS = 'ORDERS_CLEAR_FILTERS';
export const ORDERS_SET_SORT = 'ORDERS_SET_SORT';
export const ORDERS_PRODUCTION_LOAD_SUCCESS = 'ORDERS_PRODUCTION_LOAD_SUCCESS';
export const ORDERS_PRODUCTION_LOAD_ERROR = 'ORDERS_PRODUCTION_LOAD_ERROR';
export const ORDERS_RESET_SORT = 'ORDERS_RESET_SORT';
export const ORDERS_SET_TIME_TO_PAINT = 'ORDERS_SET_TIME_TO_PAINT';
