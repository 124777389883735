import update from 'immutability-helper';
import { getFileExtension } from 'services/utils/files';
import { orderItemTemplate } from './const';

// Adding order item fields depends on whole orders properties
export const addOrderItem = state => {
  const { millings, veneer, paintType, paintStyle, orderType } = state;
  let pEdge = '-';
  let type = 'gładki';
  let paintLeft = false;
  let dillakMaterial = true;

  if (!veneer) {
    if (paintType === 'połysk') pEdge = 'r2';
    else pEdge = 'r2';
  }
  if (millings.length && !millings.includes('ryflowany')) {
    if (millings[0].toLowerCase() === 'gładki cnc') type = 'gładki';
    else type = 'frez';
  }
  if (
    paintStyle === 'dwustronne' ||
    paintStyle === 'prawa - połysk / lewa - półmat'
  )
    paintLeft = true;
  if (
    [
      'materiał klienta',
      'poprawa (wina klienta)',
      'reklamacja (wina dillak)',
    ].includes(orderType.toLowerCase())
  )
    dillakMaterial = false;

  const item = {
    ...orderItemTemplate,
    type,
    w1P: pEdge,
    w2P: pEdge,
    h1P: pEdge,
    h2P: pEdge,
    paintLeft,
    dillakMaterial,
  };

  return { ...state, items: [...state.items, item] };
};

export const removeOrderItem = (state, index) => {
  const items = state.items.filter((item, i) => i !== index);

  return { ...state, items };
};

export const handleInput = (state, index, field, value, isNumber) => {
  if (field === 'hLPaintedEdge') {
    let newh2LEdge = state.items[index].h2LEdge;
    if (value) {
      newh2LEdge = state.paintType === 'połysk' ? 'r2' : 'r2';
    } else {
      newh2LEdge = '-';
    }
    return update(state, {
      items: {
        [index]: {
          [field]: { $set: value },
          h2L: { $set: newh2LEdge },
        },
      },
    });
  }
  if (field === 'transparentBothSide') {
    if (value) {
      return update(state, {
        items: {
          [index]: {
            [field]: { $set: value },
            paintLeft: { $set: value },
            paintRight: { $set: value },
          },
        },
      });
    }
  }
  if (field === 'paintLeft' || field === 'paintRight') {
    if (!value) {
      return update(state, {
        items: {
          [index]: {
            [field]: { $set: value },
            transparentBothSide: { $set: value },
          },
        },
      });
    }
  }
  if (field === 'type' && !['frez', 'witryna'].includes(value)) {
    return update(state, {
      items: {
        [index]: {
          [field]: { $set: value },
          muntinX: { $set: 0 },
          muntinH: { $set: 0 },
          muntinW: { $set: 0 },
        },
      },
    });
  }

  const numbers = /^(\s*|\d+)$/;
  if (isNumber) {
    if (!value.match(numbers)) return { ...state };
    if (!value) value = 0;
    value = parseInt(value, 10);
  }

  return update(state, {
    items: { [index]: { [field]: { $set: value } } },
  });
};

export const addItemImage = (state, index, file) => {
  const items = state.items.map((item, i) => {
    if (i === index) {
      const ext = getFileExtension(file.name);
      item.image = { ...item.image, file, path: `img_${index}.${ext}` };
      item.comments = `${item.comments} rysunek`;
    }
    return item;
  });
  return { ...state, items };
};

export const removeItemImage = (state, index) => {
  const items = state.items.map((item, i) => {
    if (i === index) {
      item.image = { path: '', file: null };
      item.comments = item.comments.replace('rysunek', '');
    }

    return item;
  });

  return { ...state, items };
};

export const importDataFromFile = (state, items) => {
  const newItems = [];

  items.forEach(item => {
    const { millings, veneer, paintType, paintStyle, orderType } = state;
    let pEdge = '-';
    let type = 'gładki';
    let paintLeft = false;
    let dillakMaterial = true;

    if (!veneer) {
      if (paintType === 'połysk') pEdge = 'r2';
      else pEdge = 'r2';
    }
    if (millings.length && !millings.includes('ryflowany')) type = 'frez';
    if (
      paintStyle === 'dwustronne' ||
      paintStyle === 'prawa - połysk / lewa - półmat'
    )
      paintLeft = true;
    if (
      [
        'materiał klienta',
        'poprawa (wina klienta)',
        'reklamacja (wina dillak)',
      ].includes(orderType.toLowerCase())
    )
      dillakMaterial = false;

    const itemObj = {
      ...orderItemTemplate,
      height: item[4],
      width: item[9],
      quantity: item[15] || 1,
      type,
      w1P: pEdge,
      w2P: pEdge,
      h1P: pEdge,
      h2P: pEdge,
      paintLeft,
      dillakMaterial,
    };
    if (
      item[4] &&
      item[9] &&
      typeof item[4] === 'number' &&
      typeof item[9] === 'number' &&
      typeof item[15] === 'number'
    )
      newItems.push(itemObj);
  });

  return { ...state, items: newItems };
};

// HELPERS
export const handleItemEdges = (right, left, item, callback) => {
  const rightEdges = ['h1P', 'h2P', 'w1P', 'w2P'];
  const leftEdges = ['h1L', 'h2L', 'w1L', 'w2L'];

  if (right)
    rightEdges.forEach(edgeName => callback(item[edgeName], edgeName));
  if (left)
    leftEdges.forEach(edgeName => callback(item[edgeName], edgeName));
};
