export const getPaintsSummaryFromOrders = (orders) => {
 const summary = [];

 const withValue = orders.filter((order) => order.quantity);

 withValue.forEach((order) => {
  const index = summary.findIndex(
   (item) =>
    item.name === order.color &&
    item.type === order.paintType &&
    item.producer === order.producer,
  );

  if (index === -1) {
   summary.push({
    name: order.color,
    type: order.paintType,
    quantity: parseFloat(order.quantity),
    surfaceRight: order.surfaceRight,
    surfaceLeft: order.surfaceLeft,
    orders: [order._id],
    producer: order.producer,
   });
  } else {
   summary[index].surfaceRight += order.surfaceRight;
   summary[index].surfaceLeft += order.surfaceLeft;
   summary[index].quantity += parseFloat(order.quantity);
   summary[index].orders.push(order._id);
  }
 });
 return summary.sort((a, b) => a.type.localeCompare(b.type));
};

export const getPaintsFromOthers = (paints) => {
 let summary = paints.filter((paint) => paint.quantity);

 summary = summary.map((item) => {
  item.quantity = parseFloat(item.quantity);
  return item;
 });

 return summary.sort((a, b) => a.type.localeCompare(b.type));
};

export const getOtherProductsSummary = (other) => {
 let summary = other.filter((item) => item.quantity);

 summary = summary.map((item) => {
  item.quantity = parseFloat(item.quantity);
  return item;
 });

 return summary.sort((a, b) => a.type.localeCompare(b.type));
};

export const getSkippedOrders = (skipped, orders) =>
 orders.filter((order) => skipped.includes(order._id));
