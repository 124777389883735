/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import { weekDays, months } from './const';

export const getYears = () => {
 const date = new Date();
 const currentYear = date.getFullYear();

 const years = [];
 for (let i = 2019; i <= currentYear; i++) years.push(i);

 return years;
};

export const initZero = number => {
 if (number < 10) return `0${number}`;
 return number;
};

export const dateToString = value => {
 const date = new Date(value);
 return `${initZero(date.getDate())}.${initZero(
  date.getMonth() + 1,
 )}.${date.getFullYear()}r.`;
};
export const getYearFromDate = value => {
 const date = new Date(value);
 return `${date.getFullYear()}r.`;
};

export const dateToStringWithoutDay = value => {
 const date = new Date(value);
 return `${initZero(date.getMonth() + 1)}.${date.getFullYear()}r.`;
};

export const dateToStringWithHour = value => {
 const date = new Date(value);
 return `${initZero(date.getDate())}.${initZero(
  date.getMonth() + 1,
 )}/${initZero(date.getHours())}:${initZero(date.getMinutes())}`;
};

export const dateToStringWithoutYear = value => {
 const date = new Date(value);
 return `${initZero(date.getDate())}.${initZero(date.getMonth() + 1)}`;
};

export const formatDateToDatePicker = d => {
 const date = new Date(d);
 if (isNaN(date.getTime())) return;
 return new Date(date).toISOString().substr(0, 10);
};

export const getHoursMinutesString = value => {
 let date = new Date(value).getTime();

 const ms = date % 1000;
 date = (date - ms) / 1000;

 const secs = date % 60;
 date = (date - secs) / 60;

 const mins = date % 60;
 const hrs = (date - mins) / 60;

 return `${initZero(hrs)}:${initZero(mins)}`;
};

export const getArrayOfDatesFromTo = (from, to) => {
 const dates = [];
 const currentDate = new Date(from);
 const lastDate = new Date(to);

 for (
  let date = currentDate;
  date <= lastDate;
  date.setDate(date.getDate() + 1)
 ) {
  const day = new Date(date);
  dates.push(day);
 }

 return dates;
};

export const isDatesEqual = (a, b) => {
 const dateA = new Date(a);
 const dateB = new Date(b);
 return (
  dateA.getDate() === dateB.getDate() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear()
 );
};

export const isMonthEqual = (a, b) => {
 const dateA = new Date(a);
 const dateB = new Date(b);
 return (
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear()
 );
};

export const getDayName = day => {
 const date = new Date(day);
 const dayNr = date.getDay();
 return weekDays[dayNr];
};
export const getMonthName = date => {
 const dateToCheck = new Date(date);
 const monthNr = dateToCheck.getMonth();
 return months[monthNr];
};
