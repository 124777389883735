import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TextInput from 'components/FormInputs/TextInput';
import { Formik } from 'formik';
import * as yup from 'yup';
import Modal from 'templates/ModalTemplate';
import { userPasswordChange } from 'services/apiRequests/users/update';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';

const schema = yup.object({
 currentPassword: yup.string().required('Hasło jest wymagane'),
 password: yup
  .string()
  .required('Hasło jest wymagane')
  .min(6, 'Hasło musi zawierać min. 6 znaków'),
 password2: yup
  .string()
  .required('Hasło jest wymagane')
  .min(6, 'Hasło musi zawierać min. 6 znaków'),
});

const PasswordChange = ({ closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();

 const validatePassword = (values) => {
  let errors = {};
  if (values.password2 !== values.password)
   errors.password2 = 'Nowe hasła muszą być identyczne';
  return errors;
 };
 const handleSubmit = async (values, actions) => {
  dispatch(setSpinner(true));
  await userPasswordChange(
   values,
   () => {
    alert.success('Hasło zmienione');
    dispatch(setSpinner(false));
    closeModal();
   },
   (message) => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
    if (message) actions.setErrors(message);
   },
  );
 };
 return (
  <Modal
   title="Zmiana hasła"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" type="submit" form="passwordChangeForm">
      Zmień
     </Button>
     <Button onClick={closeModal}>Anuluj</Button>
    </>
   }
  >
   <Formik
    validationSchema={schema}
    validate={validatePassword}
    // ONSUBMIT REQUEST
    onSubmit={handleSubmit}
    initialValues={{
     currentPassword: '',
     password: '',
     password2: '',
    }}
    render={(props) => {
     return (
      <Form noValidate onSubmit={props.handleSubmit} id="passwordChangeForm">
       <TextInput
        {...props}
        type="password"
        name="currentPassword"
        label="Aktualne hasło"
        required
       />
       <TextInput
        {...props}
        type="password"
        name="password"
        label="Nowe hasło"
        required
       />
       <TextInput
        {...props}
        type="password"
        name="password2"
        label="Nowe hasło"
        required
       />
      </Form>
     );
    }}
   />
  </Modal>
 );
};

export default PasswordChange;
