import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from 'components/header';
import PageTemplate from 'templates/PageTemplate';
import { Tabs, Tab, Button } from 'react-bootstrap';
import OrdersList from 'components/ordersList/static';
import axios from 'axios';
import { setSpinner } from 'services/store/actions/view';
import { useModal } from 'hooks/useModal';
import { useAlert } from 'react-alert';
import PickOrdersToCuttingPackageModal from './components/PickOrdersToPackageModal';
import CuttingPackagesList from 'components/cuttingPackagesList/CuttingPackagesList';
import CustomDateRangePicker from 'components/DatePicker/CustomDateRangePicker';
import moment from 'moment';
import FlexRow from 'templates/FlexRowTemplate';
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'components/DatePicker';

const CuttingAdminPanel = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { modal, showModal, closeModal } = useModal({ newPackage: false });
  const [key, setKey] = useState('to-cut');
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, 'month').startOf('day').toDate(),
  );
  const [dateTo, setDateTo] = useState(moment().endOf('day').toDate());

  const [ordersToCut, setOrdersToCut] = useState([]);
  // const [ordersInCutting, setOrdersInCutting] = useState([])
  const [cuttingPackagesInProgress, setCuttingPackagesInProgress] =
    useState([]);
  const [finishedCuttingPackages, setFinishedCuttingPackages] = useState(
    [],
  );

  const getCuttingOrders = useCallback(() => {
    dispatch(setSpinner(true));
    axios
      .post(`/api/orders/status`, {
        statuses: ['wysłane', 'piła'],
      })
      .then((res) => {
        const toCut = res.data.orders.filter(
          (order) => !order.cuttingPackage,
        );
        // const inProgress = res.data.orders.filter(
        //   (order) => order.cuttingPackage,
        // );
        setOrdersToCut(toCut);
        dispatch(setSpinner(false));
      })
      .catch((err) => {
        console.log(err);
        alert.error('Błąd');
        dispatch(setSpinner(false));
      });
  }, []);

  const getCuttingPackages = useCallback(() => {
    dispatch(setSpinner(true));
    return axios
      .get(`/api/cutting`, {
        params: {
          status: 'oczekuje, w trakcie, nie kompletne',
        },
      })
      .then((res) => {
        setCuttingPackagesInProgress(res.data.cuttingPackages);
        dispatch(setSpinner(false));
      })
      .catch((err) => {
        console.log(err);
        alert.error('Błąd');
        dispatch(setSpinner(false));
        return;
      });
  }, []);
  const getFinishedCuttingPackages = useCallback(() => {
    dispatch(setSpinner(true));
    axios
      .get(`/api/cutting`, {
        params: {
          status: 'gotowy',
          dateFrom: moment(dateFrom).startOf('day').toDate(),
          dateTo: moment(dateTo).endOf('day').toDate(),
        },
      })
      .then((res) => {
        setFinishedCuttingPackages(res.data.cuttingPackages);
        dispatch(setSpinner(false));
      })
      .catch((err) => {
        console.log(err);
        alert.error('Błąd');
        dispatch(setSpinner(false));
      });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (key === 'to-cut') getCuttingOrders();
    else if (key === 'in-progress') getCuttingPackages();
  }, [key]);

  useEffect(() => {
    if (key === 'finished' && dateFrom && dateTo) {
      const isDatesCorrect = () => {
        const diff = moment(dateTo).diff(moment(dateFrom), 'h');
        return diff > 0;
      };
      if (isDatesCorrect()) {
        getFinishedCuttingPackages();
      }
    }
  }, [key, dateFrom, dateTo]);

  return (
    <PageTemplate>
      <PageHeader
        title="Piła"
        extra={[
          <Button key={1} onClick={() => showModal('newPackage')}>
            Utwórz pakiet
          </Button>,
        ]}
        buttonFormat={false}
      />
      {key === 'finished' && (
        <FlexRow justify="end">
          <div>
            Od{' '}
            <DatePicker
              selected={dateFrom}
              onChange={setDateFrom}
              dateFormat="dd.MM.yyyy"
              locale="pl"
            />
          </div>
          <div>
            Do{' '}
            <DatePicker
              selected={dateTo}
              onChange={setDateTo}
              dateFormat="dd.MM.yyyy"
              locale="pl"
            />
          </div>
        </FlexRow>
      )}
      <Tabs id="controlled-tab-example" activeKey={key} onSelect={setKey}>
        <Tab eventKey="to-cut" title="Do wycięcia">
          <OrdersList orders={ordersToCut} listName="new" status />
        </Tab>
        <Tab eventKey="in-progress" title="Aktywne pakiety cięcia">
          <CuttingPackagesList packages={cuttingPackagesInProgress} />
        </Tab>
        <Tab eventKey="finished" title="Zakończone">
          <CuttingPackagesList packages={finishedCuttingPackages} />
        </Tab>
      </Tabs>

      {/* MODALS */}
      {modal.newPackage && (
        <PickOrdersToCuttingPackageModal
          closeModal={closeModal}
          orders={ordersToCut}
        />
      )}
    </PageTemplate>
  );
};

export default CuttingAdminPanel;
