import {
  NEWORDER_SET_COLOR,
  NEWORDER_SET_VENEER,
  NEWORDER_SET_HANDLE,
  NEWORDER_REMOVE_HANDLE,
  NEWORDER_SET_MILLING,
  NEWORDER_REMOVE_MILLING,
  NEWORDER_SET_CUSTOMMILLING,
  NEWORDER_SET_GLASSCASE,
  NEWORDER_SET_PAINTTYPE,
  NEWORDER_SET_PAINTSTYLE,
  NEWORDER_SET_CHAMFERING,
  NEWORDER_SET_NUT,
  NEWORDER_SET_FELC,
  NEWORDER_SET_NAME,
  NEWORDER_SET_COMMENTS,
  NEWORDER_ADD_ITEM,
  NEWORDER_HANDLE_INPUT,
  NEWORDER_SET_FINISHDATE,
  NEWORDER_REMOVE_ITEM,
  NEWORDER_ADD_ITEM_IMAGE,
  NEWORDER_REMOVE_ITEM_IMAGE,
  NEWORDER_SET_FIELD,
  NEWORDER_CALC_ORDER_VALUES,
  NEWORDER_CALD_PRICE,
  NEWORDER_SET_MANY_FIELDS,
  NEWORDER_CLEAR_ORDER,
  NEWORDER_SET_ORDER,
  NEWORDER_IMPORT_DATA_FROM_FILE,
  NEWORDER_SET_MUNTIN_TYPE,
} from '../types/newOrder';

// OTHERS
export const setNewOrderField = (field, value) => ({
  type: NEWORDER_SET_FIELD,
  field,
  value,
});
export const setNewOrderManyFields = (fields = {}) => ({
  type: NEWORDER_SET_MANY_FIELDS,
  fields,
});
export const clearNewOrder = () => ({ type: NEWORDER_CLEAR_ORDER });
export const setEditedOrder = order => ({
  type: NEWORDER_SET_ORDER,
  order,
});

// ITEMS
export const addNewOrderItem = (quantity = 1) => ({
  type: NEWORDER_ADD_ITEM,
  quantity,
});
export const removeNewOrderItem = index => ({
  type: NEWORDER_REMOVE_ITEM,
  index,
});
export const handleNewOrderInput = (
  index,
  field,
  value,
  isNumber = false,
) => ({
  type: NEWORDER_HANDLE_INPUT,
  index,
  field,
  value,
  isNumber,
});
export const addNewOrderItemImage = (index, file) => ({
  type: NEWORDER_ADD_ITEM_IMAGE,
  file,
  index,
});
export const removeNewOrderItemImage = index => ({
  type: NEWORDER_REMOVE_ITEM_IMAGE,
  index,
});
export const importDataFromFile = items => ({
  type: NEWORDER_IMPORT_DATA_FROM_FILE,
  items,
});

// PROPPERTIES
export const setNewOrderName = name => ({
  type: NEWORDER_SET_NAME,
  name,
});
export const setNewOrderComments = comments => ({
  type: NEWORDER_SET_COMMENTS,
  comments,
});

// DATES
export const setNewOrderFinisDate = terms => ({
  type: NEWORDER_SET_FINISHDATE,
  terms,
});

// VALUES
export const calcNewOrderValues = () => ({
  type: NEWORDER_CALC_ORDER_VALUES,
});
export const calcNewOrderPrice = prices => ({
  type: NEWORDER_CALD_PRICE,
  prices,
});

// DATA
export const setNewOrderColor = (color, hexValue = '') => ({
  type: NEWORDER_SET_COLOR,
  color,
  hexValue,
});
export const setNewOrderVeneer = veneer => ({
  type: NEWORDER_SET_VENEER,
  veneer,
});
export const setNewOrderHandle = handle => ({
  type: NEWORDER_SET_HANDLE,
  handle,
});
export const removeNewOrderHandle = handle => ({
  type: NEWORDER_REMOVE_HANDLE,
  handle,
});
export const setNewOrderMilling = milling => ({
  type: NEWORDER_SET_MILLING,
  milling,
});
export const setNewOrderCustomMilling = file => ({
  type: NEWORDER_SET_CUSTOMMILLING,
  file,
});
export const removeNewOrderMilling = milling => ({
  type: NEWORDER_REMOVE_MILLING,
  milling,
});
export const setNewOrderGlassCase = glassCase => ({
  type: NEWORDER_SET_GLASSCASE,
  glassCase,
});
export const setNewOrderPaintType = paintType => ({
  type: NEWORDER_SET_PAINTTYPE,
  paintType,
});
export const setNewOrderPaintStyle = paintStyle => ({
  type: NEWORDER_SET_PAINTSTYLE,
  paintStyle,
});
export const setNewOrderNut = isNut => ({
  type: NEWORDER_SET_NUT,
  isNut,
});
export const setNewOrderChamfering = isChamfering => ({
  type: NEWORDER_SET_CHAMFERING,
  isChamfering,
});
export const setNewOrderFelc = isFelc => ({
  type: NEWORDER_SET_FELC,
  isFelc,
});
export const setNewOrderMuntinType = muntinType => ({
  type: NEWORDER_SET_MUNTIN_TYPE,
  muntinType,
});
