import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import withContext from 'hoc/withContext';

const Th = styled.th`
  width: 25%;
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.dillakGreen;
    if (secondary) return theme.dillakWarning;
    return 'white';
  }};
`;
const Td = styled.td`
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.dillakGreenLight;
    if (secondary) return theme.dillakWarningLight;
    return 'white';
  }};
`;

const SurfaceSummary = ({ order, permissionContext }) => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    let flatPL = 0,
      flatPP = 0,
      cncPL = 0,
      cncPP = 0;
    if (order) {
      order.items.forEach(item => {
        if (item.type.toLowerCase() === 'gładki') {
          if (item.surfaceRight) flatPL += item.surfaceRight;
          if (item.surfaceLeft) flatPP += item.surfaceLeft;
        } else {
          if (item.surfaceRight) cncPL += item.surfaceRight;
          if (item.surfaceLeft) cncPP += item.surfaceLeft;
        }
      });
      setValues({ flatPL, flatPP, cncPL, cncPP });
    }
  }, [order]);

  if (permissionContext === 'admin' && values)
    return (
      <Table responsive striped bordered hover size="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              Elementy gładkie:
              <br />
              <small>
                (m<sup>2</sup>)
              </small>
            </th>

            <th colSpan={2}>
              Elementy frezowane:
              <br />
              <small>
                (m<sup>2</sup>)
              </small>
            </th>
          </tr>
          <tr>
            <Th primary>PL</Th>
            <Th secondary>PP</Th>
            <Th primary>PL</Th>
            <Th secondary>PP</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td primary>{values.flatPL && values.flatPL.toFixed(3)}</Td>
            <Td secondary>{values.flatPP && values.flatPP.toFixed(3)}</Td>
            <Td primary>{values.cncPL && values.cncPL.toFixed(3)}</Td>
            <Td secondary>{values.cncPP && values.cncPP.toFixed(3)}</Td>
          </tr>
        </tbody>
      </Table>
    );
  return null;
};

export default withContext(SurfaceSummary);
