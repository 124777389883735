export const validateItems = (items, setErrors) => {
 const errors = [];
 items.forEach((item, index) => {
  if (!item.height) errors.push(`Pozycja ${index + 1}: wysokość`);
  if (!item.width) errors.push(`Pozycja ${index + 1}: szerokość`);
  if (!item.quantity) errors.push(`Pozycja ${index + 1}: ilość`);
 });
 if (errors.length) {
  setErrors(errors);
  return true;
 }
 return false;
};
