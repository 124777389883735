import React from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

const InputWithButton = ({
 value,
 handleChange,
 handleClick,
 buttonName,
 placeholder,
}) => {
 return (
  <InputGroup>
   <FormControl
    placeholder={placeholder}
    aria-label="Number of elements"
    aria-describedby="elements-number"
    value={value}
    maxLength={20}
    onChange={handleChange}
   />
   <InputGroup.Append>
    <Button variant="outline-primary" onClick={handleClick}>
     {buttonName}
    </Button>
   </InputGroup.Append>
  </InputGroup>
 );
};

export default InputWithButton;
