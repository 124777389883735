import React from 'react';
import ElementPreview from './ElementPreview';
import OrderData from './OrderData';
import styled from 'styled-components';

const Wrapper = styled.div`
 width: inherit;
`;

const Sidebar = () => {
 return (
  <Wrapper>
   <ElementPreview />
   <hr />
   <OrderData />
  </Wrapper>
 );
};

export default Sidebar;
