import Price from './Data/Price';
import Status from './Data/Status';
import ManHours from './Data/ManHours';
import AddComment from './Production/AddComment';
import RemoveOrder from './Production/RemoveOrder';
import Ventilation from './Data/Ventilation';
import PlanHistory from './History/PlanHistory';
import PaymentStatus from './Data/PaymentStatus';
import UpdateHistory from './History/UpdateHistory';
import PaintProducers from './Data/PaintProducers';
import TransportDate from './Data/TransportDate';
import SetLostElements from './Production/SetLostElements';
import RealizationDate from './Data/RealizationDate';
import NewSimmilarOrder from './NewSimmilarOrder';
import ProductionHistory from './History/ProductionHistory';
import OrdersInProduction from './Info/OrdersInProduction';
import SetElementsToCorrect from './Production/SetElementsToCorrect';
import ReadyToPickUp from './Production/ReadyToPickUp';
import PickUpReport from './Production/PickUpReport';
import ChangeItemsDimensions from './Data/ChangeItemsDimensions';

const Modal = {
 Price,
 Status,
 ManHours,
 AddComment,
 PlanHistory,
 RemoveOrder,
 Ventilation,
 UpdateHistory,
 PaymentStatus,
 PaintProducers,
 SetLostElements,
 RealizationDate,
 NewSimmilarOrder,
 ProductionHistory,
 OrdersInProduction,
 SetElementsToCorrect,
 TransportDate,
 ReadyToPickUp,
 PickUpReport,
 ChangeItemsDimensions,
};

export default Modal;
