import { FieldArray, Formik } from 'formik';
import React from 'react';
import { useAlert } from 'react-alert';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  createFront,
  getCatalog,
  removeMillingPhoto,
  updateFront,
} from 'services/store/actions/catalog';
import { setSpinner } from 'services/store/actions/view';
import styled from 'styled-components';
import FlexRow from 'templates/FlexRowTemplate';
import ModalTemplate from 'templates/ModalTemplate';
import * as yup from 'yup';
import ImageCard from './ImageCard';

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 5px;
  display: flex;
  label {
    width: 40%;
  }
`;
const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const InputFileLabel = styled.label`
  cursor: pointer;
  margin: 0;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  padding: 3px 6px;
`;

const initValues = {
  type: 'front',
  symbol: '',
  pricingGroup: 1,
  photos: [],
};

const AddMillingModal = ({ closeModal, toUpdate, type }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleRemoveImage = imageId => {
    if (toUpdate) {
      dispatch(setSpinner(true));
      dispatch(
        removeMillingPhoto(toUpdate._id, imageId, () =>
          alert.success('Zdjęcie usunięte'),
        ),
      );
      dispatch(setSpinner(false));
    }
  };

  const handleSubmit = newFront => {
    dispatch(setSpinner(true));
    if (!toUpdate)
      return dispatch(
        createFront(
          newFront,
          () => {
            alert.success('Front utworzony');
            closeModal();
          },
          () => {
            alert.error('Błąd!');
          },
        ),
      );
    else {
      return dispatch(
        updateFront(
          newFront,
          () => {
            dispatch(getCatalog());
            alert.success('Front zaktualizowany');
            closeModal();
          },
          () => {
            alert.error('Błąd!');
          },
        ),
      );
    }
  };

  return (
    <ModalTemplate
      title="Nowy wzór frontu"
      closeModal={closeModal}
      footer={<div />}
    >
      <Formik
        validationSchema={schema}
        validate={values => {
          if (values.photos.length === 0)
            return { photos: 'Dodaj zdjęcia' };
          const isPrimaryPhoto = values.photos.some(
            photo => photo.isPrimary,
          );
          if (!isPrimaryPhoto) return { photos: 'Wybierz zdjęcie główne' };
        }}
        onSubmit={handleSubmit}
        initialValues={
          toUpdate || {
            ...initValues,
            type: type ? type : initValues.type,
          }
        }
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <StyledFormGroup controlId="formType">
                <Form.Label>Typ</Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled={true}
                  value={
                    values.type === 'front'
                      ? 'Front'
                      : values.type === 'glassCase'
                      ? 'Witryna'
                      : values.type === 'handle'
                      ? 'Uchwyt'
                      : 'Error'
                  }
                />
              </StyledFormGroup>
              <StyledFormGroup controlId="formSymbol">
                <Form.Label>Symbol</Form.Label>
                <Form.Control
                  type="text"
                  name="symbol"
                  value={values.symbol}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.symbol && !errors.symbol}
                  placeholder="Symbol"
                  className={
                    errors.symbol && touched.symbol && 'is-invalid'
                  }
                />
              </StyledFormGroup>
              {errors.symbol && touched.symbol && (
                <div className="invalid">{errors.symbol}</div>
              )}
              <StyledFormGroup controlId="formSymbol">
                <Form.Label>Grupa cenowa</Form.Label>
                <Form.Control
                  disabled={values.type === 'handle'}
                  as="select"
                  name="pricingGroup"
                  value={values.pricingGroup}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.company && !errors.company}
                  placeholder="Symbol"
                  className={
                    errors.pricingGroup &&
                    touched.pricingGroup &&
                    'is-invalid'
                  }
                >
                  {[1, 2, 3, 4].map(group => (
                    <option key={group} value={group}>
                      {group}
                    </option>
                  ))}
                </Form.Control>
                {errors.pricingGroup && touched.pricingGroup && (
                  <div className="invalid">{errors.pricingGroup}</div>
                )}
              </StyledFormGroup>
              <hr />
              <FlexRow justify="space-between">
                <span>
                  Zdjęcia <sub>(max. 3)</sub>
                </span>{' '}
                <InputFileLabel htmlFor="inputFileId">
                  Dodaj zdjęcie
                </InputFileLabel>
              </FlexRow>
              <div style={{ marginTop: 10 }}>
                <InputFile
                  type="file"
                  id="inputFileId"
                  multiple={3}
                  accept="image/*"
                  onChange={e => {
                    const files = [...e.target.files];

                    // NOTE Get img url to preview
                    Promise.all(
                      files.map(file => {
                        const previewURL = new Promise(
                          (resolve, reject) => {
                            const reader = new FileReader();
                            reader.addEventListener('load', e => {
                              resolve(e.target.result);
                            });
                            reader.addEventListener('error', reject);
                            reader.readAsDataURL(file);
                          },
                        )
                          .then(url => {
                            return {
                              file,
                              fileName: file.name,
                              isPrimary: false,
                              path: url,
                              name: '',
                            };
                          })
                          .catch(err => console.log(err));

                        return previewURL;
                      }),
                    )
                      .then(images => {
                        const updatedPhotos = [
                          ...values.photos,
                          ...images,
                        ];
                        setFieldValue('photos', updatedPhotos);
                      })
                      .catch(err => console.log(err));
                  }}
                />

                {/* NOTE Photos preview & handle */}
                <FieldArray name="photos">
                  {arrayHelpers => {
                    return (
                      <div style={{ marginBottom: 20 }}>
                        <FlexRow justify="space-between" wrap>
                          {values.photos.map((photo, index) => {
                            return (
                              <ImageCard
                                key={photo.path}
                                index={index}
                                image={photo}
                                remove={index => {
                                  if (photo._id) {
                                    handleRemoveImage(photo._id);
                                  }
                                  arrayHelpers.remove(index);
                                }}
                                setAsPrimary={index => {
                                  const updatedPhotos = values.photos.map(
                                    (photo, i) => {
                                      if (i === index)
                                        return {
                                          ...photo,
                                          isPrimary: true,
                                        };
                                      else
                                        return {
                                          ...photo,
                                          isPrimary: false,
                                        };
                                    },
                                  );
                                  setFieldValue('photos', updatedPhotos);
                                }}
                              />
                            );
                          })}
                        </FlexRow>
                        {errors.photos && (
                          <small style={{ color: 'red' }}>
                            {errors.photos}
                          </small>
                        )}
                      </div>
                    );
                  }}
                </FieldArray>
              </div>
              <hr />
              <FlexRow justify="flex-end">
                <Button variant="success" type="submit">
                  Zatwierdź
                </Button>
                <Button variant="danger" onClick={closeModal}>
                  Anuluj
                </Button>
              </FlexRow>
            </Form>
          );
        }}
      </Formik>
    </ModalTemplate>
  );
};

export default AddMillingModal;

const schema = yup.object({
  symbol: yup.string().required('Symbol jest wymagany'),
  pricingGroup: yup.number().required('Wybierz grupę cenową'),
});
