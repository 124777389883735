/* eslint-disable no-undef */
import setAuthToken from 'services/utils/setAuthToken';
import Axios from 'axios';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_USER,
  AUTH_LOAD_USER,
  AUTH_SET_MESSAGES,
} from '../types/auth';

export const logInSuccess = token => ({
  type: AUTH_LOGIN_SUCCESS,
  token,
});

export const userLoadSuccess = (user, permission, messages) => ({
  type: AUTH_LOAD_USER,
  user,
  permission,
  messages,
});

export const logInError = () => ({ type: AUTH_LOGIN_ERROR });

export const logOutUser = () => ({ type: AUTH_LOGOUT_USER });
export const setCustomerMessages = messages => ({
  type: AUTH_SET_MESSAGES,
  messages,
});

export const getCustomerMessages = userId => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.get(`/api/messages/customer/${userId}`);
    const { messages } = res.data;

    if (res.status === 200) dispatch(setCustomerMessages(messages));
  } catch (error) {
    console.log(`ERROR_USER_LOAD: `, error.response);
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};

export const loadUserData = () => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.get('/api/auth/');
    const { user, messages } = res.data;
    const { permission } = user;
    if (res.status === 200)
      dispatch(userLoadSuccess(user, permission, messages));
  } catch (error) {
    console.log(`ERROR_USER_LOAD: `, error.response);
    dispatch(logInError());
    dispatch(logOutUser());
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};
