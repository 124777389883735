import {
 EMPLOYEE_SET_ACTIVE_POSITION,
 EMPLOYEE_SET_PAINTROOM,
} from '../types/employee';

export const setEmployeeActivePosition = position => ({
 type: EMPLOYEE_SET_ACTIVE_POSITION,
 position,
});

export const setEmployeePaintRoom = paintRoom => ({
 type: EMPLOYEE_SET_PAINTROOM,
 paintRoom,
});
