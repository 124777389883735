import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexRow from 'templates/FlexRowTemplate';
import ModalTemplate from 'templates/ModalTemplate';
import { domain } from 'services/utils/const';
import { Button } from 'react-bootstrap';
import { confirm } from 'react-bootstrap-confirmation';

const Card = styled.div`
  padding: 2px;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin: 5px;
  width: 250px;
`;
const ImageWrapper = styled.div`
  width: fit-content;
  margin: auto;
`;

const Front = ({
  front,
  permission,
  removeFront,
  editFront,
  handleFront,
}) => {
  const [images] = useState(
    front.photos.map(photo => `${domain}/${photo.path}`) || [],
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (front) {
      const index = front.photos.findIndex(photo => photo.isPrimary);
      if (index > -1) setCurrentIndex(index);
    }
  }, []);

  const handleImage = type => {
    let nextIndex = 0;
    if (type === 'next') nextIndex = currentIndex + 1;
    else if (type === 'prev') nextIndex = currentIndex - 1;
    if (nextIndex >= images.length) nextIndex = 0;
    else if (nextIndex < 0) nextIndex = images.length - 1;
    return setCurrentIndex(nextIndex);
  };

  const handleRemove = async () => {
    const result = await confirm('Na pewno chcesz usunąć ten front?', {
      cancelText: 'Anuluj',
      okText: 'Usuń',
      okButtonStyle: 'danger',
    });
    if (result) await removeFront(front);
  };

  return (
    <Card>
      <ImageWrapper>
        <img
          src={images[currentIndex]}
          style={{ width: 200, height: 200, cursor: 'pointer' }}
          onClick={() => setPreview(images[currentIndex])}
        />
      </ImageWrapper>
      <FlexRow justify="space-between">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => handleImage('prev')}
          style={{ margin: 2, padding: '2px 10px' }}
        >
          {'<'}
        </Button>
        <h4>{front.symbol.toUpperCase()}</h4>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => handleImage('next')}
          style={{ margin: 2, padding: '2px 10px' }}
        >
          {'>'}
        </Button>
      </FlexRow>
      <hr />
      <FlexRow justify="center">
        <Button
          variant="success"
          style={{ width: '100%' }}
          onClick={() => handleFront(front)}
        >
          Wybierz
        </Button>
      </FlexRow>
      {permission === 'admin' && (
        <FlexRow justify="space-between">
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => (handleRemove ? handleRemove() : null)}
            disabled={!handleRemove}
          >
            Usuń
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => (editFront ? editFront(front) : null)}
            disabled={!editFront}
          >
            Edytuj
          </Button>
        </FlexRow>
      )}
      {/* MODALS */}
      {preview && (
        <ImagePreview closeModal={() => setPreview(null)} path={preview} />
      )}
    </Card>
  );
};

const ImagePreview = ({ path, closeModal }) => (
  <ModalTemplate closeModal={closeModal} size="xl" footer={<div />}>
    <div style={{ textAlign: 'center' }}>
      <img
        alt="Img"
        style={{ maxWidth: '100%', maxHeight: '100vh' }}
        src={path}
        onClick={closeModal}
        className="image"
      />
    </div>
  </ModalTemplate>
);

export default Front;
