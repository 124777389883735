/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

const StyledReactDatePicker = styled(ReactDatePicker)`
 border-radius: 5px;
 border: 1px solid #73a52e;
 height: 36px;
`;

const DatePicker = ({ value, onChange, disabled = false, ...props }) => {
 return (
  <StyledReactDatePicker
   selected={value}
   dateFormat="dd.MM.yyyy"
   onChange={onChange}
   disabled={disabled}
   locale="pl"
   {...props}
  />
 );
};
export default DatePicker;
