import React from 'react';
import Modal from 'templates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/FormInputs/TextInput';
import * as yup from 'yup';

const AddWebPage = ({ closeModal, webPages, setFieldValue }) => {
 return (
  <Modal
   title="Dodaj numer telefonu"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" type="submit" form="AddWebPageModal">
      Dodaj
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   <Formik
    validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async (values) => {
     const newWebPages = webPages.concat(values);
     setFieldValue('webPages', newWebPages);
     closeModal();
    }}
    initialValues={{
     name: '',
     webPage: '',
    }}
    render={(props) => {
     return (
      <Form noValidate onSubmit={props.handleSubmit} id="AddWebPageModal">
       <TextInput {...props} name="name" label="Nazwa" required />
       <TextInput {...props} name="webPage" label="Adres" required />
      </Form>
     );
    }}
   />
  </Modal>
 );
};

export default AddWebPage;

const schema = yup.object({
 name: yup.string().required('Nazwa jest wymagana'),
 webPage: yup.string().required('Numer jest wymagany'),
});
