import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { dateToString } from 'services/utils/date';
import { getDayStatsByDate } from '../utils';
import { StyledH3, StyledH1, StyledH6 } from 'components/header';
import { Table } from 'react-bootstrap';
import OrdersList from 'components/ordersList/static';

const Header = styled.h1`
 font-size: 64px;
 letter-spacing: 10px;
`;
const Wrapper = styled.div`
 text-align: center;
`;

const PositionDayStats = ({ position, date }) => {
 const statistics = useSelector((state) => state.statistics.stats);
 const [stats, setStats] = useState(null);

 useEffect(() => {
  if (date && statistics) {
   const stats = getDayStatsByDate(statistics, date);
   setStats(stats);
  }
 }, [statistics, date, position]);

 if (date && stats)
  return (
   <div>
    <Wrapper>
     <Header className={position}>{position.toUpperCase()}</Header>
     <small>
      Dane pochodzą z dnia <strong>{dateToString(date)}</strong>
     </small>
    </Wrapper>
    <hr />
    {stats.employees.length ? (
     <>
      <StyledH3>Pracownicy</StyledH3>
      <ul>
       {stats.employees.map((item) => (
        <li key={item._id}>{item.name}</li>
       ))}
      </ul>
      <hr />
     </>
    ) : null}
    <StyledH3>Liczby</StyledH3>
    <StatsTable stats={stats} />
    {stats.corrections.length ? (
     <strong>
      Poprawek: <span style={{ color: 'red' }}>{stats.corrections.length}</span>
     </strong>
    ) : null}
    <hr />
    <StyledH3>Zamówienia</StyledH3>
    <OrdersList
     orders={stats.orders.reduce((acc, order) => {
      if (order.order) return acc.concat({ ...order.order, desc: order.desc });
      return acc;
     }, [])}
     listName="default"
     desc
    />
   </div>
  );
 return null;
};

export default PositionDayStats;

const StatsTable = ({
 stats: { flatOneSide, flatBothSides, cncOneSide, cncBothSides },
}) => {
 return (
  <Table striped bordered responsive>
   <tbody>
    <tr>
     <th rowSpan={2}>
      <StyledH1>Płaskie</StyledH1>
     </th>
     <th>Jednostronne</th>
     <td>
      <Value value={flatOneSide} />
     </td>
     <th rowSpan={2}>
      <StyledH3>
       <Value value={flatOneSide + flatBothSides} />
      </StyledH3>
     </th>
     <th rowSpan={5}>
      <StyledH1>
       <Value value={flatOneSide + flatBothSides + cncOneSide + cncBothSides} />
      </StyledH1>
     </th>
    </tr>
    <tr>
     <th>Obustronne</th>
     <td>
      <Value value={flatBothSides} />
     </td>
    </tr>
    <tr>
     <th rowSpan={2}>
      <StyledH1 color="black">CNC</StyledH1>
     </th>
     <th>Jednostronne</th>
     <td>
      <Value value={cncOneSide} />
     </td>
     <th rowSpan={2}>
      <StyledH3>
       <Value value={cncOneSide + cncBothSides} />
      </StyledH3>
     </th>
    </tr>
    <tr>
     <th>Obustronne</th>
     <td>
      <Value value={cncBothSides} />
     </td>
    </tr>
   </tbody>
  </Table>
 );
};

const Value = ({ value }) => (
 <>
  {value ? value.toFixed(2) : 0}{' '}
  <small>
   m<sup>2</sup>
  </small>
 </>
);
