import update from 'immutability-helper';
import {
 VIEW_SET_SPINNER,
 VIEW_SET_HOMEPAGE_KEY,
 VIEW_SET_MODEL,
} from '../types/view';

const initialState = {
 isSpinner: false,
 // HomePage
 homePageKey: 'new',
 // newOrder item model
 itemModel: {
  left: '',
  rigth: '',
 },
};

const viewReducer = (state = initialState, action) => {
 switch (action.type) {
  case VIEW_SET_SPINNER: {
   return { ...state, isSpinner: action.isSpinner };
  }
  case VIEW_SET_HOMEPAGE_KEY: {
   return { ...state, homePageKey: action.key };
  }
  case VIEW_SET_MODEL: {
   return update(state, {
    itemModel: {
     [action.key]: { $set: action.value },
    },
   });
  }
  default:
   return state;
 }
};

export { viewReducer };
