import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateToString, isDatesEqual } from 'services/utils/date';
import styled from 'styled-components';
import { StyledH3, StyledH1, StyledH6 } from 'components/header';
import Select from 'components/FormInputs/Select';
import { setStatsPosition } from 'services/store/actions/statistics';
import { Table } from 'react-bootstrap';
import OrdersList from 'components/ordersList/static';
import { getPositionsFromStats } from '../utils';

const Wrapper = styled.div`
 text-align: center;
`;

const DayStats = ({ date, employee }) => {
 const dispatch = useDispatch();
 const employees = useSelector(state => state.employees.employees);
 const statistics = useSelector(state => state.statistics.stats);
 const position = useSelector(state => state.statistics.position.employee);

 const [userName, setUserName] = useState('');
 const [positions, setPositions] = useState([]);

 const [dayStats, setDayStats] = useState(null);
 const [data, setData] = useState(null);

 useEffect(() => {
  if (employees && employee) {
   const user = employees.find(item => item._id === employee);
   setUserName(`${user.firstname} ${user.surname}`);
  }
 }, [employees, employee]);

 useEffect(() => {
  if (date && statistics) {
   const stats = statistics.filter(item => isDatesEqual(item.date, date));
   setDayStats(stats);
  }
 }, [statistics, date]);

 useEffect(() => {
  if (dayStats) {
   const newPositions = getPositionsFromStats(dayStats);
   setPositions(newPositions);
   if (newPositions.length)
    dispatch(setStatsPosition('employee', newPositions[0]));
  }
 }, [dayStats]);

 useEffect(() => {
  if (dayStats && position) {
   const newData = dayStats.filter(item => item.position === position);
   setData(newData);
  }
 }, [dayStats, position]);

 const handlePosition = e =>
  dispatch(setStatsPosition('employee', e.target.value));

 if (dayStats)
  return (
   <div>
    {' '}
    <Wrapper>
     <StyledH1>{userName}</StyledH1>
     {positions.length ? (
      <Select
       style={{ width: 300, margin: 'auto' }}
       items={positions}
       value={position}
       onChange={handlePosition}
      />
     ) : null}
     <small>
      Dane pochodzą z zamówień wykonanych dnia{' '}
      <strong>{dateToString(date)}</strong>
     </small>
    </Wrapper>
    <hr />
    {data && data[0] ? (
     <>
      <StyledH3>Liczby</StyledH3>
      <StatsTable stats={data[0]} />
      <hr />
      <StyledH3>Zamówienia</StyledH3>
      {data[0].selfMadeOrders.length ? (
       <>
        <StyledH6>Wykonane samodzielnie</StyledH6>
        <OrdersList
         orders={data[0].selfMadeOrders.reduce((acc, order) => {
          if (order.order)
           return acc.concat({ ...order.order, desc: order.desc });
          return acc;
         }, [])}
         listName="default"
         desc
        />
       </>
      ) : null}
      {data[0].notSelfMadeOrders.length ? (
       <>
        <StyledH6>Wykonane z innym pracownikiem</StyledH6>
        <OrdersList
         orders={data[0].notSelfMadeOrders.reduce((acc, order) => {
          if (order.order)
           return acc.concat({ ...order.order, desc: order.desc });
          return acc;
         }, [])}
         listName="default"
         desc
        />
       </>
      ) : null}
     </>
    ) : null}
   </div>
  );

 return null;
};

export default DayStats;

const StatsTable = ({
 stats: { flatOneSide, flatBothSides, cncOneSide, cncBothSides },
}) => {
 return (
  <Table striped bordered responsive>
   <tbody>
    <tr>
     <th rowSpan={2}>
      <StyledH1>Płaskie</StyledH1>
     </th>
     <th>Jednostronne</th>
     <td>
      <Value value={flatOneSide} />
     </td>
     <th rowSpan={2}>
      <StyledH3>
       <Value value={flatOneSide + flatBothSides} />
      </StyledH3>
     </th>
     <th rowSpan={5}>
      <StyledH1>
       <Value value={flatOneSide + flatBothSides + cncOneSide + cncBothSides} />
      </StyledH1>
     </th>
    </tr>
    <tr>
     <th>Obustronne</th>
     <td>
      <Value value={flatBothSides} />
     </td>
    </tr>
    <tr>
     <th rowSpan={2}>
      <StyledH1 color="black">CNC</StyledH1>
     </th>
     <th>Jednostronne</th>
     <td>
      <Value value={cncOneSide} />
     </td>
     <th rowSpan={2}>
      <StyledH3>
       <Value value={cncOneSide + cncBothSides} />
      </StyledH3>
     </th>
    </tr>
    <tr>
     <th>Obustronne</th>
     <td>
      <Value value={cncBothSides} />
     </td>
    </tr>
   </tbody>
  </Table>
 );
};

const Value = ({ value }) => (
 <>
  {value ? value.toFixed(2) : 0}{' '}
  <small>
   m<sup>2</sup>
  </small>
 </>
);
