/* eslint-disable no-console */
import { getHingeHolesPrice } from './hingeHoles';
import { getBackMillingPrice } from './backMilling';
import { getChamferingPrice } from './chamfering';
import { getHandlesPrice } from './handles';
import { getItemsPrice } from './items';

export const calcOrderPrice = (state, prices) => {
  let paintPrice = 0;
  let transparentPaintPrice = 0;
  let handlePrice = 0;
  let chamferingPrice = 0;
  let backMillingPrice = 0;
  let hingeHolesPrice = 0;

  console.clear();
  console.log('');
  console.log(`----=====OBLICZANIE CENY ZAMÓWIENIA=====----`);
  console.log('');
  /** ========================================
   * OTWORY
   * ======================================== */
  hingeHolesPrice = getHingeHolesPrice(state, prices);
  console.log(
    `CENA WIERCENIA ${state.hingeHoles ||
      0}mb OTWORÓW: ${hingeHolesPrice}zł`,
  );
  console.log('--');

  /** ========================================
   * GIEROWANIE
   * ======================================== */
  chamferingPrice = getChamferingPrice(state, prices);
  console.log(
    `CENA ${state.chamfering || 0}mb GIEROWANIA: ${chamferingPrice}zł`,
  );
  console.log('--');
  /** ========================================
   * FREZOWANIE POD PLECY
   * ======================================== */
  backMillingPrice = getBackMillingPrice(state, prices);
  console.log(
    `CENA ${state.backMilling ||
      0}mb FREZOWANIA POD PLECY: ${backMillingPrice}zł`,
  );
  console.log('--');

  /** ========================================
   * UCHWYTY
   * ======================================== */
  handlePrice = getHandlesPrice(state, prices);
  console.log(
    `CENA ${state.milledHandle || 0}mb i ${state.milledPartHandle ||
      0}szt UCHWYTÓW FREZOWANYCH: ${handlePrice}zł`,
  );

  /** ========================================
   * LAKIEROWANIE
   * ======================================== */
  console.log(' ');
  console.log('====LAKIEROWANIE====');
  console.log(
    `typ | materiał | lakierowanie | typ lakierowania | powierzchnia | cena`,
  );
  console.log('');

  const itemsData = getItemsPrice(state, prices);
  paintPrice = itemsData.itemsPrice;
  const { items } = itemsData;
  console.log(' ');
  console.log(
    `CENA ${state.surfaceRight}PL i ${state.surfaceLeft}PP m2 LAKIEROWANIA: ${paintPrice}zł`,
  );
  console.log('====LAKIEROWANIE====');
  console.log(' ');

  /** ========================================
   * LAKIEROWANIE
   * ======================================== */
  let extraColorPrice = 0;
  if (state.color.toLowerCase().includes('dorabiany wg. próbki')) {
    extraColorPrice = prices.paint.extraColor;
    console.log(`LAKIER DORABIANY: + ${extraColorPrice}`);
  }

  //  SUMMARY
  let price =
    hingeHolesPrice +
    chamferingPrice +
    backMillingPrice +
    handlePrice +
    transparentPaintPrice +
    paintPrice +
    extraColorPrice;

  if (!price) price = 0;
  if (state.orderType.toLowerCase() === 'reklamacja (wina dillak)') {
    console.log('---===R E K L A M A C J A===---');
    price = 0;
  }

  console.log(`CENA: ${price}`);
  console.log('');
  console.log('---===KONIEC===---');

  return { ...state, items, price };
};
