import React from 'react';
import styled from 'styled-components';
import withContext from 'hoc/withContext';

const Div = styled.div`
  width: 40px;
  height: 20px;
  display: inline-block;
  background-color: ${({ type, theme: { items } }) => {
    if (type) return items[type];
    return 'white';
  }};
  border: 1px solid gray;
`;

const Legend = ({ permissionContext }) => {
  return (
    <div>
      {permissionContext === 'admin' && (
        <div>
          <Div type="long" />
          <small> - element dłuższy niż 2750mm</small>
        </div>
      )}
      {permissionContext !== 'user' && (
        <div>
          <div>
            <Div type="lost" />
            <small> - brakujący element</small>
          </div>
          <div>
            <Div type="toCorrect" />
            <small> - element do poprawy</small>
          </div>
        </div>
      )}
      {permissionContext !== 'employee' &&
        permissionContext !== 'display' && (
          <div>
            <div>
              <Div type="notIncludedToPrice" />
              <small>
                {' '}
                - cena tego elementu zostanie ustalona indywidualnie
              </small>
            </div>

            <div>
              <span>
                *
                <small>
                  {' '}
                  - w elementach dostarczony przez klienta, o grubości
                  większej niż 22mm, do powierzchni lakierowania zostanie
                  doliczona powierzchnia krawędzi
                </small>
              </span>
            </div>

            <div>
              <span>
                **
                <small>
                  {' '}
                  - przy zamówieniu mniejszym niż 0.5m<sup>2</sup> cena
                  zamówienia odpowiada kwocie za 0.5m
                  <sup>2</sup>
                </small>
              </span>
            </div>
          </div>
        )}
    </div>
  );
};

export default withContext(Legend);
