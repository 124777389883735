import Axios from 'axios';

export const registerCustomer = async (data, onEnd, onErr) => {
 console.log('REGISTER_CUSTOMER');
 try {
  const res = await Axios.post('/api/customers/', data);

  onEnd(res.data.token);
 } catch (error) {
  const { response } = error;
  console.log('ERROR_AUTH_USER: ', response);
  if (response.status === 422) onErr(parseErrors(response.data.errors));
  if (response.status === 409) onErr({ email: response.data.message });
 }
};

const parseErrors = (err) => {
 const errors = {};
 Object.keys(err).forEach((key) => {
  const error = err[key];
  errors[key] = error.msg;
 });
 return errors;
};
