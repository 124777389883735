/* eslint-disable no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'components/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { setOrdersFilters } from 'services/store/actions/orders';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  button {
    margin: 0;
  }
  input,
  select {
    width: 150px;
    margin-right: 5px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: 5px auto;
    button {
      width: 100%;
      margin: 5px auto;
    }
    input,
    select {
      width: 90%;
      margin: 2px auto;
    }
  }
`;

const Filters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.orders.filters.customer);
  const orders = useSelector(state => state.orders.endedOrders);

  const handleFilter = (field, value) =>
    dispatch(setOrdersFilters('customer', field, value));

  return (
    <div>
      <Row>
        <div>
          Od{' '}
          <DatePicker
            value={filters.dateFrom}
            onChange={value => handleFilter('dateFrom', value)}
            disabled={!orders}
          />{' '}
        </div>
        <div>
          Do{' '}
          <DatePicker
            value={filters.dateTo}
            onChange={value => handleFilter('dateTo', value)}
            disabled={!orders}
          />
        </div>
      </Row>
    </div>
  );
};

export default Filters;
