import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { dateToString } from 'services/utils/date';
import { sortMethod } from 'services/utils/sortMethods';
import { isMessageUnreaded } from 'services/utils/messages';
import { setMessageReaded } from 'services/apiRequests/messages/update';
import { useDispatch, useSelector } from 'react-redux';
import {
 getAllMessages,
 getMessagesForPosition,
} from 'services/store/actions/messages';
import { firstLetterToUpperCase } from 'services/utils/string';
import withContext from 'hoc/withContext';

const Wrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: ${({ isOwn }) => (isOwn ? 'flex-end' : 'flex-start')};
`;
const MessageContainer = styled.div`
 cursor: pointer;
 border: 1px solid grey;
 padding: 2px;
 border-radius: 5px;
 margin-bottom: 5px;
 width: 90%;
 background-color: ${({ isUnreaded }) =>
  isUnreaded ? 'rgba(0,0,0,0.05)' : 'white'};
`;
const Top = styled.div`
 display: flex;
 justify-content: space-between;
 font-size: 10px;
 margin-bottom: 2px;
`;
const Info = styled.span`
 font-weight: bold;
`;
const Position = styled.span`
 font-size: 9px;
 color: ${({ theme, position }) => theme.positions[position]};
 text-shadow: ${({ position }) =>
  position === 'podkład' ? '1px 1px 3px rgb(34, 34, 34)' : 'none'};
`;

const MessagesList = ({ messages, user, setMessage, permissionContext }) => {
 const position = useSelector((state) => state.employee.activePosition);
 const dispatch = useDispatch();

 const getMessages = async () => {
  if (permissionContext === 'admin')
   dispatch(
    getAllMessages(
     () => {},
     () => {},
    ),
   );
  else if (permissionContext === 'employee')
   dispatch(
    getMessagesForPosition(
     position,
     () => {},
     () => {},
    ),
   );
 };

 const handleClick = async (message, isUnreaded) => {
  if (isUnreaded) await setMessageReaded(message._id, () => getMessages());
  setMessage(message);
 };
 return (
  <div>
   {messages &&
    messages
     .sort((a, b) =>
      sortMethod[sortMethod.getSortMethod('addDate')](
       a,
       b,
       'addDate',
       'descend',
      ),
     )
     .map((message) => (
      <Message
       key={message._id}
       message={message}
       user={user}
       handleClick={handleClick}
      />
     ))}
  </div>
 );
};

export default withContext(MessagesList);

const Message = ({ message, user, handleClick }) => {
 const msg = message.message.substr(0, 50);
 const isOwn = user._id === message.authorId;
 const isUnreaded = isMessageUnreaded(user, message);

 return (
  <Wrapper isOwn={isOwn} onClick={() => handleClick(message, isUnreaded)}>
   <MessageContainer isUnreaded={isUnreaded}>
    <Top>
     <Info>
      {message.author}
      <FontAwesomeIcon icon={faArrowRight} />{' '}
      {message.positions.map((item) => (
       <Position position={item.toLowerCase()} key={item}>
        {firstLetterToUpperCase(item)}{' '}
       </Position>
      ))}
     </Info>
     <span>{dateToString(message.addDate)}</span>
    </Top>
    <div>
     {msg}
     {msg.length >= 50 && '...'}
    </div>
   </MessageContainer>
  </Wrapper>
 );
};
