import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
 addNewOrderItemImage,
 removeNewOrderItemImage,
} from 'services/store/actions/newOrder';

const StyledInputFile = styled.input`
 width: 0.1px;
 height: 0.1px;
 opacity: 0;
 overflow: hidden;
 position: absolute;
 z-index: -1;
`;
const StyledLabelFile = styled.label`
 cursor: pointer;
 margin: 0;
`;

const ImageCell = ({ index, item, tabIndex }) => {
 const dispatch = useDispatch();

 return (
  <td>
   {!item.image.file && !item.image.path ? (
    <>
     <StyledInputFile
      tabIndex={tabIndex}
      type="file"
      accept="image/*,application/pdf"
      id={`file${index}`}
      onChange={e => dispatch(addNewOrderItemImage(index, e.target.files[0]))}
     />
     <StyledLabelFile htmlFor={`file${index}`}>
      <FontAwesomeIcon icon={faFileUpload} title="Dodaj rysunek" />
     </StyledLabelFile>
    </>
   ) : (
    <FontAwesomeIcon
     icon={faTrashAlt}
     style={{ color: '#c01212', cursor: 'pointer' }}
     title="Usuń rysunek"
     onClick={() => dispatch(removeNewOrderItemImage(index))}
    />
   )}
  </td>
 );
};

export default ImageCell;
