import { useState } from 'react';

export const useModal = initModals => {
  const [modal, setModals] = useState(initModals);

  const showModal = key =>
    setModals({ ...modal, [key]: true });
  const closeModal = () => setModals(initModals);

  return { modal, showModal, closeModal };
};
