import React, { useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { firstLetterToUpperCase } from 'services/utils/string';
import {
  setStatsDate,
  setStatsField,
  getEmployeeStatistics,
} from 'services/store/actions/statistics';
import DatePicker from 'components/DatePicker';
import { Form } from 'react-bootstrap';
import { getEmployees } from 'services/store/actions/employees';
import { setSpinner } from 'services/store/actions/view';
import { isDatesEqual } from 'services/utils/date';
import RangeStatistics from './components/RangeStatistics';
import DayStats from './components/DayStats';

const EmployeeStats = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const date = useSelector((state) => state.statistics.date.employee);
  const employee = useSelector((state) => state.statistics.employee);
  const employees = useSelector((state) => state.employees.employees);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(
      getEmployees(
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (date.from && date.to && employee) {
      dispatch(setSpinner(true));
      dispatch(
        getEmployeeStatistics(
          date,
          employee,
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, [date, employee]);

  const handleDate = (key, value) =>
    dispatch(setStatsDate('employee', key, value));
  const handleEmployee = (value) =>
    dispatch(setStatsField('employee', value));
  return (
    <PageTemplate>
      <PageHeader
        title="Statystyki pracownika"
        extra={[
          <div key={0}>
            Od{' '}
            <DatePicker
              value={date.from}
              onChange={(e) => handleDate('from', e)}
            />
          </div>,
          <div key={1}>
            Do{' '}
            <DatePicker
              value={date.to}
              onChange={(e) => handleDate('to', e)}
            />
          </div>,
          <EmployeesSelect
            key={2}
            items={employees}
            value={employee}
            handleChange={handleEmployee}
          />,
        ]}
      />
      {isDatesEqual(date.from, date.to) ? (
        <DayStats date={date.from} employee={employee} />
      ) : (
        <RangeStatistics employee={employee} date={date} />
      )}
    </PageTemplate>
  );
};

export default EmployeeStats;

const EmployeesSelect = ({ items, value, handleChange }) => (
  <div>
    <Form.Control
      as="select"
      onChange={(e) => handleChange(e.target.value)}
      value={value}
      disabled={!items}
      style={{ width: 300, margin: '0 3px' }}
    >
      <option value="" />
      {items &&
        items
          .sort((a, b) => `${a.company}`.localeCompare(b.company))
          .map((item) => (
            <option value={item._id} key={item._id} status={item}>
              {firstLetterToUpperCase(`${item.firstname} ${item.surname}`)}
            </option>
          ))}
    </Form.Control>
  </div>
);
