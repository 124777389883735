import {
  dateToStringWithoutDay,
  getMonthName,
  getYearFromDate,
} from 'services/utils/date';

export const getOrdersChartData = orders => {
  const data = [];
  orders.forEach(order => {
    const result = {
      data: order.pickUpDate,
      monthLabel: dateToStringWithoutDay(order.pickUpDate),
      monthName: getMonthName(order.pickUpDate),
      year: getYearFromDate(order.pickUpDate),
      surface: (order.surfaceRight || 0) + (order.surfaceLeft || 0),
      price:
        (order.price || 0) +
        (order.ventilations.price || 0) +
        (order.manHours.price || 0),
    };
    data.push(result);
  });

  const result = data
    .reduce((acc, order) => {
      const isMonthExist = acc.find(
        item => item.monthLabel === order.monthLabel,
      );
      if (!isMonthExist) return acc.concat(order);

      const newAcc = acc.map(item => {
        if (item.monthLabel !== order.monthLabel) return item;
        const newItem = { ...item };
        newItem.surface += order.surface;
        newItem.price += order.price;
        return newItem;
      });
      return newAcc;
    }, [])
    .sort((a, b) => {
      const dateA = new Date(a.data);
      const dateB = new Date(b.data);
      return dateA - dateB;
    });

  const obj = {
    price: {
      labels: result.map(
        item => `${item.monthName.slice(0, 3)}.${item.year}`,
      ),
      datasets: [
        {
          type: 'line',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 2,
          fill: false,
          label: 'Kwota [zł]',
          data: result.map(item =>
            item.price ? item.price.toFixed(2) : 0,
          ),
        },
      ],
    },
    surface: {
      labels: result.map(
        item => `${item.monthName.slice(0, 3)}.${item.year}`,
      ),
      datasets: [
        {
          type: 'bar',
          backgroundColor: 'rgb(75, 192, 192)',
          label: 'Powierzchnia [m2]',
          data: result.map(item =>
            item.surface ? item.surface.toFixed(2) : 0,
          ),
        },
      ],
    },
  };
  return obj;
};
