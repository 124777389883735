/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Modal from 'templates/ModalTemplate';
import { setSpinner } from 'services/store/actions/view';
import { setOrder } from 'services/store/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getEmployees } from 'services/store/actions/employees';
import { StyledH6 } from 'components/header';
import { Form, Button, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { updateOrder } from 'services/apiRequests/orders/update';
import FlexRow from 'templates/FlexRowTemplate';

const Th = styled.th`
  width: 20px;
`;
const TightColumn = styled.th`
  width: 65px;
  background-color: ${({ side, theme }) => {
    if (side === 'left') return theme.dillakWarning;
    if (side === 'right') return theme.dillakGreen;
    return null;
  }};
`;
const PrimaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(205, 231, 169, 0.5);
`;
const SecondaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(252, 215, 160, 0.5);
`;
const Wrapper = styled.div`
  min-height: 100px;
`;

const StopOrder = ({ order, position, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const user = useSelector(state => state.auth.user);
  const employees = useSelector(state => state.employees.employees);
  const paintRoom = useSelector(state => state.employee.paintRoom);

  const [orderEmployees, setOrderEmployees] = useState([]);
  const [lostItems, setLostItems] = useState([]);
  const [itemsToStay, setItemsToStay] = useState([]);
  const [halfGriding, setHalfGriding] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!employees) {
      dispatch(setSpinner(true));
      dispatch(
        getEmployees(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd');
          },
        ),
      );
    }
    if (order.reportLost.includes(position)) {
      const newLostItems = [];
      const newToStay = [];
      order.items.forEach((item, index) => {
        if (item.elementLost && item.elementLost.position === position) {
          newLostItems.push({ index, ...item });
          newToStay.push(item._id);
        }
      });
      setItemsToStay(newToStay);
      setLostItems(newLostItems);
    }
  }, []);

  const handleCheckEmployee = ({ target: { value, checked } }) => {
    if (checked) setOrderEmployees(orderEmployees.concat(value));
    else {
      const newList = orderEmployees.filter(item => item !== value);
      setOrderEmployees(newList);
    }
  };

  const handleItemToStay = ({ target: { value, checked } }) => {
    if (checked) setItemsToStay(itemsToStay.concat(value));
    else {
      const newList = itemsToStay.filter(item => item !== value);
      setItemsToStay(newList);
    }
  };

  const handleNext = () => {
    if (step === 0) {
      if (!lostItems.length) {
        if (
          ['podkład', 'surówka'].includes(position) &&
          (!order.veneer || order.isHalfGriding)
        )
          return setStep(2);
        return handleSubmit();
      }
      return setStep(1);
    }
    if (step === 1) {
      if (
        ['podkład', 'surówka'].includes(position) &&
        (!order.veneer || order.isHalfGriding)
      )
        return setStep(2);
      return handleSubmit();
    }
    if (step === 2) return handleSubmit();
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'stop',
      order._id,
      {
        position,
        employees: orderEmployees,
        halfGriding,
        lostElementsToStay: itemsToStay,
        paintRoom,
      },
      order => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('Zakończono wykonywanie zamówienia');
        closeModal();
      },
      msg => {
        dispatch(setSpinner(false));
        alert.error(msg || 'Błąd!');
        closeModal();
      },
    );
  };

  return (
    <Modal
      title="Stop"
      closeModal={closeModal}
      backdrop="static"
      footer={
        <>
          <Button variant="success" onClick={() => handleNext()}>
            Dalej
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      <Wrapper>
        {step === 0 ? (
          <>
            <StyledH6>Kto wykonywał z Tobą to zamówienie?</StyledH6>
            {employees &&
              employees.map(item => {
                if (
                  item.positions.includes(position) &&
                  item._id !== user._id
                )
                  return (
                    <Form.Check
                      style={{ marginBottom: 10 }}
                      id={item._id}
                      checked={orderEmployees.includes(item._id)}
                      key={item._id}
                      value={item._id}
                      type="checkbox"
                      label={`${item.firstname} ${item.surname}`}
                      onChange={handleCheckEmployee}
                    />
                  );
                return null;
              })}
          </>
        ) : step === 1 && lostItems.length ? (
          <>
            <FlexRow justify="space-between">
              <StyledH6>
                Wybierz elementy które zostają na stanowisku
              </StyledH6>{' '}
              <Button
                style={{ padding: 3, lineHeight: 1 }}
                size="sm"
                onClick={() => setItemsToStay([])}
              >
                Odznacz wszystkie
              </Button>
            </FlexRow>
            <LostElements
              lostItems={lostItems}
              itemsToStay={itemsToStay}
              handleItemToStay={handleItemToStay}
            />
          </>
        ) : step === 2 ? (
          <>
            <Form.Check
              label={
                position === 'podkład'
                  ? 'Do przeszlifu'
                  : 'Zakończ przeszlif'
              }
              id="isHalfGriding"
              checked={halfGriding}
              type="checkbox"
              onChange={e => setHalfGriding(e.target.checked)}
              disabled={
                (!order.millings || !order.millings.length) &&
                !order.veneer
              }
              style={{
                margin: '0 auto',
                fontSize: 20,
                textAlign: 'center',
              }}
            />
          </>
        ) : null}
      </Wrapper>
    </Modal>
  );
};

export default StopOrder;

const LostElements = props => {
  const { lostItems } = props;
  return (
    <Table responsive="lg" hover bordered>
      <thead>
        <tr>
          <Th>Zostaw</Th>
          <Th>LP</Th>
          <TightColumn>Wys.</TightColumn>
          <TightColumn>Szer.</TightColumn>
          <TightColumn side="right">P</TightColumn>
          <TightColumn side="left">L</TightColumn>
          <Th>Ilość</Th>
          <TightColumn>Ilość braków</TightColumn>
        </tr>
      </thead>
      <tbody>
        {lostItems.map(item => {
          return <TableRow key={item._id} item={item} {...props} />;
        })}
      </tbody>
    </Table>
  );
};
const TableRow = ({ item, itemsToStay, handleItemToStay }) => {
  return (
    <tr>
      <td>
        <Form.Check
          id={item._id}
          checked={itemsToStay.includes(item._id)}
          key={item._id}
          value={item._id}
          type="checkbox"
          onChange={handleItemToStay}
        />
      </td>
      <td>{item.index + 1}</td>
      <td>{item.height}</td>
      <td>{item.width}</td>
      <PrimaryColumn>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </PrimaryColumn>
      <SecondaryColumn>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </SecondaryColumn>
      <td>{item.quantity}</td>
      <td>{item.elementLost.quantity}</td>
    </tr>
  );
};
