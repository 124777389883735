import React from 'react';
import Modal from 'templates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { employeePermRemove } from 'services/apiRequests/employees/remove';
import { useHistory } from 'react-router';

const RemoveUser = ({ userId, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const history = useHistory();

 const handleRemoveUser = async () => {
  dispatch(setSpinner(true));
  await employeePermRemove(
   userId,
   () => {
    dispatch(setSpinner(false));
    history.push('/employees');
    alert.success('Pracownik usunięty');
   },
   () => {
    alert.error('Błąd!');
    dispatch(setSpinner(false));
   },
  );
 };

 return (
  <Modal
   title="Usunięcie pracownika"
   variant="danger"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" onClick={handleRemoveUser}>
      Tak
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Nie
     </Button>
    </>
   }
  >
   Czy na pewno chcesz usunąć pracownika i jego statstyki? Ten proces będzie
   nieodwracalny
  </Modal>
 );
};

export default RemoveUser;
