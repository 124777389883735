/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { getStatsFromOrders } from 'scenes/Statistics/utils';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { StyledH1, StyledH3 } from 'components/header';
import { dateToString } from 'services/utils/date';
import FlexRow from 'templates/FlexRowTemplate';

const Wrapper = styled.div`
  text-align: center;
`;
const Title = styled.h2`
  color: ${({ color, theme }) => theme[color]};
  font-size: 35px;
  letter-spacing: 5px;
  font-weight: bold;
`;
const Info = styled.span`
  font-size: 25px;
`;

const Statistics = ({ orders, customer, date }) => {
  const customers = useSelector(state => state.customers.customers);

  const [stats, setStats] = useState(null);
  const [user, setUser] = useState('');
  useEffect(() => {
    if (orders) {
      const stats = getStatsFromOrders(orders);
      setStats(stats);
    }
  }, [orders, date]);

  useEffect(() => {
    if (customers && customer) {
      const user = customers.find(item => item._id === customer);
      setUser({
        company: `${user.company}`,
        name: `${user.firstname} ${user.surname}`,
      });
    }
  }, [customers, customer]);

  if (stats)
    return (
      <div>
        <Wrapper>
          <StyledH1>{user.company}</StyledH1>
          <StyledH3>{user.name}</StyledH3>
          <small>
            Dane pochodzą z zamówień odebranych między{' '}
            <strong>{dateToString(date.from)}</strong> a{' '}
            <strong>{dateToString(date.to)}</strong>
          </small>
        </Wrapper>
        <hr />
        <Wrapper>
          <Title color="dillakPrimary">Odebrał</Title>
          <Info>
            <strong>{stats.summarySurface.toFixed(2)}</strong>
            <small>
              m<sup>2</sup>
            </small>{' '}
            za kwotę{' '}
            <strong style={{ color: 'green' }}>{stats.price.toFixed(2)}</strong>
            <small>zł</small>
          </Info>
        </Wrapper>
        <hr />
        <Wrapper>
          <Title color="dillakWarning">Zamówienia zawierały</Title>
        </Wrapper>
        <FlexRow justify="space-around">
          <Wrapper>
            <Item value={stats.surfaces.cnc} text="elementów CNC" />
            <Item value={stats.surfaces.flat} text="elementów gładkich" />
            <hr />
            <Item value={stats.surfaces.PL} text="elementów jednostronnych" />
            <Item value={stats.surfaces.PP} text="elementów dwustronnych" />
            <hr />
            <Item
              value={stats.surfaces.veneer}
              text="elementów fornirowanych"
            />
          </Wrapper>
          <Wrapper>
            <Item value={stats.surfaces.gloss} text="połysku" />
            <Item value={stats.surfaces.semiGloss} text="półmatu" />
          </Wrapper>
          <Wrapper>
            <Item
              value={stats.other.handles}
              unit="mb"
              text="uchtytów frezowanych"
            />
            <Item
              value={stats.other.partHandles}
              unit="szt"
              text="uchwytów częściowych"
            />
            <Item
              value={stats.other.hingeHoles}
              unit="szt"
              text="otworów pod zawiasy"
            />
          </Wrapper>
        </FlexRow>
        <hr />
        <Wrapper>
          <Title color="dillakInfo">Zgłosił</Title>

          <Item
            value={stats.reclamations.surfaces.company}
            color="red"
            text="reklamacji"
          />
        </Wrapper>
        <hr />
        <Wrapper>
          <Item
            value={(stats.customerMaterialSurface / stats.summarySurface) * 100}
            unit="%"
            text="powierzchni zamówień wykonaliśmy z materiału powierzonego"
          />
        </Wrapper>
        <hr />
        <Wrapper>
          <Title color="dillakPurple">Średni czas realizacji</Title>
          <Item
            value={stats.realizationTimes.gloss}
            unit="dni"
            text="dla zamówień gładkich w połysku"
          />
          <Item
            value={stats.realizationTimes.semiGloss}
            unit="dni"
            text="dla zamówień gładkich w półmacie"
          />
          <Item
            value={stats.realizationTimes.cnc}
            unit="dni"
            text="dla zamówień CNC"
          />
          <Item
            value={stats.realizationTimes.veneer}
            unit="dni"
            text="dla zamówień fornirowanych"
          />
        </Wrapper>
      </div>
    );
  return null;
};

const Item = ({ value, unit = 'm2', text, color = 'black' }) => {
  return (
    <div>
      <Info>
        <strong style={{ color }}>
          {value && !['szt', 'dni'].includes(unit)
            ? value.toFixed(2)
            : value || 0}
        </strong>
        <small>
          {unit === 'm2' ? (
            <span>
              m<sup>2</sup>
            </span>
          ) : (
            unit
          )}
        </small>{' '}
        {text}
      </Info>
    </div>
  );
};

export default Statistics;
