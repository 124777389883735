export const orderItemTemplate = {
  type: 'gładki',
  height: 0,
  h1P: '-',
  h2P: '-',
  h1L: '-',
  h2L: '-',
  hLPaintedEdge: false,
  width: 0,
  w1P: '-',
  w2P: '-',
  w1L: '-',
  w2L: '-',
  wLPaintedEdge: false,
  paintRight: true,
  paintLeft: false,
  surfaceRight: 0,
  surfaceLeft: 0,
  edgesSurface: 0,
  milledHandle: 0,
  milledPartHandle: 0,
  thickness: 19,
  quantity: 1,
  comments: '',
  image: {
    path: '',
    file: null,
  },
  includedToPrice: true,
  dillakMaterial: true,
  transparentBothSide: false,
  muntinH: 0,
  muntinW: 0,
  muntinX: 0,
};
