import React, { useState } from 'react';
import styled from 'styled-components';
import ModalContainer from 'templates/ModalTemplate';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';

const StyledImage = styled.img`
 width: 200px;
 height: 200px;
 margin: 15px;
 display: inline-block;
 cursor: pointer;
`;
const Wrapper = styled.div`
 text-align: center;
`;

const ElementDetails = ({ location }) => {
 const [img, setImg] = useState('');
 const { state } = location;
 return (
  <PageTemplate>
   <PageHeader title={state.name.toUpperCase()} />
   <Wrapper>
    <StyledImage
     src={require(`assets/images/${state.type}s/${state.name}/model.jpg`)}
     alt="img"
     onClick={() => {
      setImg('model.jpg');
     }}
    />
    {state.imageProfile && (
     <StyledImage
      src={require(`assets/images/${state.type}s/${state.name}/przekroj.png`)}
      alt="img"
      className={state.type === 'handle' ? 'przekroj' : ''}
      onClick={() => {
       setImg('przekroj.png');
      }}
     />
    )}
    {state.imageProfile2 && (
     <StyledImage
      src={require(`assets/images/${state.type}s/${state.name}/przekroj2.png`)}
      alt="img"
      className={state.type === 'handle' ? 'przekroj' : ''}
      onClick={() => {
       setImg('przekroj2.png');
      }}
     />
    )}
    {state.imageFront && (
     <StyledImage
      src={require(`assets/images/${state.type}s/${state.name}/front.png`)}
      alt="img"
      onClick={() => {
       setImg('front.png');
      }}
     />
    )}
    {state.imageIzometric && (
     <StyledImage
      src={require(`assets/images/${state.type}s/${state.name}/izometric.png`)}
      alt="img"
      onClick={() => {
       setImg('izometric.png');
      }}
     />
    )}
   </Wrapper>

   {/* MODAL */}
   <ImageModal closeModal={() => setImg('')} state={state} img={img} />
  </PageTemplate>
 );
};

export default ElementDetails;

const ImageModal = ({ state, img, closeModal }) =>
 img && (
  <ModalContainer closeModal={closeModal} size="xl">
   <div style={{ textAlign: 'center' }}>
    <img
     alt="Img"
     style={{ maxWidth: '100%', maxHeight: '100vh' }}
     src={require(`assets/images/${state.type}s/${state.name}/${img}`)}
     onClick={closeModal}
     className="image"
    />
   </div>
  </ModalContainer>
 );
