import {
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_LOAD_ERROR,
} from '../types/settings';

const initialState = {
  paintProducers: null,
  blackBaseColors: null,
  paintSecondGroup: null,
  prices: null,
  realizationDates: null,
  contact: null,
  productionLimits: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_LOAD_SUCCESS: {
      return action.settings;
    }
    case SETTINGS_LOAD_ERROR: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export { settingsReducer };
