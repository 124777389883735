import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cells from 'components/ordersList/components/cells';
import React, { useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { sortMethod } from 'services/utils/sortMethods';
import styled from 'styled-components';

const Wrapper = styled.div`
 td {
  padding: 0 !important;
 }
`;

const ProductionList = ({ orders, handleAdd }) => {
 const [sort, setSort] = useState({ direction: 'ascend', key: 'finishDate' });

 const handleSort = key => {
  const direction = sort.direction === 'ascend' ? 'descend' : 'ascend';
  setSort({ key, direction });
 };
 return (
  <Wrapper style={{ height: 350, overflowY: 'auto', fontSize: 14 }}>
   <Table responsive="lg" bordered hover size="sm">
    <thead>
     <tr>
      <th>LP</th>
      <th onClick={() => handleSort('company')}>
       Klient
       <FontAwesomeIcon icon={faSort} />
      </th>
      <th>Nr</th>
      <th>Nazwa</th>
      <th onClick={() => handleSort('color')}>
       Kolor
       <FontAwesomeIcon icon={faSort} />
      </th>
      <th onClick={() => handleSort('paintType')}>
       Matowosć
       <FontAwesomeIcon icon={faSort} />
      </th>
      <th>Elementy</th>
      <th>PL</th>
      <th>PP</th>
      <th>Typ</th>
      <th>Data</th>
      <th onClick={() => handleSort('finishDate')}>
       Data realizacji
       <FontAwesomeIcon icon={faSort} />
      </th>
      <th>Status</th>
      <th style={{ width: 20 }}>Wsz.</th>
      <th style={{ width: 20 }}>Lew.</th>
      <th style={{ width: 20 }}>Popr.</th>
      <th style={{ width: 20 }}>Brak.</th>
      <th style={{ width: 20 }}>+</th>
     </tr>
    </thead>
    <tbody>
     {orders && orders.length
      ? orders
         .sort((a, b) => {
          return sortMethod[sortMethod.getSortMethod(sort.key)](
           a,
           b,
           sort.key,
           sort.direction,
          );
         })
         .map((order, index) => (
          <TableRow
           key={order._id}
           order={order}
           index={index}
           handleAdd={handleAdd}
          />
         ))
      : null}
    </tbody>
   </Table>
  </Wrapper>
 );
};

export default ProductionList;

const TableRow = ({ order, index, handleAdd }) => {
 const [actions, setActions] = useState({
  all: true,
  left: false,
  corrections: false,
  losts: false,
 });

 const handleAction = (e, action) => {
  const newActions = {
   all: false,
   left: false,
   corrections: false,
   losts: false,
   [action]: e.target.checked,
  };
  setActions(newActions);
 };

 return (
  <tr key={order._id}>
   <td>{index + 1}</td>
   <cells.Customer user={order.user} />
   <td>{order.number.toUpperCase()}</td>
   <cells.Name name={order.name} />
   <cells.Color order={order} />
   <cells.PaintType order={order} />
   <cells.Elements order={order} />
   <cells.Surface order={order} surface="surfaceRight" />
   <cells.Surface order={order} surface="surfaceLeft" />
   <cells.Type order={order} />
   <cells.Date date={order.date} />
   <cells.FinishDate date={order.finishDate} />
   <cells.Status order={order} />
   <td>
    <Form.Check
     type="radio"
     checked={actions.all}
     onChange={e => handleAction(e, 'all')}
    />
   </td>
   <td>
    <Form.Check
     type="radio"
     checked={actions.left}
     onChange={e => handleAction(e, 'left')}
    />
   </td>
   <td>
    <Form.Check
     type="radio"
     checked={actions.corrections}
     onChange={e => handleAction(e, 'corrections')}
    />
   </td>
   <td>
    <Form.Check
     type="radio"
     checked={actions.losts}
     onChange={e => handleAction(e, 'losts')}
    />
   </td>
   <td>
    <Button size="sm" onClick={() => handleAdd(order, actions, index)}>
     Dodaj
    </Button>
   </td>
  </tr>
 );
};
