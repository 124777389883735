import withContext from 'hoc/withContext';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sendCustomerMessage } from 'services/apiRequests/messages/post';
import { setCustomerMessages } from 'services/store/actions/auth';
import { getCustomers } from 'services/store/actions/customers';
import { setSpinner } from 'services/store/actions/view';
import Modal from 'templates/ModalTemplate';
import MessagesList from './MessagesList';

const CustomersMessages = ({ closeModal, permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const customers = useSelector((state) => state.customers.customers);
  const messages = useSelector((state) => state.auth.messages);

  const [message, setMessage] = useState('');
  const [toUser, setToUser] = useState('');
  const [toAll, setToAll] = useState(false);

  useEffect(() => {
    if (permissionContext === 'admin' && !customers) {
      dispatch(setSpinner(true));
      dispatch(
        getCustomers(
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          },
        ),
      );
    }
  }, []);

  const handleToAll = (checked) => {
    if (checked) {
      setToUser('');
      setToAll(true);
    } else {
      setToAll(false);
    }
  };

  const handleSendMessage = async () => {
    dispatch(setSpinner(true));
    await sendCustomerMessage(
      { message, toAll, toUser },
      (newMessage) => {
        dispatch(setSpinner(false));
        alert.success('Wiadomość wysłana');
        const newMessages = messages
          ? [newMessage, ...messages]
          : [newMessage];
        dispatch(setCustomerMessages(newMessages));
        setMessage('');
        setToAll(false);
        setToUser('');
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      },
    );
  };

  return (
    <Modal
      title="Wiadomości"
      closeModal={closeModal}
      footer={<div />}
      size="xl"
    >
      <Row>
        <Col xs={12} sm={6}>
          <MessagesList messages={messages} />
        </Col>
        <Col xs={12} sm={6}>
          <Form.Control
            as="textarea"
            placeholder="Treść nowej wiadomości..."
            style={{ height: '100px', marginBottom: 10 }}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          {permissionContext === 'admin' && (
            <>
              <div>
                <Form.Check
                  style={{ marginBottom: 5 }}
                  value={toAll}
                  id="toAll"
                  type="checkbox"
                  label="Do wszystkich"
                  onChange={(e) => handleToAll(e.target.checked)}
                />
              </div>
              <div>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    disabled={toAll}
                    as="select"
                    value={toUser}
                    onChange={(e) => setToUser(e.target.value)}
                  >
                    <option value="" />
                    {customers &&
                      customers
                        .sort((a, b) =>
                          `${a.company}`.localeCompare(`${b.company}`),
                        )
                        .map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.company} - {item.firstname[0]}
                          </option>
                        ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </>
          )}
          <div>
            <Button
              onClick={handleSendMessage}
              disabled={
                (permissionContext === 'admin' && !toAll && !toUser) ||
                !message ||
                (permissionContext === 'user' && !message)
              }
            >
              Wyślij
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default withContext(CustomersMessages);
