/* eslint-disable no-shadow */
import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faTrashAlt,
  faComments,
  faEdit,
  faDollarSign,
  faCalendarDay,
  faExclamation,
  faArrowAltCircleRight,
  faHandHoldingUsd,
  faStickyNote,
  faFileAlt,
  faBinoculars,
  faReply,
  faClock,
  faBars,
  faTruck,
  faCalendar,
  faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setSpinner } from 'services/store/actions/view';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const AdminButtons = ({ order, setModal, editOrder, getFiles, getLabel }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector(state => state.auth.user);

  const setOrderPriority = async priority => {
    dispatch(setSpinner(true));
    await updateOrder(
      'priority',
      order._id,
      { priority },
      order => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      }
    );
  };

  return (
    <>
      <Button variant="danger" onClick={() => setModal('_remove')}>
        <FontAwesomeIcon icon={faTrashAlt} /> Usuń
      </Button>

      <Button
        variant="outline-danger"
        onClick={() => setModal('_elementsLost')}
      >
        <FontAwesomeIcon icon={faBinoculars} /> Elementy brakujące
      </Button>

      <Button
        variant="outline-primary"
        onClick={() => setModal('_elementsToCorrect')}
      >
        <FontAwesomeIcon icon={faReply} /> Elementy do poprawy
      </Button>

      <Button
        style={{
          backgroundColor: '#6f42c1',
          borderColor: '#6f42c1',
          color: 'white',
        }}
        onClick={editOrder}
      >
        <FontAwesomeIcon icon={faEdit} /> Edytuj
      </Button>

      <Button onClick={() => setModal('_neworder')}>
        <FontAwesomeIcon icon={faPlusSquare} /> Zamów
      </Button>

      <Button variant="info" onClick={() => setModal('_status')}>
        <FontAwesomeIcon icon={faArrowAltCircleRight} /> Status
      </Button>

      <Button
        style={{
          backgroundColor: '#33cec3',
          borderColor: '#33cec3',
          color: 'black',
        }}
        onClick={() => setModal('_realizationDate')}
      >
        <FontAwesomeIcon icon={faCalendarDay} /> Zmień Datę realizacji
      </Button>

      <Button
        style={{
          backgroundColor: '#486ADC',
          borderColor: '#486ADC',
          color: 'white',
        }}
        onClick={() => setModal('_planTransport')}
      >
        <FontAwesomeIcon icon={faTruck} /> Zaplanuj transport
      </Button>

      <Button variant="primary" onClick={() => setModal('_planOrder')}>
        <FontAwesomeIcon icon={faCalendar} /> Zaplanuj zamówienie
      </Button>

      <Button variant="outline-info" onClick={() => setModal('_manHours')}>
        <FontAwesomeIcon icon={faClock} /> Roboczogodziny
      </Button>

      <Button
        variant="outline-success"
        onClick={() => setModal('_ventilation')}
      >
        <FontAwesomeIcon icon={faBars} /> Wentylacja
      </Button>

      <Button variant="secondary" onClick={getFiles}>
        <FontAwesomeIcon icon={faDownload} /> Pobierz
      </Button>

      <Button variant="outline-secondary" onClick={() => getLabel()}>
        <FontAwesomeIcon icon={faStickyNote} /> Pobierz naklejkę
      </Button>

      {user.isBoss && (
        <Button variant="success" onClick={() => setModal('_paymentStatus')}>
          <FontAwesomeIcon icon={faHandHoldingUsd} /> Status płatności
        </Button>
      )}

      <Button variant="warning" onClick={() => setModal('_changePrice')}>
        <FontAwesomeIcon icon={faDollarSign} /> Zmień cenę
      </Button>

      <Button variant="outline-dark" onClick={() => setModal('_addComment')}>
        <FontAwesomeIcon icon={faComments} /> Dodaj komentarz
      </Button>

      <Button
        variant={order.priority ? 'outline-dark' : 'dark'}
        onClick={() => {
          setOrderPriority(!order.priority);
        }}
      >
        <FontAwesomeIcon icon={faExclamation} />{' '}
        {order.priority ? 'Usuń priorytet' : 'Nadaj priorytet'}
      </Button>

      {order.status.includes('zakończone') && (
        <Button
          variant="outline-primary"
          onClick={() => setModal('_getPickUpReport')}
        >
          <FontAwesomeIcon icon={faFileAlt} /> Protokół odbioru
        </Button>
      )}
    </>
  );
};

export default AdminButtons;
