import {
  CATALOG_LOAD_SUCCESS,
  CATALOG_LOAD_ERROR,
  CATALOG_ADD_TO_LIST,
  CATALOG_REMOVE_FROM_LIST,
  CATALOG_UPDATE_IN_LIST,
} from '../types/catalog';
import update from 'immutability-helper';

const initialState = {
  fronts: [],
  glassCases: [],
  handles: [],
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATALOG_LOAD_SUCCESS: {
      return {
        ...state,
        fronts: action.fronts,
        glassCases: action.glassCases,
        handles: action.handles,
      };
    }
    case CATALOG_LOAD_ERROR: {
      return { ...initialState };
    }
    case CATALOG_ADD_TO_LIST: {
      return update(state, {
        [action.listName]: { $push: [action.item] },
      });
    }
    case CATALOG_REMOVE_FROM_LIST: {
      const index = state[action.listName].findIndex(
        item => item._id === action.id,
      );
      if (index > -1)
        return update(state, {
          [action.listName]: { $splice: [[index, 1]] },
        });
      break;
    }

    case CATALOG_UPDATE_IN_LIST: {
      return update(state, {
        [action.listName]: {
          [action.index]: { $set: action.item },
        },
      });
    }

    default:
      return state;
  }
};

export { catalogReducer };
