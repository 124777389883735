import React from 'react';
import permissionContext from 'context/PermissionContext';
import GlobalStyle from 'theme/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/MainTheme';

const MainTemplate = ({ children }) => {
 const { permission } = localStorage;
 return (
  <>
   <permissionContext.Provider value={permission}>
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
   </permissionContext.Provider>
  </>
 );
};

export default MainTemplate;
