import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faArrowRight,
 faArrowLeft,
 faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { dateToString } from 'services/utils/date';
import { sortMethod } from 'services/utils/sortMethods';
import { useDispatch, useSelector } from 'react-redux';
import { isMessageUnreaded } from 'services/utils/messages';
import Fade from 'react-reveal/Fade';
import { setMessageReaded } from 'services/apiRequests/messages/update';
import { getCustomerMessages } from 'services/store/actions/auth';
import FlexRow from 'templates/FlexRowTemplate';
import { removeMessage } from 'services/apiRequests/messages/remove';
import { setSpinner } from 'services/store/actions/view';
import withContext from 'hoc/withContext';

const Wrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: ${({ isOwn }) => (isOwn ? 'flex-end' : 'flex-start')};
`;
const MessageContainer = styled.div`
 cursor: pointer;
 border: 1px solid grey;
 padding: 2px;
 border-radius: 5px;
 margin-bottom: 5px;
 width: 90%;
 background-color: ${({ isUnreaded }) =>
  isUnreaded ? 'rgba(0,0,0,0.05)' : 'white'};
`;
const Top = styled.div`
 display: flex;
 justify-content: space-between;
 font-size: 10px;
 margin-bottom: 2px;
`;
const Info = styled.span`
 font-weight: bold;
`;

const MessagesList = ({ messages, permissionContext }) => {
 const dispatch = useDispatch();
 const user = useSelector(state => state.auth.user);
 const [message, setMessage] = useState(null);

 const handleMessage = async (msg, isUnreaded) => {
  setMessage(msg);
  if (isUnreaded) {
   await setMessageReaded(msg._id, () => {
    dispatch(getCustomerMessages(user._id));
   });
  }
 };
 const handleRemove = async msg => {
  dispatch(setSpinner(true));
  await removeMessage(
   msg._id,
   () => {
    dispatch(setSpinner(false));
    dispatch(getCustomerMessages(user._id));
    setMessage(null);
   },
   () => {
    dispatch(setSpinner(false));
   },
  );
 };
 if (!message)
  return (
   <div style={{ maxHeight: 300, overflowY: 'auto' }}>
    {messages &&
     messages
      .sort((a, b) =>
       sortMethod[sortMethod.getSortMethod('addDate')](
        a,
        b,
        'addDate',
        'descend',
       ),
      )
      .map(msg => (
       <MessageItem
        key={msg._id}
        message={msg}
        user={user}
        handleClick={handleMessage}
       />
      ))}
   </div>
  );
 return (
  <div>
   <SingleMessage
    message={message}
    goBack={() => setMessage(null)}
    handleRemove={handleRemove}
    permissionContext={permissionContext}
   />
  </div>
 );
};

export default withContext(MessagesList);

const SingleMessage = ({
 message,
 goBack,
 handleRemove,
 permissionContext,
}) => {
 return (
  <Fade>
   <Wrapper>
    <MessageContainer>
     <div>
      <FlexRow justify="space-between">
       <FontAwesomeIcon
        icon={faArrowLeft}
        onClick={goBack}
        style={{ cursor: 'pointer', fontSize: 18 }}
       />
       {permissionContext === 'admin' && (
        <FontAwesomeIcon
         icon={faTrash}
         onClick={() => handleRemove(message)}
         style={{ color: 'red', cursor: 'pointer', fontSize: 18 }}
        />
       )}
      </FlexRow>
     </div>
     <Top>
      <Info>
       {message.author}{' '}
       {message.recipient && (
        <>
         <FontAwesomeIcon icon={faArrowRight} />
         {message.recipient}
        </>
       )}
      </Info>
      <span>{dateToString(message.addDate)}</span>
     </Top>
     <div>{message.message}</div>
    </MessageContainer>
   </Wrapper>
  </Fade>
 );
};

const MessageItem = ({ message, user, handleClick }) => {
 const msg = message.message.substr(0, 50);
 const isOwn = user._id === message.authorId;
 const isUnreaded = isMessageUnreaded(user, message);

 return (
  <Wrapper isOwn={isOwn} onClick={() => handleClick(message, isUnreaded)}>
   <MessageContainer isUnreaded={isUnreaded}>
    <Top>
     <Info>
      {message.author}{' '}
      {message.recipient && (
       <>
        <FontAwesomeIcon icon={faArrowRight} />
        {message.recipient}
       </>
      )}
     </Info>
     <span>{dateToString(message.addDate)}</span>
    </Top>
    <div>
     {msg}
     {msg.length >= 50 && '...'}
    </div>
   </MessageContainer>
  </Wrapper>
 );
};
