import Axios from 'axios';

export const authUser = async (credentials, onEnd, onErr) => {
 try {
  const res = await Axios.post('/api/auth/', credentials);

  onEnd(res.data.token);
 } catch (error) {
  console.log('ERROR_AUTH_USER: ', error.response);
  if (error.response && error.response.status === 401)
   onErr(error.response.data.message);
  else if (error.response && error.response.status === 422)
   onErr(error.response.data.message);
 }
};
