import Axios from 'axios';
import { getFileExtension } from 'services/utils/files';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const sendOrder = async (order, type, onEnd, onErr) => {
  try {
    const data = new FormData();
    data.append(`order`, JSON.stringify(order));

    if (order.customMilling.file) {
      const ext = getFileExtension(order.customMilling.file.name);
      data.append(
        'file',
        order.customMilling.file,
        `customMilling.${ext}`,
      );
    }
    order.items.forEach((item, index) => {
      if (item.image.file) {
        const ext = getFileExtension(item.image.file.name);
        data.append('file', item.image.file, `img_${index}.${ext}`);
      }
    });

    const res =
      type === 'new'
        ? await Axios.post('/api/orders/neworder', data, config)
        : await Axios.put(`/api/orders/edit/${order._id}`, data, config);
    const { _id } = res.data;

    return onEnd(_id);
  } catch (err) {
    console.log(err.response || err);
    if (
      err.response &&
      err.response.data &&
      err.response.data.msg === 'na produkcji'
    ) {
      return onErr('Zamówienie na produkcji');
    }
    return onErr();
  }
};
