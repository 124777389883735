import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.dillakPrimaryLight};
  }
`;

const MuntinQuantity = ({ name, onChange, value, tabIndex, item }) => {
  return (
    <StyledSelect
      tabIndex={tabIndex}
      name={name}
      value={value}
      onChange={onChange}
      disabled={!['frez', 'witryna'].includes(item.type)}
    >
      <option value={0}>0</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </StyledSelect>
  );
};

export default MuntinQuantity;
