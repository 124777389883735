import React from 'react';
import withContext from 'hoc/withContext';

const ImageCard = ({ item, type, handleClick, permissionContext }) => {
 return (
  <div
   className="catalogCard imageCard"
   onClick={() =>
    permissionContext !== 'employee' ? handleClick(item.name, type) : null
   }
   style={{
    backgroundImage: `url(${require(`assets/images/${type}/${item.image}`)})`,
   }}
  >
   <div className="cardCaption">{item.name}</div>
  </div>
 );
};

export default withContext(ImageCard);
