import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { containsOneOf } from 'services/utils/array';

const StyledSelect = styled.select`
  height: 28px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid lightgray;
  &:focus {
    background-color: ${({ theme }) => theme.dillakPrimaryLight};
  }
`;
const Thickness = ({
  item,
  handleChange,
  handleFocus,
  handleBlur,
  tabIndex,
}) => {
  const order = useSelector(state => state.newOrder);
  const { handles } = order;
  const { h1P, h2P, w1P, w2P, type } = item;
  let options = [];

  if (containsOneOf(handles, [w1P, w2P, h1P, h2P])) options = [18, 19, 22];
  else if (type === 'frez') options = [18, 19, 22, 25, 28, 30, 38];
  else options = [3, 6, 8, 10, 12, 16, 18, 19, 22, 25, 28, 30, 38];

  return (
    <StyledSelect
      tabIndex={tabIndex}
      name="thickness"
      value={item.thickness}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      required
    >
      {options.map(item => (
        <option value={item} key={item}>
          {item}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Thickness;
