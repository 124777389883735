import React from 'react';

const Customer = ({ user }) => {
 const { company, firstname } = user;
 return (
  <td>{user ? `${company}${firstname ? ' - ' + firstname[0] : ''}` : '??'}</td>
 );
};

export default Customer;
