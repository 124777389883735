import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changeCuttingPackageStatus } from 'services/apiRequests/cutting/update';
import { setSpinner } from 'services/store/actions/view';
import { cuttingPackageStatus } from 'services/utils/const';
import Modal from 'templates/ModalTemplate';

const ChangeStatusModal = ({ cuttingPackageId, refetch, closeModal }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const [cuttingStatus, setCuttingStatus] = useState(
    cuttingPackageStatus.IN_PROGRESS,
  );

  const handleSubmit = async () => {
    if (cuttingStatus) {
      dispatch(setSpinner(true));
      await changeCuttingPackageStatus(
        cuttingPackageId,
        cuttingStatus,
        () => {
          dispatch(setSpinner(false));
          alert.success('Paczka wycięta');
          // history.push('/cutting');
          refetch();
          closeModal();
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      );
    }
  };
  return (
    <Modal
      title={'Zmiana statusu'}
      closeModal={closeModal}
      onOk={handleSubmit}
      backdrop="static"
      disabled={!cuttingStatus}
    >
      Zmień status paczki cięcia na:
      <div>
        <Form.Control
          as="select"
          value={cuttingStatus}
          onChange={e => setCuttingStatus(e.target.value)}
        >
          <option value={cuttingPackageStatus.PENDING}>
            {cuttingPackageStatus.PENDING}
          </option>
          <option value={cuttingPackageStatus.IN_PROGRESS}>
            {cuttingPackageStatus.IN_PROGRESS}
          </option>
          <option value={cuttingPackageStatus.READY}>
            {cuttingPackageStatus.READY}
          </option>
        </Form.Control>
      </div>
    </Modal>
  );
};

export default ChangeStatusModal;
