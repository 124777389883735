import { MESSAGES_LOAD_ERROR, MESSAGES_LOAD_SUCCESS } from '../types/messages';

const initialState = [];

const messagesReducer = (state = initialState, action) => {
 switch (action.type) {
  case MESSAGES_LOAD_SUCCESS: {
   return [...action.messages];
  }
  case MESSAGES_LOAD_ERROR: {
   return initialState;
  }
  default:
   return state;
 }
};

export { messagesReducer };
