import Axios from 'axios';
import {
 TIMETABLES_LOAD_SUCCESS,
 TIMETABLES_LOAD_ERROR,
 TIMETABLES_SET_POSITIONS,
 TIMETABLES_SET_VIEW,
} from '../types/timetables';

export const timetablesLoadSuccess = timetables => ({
 type: TIMETABLES_LOAD_SUCCESS,
 timetables,
});
export const timetablesLoadError = () => ({ type: TIMETABLES_LOAD_ERROR });

export const timetablesSetPosititon = (position, value) => ({
 type: TIMETABLES_SET_POSITIONS,
 position,
 value,
});
export const timetablesSetView = view => ({
 type: TIMETABLES_SET_VIEW,
 view,
});

export const getAllTimetables = (onEnd, onErr) => async dispatch => {
 try {
  const res = await Axios.get('/api/timetables');
  const { schedules } = res.data;

  if (res.status === 200) dispatch(timetablesLoadSuccess(schedules));
  onEnd();
 } catch (error) {
  console.log(error);
  dispatch(timetablesLoadError());
  onErr();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};

export const getTimetablesForPosition = (
 position,
 onEnd,
 onErr,
) => async dispatch => {
 try {
  const res = await Axios.get(`/api/timetables/position/${position}`);
  const { schedule } = res.data;

  if (res.status === 200) dispatch(timetablesLoadSuccess(schedule));
  onEnd();
 } catch (error) {
  console.log(error);
  dispatch(timetablesLoadError());
  onErr();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};
