import React, { useState } from 'react';
import Modal from 'templates/ModalTemplate';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { updateEmployeeData } from 'services/apiRequests/employees/update';
import { setEmployee } from 'services/store/actions/employees';
import FlexRow from 'templates/FlexRowTemplate';

const EmployeeEarnings = ({ closeModal, employee }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const [earnings, setEarnings] = useState(employee.earnings || 0);
 const handleChange = (e) => setEarnings(e.target.value);

 const handleSubmit = async () => {
  dispatch(setSpinner(true));

  await updateEmployeeData(
   employee._id,
   { earnings },
   (employee) => {
    dispatch(setSpinner(false));
    alert.success(`Zaktualizowano wynagrodzenie`);
    dispatch(setEmployee(employee));
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error(`Błąd!`);
   },
  );
 };
 return (
  <Modal
   title="Zmiana wynagrodzenia"
   closeModal={closeModal}
   onOk={handleSubmit}
   disabled={!earnings}
  >
   <div>
    <Form.Group>
     <FlexRow justify="space-between">
      <Form.Label>Wynagrodzenie: </Form.Label>
      <Form.Control
       value={earnings}
       onChange={handleChange}
       type="text"
       style={{ width: 200 }}
      />
     </FlexRow>
    </Form.Group>
   </div>
  </Modal>
 );
};

export default EmployeeEarnings;
