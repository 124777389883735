import { isDatesEqual } from 'services/utils/date';
import moment from 'moment';

export const getAverageValue = stats => {
  const sum = stats.reduce((acc, curr) => {
    const date = moment(curr.date);
    const sum =
      curr.flatOneSide +
      curr.flatBothSides +
      curr.cncOneSide +
      curr.cncBothSides;

    if (date.day() === 6 || date.day() === 0) return acc;
    return (acc += sum);
  }, 0);

  const workedDays = stats.reduce((acc, curr) => {
    const date = moment(curr.date);
    if (date.day() === 6 || date.day() === 0) return acc;
    return (acc += 1);
  }, 0);
  return sum / workedDays;
};

export const findByDateInStats = (stats, date, field) => {
  let value = null;

  for (let i = 0; i < stats.length; i++) {
    const statsDay = stats[i];

    if (isDatesEqual(date, statsDay.date)) {
      if (field === 'sum') {
        value =
          statsDay.flatOneSide +
          statsDay.flatBothSides +
          statsDay.cncOneSide +
          statsDay.cncBothSides;
      } else if (field === 'flatElements' || field === 'cncElements')
        value = statsDay[field] ? Math.ceil(statsDay[field]) : null;
      else value = statsDay[field];
      break;
    }
  }
  if (field === 'flatElements' || field === 'cncElements') return value;
  return value ? value.toFixed(2) : null;
};

export const findCorrectionsByDateInStats = (stats, date, field) => {
  let value = null;

  for (let i = 0; i < stats.length; i++) {
    const statsDay = stats[i];

    if (isDatesEqual(date, statsDay.date)) {
      if (statsDay.corrections.length) {
        value = statsDay.corrections.reduce(
          (acc, item) => (acc += item[field]),
          0,
        );
        break;
      }
    }
  }

  if (field === 'surface') return value ? value.toFixed(2) : null;
  else return value || null;
};

export const getCorrectionsSummary = stats => {
  const obj = stats.reduce((acc, day) => {
    if (day.corrections.length) {
      day.corrections.forEach(item => {
        if (!acc[item.reason]) {
          acc[item.reason] = {
            elements: item.elements,
            surface: item.surface,
          };
        } else {
          acc[item.reason].elements += item.elements;
          acc[item.reason].surface += item.surface;
        }
      });
    }
    return acc;
  }, {});

  return obj;
};

export const getSummaryByItemType = stats => {
  const obj = stats.reduce(
    (acc, curr) => {
      Object.keys(acc).forEach(key => (acc[key] += curr[key]));
      return acc;
    },
    {
      flatOneSide: 0,
      flatBothSides: 0,
      cncOneSide: 0,
      cncBothSides: 0,
      flatElements: 0,
      cncElements: 0,
      gloss: 0,
      glossSecondGroup: 0,
      semiGloss: 0,
    },
  );

  obj.flatSummary = obj.flatOneSide + obj.flatBothSides;
  obj.cncSummary = obj.cncOneSide + obj.cncBothSides;
  obj.summary = obj.flatSummary + obj.cncSummary;
  obj.correctionsElements = getCorrectionsValues(stats, 'elements');
  obj.correctionsSurface = getCorrectionsValues(stats, 'surface');
  obj.elements = obj.cncElements + obj.flatElements;

  return obj;
};

export const getDayStatsByDate = (stats, date) => {
  let dayStat = null;

  for (let i = 0; i < stats.length; i++) {
    const day = stats[i];

    if (isDatesEqual(date, day.date)) {
      dayStat = day;
      break;
    }
  }

  return dayStat;
};

const getCorrectionsValues = (stats, field) =>
  stats.reduce((acc, item) => {
    if (item.corrections && item.corrections.length) {
      acc += item.corrections.reduce(
        (value, curr) => (value += curr[field]),
        0,
      );
    }
    return acc;
  }, 0);
