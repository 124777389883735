import { SETTINGS_LOAD_SUCCESS, SETTINGS_LOAD_ERROR } from '../types/settings';
import Axios from 'axios';
import setAuthToken from 'services/utils/setAuthToken';

export const loadSettingsSuccess = settings => ({
 type: SETTINGS_LOAD_SUCCESS,
 settings,
});
export const loadSettingsError = () => ({ type: SETTINGS_LOAD_ERROR });

export const getGlobalSettings = (onEnd, onError) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);

 try {
  const res = await Axios.get('/api/settings');
  const { settings } = res.data;

  if (res.status === 200) dispatch(loadSettingsSuccess(settings));
  onEnd();
 } catch (error) {
  console.log(`ERROR_SETTINGS_LOAD: `, error.response);
  dispatch(loadSettingsError());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};

export const updateGlobalSettings = (
 body,
 onEnd,
 onError,
) => async dispatch => {
 try {
  const res = await Axios.put(`/api/settings`, body);
  const { settings } = res.data;

  dispatch(loadSettingsSuccess(settings));
  return onEnd();
 } catch (error) {
  console.log(`UDPATE_SETTINGS_ERROR`, error);
  dispatch(loadSettingsError());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};
