import {
  NEWORDER_SET_COLOR,
  NEWORDER_SET_HANDLE,
  NEWORDER_REMOVE_HANDLE,
  NEWORDER_SET_VENEER,
  NEWORDER_SET_MILLING,
  NEWORDER_REMOVE_MILLING,
  NEWORDER_SET_CUSTOMMILLING,
  NEWORDER_SET_GLASSCASE,
  NEWORDER_SET_PAINTTYPE,
  NEWORDER_SET_PAINTSTYLE,
  NEWORDER_SET_FELC,
  NEWORDER_SET_NUT,
  NEWORDER_SET_CHAMFERING,
  NEWORDER_SET_NAME,
  NEWORDER_SET_COMMENTS,
  NEWORDER_ADD_ITEM,
  NEWORDER_HANDLE_INPUT,
  NEWORDER_SET_FINISHDATE,
  NEWORDER_REMOVE_ITEM,
  NEWORDER_ADD_ITEM_IMAGE,
  NEWORDER_REMOVE_ITEM_IMAGE,
  NEWORDER_SET_FIELD,
  NEWORDER_CALC_ORDER_VALUES,
  NEWORDER_CALD_PRICE,
  NEWORDER_SET_MANY_FIELDS,
  NEWORDER_CLEAR_ORDER,
  NEWORDER_SET_ORDER,
  NEWORDER_IMPORT_DATA_FROM_FILE,
  NEWORDER_SET_MUNTIN_TYPE,
} from '../types/newOrder';
import {
  addVeneer,
  addHandle,
  addMilling,
  addCustomMilling,
  addGlassCase,
  addPaintType,
  addPaintStyle,
  removeHandle,
  removeMilling,
  setBackMilling,
  setChamfering,
  addMuntinType,
} from './utils/newOrder/orderData';
import {
  addOrderItem,
  handleInput,
  removeOrderItem,
  addItemImage,
  removeItemImage,
  importDataFromFile,
} from './utils/newOrder/orderItems';
import { setFinishDate } from './utils/newOrder/orderDates';
import { setOrderField } from './utils/newOrder/orderInfo';
import { calcOrderValues } from './utils/newOrder/orderValues';
import { calcOrderPrice } from './utils/newOrder/orderPrice';

const currentDay = new Date();
const threeWeeksLater = new Date(
  new Date().setDate(new Date().getDate() + 28),
);

const initialState = {
  // toForm
  userPrices: null,
  terms: {
    veneer: 0,
    milling: 0,
    gloss: 0,
    semiGloss: 0,
  },
  // muntinTypes: +, x
  muntinType: null,

  // properties
  user: null,
  userName: '',
  createdBy: null,
  motherOrder: null,
  number: null,
  orderType: 'Nowe zamówienie',
  name: '',
  comments: '',
  reasonOfComplaint: '',

  // data
  isNut: false,
  isFelc: false,
  isChamfering: false,

  color: '',
  colorHex: '',
  glossSecondGroup: false,
  transparent: false,
  paintType: 'półmat',
  paintStyle: 'jednostronne',

  handles: [],
  millings: [],
  veneer: '',
  glassCase: '',
  customMilling: { path: '', file: null },

  millingPricingGroup: 1,
  glassCasePricingGroup: 1,

  handlesMilledBy: 'dillak',
  hingeHolesDrilledBy: 'dillak',
  backMillingBy: 'dillak',
  chamferingBy: 'dillak',

  // values
  elements: 0,
  surfaceRight: 0,
  surfaceLeft: 0,
  surfaceCNC: 0,
  edgesSurface: 0,
  hingesHoles: 0,
  milledHandle: 0,
  milledPartHandle: 0,
  chamfering: 0,
  backMilling: 0,

  // dates
  date: currentDay,
  finishDate: threeWeeksLater,

  // payment
  price: 0,

  // items
  items: [],
};

const newOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    // OTHERS
    case NEWORDER_SET_FIELD:
      return setOrderField(state, action.field, action.value);
    case NEWORDER_SET_MANY_FIELDS:
      return { ...state, ...action.fields };
    case NEWORDER_CLEAR_ORDER:
      return { ...initialState };
    case NEWORDER_SET_ORDER:
      return { ...action.order };

    // ITEMS
    case NEWORDER_ADD_ITEM:
      return addOrderItem(state);
    case NEWORDER_REMOVE_ITEM:
      return removeOrderItem(state, action.index);
    case NEWORDER_HANDLE_INPUT:
      return handleInput(
        state,
        action.index,
        action.field,
        action.value,
        action.isNumber,
      );
    case NEWORDER_ADD_ITEM_IMAGE:
      return addItemImage(state, action.index, action.file);
    case NEWORDER_REMOVE_ITEM_IMAGE:
      return removeItemImage(state, action.index);
    case NEWORDER_IMPORT_DATA_FROM_FILE:
      return importDataFromFile(state, action.items);

    //  PROPERIES
    case NEWORDER_SET_NAME:
      return { ...state, name: action.name };
    case NEWORDER_SET_COMMENTS:
      return { ...state, comments: action.comments };

    // DATES
    case NEWORDER_SET_FINISHDATE:
      return setFinishDate(state, action.terms);

    // VALUES
    case NEWORDER_CALC_ORDER_VALUES:
      return calcOrderValues(state);
    case NEWORDER_CALD_PRICE:
      return calcOrderPrice(state, action.prices);
    // DATA
    case NEWORDER_SET_COLOR:
      return { ...state, color: action.color, colorHex: action.hexValue };
    case NEWORDER_SET_VENEER:
      return addVeneer(state, action.veneer);
    case NEWORDER_SET_HANDLE:
      return addHandle(state, action.handle);
    case NEWORDER_REMOVE_HANDLE:
      return removeHandle(state, action.handle);
    case NEWORDER_SET_MILLING:
      return addMilling(state, action.milling);
    case NEWORDER_SET_CUSTOMMILLING:
      return addCustomMilling(state, action.file);
    case NEWORDER_REMOVE_MILLING:
      return removeMilling(state, action.milling);
    case NEWORDER_SET_GLASSCASE:
      return addGlassCase(state, action.glassCase);
    case NEWORDER_SET_PAINTTYPE:
      return addPaintType(state, action.paintType);
    case NEWORDER_SET_PAINTSTYLE:
      return addPaintStyle(state, action.paintStyle);
    case NEWORDER_SET_FELC:
      return setBackMilling(state, 'isFelc', action.isFelc);
    case NEWORDER_SET_NUT:
      return setBackMilling(state, 'isNut', action.isNut);
    case NEWORDER_SET_CHAMFERING:
      return setChamfering(state, action.isChamfering);
    case NEWORDER_SET_MUNTIN_TYPE:
      return addMuntinType(state, action.muntinType);

    default:
      return state;
  }
};

export { newOrderReducer };
