import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import CuttingPackageStatus from 'components/CuttingPackageStatus/CuttingPackageStatus';
import { orderItemCuttingStatus } from 'services/utils/const';
import { dateToString } from 'services/utils/date';

const Tr = styled.tr`
  cursor: pointer;
`;

const PackageListRow = ({ p, index }) => {
  const history = useHistory();
  const getElementsWithStatusQty = useCallback(
    (status) => {
      const qty = p.orders
        .reduce((acc, order) => {
          return [...acc, ...order.items];
        }, [])
        .reduce((qty, item) => {
          if (item.cuttingStatus === status) return (qty += item.quantity);
          return qty;
        }, 0);
      return qty;
    },
    [p],
  );
  const getOrdersNames = useCallback(() => {
    const names = p.orders.reduce((acc, order) => {
      const company = order.user.company;
      const firstname = order.user.firstname;
      const number = order.number;

      return `${acc}${company.slice(0, 25)}${
        firstname ? ` ${firstname[0]}` : ''
      } - ${number}, `;
    }, '');
    return names;
  }, [p]);

  return (
    <Tr onClick={() => history.push(`/cutting/package/${p._id}`)}>
      <td>{index + 1}</td>
      <td>{p.name}</td>
      <td>
        {p.createdBy?.firstname} {p.createdBy?.surname}
      </td>
      <td>{p.orders.length}</td>
      <td>{getOrdersNames()}</td>
      <td>{getElementsWithStatusQty(orderItemCuttingStatus.TO_CUT)}</td>
      <td>{getElementsWithStatusQty(orderItemCuttingStatus.SUSPENDED)}</td>
      <td>{getElementsWithStatusQty(orderItemCuttingStatus.SKIPPED)}</td>
      <td>{dateToString(p.createdAt)}</td>
      <td>{<CuttingPackageStatus status={p.status} />}</td>
    </Tr>
  );
};

export default PackageListRow;
