import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ModalContainer from 'templates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const ManHours = ({ order, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const [manHours, setManHours] = useState('');

 const handleInput = (e) => {
  const { value } = e.target;
  const numbers = /^[\d]*$/;
  if (!value.match(numbers)) return;
  setManHours(parseInt(value, 10) || 0);
 };
 const handleSubmit = async () => {
  dispatch(setSpinner(true));
  await updateOrder(
   'manhours',
   order._id,
   { manHours },
   (order) => {
    dispatch(setOrder(order));
    dispatch(setSpinner(false));
    alert.success('Zaktualizowano roboczogodziny');
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('błąd!');
    closeModal();
   },
  );
 };
 return (
  <ModalContainer
   title="Roboczogodziny"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" onClick={handleSubmit}>
      Zmień
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   Aktualna ilość roboczogodzin wynosi:{' '}
   <strong>{order.manHours.hours || '0'}</strong> h.
   <br />
   Chcesz zmienić na:{' '}
   <Form.Control
    type="text"
    placeholder="ilość godzin"
    style={{ width: '30%', display: 'inline' }}
    value={manHours}
    onChange={handleInput}
   />
  </ModalContainer>
 );
};

export default ManHours;
