import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const LastEdit = ({ order }) => {
 const laseEditedId = sessionStorage.getItem('lastEditedOrder');
 return (
  <td
   style={
    order._id === laseEditedId
     ? { fontWeight: 'bold', cursor: 'default', opacity: '.7' }
     : { visibility: 'hidden', cursor: 'default', opacity: '.7' }
   }
   title="Ostatnio edytowane"
  >
   <FontAwesomeIcon icon={faEdit} />
  </td>
 );
};

export default LastEdit;
