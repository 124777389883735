import React from 'react';
import { cuttingPackageStatus } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';
import styled from 'styled-components';

const Span = styled.span`
  font-weight: bold;
  font-size: ${({ size }) => `${size}` || 16}px;
  color: ${({ status }) => {
    if (status === cuttingPackageStatus.PENDING) return 'gray';
    else if (status === cuttingPackageStatus.IN_PROGRESS)
      return 'lightblue';
    else if (status === cuttingPackageStatus.NOT_COMPLETE) return 'red';
    else if (status === cuttingPackageStatus.ready) return 'green';
    return 'black';
  }};
`;

const CuttingPackageStatus = ({ status, size }) => (
  <Span status={status} size={size}>
    {firstLetterToUpperCase(status)}
  </Span>
);

export default CuttingPackageStatus;
