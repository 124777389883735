import React from 'react';
import styled from 'styled-components';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import { updateOrder } from 'services/apiRequests/orders/update';
import { useDispatch } from 'react-redux';
import { updateOrderInList } from 'services/store/actions/orders';
import { setSpinner } from 'services/store/actions/view';
import withContext from 'hoc/withContext';
import { useAlert } from 'react-alert';

const StyledTd = styled.td`
  padding: 0px !important;
`;
const StyledContextMenu = styled(ContextMenu)`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.dillakLightGrey};
`;
const StyledMenuItem = styled(MenuItem)`
  font-weight: bold;
  padding: 3px 20px;
  &:hover {
    background-color: ${({ theme }) => theme.dillakPrimary};
  }
`;

const PaymentStatus = ({ order, setIsRedirect, permissionContext }) => {
  const { isPaid, _id } = order;
  return (
    <StyledTd style={{ padding: 0 }}>
      {' '}
      <>
        <ContextMenuTrigger id={`menuItemId_${_id}`}>
          <div
            style={{
              color: isPaid ? 'green' : 'red',
              fontWeight: 'bold',
            }}
          >
            {isPaid ? 'Opłacone' : 'Nieopłacone'}
          </div>
        </ContextMenuTrigger>
        {permissionContext === 'admin' && (
          <PaymentContextMenu setIsRedirect={setIsRedirect} orderId={_id} />
        )}
      </>
    </StyledTd>
  );
};

const PaymentContextMenu = ({ orderId, setIsRedirect }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const updatePaymentStatus = async isPaid => {
    dispatch(setSpinner(true));
    await updateOrder(
      'paymentstatus',
      orderId,
      { isPaid },
      order => {
        dispatch(updateOrderInList(order));
        dispatch(setSpinner(false));
        isPaid
          ? alert.success(`Zamówienie opłacone`)
          : alert.error('Zamówienie nieopłacone');
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('błąd!');
      }
    );
  };
  return (
    <>
      <StyledContextMenu
        id={`menuItemId_${orderId}`}
        onHide={() => setIsRedirect(true)}
        onShow={() => setIsRedirect(false)}
      >
        <StyledMenuItem onClick={async () => updatePaymentStatus(true)}>
          <span style={{ color: 'Green' }}>Opłacone</span>
        </StyledMenuItem>
        <StyledMenuItem onClick={async () => updatePaymentStatus(false)}>
          <span style={{ color: 'Red' }}>Nieopłacone</span>
        </StyledMenuItem>
      </StyledContextMenu>
    </>
  );
};

export default withContext(PaymentStatus);
