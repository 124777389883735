import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { sortMethod } from 'services/utils/sortMethods';
import {
 setSortCustomersList,
 setCustomer,
} from 'services/store/actions/customers';

const StyledTh = styled.th`
 cursor: pointer;
`;
const StyledRow = styled.tr`
 cursor: pointer;
 background-color: ${({ isaccepted }) =>
  !isaccepted ? '#ffa3a3' : 'inherit'}!important;
`;

const List = ({ customers }) => {
 const dispatch = useDispatch();
 const history = useHistory();
 const sort = useSelector(state => state.customers.sort);
 const { sortBy, direction } = sort;

 const [sortDirection, setSortDirection] = useState(direction);

 useEffect(() => {
  setSortDirection(direction === 'ascend' ? 'descend' : 'ascend');
 }, [sortBy, direction]);

 const handleSort = (by, dir) => dispatch(setSortCustomersList(by, dir));
 const handleSetCustomer = data => dispatch(setCustomer(data));

 if (customers)
  return (
   <div>
    <Table responsive="lg" striped bordered hover size="sm">
     <thead>
      <tr>
       <th>LP</th>
       <StyledTh onClick={() => handleSort('customerCompany', sortDirection)}>
        Firma <FontAwesomeIcon icon={faSort} />
       </StyledTh>
       <th>Zamawiający</th>
       <th>E-mail</th>
       <th>Telefon</th>
       <StyledTh onClick={() => handleSort('ordersNumber', sortDirection)}>
        Ilość zamówień <FontAwesomeIcon icon={faSort} />
       </StyledTh>
      </tr>
     </thead>
     <tbody>
      {customers
       .sort((a, b) =>
        sortMethod[sortMethod.getSortMethod(sortBy)](a, b, sortBy, direction),
       )
       .map((item, index) => (
        <ListRow
         item={item}
         index={index}
         history={history}
         setCustomer={handleSetCustomer}
         key={item._id}
        />
       ))}
     </tbody>
    </Table>
   </div>
  );
 return null;
};

export default List;

const ListRow = ({ item, index, history, setCustomer }) => {
 return (
  <StyledRow
   onClick={() => {
    setCustomer(item);
    history.push(`/customers/${item._id}`, { ...item });
   }}
   isaccepted={item.isAccepted}
   title={!item.isAccepted ? 'Konto niezatwierdzone' : ''}
  >
   <td>
    {item.isVip ? <strong style={{ color: 'blue' }}>VIP</strong> : index + 1}
   </td>
   <td>{item.company}</td>
   <td>
    {item.firstname} {item.surname}
   </td>
   <td>{item.email}</td>
   <td>{item.phone}</td>
   <td>{item.ordersNumber}</td>
  </StyledRow>
 );
};
