/* eslint-disable no-shadow */
import React from 'react';
import PageTemplate from 'templates/PageTemplate';
import withContext from 'hoc/withContext';
import AdminHomePage from './Admin';
import CustomerHomePage from './Customer';
import EmployeeHomePage from './Employee';

const HomePage = ({ permissionContext }) => {
 let HomePage = null;
 if (permissionContext === 'admin') HomePage = <AdminHomePage />;
 else if (permissionContext === 'user') HomePage = <CustomerHomePage />;
 else if (permissionContext === 'employee') HomePage = <EmployeeHomePage />;
 return <PageTemplate>{HomePage}</PageTemplate>;
};

export default withContext(HomePage);
