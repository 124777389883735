import React, { useState } from 'react';
import { Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import FlexRow from 'templates/FlexRowTemplate';
import { StyledH3 } from 'components/header';
import { Formik } from 'formik';
import styled from 'styled-components';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
 width: 600px;
`;
const List = styled.div`
 max-height: 600px;
 width: 300px;
 overflow-y: auto;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
 color: red;
 cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
 opacity: 0.2;
 &:hover {
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
 }
`;

const BlackBaseColors = ({ blackBaseColors, handleSubmit }) => {
 const [isEdit, setIsEdit] = useState(false);
 const [color, setColor] = useState('');

 const removeItem = (color, arr, arrName, setFieldValue) => {
  const newArr = arr.filter((item) => item !== color);
  setFieldValue(arrName, newArr);
 };
 const addItem = (arr, arrName, setFieldValue) => {
  const newArr = arr.concat(color);
  setFieldValue(arrName, newArr);
  setColor('');
 };
 return (
  <div>
   <Formik
    // validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async (values) =>
     await handleSubmit(
      { ...values },
      () => setIsEdit(false),
      'Zaktualizowano bazę czarnego podkładu',
     )
    }
    initialValues={{
     blackBaseColors,
    }}
    render={(props) => {
     const { values, handleSubmit, setFieldValue } = props;
     return (
      <>
       <Form noValidate onSubmit={handleSubmit}>
        <Wrapper>
         <div>
          <StyledH3>Kolory wymagające czarnego podkładu</StyledH3>
          {values.blackBaseColors && values.blackBaseColors.length ? (
           <>
            <List>
             <ul>
              {values.blackBaseColors
               .sort((a, b) => a.localeCompare(b))
               .map((item) => (
                <li key={item}>
                 <FlexRow justify="space-between">
                  {item.toUpperCase()}{' '}
                  <RemoveIcon
                   disabled={!isEdit}
                   title="Usuń"
                   icon={faMinusCircle}
                   onClick={
                    !isEdit
                     ? null
                     : () => {
                        removeItem(
                         item,
                         values.blackBaseColors,
                         'blackBaseColors',
                         setFieldValue,
                        );
                       }
                   }
                  />
                 </FlexRow>
                </li>
               ))}
             </ul>
            </List>
           </>
          ) : null}
          {isEdit && (
           <InputGroup style={{ width: 400 }}>
            <FormControl
             placeholder="Kolor"
             value={color}
             onChange={(e) => setColor(e.target.value)}
            />
            <InputGroup.Append>
             <Button
              variant="outline-primary"
              onClick={() =>
               addItem(values.blackBaseColors, 'blackBaseColors', setFieldValue)
              }
             >
              Dodaj
             </Button>
            </InputGroup.Append>
           </InputGroup>
          )}
         </div>
         <FlexRow justify="flex-end">
          <Button variant="success" type="submit" disabled={!isEdit}>
           Zatwierdź
          </Button>
          <Button
           variant={isEdit ? 'outline-secondary' : 'secondary'}
           onClick={() => setIsEdit(!isEdit)}
          >
           {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
          </Button>
         </FlexRow>
        </Wrapper>
       </Form>
      </>
     );
    }}
   />
  </div>
 );
};

export default BlackBaseColors;
