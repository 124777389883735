import React, { useState } from 'react';
import { StyledH3, StyledH6 } from 'components/header';
import TextInput from 'components/FormInputs/TextInput';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import FlexRow from 'templates/FlexRowTemplate';
import styled from 'styled-components';
import AddEmail from './components/AddEmail';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddPhone from './components/AddPhone';
import AddBankAccount from './components/AddBankAccount';
import AddWebPage from './components/AddWebPage';
import AddAddress from './components/AddAddress';

const Wrapper = styled.div`
 width: fit-content;
`;
const Column = styled.div`
 width: 500px;
`;
const ItemsWrapper = styled.div`
 margin-bottom: 20px;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
 color: red;
 cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
 opacity: 0.5;
 &:hover {
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
 }
`;

const initModals = {
 webPage: false,
 email: false,
 phone: false,
 address: false,
 bankAccount: false,
};

const Contact = ({ contact, handleSubmit }) => {
 const [isEdit, setIsEdit] = useState(false);
 const [modals, setModals] = useState(initModals);

 const handleModal = (key) => setModals({ ...modals, [key]: true });
 const closeModal = () => setModals({ initModals });

 const removeItemFromArr = (name, arr, arrName, setFieldValue) => {
  const newArr = arr.filter((item) => item.name !== name);
  setFieldValue(arrName, newArr);
 };
 return (
  <div>
   <Formik
    // validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async (values) =>
     await handleSubmit(
      { contact: values },
      () => setIsEdit(false),
      'Zaktualizowano dane kontaktowe',
     )
    }
    initialValues={{
     ...contact,
    }}
    render={(props) => {
     const { values, handleSubmit, setFieldValue } = props;
     return (
      <>
       <Form noValidate onSubmit={handleSubmit}>
        <Wrapper>
         <FlexRow justify="flex-start">
          <div>
           <StyledH3>Dane kontaktowe</StyledH3>
           <FlexRow justify="space-between">
            <Column>
             <StyledH6>Dane firmy</StyledH6>{' '}
             <TextInput
              {...props}
              name="companyName"
              label="Nazwa firmy"
              disabled={!isEdit}
              required
             />
             <TextInput
              {...props}
              name="NIP"
              label="NIP"
              disabled={!isEdit}
              required
             />
             <TextInput
              {...props}
              name="REGON"
              label="REGON"
              disabled={!isEdit}
              required
             />
             {/* ADDRESSES */}
             <StyledH6>Adresy</StyledH6>
             <ItemsWrapper>
              {values.addresses && values.addresses.length ? (
               <>
                {values.addresses.map((item) => (
                 <AddressCard
                  address={item}
                  key={item.name}
                  isEdit={isEdit}
                  onClick={() =>
                   removeItemFromArr(
                    item.name,
                    values.addresses,
                    'addresses',
                    setFieldValue,
                   )
                  }
                 />
                ))}
                {isEdit && (
                 <AddItemButton
                  onClick={() => handleModal('address')}
                  isEdit={isEdit}
                 />
                )}
               </>
              ) : (
               isEdit && (
                <AddItemButton
                 onClick={() => handleModal('address')}
                 isEdit={isEdit}
                />
               )
              )}
             </ItemsWrapper>
            </Column>
            {/* EMAILS */}
            <Column>
             <StyledH6>E-mail</StyledH6>
             <ItemsWrapper>
              {values.emails && values.emails.length ? (
               <>
                {values.emails.map((item) => (
                 <ContactSmallItem
                  name={item.name}
                  value={item.email}
                  key={item.email}
                  isEdit={isEdit}
                  onClick={() =>
                   removeItemFromArr(
                    item.name,
                    values.emails,
                    'emails',
                    setFieldValue,
                   )
                  }
                 />
                ))}
                {isEdit && (
                 <AddItemButton
                  onClick={() => handleModal('email')}
                  isEdit={isEdit}
                 />
                )}
               </>
              ) : (
               isEdit && (
                <AddItemButton
                 onClick={() => handleModal('email')}
                 isEdit={isEdit}
                />
               )
              )}
             </ItemsWrapper>
             {/* PHONES */}
             <StyledH6>Nr telefonu</StyledH6>
             <ItemsWrapper>
              {values.phones && values.phones.length ? (
               <>
                {values.phones.map((item) => (
                 <ContactSmallItem
                  name={item.name}
                  value={item.number}
                  key={item.number}
                  isEdit={isEdit}
                  onClick={() =>
                   removeItemFromArr(
                    item.name,
                    values.phones,
                    'phones',
                    setFieldValue,
                   )
                  }
                 />
                ))}
                {isEdit && (
                 <AddItemButton
                  onClick={() => handleModal('phone')}
                  isEdit={isEdit}
                 />
                )}
               </>
              ) : (
               isEdit && (
                <AddItemButton
                 onClick={() => handleModal('phone')}
                 isEdit={isEdit}
                />
               )
              )}
             </ItemsWrapper>

             {/* BANK ACCOUNTS */}
             <StyledH6>Nr konta</StyledH6>
             <ItemsWrapper>
              {values.bankAccounts && values.bankAccounts.length ? (
               <>
                {values.bankAccounts.map((item) => (
                 <ContactSmallItem
                  name={item.name}
                  value={`${item.bankName} ${item.accountNumber}`}
                  key={item.accountNumber}
                  isEdit={isEdit}
                  onClick={() =>
                   removeItemFromArr(
                    item.name,
                    values.bankAccounts,
                    'bankAccounts',
                    setFieldValue,
                   )
                  }
                 />
                ))}
                {isEdit && (
                 <AddItemButton
                  onClick={() => handleModal('bankAccount')}
                  isEdit={isEdit}
                 />
                )}
               </>
              ) : (
               isEdit && (
                <AddItemButton
                 onClick={() => handleModal('bankAccount')}
                 isEdit={isEdit}
                />
               )
              )}
             </ItemsWrapper>

             {/* WEBPAGES */}
             <StyledH6>Strony internetowe</StyledH6>
             <ItemsWrapper>
              {values.webPages && values.webPages.length ? (
               <>
                {values.webPages.map((item) => (
                 <ContactSmallItem
                  name={item.name}
                  value={`${item.webPage}`}
                  key={item.webPage}
                  isEdit={isEdit}
                  onClick={() =>
                   removeItemFromArr(
                    item.name,
                    values.webPages,
                    'webPages',
                    setFieldValue,
                   )
                  }
                 />
                ))}
                {isEdit && (
                 <AddItemButton
                  onClick={() => handleModal('webPage')}
                  isEdit={isEdit}
                 />
                )}
               </>
              ) : (
               isEdit && (
                <AddItemButton
                 onClick={() => handleModal('webPage')}
                 isEdit={isEdit}
                />
               )
              )}
             </ItemsWrapper>
            </Column>
           </FlexRow>
          </div>
         </FlexRow>
         <FlexRow justify="flex-end">
          <Button variant="success" type="submit" disabled={!isEdit}>
           Zatwierdź
          </Button>
          <Button
           variant={isEdit ? 'outline-secondary' : 'secondary'}
           onClick={() => setIsEdit(!isEdit)}
          >
           {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
          </Button>
         </FlexRow>
        </Wrapper>
       </Form>{' '}
       {/* MODALS */}
       {modals.email && (
        <AddEmail
         closeModal={closeModal}
         emails={values.emails}
         setFieldValue={setFieldValue}
        />
       )}
       {modals.phone && (
        <AddPhone
         closeModal={closeModal}
         phones={values.phones}
         setFieldValue={setFieldValue}
        />
       )}
       {modals.bankAccount && (
        <AddBankAccount
         closeModal={closeModal}
         bankAccounts={values.bankAccounts}
         setFieldValue={setFieldValue}
        />
       )}
       {modals.webPage && (
        <AddWebPage
         closeModal={closeModal}
         webPages={values.webPages}
         setFieldValue={setFieldValue}
        />
       )}
       {modals.address && (
        <AddAddress
         closeModal={closeModal}
         addresses={values.addresses}
         setFieldValue={setFieldValue}
        />
       )}
      </>
     );
    }}
   />
  </div>
 );
};

export default Contact;

const ContactSmallItem = ({ name, value, isEdit, onClick }) => (
 <div
  style={{ marginBottom: 5, display: 'flex', justifyContent: 'space-between' }}
 >
  <span>
   {name}: <strong>{value}</strong>
  </span>{' '}
  {isEdit && (
   <RemoveIcon
    disabled={!isEdit}
    title="Usuń"
    icon={faMinusCircle}
    onClick={!isEdit ? null : onClick}
   />
  )}
 </div>
);

const AddressCard = ({ address, isEdit, onClick }) => {
 return (
  <div
   style={{
    width: '70%',
    position: 'relative',
    border: '1px solid lightgray',
    padding: 10,
    margin: 5,
   }}
  >
   <div style={{ position: 'absolute', top: 2, right: 2 }}>
    {isEdit && (
     <RemoveIcon
      disabled={!isEdit}
      title="Usuń"
      icon={faMinusCircle}
      onClick={!isEdit ? null : onClick}
     />
    )}
   </div>
   <div>
    <strong>{address.name}</strong>
   </div>
   <div>
    <span>
     Miejscowość: <strong>{address.city}</strong>
    </span>
   </div>
   <div>
    <span>
     Kod pocztowy: <strong>{address.postcode}</strong>
    </span>
   </div>
   <div>
    <span>
     Ulica: <strong>{address.street}</strong>
    </span>
   </div>
   <div>
    <span>
     Opis: <strong>{address.description}</strong>
    </span>
   </div>
  </div>
 );
};

const AddItemButton = ({ onClick, isEdit }) => (
 <Button
  variant="outline-secondary"
  size="sm"
  style={{ width: '70%' }}
  onClick={onClick}
  disabled={!isEdit}
 >
  Dodaj
 </Button>
);
