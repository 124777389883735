/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import DatePicker from 'components/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'components/FormInputs/Select';
import { statsPositions } from 'services/utils/const';
import { useAlert } from 'react-alert';
import {
  setStatsDate,
  setStatsPosition,
  getProductionStatistics,
  clearStats,
} from 'services/store/actions/statistics';
import { setSpinner } from 'services/store/actions/view';
import {
  clearOrdersList,
  getOrdersByPickUpDate,
} from 'services/store/actions/orders';
import { isDatesEqual } from 'services/utils/date';
import CompanyStats from './components/CompanyStats';
import PositionStatsDatesRange from './components/PositionStats/DatesRange';
import PositionDayStats from './components/PositionStats/Day';

const ProductionStatistics = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const date = useSelector(state => state.statistics.date.production);
  const position = useSelector(state => state.statistics.position.production);

  useEffect(() => {
    dispatch(setSpinner(true));
    if (position !== 'dillak')
      dispatch(
        getProductionStatistics(
          { ...date, position },
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          }
        )
      );
    else
      dispatch(
        getOrdersByPickUpDate(
          date.from,
          date.to,
          () => {
            dispatch(setSpinner(false));
          },
          () => {
            dispatch(setSpinner(false));
            alert.error('Błąd!');
          }
        )
      );
    return () => {
      dispatch(clearStats());
      dispatch(clearOrdersList());
    };
  }, [date, position]);

  const handleDate = (key, value) =>
    dispatch(setStatsDate('production', key, value));
  const handlePosition = value =>
    dispatch(setStatsPosition('production', value));
  return (
    <PageTemplate>
      <PageHeader
        title="Statystyki produkcji"
        extra={[
          <div key={0}>
            Od{' '}
            <DatePicker
              value={date.from}
              onChange={e => handleDate('from', e)}
            />
          </div>,
          <div key={1}>
            Do{' '}
            <DatePicker value={date.to} onChange={e => handleDate('to', e)} />
          </div>,
          <Select
            key={2}
            items={statsPositions}
            value={position}
            emptyItem={false}
            onChange={e => handlePosition(e.target.value)}
          />,
        ]}
      />
      {position === 'dillak' ? (
        <CompanyStats dates={date} position={position} />
      ) : isDatesEqual(date.from, date.to) ? (
        <PositionDayStats date={date.from} position={position} />
      ) : (
        <PositionStatsDatesRange dates={date} position={position} />
      )}
    </PageTemplate>
  );
};

export default ProductionStatistics;
