/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
export const getItemsPrice = (order, prices) => {
  let itemsPrice = 0;
  let roundedPrice = 0;

  const items = order.items.map((item, index) => {
    console.log(`ELEMENT: ${index + 1}`);
    let itemPrice = 0;
    const setItemPrice = value => (itemPrice = value);

    item = handleItem(order, item, setItemPrice, prices);

    // LONG ITEM
    if (item.height >= 2800 || item.width >= 2800) {
      let longItemPrice = 0;
      const surface = getItemSurface(order, item);
      longItemPrice = item.dillakMaterial
        ? surface * prices.paint.companyMaterial.longElement
        : surface * prices.paint.customerMaterial.longElement;
      console.log(
        `^ Element>2800. Pow.: ${surface}m2, cena dodana: ${longItemPrice}`,
      );
      itemPrice += longItemPrice;
    }

    if (order.muntinType) {
      if (order.muntinType === 'x') {
        console.log(
          `^ Szpros X, Szt.: ${
            item.muntinX
          }szt, cena dodana: ${item.muntinX * prices.paint.muntin * 2}`,
        );
        itemPrice += item.muntinX * prices.paint.muntin * 2;
      } else if (order.muntinType === '+') {
        console.log(
          `^ Szpros +, Szt.: PION: ${item.muntinH}szt, POZIOM: ${
            item.muntinW
          }szt, cena dodana: ${(item.muntinH + item.muntinW) *
            prices.paint.muntin}`,
        );
        itemPrice += (item.muntinH + item.muntinW) * prices.paint.muntin;
      }
    }

    if (order.transparent) {
      const surface = getItemSurface(order, item);
      if (!item.transparentBothSide) {
        // 2str
        console.log(
          `^ Lakier XMAT 1stronnie, Pow.: ${surface}m2, cena dodana: ${surface *
            prices.paint.transparent.oneSide}`,
        );
        itemPrice += surface * prices.paint.transparent.oneSide;
      } else {
        console.log(
          `^ Lakier XMAT 2stronnie, Pow.: ${surface}m2, cena dodana: ${surface *
            (prices.paint.transparent.oneSide +
              prices.paint.companyMaterial.leftSide)}`,
        );
        itemPrice +=
          surface *
          (prices.paint.transparent.oneSide +
            prices.paint.companyMaterial.leftSide);
      }
    }

    if (
      order.paintType.toLowerCase() === 'połysk' &&
      order.glossSecondGroup
    ) {
      let surface = getItemSurface(order, item);
      if (item.paintLeft && item.paintRight) surface = surface * 2;
      console.log(
        `^ Połysk 2gr, Pow.: ${surface}m2, cena dodana ${
          prices.paint.glossSecondGroup
        }zł/m2: ${surface * prices.paint.glossSecondGroup}`,
      );
      itemPrice += surface * prices.paint.glossSecondGroup;
    }

    itemsPrice += itemPrice;
    return item;
  });

  if (order.surfaceLeft + order.surfaceRight < 0.5) {
    roundedPrice = getRoundedPrice(order, prices) / 2;

    console.log(`PP+PL<1, zaokrąglona cena: ${roundedPrice}`);
  }

  return {
    items,
    itemsPrice: itemsPrice > roundedPrice ? itemsPrice : roundedPrice,
  };
};

const handleItem = (order, item, setItemPrice, prices) => {
  const { paintRight, paintLeft, thickness } = item;
  // POWIERZCHNIA ELEMENTU
  const surface = getItemSurface(order, item);
  item.includedToPrice = true;

  if (order.orderType.toLowerCase() === 'reklamacja (wina dillak)') {
    setItemPrice(0);

    return item;
  }
  if (order.veneer) {
    /**---------------------
     * FORNIR
     -----------------------*/
    //    GŁADKI
    if (item.type === 'gładki') {
      if (order.color.includes('bejca')) {
        // Bejca
        if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
          // - jednostronny
          const price = surface * prices.paint.veneer.mordantOneSide;
          setItemPrice(price);

          console.log(
            `Fornir bejcowany gładki jednostronny ${surface}m2: ${price}`,
          );
          return item;
        }
        if (paintRight && paintLeft) {
          // - dwustronny
          const price = surface * prices.paint.veneer.mordantTwoSides;
          setItemPrice(price);

          console.log(
            `Fornir bejcowany gładki dwustronny  ${surface}m2: ${price}`,
          );
          return item;
        }
        item.includedToPrice = false;

        console.log(`Fornir bejcowany gładki inny :${0}`);
        return item;
      }
      // Bezbarwny
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price = surface * prices.paint.veneer.colorlessOneSide;
        setItemPrice(price);

        console.log(
          `Fornir bezbarwny gładki jednostronny  ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        // - dwustronny
        const price = surface * prices.paint.veneer.colorlessTwoSides;
        setItemPrice(price);

        console.log(
          `Fornir bezbarwny gładki dwustronny  ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Fornir bezbarwny gładki inny :${0}`);
      return item;
    }
    if (item.type === 'ryflowany') {
      //    RYFLOWANY
      if (order.color.includes('bejca')) {
        //    Bejca
        if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
          // - jednostronny
          const price =
            surface * prices.paint.veneer.groovedMordantOneSide;
          setItemPrice(price);

          console.log(
            `Fornir bejcowany ryflowany jednostronny  ${surface}m2: ${price}`,
          );
          return item;
        }
        if (paintRight && paintLeft) {
          // - dwustronny
          const price =
            surface * prices.paint.veneer.groovedMordantTwoSides;
          setItemPrice(price);

          console.log(
            `Fornir bejcowany ryflowany dwustronny  ${surface}m2: ${price}`,
          );
          return item;
        }
        item.includedToPrice = false;

        console.log(`Fornir bejcowany ryflowany inny :${0}`);
        return item;
      }
      //    Bezbarwny
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price =
          surface * prices.paint.veneer.groovedColorlessOneSide;
        setItemPrice(price);

        console.log(
          `Fornir bezbarwny ryflowany jednostronny  ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        // - dwustronny
        const price =
          surface * prices.paint.veneer.groovedColorlessTwoSides;
        setItemPrice(price);

        console.log(
          `Fornir bezbarwny ryflowany dwustronny  ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Fornir bezbarwny ryflowany inny :${0}`);
      return item;
    }
    item.includedToPrice = false;

    console.log(`Fornir inny :${0}`);
    return item;
  }
  /**---------------------
     * MDF
     ---------------------*/
  if (
    ['materiał klienta', 'poprawa (wina klienta)'].includes(
      order.orderType.toLowerCase(),
    ) &&
    !item.dillakMaterial
  ) {
    //   MATERIAŁ POWIERZONY
    if (item.type === 'frez') {
      //    FREZ
      // Połysk
      if (order.paintType === 'połysk') {
        item.includedToPrice = false;

        console.log(`Frez powierzony połysk:${0}`);
        return item;
      }
      // Półmat/Mat
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price =
          surface *
          prices.paint.customerMaterial.cnc[
            order.millingPricingGroup || 1
          ];
        setItemPrice(price);

        console.log(
          `Frez [gr.${order.millingPricingGroup ||
            1}] powierzony półmat jednostronny ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        // - dwustronny
        const price =
          surface *
          (prices.paint.customerMaterial.cnc[
            order.millingPricingGroup || 1
          ] +
            prices.paint.customerMaterial.leftSide);
        setItemPrice(price);

        console.log(
          `Frez [gr.${order.millingPricingGroup ||
            1}] powierzony półmat dwustronny ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Frez powierzony półmat inny:${0}`);
      return item;
    }
    if (item.type === 'ryflowany') {
      //   RYFLOWANY
      // Połysk
      if (order.paintType === 'połysk') {
        item.includedToPrice = false;

        console.log(`Ryflowany powierzony połysk:${0}`);
        return item;
      }
      // Półmat/Mat
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price =
          surface *
          (prices.paint.customerMaterial.semiGloss.groovedOneSide +
            prices.bold[thickness]);
        setItemPrice(price);

        console.log(
          `Ryflowany powierzony półmat jednostronny ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        // - dwustronny
        const price =
          surface *
          (prices.paint.customerMaterial.semiGloss.groovedOneSide +
            prices.paint.customerMaterial.leftSide +
            prices.bold[thickness]);
        setItemPrice(price);

        console.log(
          `Ryflowany powierzony półmat dwustronny ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Ryflowany powierzony półmat inny:${0}`);
      return item;
    }
    if (item.type === 'gładki') {
      //   GŁADKI
      // Połysk
      if (order.paintType === 'połysk') {
        if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
          // - jednostronny
          const price =
            surface * prices.paint.customerMaterial.gloss.oneSide;
          setItemPrice(price);

          console.log(
            `Gładki powierzony połysk jednostronny ${surface}m2: ${price}`,
          );
          return item;
        }
        if (paintRight && paintLeft) {
          if (
            order.paintStyle.toLowerCase() ===
            'prawa - połysk / lewa - półmat'
          ) {
            // połysk/półmat
            const price =
              surface *
              (prices.paint.customerMaterial.gloss.oneSide +
                prices.paint.customerMaterial.leftSide);
            setItemPrice(price);

            console.log(
              `Gładki powierzony połysk/półmat dwustronny ${surface}m2: ${price}`,
            );
            return item;
          }
          //  dwustronny
          const price =
            surface * prices.paint.customerMaterial.gloss.twoSides;
          setItemPrice(price);

          console.log(
            `Gładki powierzony połysk dwustronny ${surface}m2: ${price}`,
          );
          return item;
        }
        item.includedToPrice = false;

        console.log(`Gładki powierzony połysk inny:${0}`);
        return item;
      }
      // Półmat/Mat
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price =
          surface * prices.paint.customerMaterial.semiGloss.oneSide;
        setItemPrice(price);

        console.log(
          `Gładki powierzony półmat jednostronny ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        // - dwustronny
        const price =
          surface *
          (prices.paint.customerMaterial.semiGloss.oneSide +
            prices.paint.customerMaterial.leftSide);
        setItemPrice(price);

        console.log(
          `Gładki powierzony półmat dwustronny ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Gładki powierzony półmat inny:${0}`);
      return item;
    }
    if (item.type === 'witryna') {
      //   WITRYNA
      // Połysk
      if (order.paintType === 'połysk') {
        item.includedToPrice = false;

        console.log(`Witryna powierzony połysk:${0}`);
        return item;
      }
      // Półmat/Mat
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price =
          surface *
          prices.paint.customerMaterial.glassCase[
            order.glassCasePricingGroup || 1
          ];
        setItemPrice(price);

        console.log(
          `Witryna  [gr.${order.glassCasePricingGroup ||
            1}] powierzony półmat jednostronny ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        // - dwustronny
        const price =
          surface *
          (prices.paint.customerMaterial.glassCase[
            order.glassCasePricingGroup || 1
          ] +
            prices.paint.customerMaterial.leftSide);
        setItemPrice(price);

        console.log(
          `Witryna [gr.${order.glassCasePricingGroup ||
            1}] powierzony półmat dwustronny ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Witryna powierzony półmat inny:${0}`);
      return item;
    }
  }
  //   MATERIAŁ DILLAK
  if (item.type === 'frez') {
    //    FREZ
    // Połysk
    if (order.paintType === 'połysk') {
      item.includedToPrice = false;

      console.log(`Frez dillak połysk:${0}`);
      return item;
    }
    // Półmat/Mat
    if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
      // - jednostronny
      const price =
        surface *
        (prices.paint.companyMaterial.cnc[order.millingPricingGroup || 1] +
          prices.bold[thickness]);
      setItemPrice(price);

      console.log(
        `Frez [gr.${order.millingPricingGroup ||
          1}] dillak półmat jednostronny ${surface}m2: ${price}`,
      );
      return item;
    }
    if (paintRight && paintLeft) {
      // - dwustronny
      const price =
        surface *
        (prices.paint.companyMaterial.cnc[order.millingPricingGroup || 1] +
          prices.paint.companyMaterial.leftSide +
          prices.bold[thickness]);
      setItemPrice(price);

      console.log(
        `Frez [gr.${order.millingPricingGroup ||
          1}] dillak półmat dwustronny ${surface}m2: ${price}`,
      );
      return item;
    }
    item.includedToPrice = false;

    console.log(`Frez dillak półmat inny:${0}`);
    return item;
  }
  if (item.type === 'ryflowany') {
    //   RYFLOWANY
    // Połysk
    if (order.paintType === 'połysk') {
      item.includedToPrice = false;

      console.log(`Ryflowany dillak połysk:${0}`);
      return item;
    }
    // Półmat/Mat
    if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
      // - jednostronny
      const price =
        surface *
        (prices.paint.companyMaterial.semiGloss.groovedOneSide +
          prices.bold[thickness]);
      setItemPrice(price);

      console.log(
        `Ryflowany dillak półmat jednostronny ${surface}m2: ${price}`,
      );
      return item;
    }
    if (paintRight && paintLeft) {
      // - dwustronny
      const price =
        surface *
        (prices.paint.companyMaterial.semiGloss.groovedOneSide +
          prices.paint.companyMaterial.leftSide +
          prices.bold[thickness]);
      setItemPrice(price);

      console.log(
        `Ryflowany dillak półmat dwustronny ${surface}m2: ${price}`,
      );
      return item;
    }
    item.includedToPrice = false;

    console.log(`Ryflowany dillak półmat inny:${0}`);
    return item;
  }
  if (item.type === 'gładki') {
    //   GŁADKI
    // Połysk
    if (order.paintType === 'połysk') {
      if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
        // - jednostronny
        const price =
          surface *
          (prices.paint.companyMaterial.gloss.oneSide +
            prices.bold[thickness]);
        setItemPrice(price);

        console.log(
          `Gładki dillak połysk jednostronny ${surface}m2: ${price}`,
        );
        return item;
      }
      if (paintRight && paintLeft) {
        if (
          order.paintStyle.toLowerCase() ===
          'prawa - połysk / lewa - półmat'
        ) {
          // połysk/półmat
          const price =
            surface *
            (prices.paint.companyMaterial.gloss.oneSide +
              prices.paint.companyMaterial.leftSide +
              prices.bold[thickness]);
          setItemPrice(price);

          console.log(
            `Gładki dillak połysk/półmat dwustronny ${surface}m2: ${price}`,
          );
          return item;
        }
        //  dwustronny
        const price =
          surface *
          (prices.paint.companyMaterial.gloss.twoSides +
            prices.bold[thickness]);
        setItemPrice(price);

        console.log(
          `Gładki dillak połysk dwustronny ${surface}m2: ${price}`,
        );
        return item;
      }
      item.includedToPrice = false;

      console.log(`Gładki dillak połysk inny:${0}`);
      return item;
    }
    // Półmat/Mat
    if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
      // - jednostronny
      const price =
        surface *
        (prices.paint.companyMaterial.semiGloss.oneSide +
          prices.bold[thickness]);
      setItemPrice(price);

      console.log(
        `Gładki dillak półmat jednostronny ${surface}m2: ${price}`,
      );
      return item;
    }
    if (paintRight && paintLeft) {
      // - dwustronny
      const price =
        surface *
        (prices.paint.companyMaterial.semiGloss.oneSide +
          prices.paint.companyMaterial.leftSide +
          prices.bold[thickness]);
      setItemPrice(price);

      console.log(
        `Gładki dillak półmat dwustronny ${surface}m2: ${price}`,
      );
      return item;
    }
    item.includedToPrice = false;

    console.log(`Gładki dillak półmat inny:${0}`);
    return item;
  }
  if (item.type === 'witryna') {
    //   WITRYNA
    // Połysk
    if (order.paintType === 'połysk') {
      item.includedToPrice = false;

      console.log(`Witryna dillak połysk:${0}`);
      return item;
    }
    // Półmat/Mat
    if ((paintRight && !paintLeft) || (!paintRight && paintLeft)) {
      // - jednostronny
      const price =
        surface *
        (prices.paint.companyMaterial.glassCase[
          order.glassCasePricingGroup || 1
        ] +
          prices.bold[thickness]);

      setItemPrice(price);
      console.log(
        `Witryna [gr.${order.glassCasePricingGroup ||
          1}] dillak półmat jednostronny ${surface}m2: ${price}`,
      );
      return item;
    }
    if (paintRight && paintLeft) {
      // - dwustronny
      const price =
        surface *
        (prices.paint.companyMaterial.glassCase[
          order.glassCasePricingGroup || 1
        ] +
          prices.paint.companyMaterial.leftSide +
          prices.bold[thickness]);

      setItemPrice(price);
      console.log(
        `Witryna [gr.${order.glassCasePricingGroup ||
          1}] dillak półmat dwustronny ${surface}m2: ${price}`,
      );
      return item;
    }
    item.includedToPrice = false;

    console.log(`Witryna dillak półmat inny:${0}`);
    return item;
  }
  return item;
};

const getItemSurface = (order, item) => {
  const { paintRight, paintLeft, surfaceLeft, surfaceRight } = item;

  let surface;

  if ((paintRight && !paintLeft) || (!paintRight && paintLeft))
    surface = surfaceRight;
  else if (paintRight && paintLeft) surface = surfaceLeft;
  else surface = surfaceLeft;

  if (
    ['materiał klienta', 'poprawa (wina klienta)'].includes(
      order.orderType.toLowerCase(),
    ) &&
    !item.dillakMaterial &&
    (!order.veneer || (order.veneer && item.type !== 'ryflowany'))
  ) {
    surface += item.edgesSurface || 0;
  }

  return surface;
};

// if whole order surface is <1m2
const getRoundedPrice = (order, prices) => {
  if (order.orderType.toLowerCase() === 'reklamacja (wina dillak)')
    return 0;
  if (order.veneer) {
    if (order.millings.includes('ryflowany')) {
      if (order.color.includes('bejca')) {
        if (order.paintStyle === 'jednostronne')
          return prices.paint.veneer.groovedMordantOneSide;
        return prices.paint.veneer.groovedMordantTwoSides;
      }
      if (order.paintStyle === 'jednostronne')
        return prices.paint.veneer.groovedColorlessOneSide;
      return prices.paint.veneer.groovedColorlessTwoSides;
    }
    if (order.color.includes('bejca')) {
      if (order.paintStyle === 'jednostronne')
        return prices.paint.veneer.mordantOneSide;
      return prices.paint.veneer.mordantTwoSides;
    }
    if (order.paintStyle === 'jednostronne')
      return prices.paint.veneer.colorlessOneSide;
    return prices.paint.veneer.colorlessTwoSides;
  }
  if (
    ['materiał klienta', 'poprawa (wina klienta)'].includes(
      order.orderType.toLowerCase(),
    )
  ) {
    if (order.millings.length) {
      if (order.millings.includes('ryflowany')) {
        if (order.paintStyle === 'jednostronne')
          return prices.paint.customerMaterial.semiGloss.groovedOneSide;
        return (
          prices.paint.customerMaterial.semiGloss.groovedOneSide +
          prices.paint.customerMaterial.leftSide
        );
      }
      if (order.paintStyle === 'jednostronne')
        return prices.paint.customerMaterial.cnc[
          order.millingPricingGroup
        ];
      return (
        prices.paint.customerMaterial.cnc[order.millingPricingGroup] +
        prices.paint.customerMaterial.leftSide
      );
    }
    if (order.paintType === 'połysk') {
      if (
        order.paintStyle.toLowerCase() === 'prawa - połysk / lewa - półmat'
      )
        return (
          prices.paint.customerMaterial.gloss.oneSide +
          prices.paint.customerMaterial.leftSide
        );

      if (order.paintStyle === 'jednostronne')
        return prices.paint.customerMaterial.gloss.oneSide;

      return prices.paint.customerMaterial.gloss.twoSides;
    }
    if (order.paintStyle === 'jednostronne')
      return prices.paint.customerMaterial.semiGloss.oneSide;
    return (
      prices.paint.customerMaterial.semiGloss.oneSide +
      prices.paint.customerMaterial.leftSide
    );
  }
  if (order.millings.length) {
    if (order.millings.includes('ryflowany')) {
      if (order.paintStyle === 'jednostronne')
        return prices.paint.companyMaterial.semiGloss.groovedOneSide;
      return (
        prices.paint.companyMaterial.semiGloss.groovedOneSide +
        prices.paint.companyMaterial.leftSide
      );
    }
    if (order.paintStyle === 'jednostronne')
      return prices.paint.companyMaterial.cnc[order.millingPricingGroup];
    return (
      prices.paint.companyMaterial.cnc[order.millingPricingGroup] +
      prices.paint.companyMaterial.leftSide
    );
  }
  if (order.paintType === 'połysk') {
    if (order.paintStyle === 'jednostronne')
      return prices.paint.companyMaterial.gloss.oneSide;
    return prices.paint.companyMaterial.gloss.twoSides;
  }
  if (order.paintStyle === 'jednostronne')
    return prices.paint.companyMaterial.semiGloss.oneSide;
  return (
    prices.paint.companyMaterial.semiGloss.oneSide +
    prices.paint.companyMaterial.leftSide
  );
};
