import React from 'react';
import { Table } from 'react-bootstrap';
import PackageListRow from './PackageListRow';

const CuttingPackagesList = ({ packages }) => {
  if (packages && packages.length)
    return (
      <div>
        <Table responsive="lg" bordered hover size="sm">
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Nazwa</th>
              <th>Utworzone przez</th>
              <th>Ilość zamówień</th>
              <th>Zamówienia</th>
              <th>Elem. do wycięcia</th>
              <th>Elem. wstrzymane</th>
              <th>Elem. pominięte</th>
              <th>Data utworzenia</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((p, i) => (
              <PackageListRow key={p._id} index={i} p={p} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  return 'Nie znaleziono paczek cięcia';
};

export default CuttingPackagesList;
