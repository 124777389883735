import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'components/FormInputs/Select';
import { setPaintOrderValue } from 'services/store/actions/paints';

const ProducerCell = ({ index, order: { _id, producer } }) => {
 const dispatch = useDispatch();
 const paints = useSelector(state => state.paints.paints);
 const skipped = useSelector(state => state.paints.skipped);
 const paintProducers = useSelector(state => state.settings.paintProducers);

 const handleSelect = ({ target: { value } }) => {
  dispatch(setPaintOrderValue(index, 'producer', value));
 };
 return (
  <td data-isnotclickable="1">
   <Select
    data-isnotclickable="1"
    value={producer}
    items={paintProducers.producers}
    onChange={handleSelect}
    disabled={skipped.includes(_id)}
   />
  </td>
 );
};

export default ProducerCell;
