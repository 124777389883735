import React from 'react';
import withContext from 'hoc/withContext';
import './Card.scss';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 3px;
  right: 3px;
  border: 1px solid white;
  border-radius: 3px;
  padding: 1px 2px;
  font-size: 26px;
  &:hover {
    transform: translateY(3px);
    transition: transform 0.2s;
  }
`;

const Card = ({
  item,
  handleClick,
  handleAddToSecondPricingGroup,
  type = null,
  permissionContext,
}) => (
  <div
    className="catalogCard colorCard"
    color={item.color}
    onClick={() =>
      permissionContext !== 'employee'
        ? handleClick(item.name, type, item.hexValue)
        : null
    }
    style={{ backgroundColor: item.hexValue }}
  >
    {permissionContext && (
      <StyledIcon
        onClick={() => handleAddToSecondPricingGroup(item.name)}
        icon={faDollarSign}
        title="Dodaj do 2 grupy cenowej"
      />
    )}
    <div className="cardCaption">{item.name}</div>
  </div>
);

export default withContext(Card);
