export const getDataFromOrders = (orders, paintSecondGroup) => {
  let price = 0;
  let cnc = 0;
  let semiGloss = 0;
  let gloss = 0;
  let glossSecondGroup = 0;

  orders.forEach(({ order }) => {
    if (!order) return;
    if (order.price) price += order.price;
    if (order.manHours && order.manHours.price)
      price += order.manHours.price;
    if (order.ventilations && order.ventilations.price)
      price += order.ventilations.price;

    if (order.surfaceCNC) cnc += order.surfaceCNC;

    if (['półmat', 'mat'].includes(order.paintType)) {
      if (order.surfaceRight) semiGloss += order.surfaceRight;
      if (order.surfaceLeft) semiGloss += order.surfaceLeft * 2;
    }
    if (order.paintType === 'połysk') {
      if (order.paintStyle === 'prawa - połysk / lewa - półmat') {
        if (
          order.glossSecondGroup ||
          paintSecondGroup.includes(order.color.toLowerCase())
        ) {
          if (order.surfaceRight) glossSecondGroup += order.surfaceRight;
          if (order.surfaceLeft) semiGloss += order.surfaceLeft * 2;
        } else {
          if (order.surfaceRight) gloss += order.surfaceRight;
          if (order.surfaceLeft) semiGloss += order.surfaceLeft * 2;
        }
      } else {
        if (
          order.glossSecondGroup ||
          paintSecondGroup.includes(order.color.toLowerCase())
        ) {
          if (order.surfaceRight) glossSecondGroup += order.surfaceRight;
          if (order.surfaceLeft) glossSecondGroup += order.surfaceLeft * 2;
        } else {
          if (order.surfaceRight) gloss += order.surfaceRight;
          if (order.surfaceLeft) gloss += order.surfaceLeft * 2;
        }
      }
    }
  });

  return { price, cnc, semiGloss, gloss, glossSecondGroup };
};
