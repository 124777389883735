import React from 'react';
import withContext from 'hoc/withContext';
import { dateToString } from 'services/utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { isDeadlinePassed } from 'services/utils/orders';

const FinishDate = ({ date, permissionContext }) => {
 const orderFinishDate = new Date(date);
 const dateOnProd = orderFinishDate.setDate(orderFinishDate.getDate() - 3);
 const finishDate = permissionContext === 'user' ? date : dateOnProd;
 const isDeadline = isDeadlinePassed(finishDate);
 return (
  <td>
   {dateToString(finishDate)}{' '}
   {permissionContext !== 'user' && isDeadline && (
    <FontAwesomeIcon
     style={{ fontWeight: 'bold' }}
     icon={faExclamationCircle}
    />
   )}
  </td>
 );
};

export default withContext(FinishDate);
