export const getColorsFromOrders = orders =>
  orders.reduce(
    (colors, order) =>
      !colors.includes(order.color) ? colors.concat(order.color) : colors,
    [],
  );

export const getCustomersFromOrders = orders =>
  orders.reduce(
    (customers, order) => {
      const isExist = customers.find(item => item._id === order.user._id);

      if (isExist) return customers;
      return customers.concat(order.user);
    },

    [],
  );
