import React from 'react';
import Modal from 'templates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/FormInputs/TextInput';
import * as yup from 'yup';

const AddBankAccount = ({ closeModal, bankAccounts, setFieldValue }) => {
 return (
  <Modal
   title="Dodaj konto bankowe"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" type="submit" form="AddBankAccountModal">
      Dodaj
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   <Formik
    validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async (values) => {
     const newBankAccounts = bankAccounts.concat(values);
     setFieldValue('bankAccounts', newBankAccounts);
     closeModal();
    }}
    initialValues={{
     name: '',
     bankName: '',
     accountNumber: '',
    }}
    render={(props) => {
     return (
      <Form noValidate onSubmit={props.handleSubmit} id="AddBankAccountModal">
       <TextInput {...props} name="name" label="Nazwa" required />
       <TextInput {...props} name="bankName" label="Nazwa banku" required />
       <TextInput
        {...props}
        name="accountNumber"
        label="Numer konta"
        required
       />
      </Form>
     );
    }}
   />
  </Modal>
 );
};

export default AddBankAccount;

const schema = yup.object({
 name: yup.string().required('Nazwa jest wymagana'),
 bankName: yup.string().required('Nazwa jest wymagana'),
 accountNumber: yup
  .string()
  .length(26, 'Nr konta musi zawierać 26 znaków')
  .matches(/^\d{26}$/, 'Tylko cyfry'),
});
