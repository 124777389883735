import {
  CUSTOMERS_LIST_LOAD_SUCCESS,
  CUSTOMERS_LIST_LOAD_ERROR,
  CUSTOMERS_SET_SORT,
  CUSTOMERS_SET_CUSTOMER,
  CUSTOMERS_CLEAR_CUSTOMER_DATA,
} from '../types/customers';
import update from 'immutability-helper';

const initialState = {
  customers: null,
  customerData: null,
  sort: {
    sortBy: 'customerCompany',
    direction: 'ascend',
  },
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMERS_LIST_LOAD_SUCCESS: {
      return { ...state, customers: action.customers };
    }
    case CUSTOMERS_LIST_LOAD_ERROR: {
      return { ...state, customers: null };
    }
    case CUSTOMERS_SET_SORT: {
      return update(state, {
        sort: {
          sortBy: { $set: action.sortBy },
          direction: { $set: action.direction },
        },
      });
    }
    case CUSTOMERS_SET_CUSTOMER: {
      return { ...state, customerData: action.customer };
    }
    case CUSTOMERS_CLEAR_CUSTOMER_DATA: {
      return { ...state, customerData: null };
    }
    default:
      return state;
  }
};

export { customersReducer };
