import React, { useState } from 'react';
import Modal from 'templates/ModalTemplate';
import { StyledH3, StyledH6 } from 'components/header';
import FlexRow from 'templates/FlexRowTemplate';
import { Button, Form } from 'react-bootstrap';
import withContext from 'hoc/withContext';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setNewOrderManyFields } from 'services/store/actions/newOrder';
import ItemsList from 'components/Orders/ItemsLists/withCheckbox';
import { reasonsOfComplaint } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';

const NewSimmilarOrder = ({ order, permissionContext, closeModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState(null);
  const [reasonOfComplaint, setReasonOfComplaint] = useState(undefined);
  const [items, setItems] = useState([]);

  const handleItems = ({ target: { checked, value } }) => {
    if (checked) setItems(items.concat(value));
    else {
      const newList = items.filter(item => item !== value);
      setItems(newList);
    }
  };

  const handleNewOrder = () => {
    const fields = {
      reasonOfComplaint,
      orderType,
      handles: order.handles,
      millings: order.millings,
      veneer: order.veneer,
      glassCase: order.glassCase,
      paintMaker: order.paintMaker,
      paintMakerBase: order.paintMakerBase,
      color: order.color,
      paintType: order.paintType,
      paintStyle: order.paintStyle,
      isBlackBase: order.isBlackBase,
      isNut: order.isNut,
      isFelc: order.isFelc,
      isChamfering: order.isChamfering,
      colorHex: order.colorHex,
      customMilling: { path: '' },
      number: `${order.number.split('.')[0]}.${orderType[0]}`,
    };
    if (items.length) {
      fields.items = [];
      order.items.forEach(item => {
        if (items.includes(item._id))
          fields.items.push({
            ...item,
            image: { path: '' },
            elementToCorrect: { position: '', quantity: 0 },
            elementLost: { position: '', quantity: 0 },
            comments: '',
          });
      });
    }
    if (permissionContext === 'admin') {
      fields.user = order.user._id;
      fields.userName = `${order.user.company} - ${order.user.firstname || ''}`;
      fields.userPrices = order.user.discounts;
    }

    dispatch(setNewOrderManyFields({ ...fields }));
    closeModal();
    history.push(`/newOrder`);
  };
  return (
    <Modal
      title="Zamów"
      closeModal={closeModal}
      disabled={
        !orderType ||
        (orderType.toLowerCase() === 'reklamacja (wina DILLAK)' &&
          !reasonOfComplaint)
      }
      onOk={handleNewOrder}
    >
      {!orderType ? (
        <>
          <StyledH3>Wybierz rodzaj zamówienia</StyledH3>
          <FlexRow direction="column">
            <Button onClick={() => setOrderType('Domówienie')}>
              Domówienie
            </Button>

            <Button
              variant="danger"
              onClick={() => setOrderType('Reklamacja (wina DILLAK)')}
            >
              Reklamacja (wina DILLAK)
            </Button>

            <Button
              variant="warning"
              onClick={() => setOrderType('Poprawa (wina klienta)')}
            >
              Poprawa (wina klienta)
            </Button>
          </FlexRow>
        </>
      ) : (
        <>
          {orderType.toLowerCase() === 'reklamacja (wina DILLAK)' && (
            <OrderReasonOfComplaint
              reasonOfComplaint={reasonOfComplaint}
              setReasonOfComplaint={setReasonOfComplaint}
            />
          )}
          <StyledH3>Wybierz elementy*</StyledH3>
          <ItemsList
            items={order.items}
            checkedList={items}
            handleCheck={handleItems}
          />
          <div>
            <small>*Możesz pominąć wybór elementów</small>
          </div>
        </>
      )}
    </Modal>
  );
};

export default withContext(NewSimmilarOrder);

const OrderReasonOfComplaint = ({
  reasonOfComplaint,
  setReasonOfComplaint,
}) => {
  return (
    <>
      <strong>Przyczyna reklamacji</strong>
      <Form.Group
        onChange={e => setReasonOfComplaint(e.target.value)}
        value={reasonOfComplaint}
      >
        {reasonsOfComplaint.map(item => (
          <Form.Check
            custom
            key={item}
            value={item}
            type="radio"
            label={firstLetterToUpperCase(item)}
            id={item}
            name="reasonOfComplaint"
          />
        ))}
      </Form.Group>
    </>
  );
};
