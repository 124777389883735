import update from 'immutability-helper';
import {
  STATS_LOAD_SUCCESS,
  STATS_LOAD_ERROR,
  STATS_SET_DATE,
  STATS_SET_POSITION,
  STATS_CLEAR,
  STATS_SET_FIELD,
} from '../types/statistics';

const dateFrom = new Date(new Date().setDate(1));

const initialState = {
  stats: null,
  employee: '',
  customer: '',
  position: {
    production: 'dillak',
    employee: '',
  },
  date: {
    production: {
      from: dateFrom,
      to: new Date(),
    },
    employee: {
      from: dateFrom,
      //  to: dateFrom,
      to: new Date(),
    },
    customer: {
      from: dateFrom,
      to: new Date(),
    },
  },
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATS_SET_FIELD: {
      return { ...state, [action.field]: action.value };
    }
    case STATS_CLEAR: {
      return { ...state, stats: null, employee: '', customer: '' };
    }
    case STATS_LOAD_SUCCESS: {
      return { ...state, stats: action.stats };
    }
    case STATS_LOAD_ERROR: {
      return { ...state, stats: null };
    }
    case STATS_SET_DATE: {
      return update(state, {
        stats: { $set: null },
        date: { [action.field]: { [action.key]: { $set: action.date } } },
      });
    }
    case STATS_SET_POSITION: {
      return update(state, {
        stats: { $set: action.key === 'employee' ? state.stats : null },
        position: { [action.key]: { $set: action.value } },
      });
    }
    default:
      return state;
  }
};

export { statisticsReducer };
