import React from 'react';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { orderRemove } from 'services/apiRequests/orders/remove';
import ModalContainer from 'templates/ModalTemplate';
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';

const RemoveOrder = ({ closeModal, order }) => {
 const dispatch = useDispatch();
 const history = useHistory();
 const alert = useAlert();

 const handleRemove = async () => {
  dispatch(setSpinner(true));
  await orderRemove(
   order._id,
   () => {
    dispatch(setSpinner(false));
    alert.success('Zamówienie usunięte');
    history.push('/');
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
   },
  );
 };
 return (
  <ModalContainer
   title="Usuwanie zamówienia"
   variant="danger"
   closeModal={closeModal}
   onOk={handleRemove}
  >
   Usunięcie zamówienia jest nieodwracalne. Czy na pewno chcesz to zrobić?
  </ModalContainer>
 );
};

export default RemoveOrder;
