import * as yup from 'yup';

export const schema = yup.object({
 firstname: yup
  .string()
  .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Nieprawidłowy format'),
 surname: yup
  .string()
  .matches(/^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, 'Nieprawidłowy format'),
 company: yup.string().required('Nazwa firmy jest wymagana'),
 email: yup
  .string()
  .email('Format adresu email jest nieprawidłowy')
  .required('Email jest wymagany'),
 password: yup
  .string()
  .min(6, 'Hasło musi składać się z min. 6 znaków')
  .required('Hasło jest wymagane'),
 password2: yup
  .string()
  .min(6, 'Hasło musi składać się z min. 6 znaków')
  .required('Potwierdzenie hasła jest wymagane'),
 rodo: yup.bool().required('Zgoda jest wymagana'),
 reg: yup.bool().required('Zgoda jest wymagana'),
 msg: yup.bool().required('Zgoda jest wymagana'),
});
