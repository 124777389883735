import React from 'react';
import { Form } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';

const Select = (props) => {
 const { items, emptyItem = true, ...rest } = props;
 return (
  <Form.Control {...rest} as="select">
   {emptyItem && <option value=""></option>}
   {items &&
    items.map((item) => (
     <option
      key={item}
      value={item}
      className={item}
      style={{ color: 'black' }}
     >
      {typeof item === 'string' ? firstLetterToUpperCase(item) : item}
     </option>
    ))}
  </Form.Control>
 );
};

export default Select;
