import React, { useState, useEffect } from 'react';
import ModalContainer from 'templates/ModalTemplate';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import { getGlobalSettings } from 'services/store/actions/settings';
import { firstLetterToUpperCase } from 'services/utils/string';

const Row = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin-bottom: 5px;
`;
const Input = styled(Form.Control)`
 width: 50%;
 display: inline-block;
`;

const PaintProducers = ({ order, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();

 const paintProducers = useSelector(state => state.settings.paintProducers);
 const { paintMaker, paintMakerBase } = order;

 const [values, setValues] = useState({
  paintMaker,
  paintMakerBase,
 });

 useEffect(() => {
  dispatch(setSpinner(true));
  dispatch(
   getGlobalSettings(
    () => {
     dispatch(setSpinner(false));
    },
    () => {
     dispatch(setSpinner(false));
     alert.error('Błąd!');
    },
   ),
  );
 }, []);

 const handleChange = (key, value) => setValues({ ...values, [key]: value });
 const handleSubmit = async () => {
  dispatch(setSpinner(true));
  await updateOrder(
   'paintproducers',
   order._id,
   { ...values },
   order => {
    dispatch(setOrder(order));
    dispatch(setSpinner(false));
    alert.success('Zaktualizowano producentów lakieru');
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('błąd!');
    closeModal();
   },
  );
 };
 return (
  <ModalContainer
   title="Producenci lakierów"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" onClick={handleSubmit}>
      Zmień
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   {paintProducers && paintProducers.producers && (
    <>
     <Row>
      <span>Producent podkładu:</span>{' '}
      <ProducersSelect
       list={paintProducers.producers}
       value={values.paintMakerBase}
       handleChange={e => handleChange('paintMakerBase', e.target.value)}
      />
      {/* <Input
       type="text"
       placeholder="Podkład"
       value={values.paintMakerBase}
       onChange={e => handleInput('paintMakerBase', e.target.value)}
      /> */}
     </Row>
     <Row>
      <span>Producent lakieru:</span>{' '}
      <ProducersSelect
       list={paintProducers.producers}
       value={values.paintMaker}
       handleChange={e => handleChange('paintMaker', e.target.value)}
      />
      {/* <Input
       type="text"
       placeholder="Lakier"
       value={values.paintMaker}
       onChange={e => handleInput('paintMaker', e.target.value)}
      /> */}
     </Row>
    </>
   )}
  </ModalContainer>
 );
};

export default PaintProducers;

const ProducersSelect = ({ list, value, handleChange }) => {
 return (
  <Form.Control
   style={{ width: 300 }}
   value={value}
   onChange={handleChange}
   as="select"
  >
   {list.map(item => (
    <option key={item} value={item} className={item}>
     {firstLetterToUpperCase(item)}
    </option>
   ))}
  </Form.Control>
 );
};
