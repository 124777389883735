import {
  CUTTING_PACKAGE_SET_ITEM_STATUS,
  CUTTING_PACKAGE_SET_FIELD,
  CUTTING_PACKAGE_SET_ITEM_MACHINING,
} from '../types/newCuttingPackage';
import update from 'immutability-helper';

const initialState = {
  name: '',
  createdBy: null,
  status: 'oczekuje',
  orders: [],
};

const newCuttingPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUTTING_PACKAGE_SET_ITEM_STATUS: {
      return update(state, {
        orders: {
          [action.orderIndex]: {
            items: {
              [action.itemIndex]: {
                cuttingStatus: { $set: action.status },
                cuttingPackage: { $set: undefined },
              },
            },
          },
        },
      });
    }
    case CUTTING_PACKAGE_SET_ITEM_MACHINING: {
      return update(state, {
        orders: {
          [action.orderIndex]: {
            items: {
              [action.itemIndex]: {
                isMachiningRequired: { $set: action.value },
              },
            },
          },
        },
      });
    }
    case CUTTING_PACKAGE_SET_FIELD: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    default:
      return state;
  }
};

export { newCuttingPackageReducer };
