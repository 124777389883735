import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { schema } from './utils/validateRegisterForm';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { registerCustomer } from 'services/apiRequests/customers/post';
import AccountCreatedModal from './components/AccountCreatedModal';

const StyledH1 = styled.h1`
  margin-bottom: 30px;
  font-size: 50px;
  @media (max-width: 600px) {
    font-size: 35px;
    margin-bottom: 10px;
  }
`;
const StyledInfo = styled.p`
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 2px;
  @media (max-width: 600px) {
    font-size: 8px;
  }
`;
const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledContent = styled.div`
  width: 70%;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 5px;
`;
const StyledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  background-color: ${({ theme }) => theme.dillakGreen};
  color: white;
  border: none;
  font-size: 20px;
  letter-spacing: 3px;
  padding: 10px 30px;
  transition: transform 0.2s;
  &:hover {
    transform: translateX(-10px);
  }
  @media (max-width: 600px) {
    padding: 3px 20px;
  }
`;

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isCreated, setIsCreated] = useState(false);
  return (
    <>
      <StyledContainer>
        <StyledButton onClick={() => history.push('/login')}>
          <FontAwesomeIcon icon={faArrowLeft} /> Zaloguj
        </StyledButton>
        <StyledContent>
          <StyledH1>Rejestracja</StyledH1>
          <Formik
            validationSchema={schema}
            validate={values => {
              const errors = {};
              if (values.password2 !== values.password) {
                errors.password2 = 'Hasła muszą być identyczne';
              }
              return errors;
            }}
            // ONSUBMIT REQUEST
            onSubmit={async (values, actions) => {
              dispatch(setSpinner(true));
              await registerCustomer(
                values,
                () => {
                  setIsCreated(true);
                },
                errors => {
                  actions.setErrors(errors);
                }
              );
              dispatch(setSpinner(false));
            }}
            initialValues={{
              firstname: '',
              surname: '',
              company: '',
              email: '',
              password: '',
              password2: '',
              rodo: '',
              reg: '',
              msg: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <StyledFormGroup controlId="formName">
                  <Form.Control
                    required
                    type="text"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Imię*"
                    className={
                      errors.firstname && touched.firstname && 'is-invalid'
                    }
                  />
                  {errors.firstname && touched.firstname && (
                    <div className="invalid">{errors.firstname}</div>
                  )}
                </StyledFormGroup>

                <StyledFormGroup controlId="formSurame">
                  <Form.Control
                    type="text"
                    name="surname"
                    value={values.surname}
                    onChange={handleChange}
                    placeholder="Nazwisko"
                  />
                  {errors.surname && touched.surname && (
                    <div className="invalid">{errors.surname}</div>
                  )}
                </StyledFormGroup>

                <StyledFormGroup controlId="formCompany">
                  <Form.Control
                    required
                    type="text"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.company && !errors.company}
                    placeholder="Nazwa firmy*"
                    className={
                      errors.company && touched.company && 'is-invalid'
                    }
                  />
                  {errors.company && touched.company && (
                    <div className="invalid">{errors.company}</div>
                  )}
                </StyledFormGroup>

                <StyledFormGroup controlId="formEmail">
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.email && !errors.email}
                    placeholder="Adres email*"
                    className={errors.email && touched.email && 'is-invalid'}
                  />
                  {errors.email && touched.email && (
                    <div className="invalid">{errors.email}</div>
                  )}
                </StyledFormGroup>

                <StyledFormGroup controlId="formPassword">
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.password && !errors.password}
                    placeholder="Hasło*"
                    className={
                      errors.password && touched.password && 'is-invalid'
                    }
                  />
                  {errors.password && touched.password && (
                    <div className="invalid">{errors.password}</div>
                  )}
                </StyledFormGroup>

                <StyledFormGroup controlId="formConfirmPassword">
                  <Form.Control
                    required
                    type="password"
                    name="password2"
                    value={values.password2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.password2 && !errors.password2}
                    placeholder="Powtórz hasło*"
                    className={
                      errors.password2 && touched.password2 && 'is-invalid'
                    }
                  />
                  {errors.password2 && touched.password2 && (
                    <div className="invalid">{errors.password2}</div>
                  )}
                </StyledFormGroup>

                <StyledFormGroup
                  controlId="formCheckboxRODO"
                  style={{ marginBottom: 0 }}
                >
                  <Form.Check
                    type="checkbox"
                    required
                    name="rodo"
                    onChange={handleChange}
                    value={values.rodo}
                    onBlur={handleBlur}
                    className="text-muted"
                    label={<Rodo />}
                  />
                  {errors.rodo && <div className="invalid">{errors.rodo}</div>}
                </StyledFormGroup>

                <StyledFormGroup
                  controlId="formChecboxREG"
                  style={{ marginBottom: 0 }}
                >
                  <Form.Check
                    type="checkbox"
                    required
                    name="reg"
                    onChange={handleChange}
                    value={values.reg}
                    onBlur={handleBlur}
                    className="text-muted"
                    label={<Reg />}
                  />
                  {errors.reg && <div className="invalid">{errors.reg}</div>}
                </StyledFormGroup>

                <StyledFormGroup
                  controlId="formChecboxMSG"
                  style={{ marginBottom: 0 }}
                >
                  <Form.Check
                    type="checkbox"
                    required
                    name="msg"
                    onChange={handleChange}
                    value={values.msg}
                    onBlur={handleBlur}
                    className="text-muted"
                    label={<EmailReg />}
                  />
                  {errors.msg && <div className="invalid">{errors.msg}</div>}
                </StyledFormGroup>

                <Form.Text className="text-muted text--required">
                  * - pola wymagane
                </Form.Text>

                <Button
                  type="submit"
                  variant="success"
                  style={{
                    margin: '5px 0',
                    padding: '10px 0',
                    letterSpacing: 5,
                    width: '100%',
                  }}
                >
                  Zarejestruj
                </Button>
              </Form>
            )}
          </Formik>
        </StyledContent>
      </StyledContainer>
      {/* MODALS */}
      {isCreated && (
        <AccountCreatedModal closeModal={() => setIsCreated(false)} />
      )}
    </>
  );
};

export default Register;

const Rodo = () => (
  <StyledInfo>
    Wyrażam zgodę na przetwarzanie moich danych osobowych przez firmę: DILLAK,
    NIP: 712-30-92-842, REGON: 061428029, w celu założenia i utrzymywania mojego
    konta użytkownika na warunkach opisanych w
    <a href="/regulations"> regulaminie</a> i przy zachowaniu przepisów
    rozporządzenia o ochronie danych osobowych (RODO). Zostałem poinformowany,
    że zgodę mogę wycofać w dowolnym momencie. Zostałem poinformowany, że mam
    prawo wglądu, poprawiania oraz żądania usunięcia moich danych osobowych w
    dowolnej chwili. *
  </StyledInfo>
);
const Reg = () => (
  <StyledInfo>
    Zapoznałem się z <a href="/regulations">regulaminem.*</a>
  </StyledInfo>
);
const EmailReg = () => (
  <StyledInfo>
    Wyrażam zgodę na otrzymywanie wiadomości e-mail z informacjami na temat
    moich zamówień.
  </StyledInfo>
);
