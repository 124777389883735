import React, { useEffect } from 'react';
import ModalTemplate from 'templates/ModalTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { cuttingPackageSetField } from 'services/store/actions/newCuttingPackage';
import { Form } from 'react-bootstrap';
import { dateToStringWithoutYear } from 'services/utils/date';

const ConfirmationModal = ({ closeModal, handleSubmit }) => {
  const dispatch = useDispatch();
  const pckge = useSelector(state => state.newCuttingPackage);

  useEffect(() => {
    const date = dateToStringWithoutYear(new Date());
    const itemsQty = pckge.orders
      .reduce((acc, order) => {
        return [...acc, ...order.items];
      }, [])
      .reduce((qty, item) => {
        if (item.cuttingStatus === 'do wycięcia')
          return (qty += item.quantity);
        return qty;
      }, 0);

    let name = `Paczka ciecia ${date}/ ${pckge.orders.length}zam/ ${itemsQty}elem`;
    dispatch(cuttingPackageSetField('name', name));
  }, []);

  const handleName = e =>
    dispatch(cuttingPackageSetField('name', e.target.value));

  return (
    <ModalTemplate
      title="Nazwij paczkę"
      closeModal={closeModal}
      onOk={handleSubmit}
      disabled={!pckge.name}
    >
      <Form.Control
        placeholder="Nazwa paczki"
        value={pckge.name}
        onChange={handleName}
      />
    </ModalTemplate>
  );
};

export default ConfirmationModal;
