import React, { useEffect, useState } from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

const useLightbox = (imgs) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(imgs);
  }, [imgs]);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return {
    openImage: handleClick,
    lightbox: !!currentImage && (
      <Lightbox
        mainSrc={currentImage.src}
        imageTitle={currentImage.caption}
        mainSrcThumbnail={currentImage.src}
        nextSrc={nextImage.original}
        nextSrcThumbnail={nextImage.src}
        prevSrc={prevImage.original}
        prevSrcThumbnail={prevImage.src}
        onCloseRequest={handleClose}
        onMovePrevRequest={handleMovePrev}
        onMoveNextRequest={handleMoveNext}
      />
    ),
  };
};

export default useLightbox;
