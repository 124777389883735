import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { useSelector, useDispatch } from 'react-redux';
import FlexRow from 'templates/FlexRowTemplate';
import { StyledH1 } from 'components/header';
import { setSpinner } from 'services/store/actions/view';
import { getOrder, setOrder } from 'services/store/actions/orders';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { getUserOrdersInProduction } from 'services/apiRequests/orders/get';
import { useAlert } from 'react-alert';
import PlanOrder from 'scenes/Timetables/Timetable/components/Modals/PlanOrder';
import withContext from 'hoc/withContext';
import Data from '../components/Order/Data';
import Status from '../components/Order/Status';
import ItemsList from '../components/Order/ItemsList';
import EmployeeComments from '../components/Order/EmployeeComments';
import Summary from '../components/Order/Summary';
import SurfaceSummary from '../components/Order/SurfaceSummary';
import Legend from '../components/Order/Legend';
import HistoryButtons from '../components/Order/HistoryButtons';
import PaintProducers from '../components/Order/PaintProducers';
import Buttons from '../components/buttons';
import Modal from '../components/modals';
import Images from '../components/Order/Images';
import StartOrder from '../components/modals/Production/StartOrder';
import PauseOrder from '../components/modals/Production/PauseOrder';
import StopOrder from '../components/modals/Production/StopOrder';
import TransportDate from '../components/modals/Data/TransportDate';

const Header = styled(StyledH1)`
  cursor: pointer;
`;
const StyledSpan = styled.span`
  font-size: 20px;
`;
const Wrapper = styled.div`
  width: 49%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Order = ({ match, permissionContext }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { params } = match;
  const order = useSelector(state => state.orders.order);
  const position = useSelector(state => state.employee.activePosition);

  const [modal, setModal] = useState(null);
  const [ordersInProduction, setOrdersInProduction] = useState([]);

  useEffect(() => {
    dispatch(setSpinner(true));
    dispatch(
      getOrder(
        params.id,
        () => {
          dispatch(setSpinner(false));
        },
        () => {
          dispatch(setSpinner(false));
          alert.error('Błąd!');
        },
      ),
    );
    return () => dispatch(setOrder(null));
  }, []);

  useEffect(() => {
    if (order)
      getUserOrdersInProduction(
        order.user._id,
        orders => {
          setOrdersInProduction(
            orders.filter(item => item._id !== params.id),
          );
        },
        () => {},
      );
  }, [order]);

  const closeModal = () => setModal(null);

  console.log(order);
  if (order) {
    const { user } = order;
    return (
      <PageTemplate>
        <FlexRow justify="flex-end" wrap>
          <Buttons order={order} setModal={setModal} />
        </FlexRow>
        <FlexRow justify="space-between">
          <Header
            onClick={() => history.push(`/customers/${order.user._id}`)}
          >
            {user.company}{' '}
            {user.firstname && <StyledSpan>- {user.firstname}</StyledSpan>}
            {permissionContext === 'admin' && (
              <sup>
                <sup
                  style={{
                    color: 'blue',
                    fontSize: 14,
                    fontWeight: 'bold',
                  }}
                >
                  {order.user.isVip ? 'VIP' : ''}
                </sup>
              </sup>
            )}
          </Header>
          <Status
            order={order}
            ordersInProduction={ordersInProduction}
            setModal={setModal}
          />
        </FlexRow>
        <Data order={order} />
        <ItemsList order={order} />
        <FlexRow justify="space-between" wrap>
          <Wrapper>
            {order.employeesComments.length ? (
              <EmployeeComments order={order} />
            ) : null}
            <Images order={order} />
          </Wrapper>
          <Wrapper>
            <Summary order={order} />
            <SurfaceSummary order={order} />
            <Legend />
            <HistoryButtons order={order} setModal={setModal} />
            <PaintProducers order={order} setModal={setModal} />
          </Wrapper>
        </FlexRow>
        {/* MODALS */}
        {modal === '_planOrder' && (
          <PlanOrder closeModal={closeModal} orderData={order} />
        )}
        {modal === '_planTransport' && (
          <TransportDate closeModal={closeModal} order={order} />
        )}
        {modal === '_changeDimensions' && (
          <Modal.ChangeItemsDimensions
            order={order}
            closeModal={closeModal}
          />
        )}
        {modal === '_getPickUpReport' && (
          <Modal.PickUpReport order={order} closeModal={closeModal} />
        )}
        {modal === '_readyToPickUp' && (
          <Modal.ReadyToPickUp order={order} closeModal={closeModal} />
        )}
        {modal === '_ordersInProduction' && (
          <Modal.OrdersInProduction
            orders={ordersInProduction}
            closeModal={closeModal}
          />
        )}
        {modal === '_neworder' && (
          <Modal.NewSimmilarOrder order={order} closeModal={closeModal} />
        )}
        {modal === '_remove' && (
          <Modal.RemoveOrder order={order} closeModal={closeModal} />
        )}
        {modal === '_elementsToCorrect' && (
          <Modal.SetElementsToCorrect
            order={order}
            closeModal={closeModal}
            position={position}
          />
        )}
        {modal === '_elementsLost' && (
          <Modal.SetLostElements
            order={order}
            closeModal={closeModal}
            position={position}
          />
        )}
        {modal === '_addComment' && (
          <Modal.AddComment
            order={order}
            closeModal={closeModal}
            position={position}
          />
        )}
        {modal === '_paintProducers' && (
          <Modal.PaintProducers order={order} closeModal={closeModal} />
        )}
        {modal === '_changePrice' && (
          <Modal.Price order={order} closeModal={closeModal} />
        )}
        {modal === '_manHours' && (
          <Modal.ManHours order={order} closeModal={closeModal} />
        )}
        {modal === '_ventilation' && (
          <Modal.Ventilation order={order} closeModal={closeModal} />
        )}
        {modal === '_paymentStatus' && (
          <Modal.PaymentStatus order={order} closeModal={closeModal} />
        )}
        {modal === '_realizationDate' && (
          <Modal.RealizationDate order={order} closeModal={closeModal} />
        )}
        {modal === '_status' && (
          <Modal.Status order={order} closeModal={closeModal} />
        )}
        {modal === '_updateHistory' && (
          <Modal.UpdateHistory order={order} closeModal={closeModal} />
        )}
        {modal === '_productionHistory' && (
          <Modal.ProductionHistory order={order} closeModal={closeModal} />
        )}
        {modal === '_planHistory' && (
          <Modal.PlanHistory order={order} closeModal={closeModal} />
        )}
        {/* EMPLOYEE MODALS */}
        {modal === '_startOrder' && (
          <StartOrder
            order={order}
            position={position}
            closeModal={closeModal}
          />
        )}
        {modal === '_pauseOrder' && (
          <PauseOrder
            order={order}
            position={position}
            closeModal={closeModal}
          />
        )}
        {modal === '_stopOrder' && (
          <StopOrder
            order={order}
            position={position}
            closeModal={closeModal}
          />
        )}
      </PageTemplate>
    );
  }
  return <PageTemplate>{null}</PageTemplate>;
};

export default withContext(Order);
