import Axios from 'axios';

export const changeCuttingPackageStatus = async (
  cuttingPackageId,
  status,
  onEnd,
  onError,
) => {
  try {
    const res = await Axios.put(
      `/api/cutting/${cuttingPackageId}/status`,
      { status },
    );

    onEnd();
    return res.data.cuttingPackage;
  } catch (error) {
    console.log(error.response);
    onError();
    return null;
  }
};
