import React from 'react';
import Modal from 'templates/ModalTemplate';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logOutUser } from 'services/store/actions/auth';

const UserNotAccepted = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(logOutUser());
  };
  return (
    <Modal
      closeModal={handleClose}
      title="Konto nie jest zweryfikowane"
      variant="danger"
      footer={
        <Button variant="success" onClick={handleClose}>
          Zamknij
        </Button>
      }
      onHide={handleClose}
    >
      Administrator został poinformowany o utworzeniu konta, musisz oczekiwać na
      akceptację.
      <br />
      <br />
      <h4 style={{ fontWeight: 'bold' }}>Dane kontaktowe:</h4>
      <p>
        {/* tel.: <strong>796 999 540</strong> */}
        <br />
        E-mail: <strong>biuro@dillak.pl</strong>
      </p>
    </Modal>
  );
};

export default UserNotAccepted;
