/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PageTemplate from 'templates/PageTemplate';
import { PageHeader } from 'components/header';
import SidebarTemplate from 'templates/SidebarTemplate';
import withContext from 'hoc/withContext';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  setNewOrderFinisDate,
  setNewOrderField,
  calcNewOrderValues,
} from 'services/store/actions/newOrder';
import { getGlobalSettings } from 'services/store/actions/settings';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router';
import { substractSimilarObjects } from 'services/utils/object';
import { validateItems } from '../utils/validateItems';
import ItemsErrors from '../components/Modals/ItemsErrors';
import OrderAs from './components/Modals/OrderAs';
import OrderForm from '../components/OrderForm';
import Sidebar from '../components/Sidebar';
import OrderType from './components/Modals/OrderType';
import { initZero } from 'services/utils/date';

const initModals = { orderAs: false, orderType: false };

const NewOrder = ({ permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const newOrder = useSelector(state => state.newOrder);
  const realizationDates = useSelector(
    state => state.settings.realizationDates,
  );
  const paintSecondGroup = useSelector(
    state => state.settings.paintSecondGroup,
  );

  const [errors, setErrors] = useState([]);
  const [modals, setModals] = useState(initModals);

  useEffect(() => {
    dispatch(
      getGlobalSettings(
        () => {},
        () => {
          alert.error('Błąd pobierania ustawień!');
        },
      ),
    );
  }, []);

  // set order creator and order owner
  useEffect(() => {
    if (user._id) {
      dispatch(setNewOrderField('createdBy', user._id));
      if (!newOrder.user) dispatch(setNewOrderField('user', user));
      if (!newOrder.userName)
        dispatch(
          setNewOrderField(
            'userName',
            `${user.company} - ${user.firstname}`,
          ),
        );

      if (!newOrder.number) {
        const today = new Date();
        dispatch(
          setNewOrderField(
            'number',
            `-/${initZero(today.getMonth() + 1)}`,
          ),
        );
      }
      if (!newOrder.userPrices)
        dispatch(setNewOrderField('userPrices', user.discounts));
    }
  }, [user]);

  useEffect(() => {
    if (newOrder.color && paintSecondGroup) {
      const isExist = paintSecondGroup.find(
        item =>
          item
            .toLowerCase()
            .trim()
            .replace(' ', '') ===
          newOrder.color
            .toLowerCase()
            .trim()
            .replace(' ', ''),
      );

      dispatch(setNewOrderField('glossSecondGroup', !!isExist));
    }
  }, [newOrder.color, paintSecondGroup]);

  useEffect(() => {
    if (realizationDates && newOrder.user?.realizationDates) {
      const terms = substractSimilarObjects(
        realizationDates,
        newOrder.user.realizationDates,
      );
      dispatch(setNewOrderField('terms', terms));
    }
  }, [newOrder.user, realizationDates]);

  // set order finish date
  useEffect(() => {
    if (
      newOrder.terms.veneer &&
      newOrder.terms.milling &&
      newOrder.terms.gloss &&
      newOrder.terms.semiGloss
    )
      dispatch(setNewOrderFinisDate(newOrder.terms));
  }, [
    newOrder.terms,
    newOrder.color,
    newOrder.paintType,
    newOrder.millings,
    newOrder.veneer,
  ]);

  //  handlers
  const handleModal = key => setModals({ ...modals, [key]: true });
  const closeModal = () => setModals(initModals);

  const handleNext = () => {
    const isErrors = validateItems(newOrder.items, setErrors);
    if (!isErrors) {
      dispatch(calcNewOrderValues());
      history.push('neworder/summary');
    }
  };

  return (
    <PageTemplate>
      <SidebarTemplate Component={<Sidebar />}>
        <PageHeader
          title="Nowe zamówienie"
          extra={
            permissionContext === 'admin'
              ? [
                  <Button
                    disabled={!realizationDates}
                    onClick={() => handleModal('orderAs')}
                    key={1}
                  >
                    Zamów jako
                  </Button>,
                ]
              : permissionContext === 'user'
              ? [
                  <Button onClick={() => handleModal('orderType')} key={1}>
                    Typ zamówienia
                  </Button>,
                ]
              : null
          }
        />
        <OrderForm order={newOrder} handleNext={handleNext} />
      </SidebarTemplate>
      {/* MODALS */}
      {errors.length ? (
        <ItemsErrors errors={errors} closeModal={() => setErrors([])} />
      ) : null}
      {modals.orderAs && <OrderAs closeModal={closeModal} />}
      {modals.orderType && <OrderType closeModal={closeModal} />}
    </PageTemplate>
  );
};

export default withContext(NewOrder);
