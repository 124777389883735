import React, { useState, useEffect } from 'react';
import Modal from 'templates/ModalTemplate';
import { Form } from 'react-bootstrap';
import Select from 'components/FormInputs/Select';
import { months } from 'services/utils/const';
import FlexRow from 'templates/FlexRowTemplate';
import { getYears, formatDateToDatePicker } from 'services/utils/date';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { useAlert } from 'react-alert';
import { loadEmployeesSuccess } from 'services/store/actions/employees';
import { updateWorkedHours } from 'services/apiRequests/employees/update';
const today = new Date();

const WorkedHours = ({ employees, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const [year, setYear] = useState(today.getFullYear());
 const [month, setMonth] = useState(months[today.getMonth()]);

 const [employeesData, setEmployeesData] = useState([]);

 useEffect(() => {
  if ((employees, year, month)) {
   const data = employees.map(({ _id, firstname, surname, workedHours }) => {
    const obj = {
     _id,
     name: `${firstname} ${surname}`,
     date: formatDateToDatePicker(new Date(year, months.indexOf(month), 10)),
    };
    obj.hours = getWorkedHoursValue(workedHours, year, month);
    return obj;
   });

   setEmployeesData(data);
  }
 }, [employees, year, month]);

 const handleInput = (item, value) => {
  const numbers = /^[\d|.|,]*$/;
  if (!value.match(numbers)) return;
  if (!value) value = 0;
  const newData = employeesData.map(employee => {
   if (item._id === employee._id) employee.hours = parseInt(value);
   return employee;
  });
  setEmployeesData(newData);
 };
 const handleYear = e => setYear(parseInt(e.target.value));
 const handleMonth = e => setMonth(e.target.value);
 const handleSubmit = async () => {
  dispatch(setSpinner(true));
  await updateWorkedHours(
   { employeesData },
   employees => {
    alert.success('Zaktualizowano godziny pracy');
    dispatch(loadEmployeesSuccess(employees));
    dispatch(setSpinner(false));
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('Błąd');
   },
  );
 };
 if (employees)
  return (
   <Modal title="Godziny pracy" closeModal={closeModal} onOk={handleSubmit}>
    <FlexRow justify="space-around">
     <div>
      <strong>Rok</strong>
      <Select value={year} items={getYears()} onChange={handleYear} />
     </div>
     <div>
      <strong>Miesiąc</strong>
      <Select value={month} items={months} onChange={handleMonth} />
     </div>
    </FlexRow>
    {employeesData.length
     ? employeesData.map(item => (
        <Employee key={item._id} item={item} handleInput={handleInput} />
       ))
     : null}
   </Modal>
  );
 return null;
};

export default WorkedHours;

const Employee = ({ item, handleInput }) => {
 return (
  <FlexRow justify="space-between">
   <strong>{item.name}: </strong>{' '}
   <Form.Control
    type="text"
    placeholder="godz."
    style={{ width: 100, display: 'inline' }}
    value={item.hours}
    onChange={e => handleInput(item, e.target.value)}
   />
  </FlexRow>
 );
};

const getWorkedHoursValue = (arr, year, month) => {
 const monthIndex = months.indexOf(month);

 const value = arr.find(({ date }) => isCurrentMonth(date, year, monthIndex));

 return value ? value.hours : '';
};

const isCurrentMonth = (dateA, year, month) => {
 const a = new Date(dateA);

 return a.getFullYear() === year && a.getMonth() === month;
};
