import React, { useState } from 'react';
import ModalTemplate from 'templates/ModalTemplate';
import { useSelector } from 'react-redux';
import MessagesList from 'components/Messages/MessagesList';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import Message from 'components/Messages/Message';
import NewMessage from 'components/Messages/NewMessage';
import GenerateOrder from './GenerateOrder';

const Container = styled.div`
 display: flex;
`;
const Wrapper = styled.div`
 width: 50%;
 padding: 5px 15px;
`;

const initView = {
 message: null,
 generateOrder: null,
 newMessage: null,
};

const Messages = ({ closeModal }) => {
 const messages = useSelector((state) => state.messages);
 const user = useSelector((state) => state.auth.user);

 const [view, setView] = useState(initView);

 const handleView = (key, value) => setView({ ...initView, [key]: value });
 return (
  <ModalTemplate
   size="xl"
   title="Wiadomości"
   closeModal={closeModal}
   footer={
    <>
     <Button
      variant="outline-primary"
      onClick={() => handleView('generateOrder', true)}
     >
      Generuj polecenie
     </Button>
     <Button variant="success" onClick={() => handleView('newMessage', true)}>
      Nowa wiadomosć
     </Button>
     <Button variant="danger" onClick={closeModal}>
      Anuluj
     </Button>
    </>
   }
  >
   {!view.generateOrder ? (
    <Container>
     <Wrapper>
      <MessagesList
       messages={messages}
       user={user}
       setMessage={(message) => handleView('message', message)}
      />
     </Wrapper>
     <Wrapper>
      {view.message && (
       <Message
        message={view.message}
        closeMessage={() => handleView('message', null)}
       />
      )}
      {view.newMessage && <NewMessage />}
     </Wrapper>
    </Container>
   ) : (
    <GenerateOrder />
   )}
  </ModalTemplate>
 );
};

export default Messages;
