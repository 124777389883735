import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'components/DatePicker';
import {
 setOrdersFilters,
 clearOrdersFilters,
} from 'services/store/actions/orders';
import { getCustomers } from 'services/store/actions/customers';
import FlexRow from 'templates/FlexRowTemplate';

const Row = styled.div`
 display: flex;
 flex-direction: row;
 flex-wrap: no-wrap;
 button {
  margin: 0;
 }
 input,
 select {
  width: 150px;
  margin-right: 5px;
 }
 @media (max-width: 600px) {
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: 5px auto;
  button {
   width: 100%;
   margin: 5px auto;
  }
  input,
  select {
   width: 90%;
   margin: 2px auto;
  }
 }
`;

const Filters = ({ isEndedLoading }) => {
 const dispatch = useDispatch();
 const filters = useSelector(state => state.orders.filters.ended);
 const orders = useSelector(state => state.orders.endedOrders);
 const customers = useSelector(state => state.customers.customers);

 useEffect(() => {
  dispatch(
   getCustomers(
    () => {},
    () => {},
   ),
  );
 }, []);

 const handleFilter = (field, value) =>
  dispatch(setOrdersFilters('ended', field, value));
 const handleClearFilters = () => dispatch(clearOrdersFilters('ended'));

 return (
  <div>
   <Row>
    {isEndedLoading && (
     <Spinner animation="border" variant="success" style={{ marginRight: 5 }} />
    )}
    <Form.Control
     as="select"
     onChange={e => handleFilter('company', e.target.value)}
     value={filters.company}
     disabled={!orders || !customers}
    >
     <option value="">Klient</option>
     {customers &&
      customers
       .sort((a, b) => a.company.localeCompare(b.company))
       .map(item => (
        <option key={item._id} value={item._id}>
         {item.company}
         {item.firstname ? ` - ${item.firstname[0]}` : ''}
        </option>
       ))}
    </Form.Control>
    <Form.Control
     type="text"
     value={filters.number}
     onChange={e => handleFilter('number', e.target.value)}
     placeholder="Numer"
     disabled={!orders}
    />
    <Form.Control
     type="text"
     value={filters.color}
     onChange={e => handleFilter('color', e.target.value)}
     placeholder="Kolor"
     disabled={!orders}
    />
    <Form.Control
     type="text"
     value={filters.name}
     onChange={e => handleFilter('name', e.target.value)}
     placeholder="Nazwa"
     disabled={!orders}
    />
    <FlexRow justify="flex-start" buttonFormat={false}>
     <div>
      {' '}
      Od{' '}
      <DatePicker
       value={filters.dateFrom}
       onChange={date => handleFilter('dateFrom', date)}
       disabled={!orders}
      />
     </div>
     <div>
      Do{' '}
      <DatePicker
       value={filters.dateTo}
       onChange={date => handleFilter('dateTo', date)}
       disabled={!orders}
      />
     </div>
    </FlexRow>
    <Button size="sm" onClick={handleClearFilters}>
     Wyczyść
    </Button>
   </Row>
  </div>
 );
};

export default Filters;
