import React from 'react';
import withContext from 'hoc/withContext';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { dateToString } from 'services/utils/date';

const Th = styled.th`
 width: 150px;
`;

const EmployeeComments = ({ order, permissionContext }) => {
 const { employeesComments: comments } = order;
 if (permissionContext !== 'user')
  return (
   <Table responsive striped bordered hover size="sm">
    <thead>
     <tr>
      <th colSpan={3}>Uwagi pracowników:</th>
     </tr>
     <tr>
      <Th>Kiedy:</Th>
      <Th>Kto</Th>
      <th>Uwagi:</th>
     </tr>
    </thead>
    <tbody>
     {comments.map(item => {
      return (
       <tr key={item._id}>
        <td>{dateToString(item.date)}</td>
        <td>{item.position}</td>
        <td>{item.comment}</td>
       </tr>
      );
     })}
    </tbody>
   </Table>
  );
 return null;
};

export default withContext(EmployeeComments);
