import React, { useState } from 'react';
import { StyledH3 } from 'components/header';
import TextInput from 'components/FormInputs/TextInput';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';
import FlexRow from 'templates/FlexRowTemplate';
import styled from 'styled-components';
import * as yup from 'yup';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firstLetterToUpperCase } from 'services/utils/string';
import Select from 'components/FormInputs/Select';

const Wrapper = styled.div`
 max-width: 600px;
`;
const List = styled.div`
 max-height: 600px;
 width: 300px;
 overflow-y: auto;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
 color: red;
 cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
 opacity: 0.2;
 &:hover {
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
 }
`;

const PaintOrders = ({ paintOrders, handleSubmit }) => {
 const [isEdit, setIsEdit] = useState(false);
 const [newPaint, setNewPaint] = useState({ name: '', type: '' });
 const [newOther, setNewOther] = useState({ name: '', type: '' });

 const handleNewItem = (type, field, value) =>
  type === 'paint'
   ? setNewPaint({ ...newPaint, [field]: value })
   : type === 'other'
   ? setNewOther({ ...newOther, [field]: value })
   : null;

 const removeItem = (name, type, arr, arrName, setFieldValue) => {
  const newArr = arr.filter(item => item.name !== name || item.type !== type);
  setFieldValue(arrName, newArr);
 };
 const addItem = (arr, arrName, setFieldValue, item) => {
  if (!item.name || !item.type) return;
  const newArr = arr.concat(item);
  setFieldValue(arrName, newArr);
  setNewOther({ name: '', type: '' });
  setNewPaint({ name: '', type: '' });
 };
 return (
  <div>
   <Formik
    // validationSchema={schema}
    // ONSUBMIT REQUEST
    onSubmit={async values =>
     await handleSubmit(
      { paintOrders: values },
      () => setIsEdit(false),
      'Zaktualizowano',
     )
    }
    initialValues={{
     ...paintOrders,
    }}
    render={props => {
     const { values, handleSubmit, setFieldValue } = props;
     return (
      <Form noValidate onSubmit={handleSubmit}>
       <Wrapper>
        <StyledH3>Lakiery</StyledH3>
        {values.paints && values.paints.length ? (
         <>
          <List>
           <ul>
            {values.paints
             .sort((a, b) => a.name.localeCompare(b.name))
             .map(item => (
              <li key={item.name + item.type}>
               <FlexRow justify="space-between">
                {firstLetterToUpperCase(item.name)} <small>{item.type}</small>
                <RemoveIcon
                 disabled={!isEdit}
                 title="Usuń"
                 icon={faMinusCircle}
                 onClick={
                  !isEdit
                   ? null
                   : () => {
                      removeItem(
                       item.name,
                       item.type,
                       values.paints,
                       'paints',
                       setFieldValue,
                      );
                     }
                 }
                />
               </FlexRow>
              </li>
             ))}
           </ul>
          </List>
         </>
        ) : null}{' '}
        {isEdit && (
         <InputGroup style={{ width: 500 }}>
          <FormControl
           placeholder="Nazwa"
           value={newPaint.name}
           onChange={e => handleNewItem('paint', 'name', e.target.value)}
          />
          <FormControl
           placeholder="Typ"
           value={newPaint.type}
           onChange={e => handleNewItem('paint', 'type', e.target.value)}
          />
          <InputGroup.Append>
           <Button
            disabled={!newPaint.name || !newPaint.type}
            variant="outline-primary"
            onClick={() =>
             addItem(values.paints, 'paints', setFieldValue, newPaint)
            }
           >
            Dodaj
           </Button>
          </InputGroup.Append>
         </InputGroup>
        )}
        <StyledH3>Inne</StyledH3>
        {values.other && values.other.length ? (
         <>
          <List>
           <ul>
            {values.other
             .sort((a, b) => a.name.localeCompare(b.name))
             .map(item => (
              <li key={item.name + item.type}>
               <FlexRow justify="space-between">
                {firstLetterToUpperCase(item.name)} <small>{item.type}</small>
                <RemoveIcon
                 disabled={!isEdit}
                 title="Usuń"
                 icon={faMinusCircle}
                 onClick={
                  !isEdit
                   ? null
                   : () => {
                      removeItem(
                       item.name,
                       item.type,
                       values.other,
                       'other',
                       setFieldValue,
                      );
                     }
                 }
                />
               </FlexRow>
              </li>
             ))}
           </ul>
          </List>
         </>
        ) : null}{' '}
        {isEdit && (
         <InputGroup style={{ width: 500 }}>
          <FormControl
           placeholder="Nazwa"
           value={newOther.name}
           onChange={e =>
            handleNewItem('other', 'name', e.target.value, newOther)
           }
          />
          <FormControl
           placeholder="Opis"
           value={newOther.type}
           onChange={e => handleNewItem('other', 'type', e.target.value)}
          />
          <InputGroup.Append>
           <Button
            disabled={!newOther.name || !newOther.type}
            variant="outline-primary"
            onClick={() =>
             addItem(values.other, 'other', setFieldValue, newOther)
            }
           >
            Dodaj
           </Button>
          </InputGroup.Append>
         </InputGroup>
        )}
        <FlexRow justify="flex-end">
         <Button variant="success" type="submit" disabled={!isEdit}>
          Zatwierdź
         </Button>
         <Button
          variant={isEdit ? 'outline-secondary' : 'secondary'}
          onClick={() => setIsEdit(!isEdit)}
         >
          {isEdit ? 'Zablokuj edycję' : 'Edytuj'}
         </Button>
        </FlexRow>
       </Wrapper>
      </Form>
     );
    }}
   />
  </div>
 );
};

export default PaintOrders;

const schema = yup.object({
 gloss: yup.string().required('wymagane'),
 semiGloss: yup.string().required('wymagane'),
 base: yup.string().required('wymagane'),
});
