import {
 CUSTOMERS_LIST_LOAD_ERROR,
 CUSTOMERS_LIST_LOAD_SUCCESS,
 CUSTOMERS_SET_SORT,
 CUSTOMERS_SET_CUSTOMER,
 CUSTOMERS_CLEAR_CUSTOMER_DATA,
} from '../types/customers';
import setAuthToken from 'services/utils/setAuthToken';
import Axios from 'axios';

export const loadCustomersSuccess = customers => ({
 type: CUSTOMERS_LIST_LOAD_SUCCESS,
 customers,
});
export const loadCustomersError = () => ({ type: CUSTOMERS_LIST_LOAD_ERROR });
export const setSortCustomersList = (sortBy, direction) => ({
 type: CUSTOMERS_SET_SORT,
 sortBy,
 direction,
});
export const setCustomer = customer => ({
 type: CUSTOMERS_SET_CUSTOMER,
 customer,
});
export const clearCustomerData = () => ({
 type: CUSTOMERS_CLEAR_CUSTOMER_DATA,
});

export const getCustomers = (onEnd, onError) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);
 try {
  const res = await Axios.get('/api/customers/');
  const { customers } = res.data;

  if (res.status === 200) dispatch(loadCustomersSuccess(customers));
  onEnd();
 } catch (error) {
  console.log(`ERROR_CUSTOMERS_LOAD: `, error.response);
  dispatch(loadCustomersError());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};

export const getCustomerData = (
 customerId,
 onEnd,
 onError,
) => async dispatch => {
 if (localStorage.token) setAuthToken(localStorage.token);

 const res = await Axios.get(`/api/customers/${customerId}`);
 const { customer } = res.data;

 if (res.status === 200) dispatch(setCustomer(customer));
 onEnd();
 try {
 } catch (error) {
  console.log(`THUNK_CUSTOMER_DATA: `, error.response);
  dispatch(clearCustomerData());
  onError();
  if (error.response && error.response.status === 401)
   window.location.reload(true);
 }
};
