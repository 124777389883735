import React from 'react';
import { StyledH3 } from 'components/header';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { handleOther } from 'services/store/actions/paints';

const PermProductsList = ({ perms }) => {
 const dispatch = useDispatch();

 const handleInput = (type, index, value) => {
  const numbers = /^[\d|.|,]*$/;
  if (!value.match(numbers)) return;
  dispatch(handleOther(type, index, value.replace(',', '.') || ''));
 };

 if (perms)
  return (
   <div>
    <StyledH3>Lakiery</StyledH3>
    {perms.paints && (
     <List items={perms.paints} type="paints" handleInput={handleInput} />
    )}

    <StyledH3>Inne</StyledH3>
    {perms.other && (
     <List items={perms.other} type="other" handleInput={handleInput} />
    )}
   </div>
  );
 return null;
};

export default PermProductsList;

const Input = styled.input`
 width: 100px;
 border-radius: 3px;
 border: 1px solid lightgray;
 &::placeholder {
  color: #999999;
 }
`;

const List = ({ items, type, handleInput }) => {
 return (
  <Table bordered responsive="lg" striped hover size="sm">
   <thead>
    <tr>
     <th style={{ width: 40 }}>Lp</th>
     <th style={{ width: 500 }}>Nazwa</th>
     <th>Opis</th>
     <th style={{ width: 500 }}>
      Ilość <small>[kg]</small>
     </th>
    </tr>
   </thead>
   <tbody>
    {items.map((item, i) => {
     return (
      <tr key={item.name + item.type + i}>
       <td>{i + 1}</td>
       <td>{item.name}</td>
       <td>{item.type}</td>
       <td>
        <Input
         type="text"
         value={item.quantity}
         onChange={(e) => handleInput(type, i, e.target.value)}
        />
       </td>
      </tr>
     );
    })}
   </tbody>
  </Table>
 );
};
