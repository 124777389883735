import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { StyledH6 } from 'components/header';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
 setOrdersFilters,
 clearOrdersFilters,
} from 'services/store/actions/orders';
import { productionStatuses, paintTypes } from 'services/utils/const';
import { firstLetterToUpperCase } from 'services/utils/string';

const StyledWrapper = styled.div`
 display: flex;
 justify-content: space-between;
 flex-direction: row;
 flex-wrap: nowrap;
 div {
  width: 18%;
  margin: 5px;
  text-align: center;
  div {
   width: 100%;
   margin: 0;
  }
 }
 @media (max-width: 600px) {
  flex-direction: column;
  div {
   width: 100%;
   margin: 5px auto;
  }
 }
`;

const Filters = () => {
 const dispatch = useDispatch();
 const orders = useSelector(state => state.orders.productionOrders);
 const customers = useSelector(state => state.orders.ordersCustomersProduction);
 const colors = useSelector(state => state.orders.ordersColors);
 const filters = useSelector(state => state.orders.filters.production);

 const handleFilter = (name, field, value) =>
  dispatch(setOrdersFilters(name, field, value));
 const clearFilters = () => dispatch(clearOrdersFilters('production'));

 return (
  <div>
   <StyledWrapper>
    <CustomersSelect
     name="Klient"
     list={customers}
     value={filters.customer}
     handleChange={handleFilter}
     disabled={!orders}
    />
    <Select
     name="Status"
     listName="production"
     fieldName="status"
     list={productionStatuses}
     value={filters.status}
     handleChange={handleFilter}
     disabled={!orders}
    />
    <Select
     name="Kolor"
     listName="production"
     fieldName="color"
     list={colors}
     value={filters.color}
     handleChange={handleFilter}
     disabled={!orders}
    />
    <Select
     name="Matowość"
     listName="production"
     fieldName="paintType"
     list={paintTypes}
     value={filters.paintType}
     handleChange={handleFilter}
     disabled={!orders}
    />
    <Select
     name="Nazwa"
     listName="production"
     fieldName="name"
     value={filters.name}
     handleChange={handleFilter}
     disabled={!orders}
     as="input"
    />
    <Button variant="warning" onClick={clearFilters}>
     Wyczyść
    </Button>
   </StyledWrapper>
  </div>
 );
};

export default Filters;

const Option = styled.option`
 background-color: ${({ theme, status }) => {
  if (status) return theme.positions[status];
  return 'white';
 }};
 font-weight: bold;
`;

const Select = ({
 name,
 list,
 listName,
 fieldName,
 value,
 handleChange,
 disabled,
 as = 'select',
}) => (
 <div>
  <StyledH6 style={{ color: 'black' }}>{name}</StyledH6>
  <Form.Control
   as={as}
   onChange={e => handleChange(listName, fieldName, e.target.value)}
   value={value}
   disabled={disabled}
  >
   {as === 'select' ? (
    <>
     <option value="" />
     {list &&
      list
       .sort((a, b) => `${a}`.localeCompare(b))
       .map(item => (
        <Option value={item} key={item} status={item}>
         {typeof item === 'string' ? firstLetterToUpperCase(`${item}`) : item}
        </Option>
       ))}
    </>
   ) : null}
  </Form.Control>
 </div>
);
const CustomersSelect = ({ name, list, value, handleChange, disabled }) => (
 <div>
  <StyledH6 style={{ color: 'black' }}>{name}</StyledH6>
  <Form.Control
   as="select"
   onChange={e => handleChange('production', 'customer', e.target.value)}
   value={value}
   disabled={disabled}
  >
   <>
    <option value="" />
    {list &&
     list
      .sort((a, b) => `${a.company}`.localeCompare(b.company))
      .map(item => (
       <Option value={item._id} key={item._id} status={item._id}>
        {firstLetterToUpperCase(
         `${item.company}${
          item.firstname ? ` - ${item.firstname[0].toUpperCase()}` : ''
         }`,
        )}
       </Option>
      ))}
   </>
  </Form.Control>
 </div>
);
