import Axios from 'axios';
import setAuthToken from 'services/utils/setAuthToken';
import {
  ORDERS_LOAD_SUCCESS,
  ORDERS_LOAD_ERROR,
  ORDERS_PRODUCTION_LOAD_SUCCESS,
  ORDERS_PRODUCTION_LOAD_ERROR,
  ORDERS_CLEAR_LIST,
  ORDERS_SET_ENDED_FILTERS,
  ORDERS_CLEAR_FILTERS,
  ORDERS_UPDATE_ORDER_IN_LIST,
  ORDERS_SET_SORT,
  ORDERS_SET_ORDER,
  ORDERS_ORDER_LOAD_ERROR,
  ORDERS_RESET_SORT,
  ORDERS_SET_TIME_TO_PAINT,
} from '../types/orders';

export const loadOrdersSuccess = (orders, list) => ({
  type: ORDERS_LOAD_SUCCESS,
  orders,
  list,
});
export const loadOrdersError = () => ({ type: ORDERS_LOAD_ERROR });

export const loadProductionOrdersSuccess = orders => ({
  type: ORDERS_PRODUCTION_LOAD_SUCCESS,
  orders,
});
export const loadProductionOrdersError = () => ({
  type: ORDERS_PRODUCTION_LOAD_ERROR,
});

export const clearOrdersList = () => ({ type: ORDERS_CLEAR_LIST });
export const setOrder = order => ({
  type: ORDERS_SET_ORDER,
  order,
});
export const orderLoadError = () => ({ type: ORDERS_ORDER_LOAD_ERROR });
export const updateOrderInList = (order, list) => ({
  type: ORDERS_UPDATE_ORDER_IN_LIST,
  order,
  list,
});

export const setTimeToPaint = value => ({
  type: ORDERS_SET_TIME_TO_PAINT,
  value,
});

export const setOrdersFilters = (list, field, value) => ({
  type: ORDERS_SET_ENDED_FILTERS,
  list,
  field,
  value,
});
export const clearOrdersFilters = filtersName => ({
  type: ORDERS_CLEAR_FILTERS,
  filtersName,
});
export const setSortOrders = (listName, sortBy, direction) => ({
  type: ORDERS_SET_SORT,
  listName,
  sortBy,
  direction,
});
export const resetOrdersSort = listName => ({
  type: ORDERS_RESET_SORT,
  listName,
});

//    THUNK
// new
export const getOrdersByStatus = (
  statuses,
  onEnd,
  onError,
  list
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.post('/api/orders/status', { statuses });
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders, list));
    onEnd();
  } catch (error) {
    console.log(`ERROR_ORDERS_LOAD: `, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};

// ended
export const getOrdersByPickUpDate = (
  dateFrom,
  dateTo,
  onEnd,
  onError
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.post('/api/orders/pickupdate', {
      dateFrom,
      dateTo,
    });
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders, 'endedOrders'));
    onEnd();
  } catch (error) {
    console.log(`ERROR_ORDERS_LOAD: `, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};
export const getEndedOrders = (
  dateFrom,
  dateTo,
  onEnd,
  onError
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.post('/api/orders/ended', {
      dateFrom,
      dateTo,
    });
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders, 'endedOrders'));
    onEnd();
  } catch (error) {
    console.log(`ERROR_ORDERS_LOAD: `, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload();
  }
};

// production
export const getProductionOrders = (onEnd, onError) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.get('/api/orders/orders/production');
    const { orders, timeOnPosition } = res.data;

    if (res.status === 200) dispatch(loadProductionOrdersSuccess(orders));
    if (timeOnPosition) dispatch(setTimeToPaint(timeOnPosition));
    onEnd();
  } catch (error) {
    console.log(`ERROR_ORDERS_LOAD: `, error.response || error);
    dispatch(loadProductionOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};

// customer
export const getCustomerOrdersInProgress = (
  customerId,
  onEnd,
  onError
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const res = await Axios.get(
      `/api/orders/customer/${customerId}/inprogress`
    );
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders, 'newOrders'));
    onEnd();
  } catch (error) {
    console.log(`THUNK_CUSTOMER_ORDERS_LOAD`, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload();
  }
};
export const getCustomerEndedOrders = (
  customerId,
  dateFrom,
  dateTo,
  onEnd,
  onError
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const res = await Axios.post(`/api/orders/customer/${customerId}/ended`, {
      dateFrom,
      dateTo,
    });
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders, 'endedOrders'));
    onEnd();
  } catch (error) {
    console.log(`THUNK_CUSTOMER_ORDERS_LOAD`, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload();
  }
};
export const getCustomerOrders = (
  customerId,
  dateFrom,
  dateTo,
  onEnd,
  onError
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const body = { dateFrom, dateTo };
    const res = await Axios.post(`/api/orders/customer/${customerId}`, body);
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders));
    onEnd();
  } catch (error) {
    console.log(`THUNK_CUSTOMER_ORDERS_LOAD`, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};

export const getCustomerOrdersToStats = (
  customerId,
  dateFrom,
  dateTo,
  onEnd,
  onError
) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const body = { dateFrom, dateTo };
    const res = await Axios.post(
      `/api/orders/customer/tostats/${customerId}`,
      body
    );
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders));
    onEnd();
  } catch (error) {
    console.log(`THUNK_CUSTOMER_ORDERS_TO_STATS_LOAD`, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};

// to paint order
export const getOrdersWithoutPaint = (onEnd, onError) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);
  try {
    const res = await Axios.get(`/api/orders/withoutpaint/all`);
    const { orders } = res.data;

    if (res.status === 200) dispatch(loadOrdersSuccess(orders));
    onEnd();
  } catch (error) {
    console.log(`THUNK_WITHOUTPAINT_ORDERS_LOAD`, error.response);
    dispatch(loadOrdersError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};

// order
export const getOrder = (orderId, onEnd, onError) => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await Axios.get(`/api/orders/${orderId}`);
    const { order } = res.data;

    if (res.status === 200) dispatch(setOrder(order));
    onEnd();
  } catch (error) {
    console.log(`ERROR_ORDER_LOAD: `, error.response);
    dispatch(orderLoadError());
    onError();
    if (error.response && error.response.status === 401)
      window.location.reload(true);
  }
};
