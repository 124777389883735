export const getBackMillingPrice = (order, prices) => {
  const { orderType, backMilling, backMillingBy, items } = order;
  const { services } = prices;

  if (orderType.toLowerCase() === 'reklamacja (wina dillak)') return 0;
  else if (
    ['materiał klienta', 'poprawa (wina klienta)'].includes(
      orderType.toLowerCase(),
    )
  ) {
    // back millied by dillak
    if (backMillingBy === 'dillak')
      return backMilling * services.backMilling;
    else {
      //   order includes items from dillak
      if (items.some(item => item.dillakMaterial)) {
        const dillakBackMillignLength = items.reduce((length, item) => {
          if (item.dillakMaterial) {
            return (length += itemBackMillingLength(item));
          }
          return length;
        }, 0);

        return dillakBackMillignLength * services.backMilling;
      } else return 0;
    }
  }
  return backMilling * services.backMilling;
};

const itemBackMillingLength = ({
  h1L,
  h2L,
  w1L,
  w2L,
  height,
  width,
  quantity,
}) => {
  let length = 0;
  if (['nut', 'felc'].includes(h1L)) length += quantity * height * 0.001;
  if (['nut', 'felc'].includes(h2L)) length += quantity * height * 0.001;
  if (['nut', 'felc'].includes(w1L)) length += quantity * width * 0.001;
  if (['nut', 'felc'].includes(w2L)) length += quantity * width * 0.001;
  return length;
};
