import React from 'react';
import FlexRow from 'templates/FlexRowTemplate';
import { employeePositions } from 'services/utils/const';
import { Form } from 'react-bootstrap';
import { firstLetterToUpperCase } from 'services/utils/string';
import { useDispatch } from 'react-redux';
import { timetablesSetPosititon } from 'services/store/actions/timetables';
import PositionTimetable from './components/PositionTimetable';

const CardsView = ({ timetables, positions, handleModal }) => {
 const dispatch = useDispatch();

 const handleChange = (e) =>
  dispatch(timetablesSetPosititon(e.target.value, e.target.checked));
 return (
  <div>
   <FlexRow justify="space-between">
    <Positions handlePosition={handleChange} positions={positions} />
   </FlexRow>
   <hr />
   <FlexRow justify="space-between">
    {Object.keys(positions).map((key) => {
     if (positions[key])
      return (
       <PositionTimetable
        key={key}
        position={key}
        timetable={timetables[key]}
        handleModal={handleModal}
       />
      );
     return null;
    })}
   </FlexRow>
  </div>
 );
};

export default CardsView;

const Positions = ({ handlePosition, positions }) =>
 employeePositions.map((item) => (
  <Form.Group controlId={item} key={item}>
   <Form.Check>
    <Form.Check.Input
     value={item}
     onChange={handlePosition}
     checked={positions[item]}
    />
    <Form.Check.Label className={item}>
     {firstLetterToUpperCase(item)}
    </Form.Check.Label>
   </Form.Check>
  </Form.Group>
 ));
