import React from 'react';
import { Button } from 'react-bootstrap';
import ModalContainer from 'templates/ModalTemplate';
import { useDispatch } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';

const PaymentStatus = ({ order, closeModal }) => {
 const dispatch = useDispatch();
 const alert = useAlert();

 const handleSubmit = async (isPaid) => {
  dispatch(setSpinner(true));
  await updateOrder(
   'paymentstatus',
   order._id,
   { isPaid },
   (order) => {
    dispatch(setOrder(order));
    dispatch(setSpinner(false));
    alert.success('Zaktualizowano status płatności');
    closeModal();
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('błąd!');
    closeModal();
   },
  );
 };
 return (
  <ModalContainer
   title="Status płatności"
   closeModal={closeModal}
   footer={
    <>
     <Button variant="success" onClick={() => handleSubmit(true)}>
      Opłacone
     </Button>
     <Button variant="danger" onClick={() => handleSubmit(false)}>
      Nieopłacone
     </Button>
    </>
   }
  >
   <p>
    Zamówienie{' '}
    <strong>
     {order.user.company} - {order.user.firstname} - {order.number}
    </strong>{' '}
    jest:
   </p>
  </ModalContainer>
 );
};

export default PaymentStatus;
