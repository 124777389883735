import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import ListRow from './ListRow';

const Th = styled.th`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  background-color: ${({ primary, secondary, theme }) => {
    if (primary) return theme.dillakGreen;
    if (secondary) return theme.dillakWarning;
  }};
  ${({ small }) => (small ? 'font-weight:normal;font-size:12px' : '')}
`;

const ItemsList = ({ order, withOrderData, ...props }) => {
  return (
    <div>
      <Table responsive="xl" bordered hover size="sm">
        <thead>
          <tr>
            <Th width="50" rowSpan={4}>
              Lp
            </Th>
            {withOrderData && <th rowSpan={4}>Zamówienie</th>}
            <Th width="95" rowSpan={4}>
              Obróbka
            </Th>
            <Th width="95" rowSpan={4}>
              Status
            </Th>
            <Th width="50" rowSpan={4}>
              <small>Materiał Dillak</small>
            </Th>
            <Th width="95" rowSpan="4">
              Typ
            </Th>
            <th colSpan="6" style={{ width: 220 }}>
              Wysokość
            </th>
            <th colSpan="6" style={{ width: 220 }}>
              Szerokość
            </th>
            <Th width="50" rowSpan="4">
              Grubość płyty
              <br />
              <small>[mm]</small>
            </Th>
            <Th width="50" rowSpan="4">
              Ilość
            </Th>
            <th
              rowSpan="2"
              colSpan={order.transparent ? 3 : 2}
              style={{ width: 100 }}
            >
              Lakierowane strony
            </th>
            {order.isUnderGlass ? (
              <Th rowSpan="4" style={{ fontSize: 12 }}>
                Podf. pod szkło
              </Th>
            ) : null}
            {order.muntinType ? (
              <th rowSpan="2" colSpan={order.muntinType === '+' ? 2 : 1}>
                Szprosy
              </th>
            ) : null}
            <th rowSpan="4" style={{ width: 500 }}>
              Uwagi
            </th>
            <th rowSpan="2" colSpan="2" style={{ width: 100 }}>
              Powierzchnia
              <br />
              <small>
                [m<sup>2</sup>]
              </small>
            </th>
            <th width={50} rowSpan="4">
              <small>Brak</small>
            </th>
            <th width={50} rowSpan="4">
              <small>Popr.</small>
            </th>
          </tr>
          <tr>
            <Th width="50" rowSpan="2">
              wys.
              <br />
              <small>[mm]</small>
            </Th>
            <td colSpan="2" style={{ width: 100 }}>
              Uchwyt frezowany/R
            </td>
            <td colSpan="2" style={{ width: 100 }}>
              Otw. pod zaw./R
            </td>
            <Th width="20" secondary rowSpan="2" small>
              <div>Lakier 100mm po wys</div>
            </Th>
            <Th width="50" rowSpan="2">
              szer.
              <br />
              <small>[mm]</small>
            </Th>
            <td colSpan="2" style={{ width: 100 }}>
              Uchwyt frezowany/R
            </td>
            <td colSpan="2" style={{ width: 100 }}>
              Otw. pod zaw./R
            </td>
            <Th width="20" secondary rowSpan="2" small>
              <div>Lakier 100mm po szer</div>
            </Th>
          </tr>
          <tr>
            <Th width={50} primary>
              W1
            </Th>
            <Th width={50} primary>
              W2
            </Th>
            <Th width={50} secondary>
              {`W1'`}
            </Th>
            <Th width={50} secondary>
              {`W2'`}
            </Th>
            <Th width={50} primary>
              S1
            </Th>
            <Th width={50} primary>
              S2
            </Th>
            <Th width={50} secondary>
              {`S1'`}
            </Th>
            <Th width={50} secondary>
              {`S2'`}
            </Th>
            <Th width={50} primary>
              P
            </Th>
            <Th width={50} secondary>
              L
            </Th>
            {order.muntinType === 'x' && (
              <Th
                style={{ fontSize: 12 }}
                title="Ilość szprosów w kszt. X"
                width={50}
              >
                Ilość [X]
              </Th>
            )}
            {order.muntinType === '+' && (
              <>
                <Th
                  style={{ fontSize: 12 }}
                  title="Ilość szprosów PIONOWYCH"
                  width={50}
                >
                  Ilość |{' '}
                </Th>
                <Th
                  style={{ fontSize: 12 }}
                  title="Ilość szprosów POZIOMYCH"
                  width={50}
                >
                  Ilość -{' '}
                </Th>
              </>
            )}
            {order.transparent && (
              <Th
                width={50}
                secondary
                style={{ fontSize: 12, fontWeight: 'normal' }}
              >
                Bezb. 2str
              </Th>
            )}
            <Th width={50} primary>
              PL
            </Th>
            <Th width={50} secondary>
              PP
            </Th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) =>
            item !== null ? (
              <ListRow
                item={item}
                order={order}
                index={index}
                withOrderData={withOrderData}
                key={item._id}
                {...props}
              />
            ) : null,
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsList;
