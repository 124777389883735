/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
export const getStatsFromOrders = orders => {
  const obj = orders.reduce(
    (acc, order) => {
      const {
        surfaceLeft,
        surfaceRight,
        surfaceCNC,
        orderType,
        paintType,
        veneer,
        milledHandle,
        milledPartHandle,
        hingesHoles,
        price,
        ventilations,
        manHours,
        elements,
        reasonOfComplaint,
      } = order;
      const orderSurface = surfaceLeft + surfaceRight;

      //    SURFACE
      acc.summarySurface += orderSurface;
      if (orderType.toLowerCase() === 'materiał klienta')
        acc.customerMaterialSurface += orderSurface;
      acc.surfaces.cnc += surfaceCNC;
      acc.surfaces.flat += orderSurface - surfaceCNC;
      acc.surfaces.PL += surfaceRight;
      acc.surfaces.PP += surfaceLeft;
      if (paintType === 'połysk') acc.surfaces.gloss += orderSurface;
      else acc.surfaces.semiGloss += orderSurface;
      if (veneer) acc.surfaces.veneer += orderSurface;

      //    OTHERS
      acc.other.handles += milledHandle;
      acc.other.partHandles += milledPartHandle;
      acc.other.hingeHoles += hingesHoles;

      //    PRICE
      acc.price +=
        (price || 0) + (ventilations.price || 0) + (manHours.price || 0);

      //    RECLAMATIONS
      if (orderType.toLowerCase() === 'reklamacja (wina dillak)') {
        acc.reclamations.surfaces.company += orderSurface;
        acc.reclamations.elements.company += elements;
      } else if (orderType.toLowerCase() === 'poprawa (wina klienta)') {
        acc.reclamations.surfaces.customer += orderSurface;
        acc.reclamations.elements.customer += elements;
      }
      if (reasonOfComplaint) {
        if (acc.reclamations.reasons[reasonOfComplaint])
          acc.reclamations.reasons[reasonOfComplaint] += orderSurface;
        else acc.reclamations.reasons[reasonOfComplaint] = orderSurface;
      }

      return acc;
    },
    {
      surfaces: {
        cnc: 0,
        flat: 0,
        PL: 0,
        PP: 0,
        gloss: 0,
        semiGloss: 0,
        veneer: 0,
      },
      other: {
        handles: 0,
        partHandles: 0,
        hingeHoles: 0,
      },
      summarySurface: 0,
      customerMaterialSurface: 0,
      price: 0,
      reclamations: {
        surfaces: {
          company: 0,
          customer: 0,
        },
        elements: {
          company: 0,
          customer: 0,
        },
        reasons: {},
      },
      realizationTimes: {
        gloss: 0,
        semiGloss: 0,
        cnc: 0,
        veneer: 0,
      },
    }
  );

  const veneerOrders = orders.filter(item => item.veneer);
  const millingsOrders = orders.filter(item => item.millings.length);
  const glossOrders = orders.filter(
    item => !item.millings.length && item.paintType === 'połysk'
  );
  const semiGlossOrders = orders.filter(
    item => !item.millings.length && !item.paintType !== 'połysk'
  );

  obj.realizationTimes.veneer = getRealizationTime(veneerOrders);
  obj.realizationTimes.gloss = getRealizationTime(glossOrders);
  obj.realizationTimes.semiGloss = getRealizationTime(semiGlossOrders);
  obj.realizationTimes.cnc = getRealizationTime(millingsOrders);

  return obj;
};

export const getBestCustomers = orders => {
  const customers = {};

  orders.forEach(order => {
    const name = `${order.user.company} - ${order.user.firstname}`;
    const surface = order.surfaceLeft + order.surfaceRight;
    const price = order.price + order.ventilations.price + order.manHours.price;

    if (!customers[name]) {
      customers[name] = {
        customer: order.user._id,
        name,
        orders: 1,
        surface,
        price,
      };
    } else {
      customers[name].orders += 1;
      customers[name].surface += surface;
      customers[name].price += price;
    }
  });

  let bestCustomers = [];
  Object.keys(customers).forEach(key => {
    const customer = customers[key];
    bestCustomers.push({ ...customer, customerName: key });
  });

  bestCustomers = bestCustomers.sort((a, b) => b.price - a.price);
  return bestCustomers.slice(0, 5);
};

const getRealizationTime = orders => {
  const realizationTime = orders.reduce((time, order) => {
    if (order.date && order.productionFinish) {
      const dateStart = new Date(order.date);
      const dateEnd = new Date(order.productionFinish);
      const realizationTime = dateEnd.getTime() - dateStart.getTime();

      return (time += realizationTime);
    }
    return time;
  }, 0);

  const averageTime = realizationTime / orders.length / (60 * 60 * 24 * 1000);

  return Math.round(averageTime);
};
