import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import './theme/index.scss';
import 'bootstrap/scss/bootstrap.scss';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl'; // the locale you want
import { Provider } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { registerLocale } from 'react-datepicker';
import App from './App';
import store from './services/store';

registerLocale('pl', pl);

Sentry.init({
  dsn:
    'https://3a35df68d2a6403cb25f181a39ca273a@o331704.ingest.sentry.io/5626753',
});

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '10px',
  transition: transitions.FADE,
};

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById('root')
);
