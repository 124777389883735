import React, { useState } from 'react';
import Modal from 'templates/ModalTemplate';
import { Button, Table, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { productionStatuses, reasonsOfComplaint } from 'services/utils/const';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from 'services/store/actions/view';
import { updateOrder } from 'services/apiRequests/orders/update';
import { setOrder } from 'services/store/actions/orders';
import { useAlert } from 'react-alert';
import { firstLetterToUpperCase } from 'services/utils/string';
import withContext from 'hoc/withContext';

const SetElementsToCorrect = ({ order, closeModal, permissionContext }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const position = useSelector(state => state.employee.activePosition);

  const [list, setList] = useState(
    order.items.map(item => ({ _id: item._id, ...item.elementToCorrect }))
  );
  const [reasonOfComplaint, setReasonOfComplaint] = useState('');

  const handleItem = (index, key, value) => {
    const newList = [...list];
    newList[index][key] = value;
    setList(newList);
  };

  const handleSubmit = async () => {
    dispatch(setSpinner(true));
    await updateOrder(
      'tocorrect',
      order._id,
      { toCorrect: list, position: position || 'Biuro', reasonOfComplaint },
      order => {
        dispatch(setOrder(order));
        dispatch(setSpinner(false));
        alert.success('zaktualizowano elementy do poprawy');
        closeModal();
      },
      () => {
        dispatch(setSpinner(false));
        alert.error('Błąd!');
      }
    );
  };

  const isDisabled = () =>
    list.some(
      item =>
        (!item.position && item.quantity) ||
        (item.position && item.quantity === 0)
    ) ||
    (permissionContext === 'employee' && !reasonOfComplaint);

  const clearLost = () => {
    const newList = list.map(item => ({ ...item, position: '', quantity: 0 }));
    setList(newList);
  };

  return (
    <Modal
      title="Elementy do poprawy"
      closeModal={closeModal}
      size="lg"
      footer={
        <>
          {permissionContext === 'admin' && (
            <Button variant="outline-primary" onClick={clearLost}>
              Resetuj
            </Button>
          )}
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={isDisabled()}
          >
            Zatwierdź
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Anuluj
          </Button>
        </>
      }
    >
      {permissionContext === 'employee' && (
        <div style={{ textAlign: 'center' }}>
          <small>
            Jeżeli nie możesz wybrać odpowiedniego stanowiska, zgłoś się do
            biura.
          </small>
        </div>
      )}
      {permissionContext === 'employee' && (
        <OrderReasonOfComplaint
          reasonOfComplaint={reasonOfComplaint}
          setReasonOfComplaint={setReasonOfComplaint}
        />
      )}
      <Items
        items={order.items}
        list={list}
        handleItem={handleItem}
        position={position}
        permissionContext={permissionContext}
      />
    </Modal>
  );
};

export default withContext(SetElementsToCorrect);

const OrderReasonOfComplaint = ({
  reasonOfComplaint,
  setReasonOfComplaint,
}) => {
  return (
    <>
      <strong>Przyczyna poprawy</strong>
      <Form.Control
        onChange={e => setReasonOfComplaint(e.target.value)}
        value={reasonOfComplaint}
        as="select"
        multiple={false}
      >
        <option value="" />
        {reasonsOfComplaint.map(item => (
          <option key={item} value={item}>
            {firstLetterToUpperCase(item)}
          </option>
        ))}
      </Form.Control>
      <br />
    </>
  );
};

const Th = styled.th`
  width: 20px;
`;
const TightColumn = styled.th`
  width: 65px;
  background-color: ${({ side, theme }) => {
    if (side === 'left') return theme.dillakWarning;
    if (side === 'right') return theme.dillakGreen;
    return null;
  }};
`;
const PrimaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(205, 231, 169, 0.5);
`;
const SecondaryColumn = styled.td`
  vertical-align: middle;
  background-color: rgba(252, 215, 160, 0.5);
`;

const Items = props => {
  const { items } = props;
  return (
    <Table responsive="lg" hover bordered>
      <thead>
        <tr>
          <Th>LP</Th>
          <TightColumn>Wys.</TightColumn>
          <TightColumn>Szer.</TightColumn>
          <TightColumn side="right">P</TightColumn>
          <TightColumn side="left">L</TightColumn>
          <TightColumn>Ilość</TightColumn>
          <TightColumn>Stanowisko</TightColumn>
          <TightColumn>Do poprawy</TightColumn>
          <Th>
            <small>Braki</small>
          </Th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const quantityArr = [];
          for (let i = 0; i < item.quantity + 1; i++) {
            quantityArr.push(i);
          }
          return (
            <TableRow
              key={index}
              index={index}
              quantityArr={quantityArr}
              item={item}
              {...props}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

const TableRow = ({
  item,
  index,
  list,
  quantityArr,
  handleItem,
  permissionContext,
  position,
}) => {
  const isDisabled = () => {
    if (permissionContext === 'employee') {
      if (item.elementLost.position && item.elementLost.position !== position)
        return true;
    }
    return false;
  };
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{item.height}</td>
      <td>{item.width}</td>
      <PrimaryColumn>
        {item.paintRight ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </PrimaryColumn>
      <SecondaryColumn>
        {item.paintLeft ? <FontAwesomeIcon icon={faCheck} /> : ''}
      </SecondaryColumn>
      <td>{item.quantity}</td>
      <td>
        <Form.Control
          disabled={isDisabled()}
          style={{ padding: 3, textAlign: 'center' }}
          value={list[index].position}
          onChange={e => handleItem(index, 'position', e.target.value)}
          as="select"
        >
          <option value="" />
          {positionsToCorrect(position).map(item => (
            <option
              key={item}
              value={item}
              className={item}
              style={{ color: 'black' }}
            >
              {firstLetterToUpperCase(item)}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        <Form.Control
          disabled={isDisabled()}
          style={{ padding: 3, textAlign: 'center' }}
          value={list[index].quantity}
          onChange={e =>
            handleItem(index, 'quantity', parseInt(e.target.value))
          }
          as="select"
        >
          {quantityArr.map(item => (
            <option
              key={item}
              value={item}
              className={item}
              style={{ color: 'black' }}
            >
              {item}
            </option>
          ))}
        </Form.Control>
      </td>
      <td>
        {item.elementLost.position ? (
          <small className={item.elementLost.position}>
            {`${firstLetterToUpperCase(
              item.elementLost.position.slice(0, 3)
            )}_${item.elementLost.quantity}`}
          </small>
        ) : null}
      </td>
    </tr>
  );
};

const positionsToCorrect = position => {
  let arr = [];
  if (!position) return (arr = productionStatuses);
  if (position === 'szlifiernia' || position === 'lakiernia')
    arr.push('podkład');
  if (position !== 'szlifiernia') arr.push('szlifiernia');
  if (position === 'polernia') arr.push('lakiernia');
  if (position === 'pakowanie') arr.push('polernia');
  return arr;
};
