import Axios from 'axios';

export const getPaymentsByDate = async (dates, onEnd, onErr) => {
  try {
    const res = await Axios.post(`/api/payments/by-date`, dates);
    const { payments } = res.data;

    onEnd(payments);
  } catch (error) {
    console.log('ERROR_PAYMENTS_GET: ', error.response);
    onErr();
  }
};
