import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faArrowRight,
 faWindowClose,
 faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { dateToString } from 'services/utils/date';
import FlexRow from 'templates/FlexRowTemplate';
import Fade from 'react-reveal/Fade';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { setSpinner } from 'services/store/actions/view';
import { removeMessage } from 'services/apiRequests/messages/remove';
import { getAllMessages } from 'services/store/actions/messages';
import { firstLetterToUpperCase } from 'services/utils/string';
import withContext from 'hoc/withContext';

const Wrapper = styled.div`
 border: 1px solid lightgray;
 border-radius: 5px;
 padding: 5px 5px 0 5px;
`;
const Top = styled.div`
 display: flex;
 justify-content: space-between;
 font-size: 16px;
 margin-bottom: 2px;
`;
const Info = styled.span`
 font-weight: bold;
`;
const Position = styled.span`
 font-size: 12px;
 color: ${({ theme, position }) => theme.positions[position]};
 text-shadow: ${({ position }) =>
  position === 'Podkład' ? '1px 1px 3px rgb(34, 34, 34)' : 'none'};
`;
const MessageContent = styled.p`
 border-radius: 5px;
 background-color: rgb(233, 233, 233);
 padding: 10px 15px;
 margin-top: 10px;
`;
const Icon = styled(FontAwesomeIcon)`
 cursor: pointer;
 opacity: 0.5;
 &:hover {
  opacity: 1;
 }
`;

const Message = ({ message, closeMessage, permissionContext }) => {
 const dispatch = useDispatch();
 const alert = useAlert();

 const handleRemove = async () => {
  dispatch(setSpinner(true));
  await removeMessage(
   message._id,
   () => {
    dispatch(
     getAllMessages(
      () => {
       dispatch(setSpinner(false));
       closeMessage();
      },
      () => {
       dispatch(setSpinner(false));
       alert.error('Błąd!');
      },
     ),
    );
   },
   () => {
    dispatch(setSpinner(false));
    alert.error('Błąd!');
   },
  );
 };
 return (
  <Fade>
   <Wrapper>
    <FlexRow justify="space-between">
     {permissionContext === 'admin' ? (
      <Icon icon={faTrash} style={{ color: 'red' }} onClick={handleRemove} />
     ) : (
      <div />
     )}
     <Icon icon={faWindowClose} onClick={closeMessage} />
    </FlexRow>
    <Top>
     <Info>
      {message.author}
      <FontAwesomeIcon icon={faArrowRight} />{' '}
      {message.positions.map((item) => (
       <Position position={item.toLowerCase()} key={item}>
        {firstLetterToUpperCase(item)}{' '}
       </Position>
      ))}
     </Info>

     <span>{dateToString(message.addDate)}</span>
    </Top>
    <MessageContent>{message.message}</MessageContent>
   </Wrapper>
  </Fade>
 );
};

export default withContext(Message);
